<template>
    <modal-template v-model="modal">
        <div class="text-h6 text--secondary mb-2 mt-2">
            {{ form.id ? "Edit" : "Create" }} a Main Category
        </div>

        <form novalidate @submit.prevent="submit">
            <validation-observer ref="validation">
                <v-row>
                    <v-col cols="12" md="6">
                        <text-input
                            autofocus
                            name="Name"
                            label="Name"
                            required
                            rules="required"
                            v-model="form.name"
                        />
                        <text-area
                            name="Description"
                            v-model="form.description"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="mb-2">Category Image</div>
                        <SelectFileAndDisplaySingle
                            v-model="form.image"
                            max-width="300px"
                            :allowed-extensions="['.jpg', '.jpeg', '.png']"
                        />
                        <div class="mt-4"></div>
                        <text-input
                            name="Sort Order"
                            hint="Higher number will be displayed first"
                            persistent-hint
                            v-model="form.sort"
                        />
                    </v-col>
                </v-row>
            </validation-observer>

            <v-btn
                color="success"
                type="submit"
                class="mt-6"
                :loading="form.processing"
            >
                Save
            </v-btn>
        </form>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../../../../../Comps/Master/ModalTemplate.vue";
import TextInput from "../../../../../../Comps/Forms/TextInput.vue";
import SelectFileAndDisplaySingle from "../../../../../../Comps/General/SelectFileAndDisplaySingle.vue";
import TextArea from "../../../../../../Comps/Forms/TextArea.vue";

export default {
    name: "MainCategoryModal",
    components: {
        TextArea,
        SelectFileAndDisplaySingle,
        TextInput,
        ModalTemplate,
    },
    data() {
        return {
            form: this.$inertia.form({}),
            modal: false,
        };
    },

    methods: {
        edit(category) {
            this.modal = true;
            this.form = this.$inertia.form(category);
        },

        create() {
            this.modal = true;
            this.form = this.$inertia.form({
                id: "",
                name: "",
                description: "",
                image: null,
            });
        },

        async submit() {
            if (!(await this.$refs.validation.validate())) {
                return;
            }

            if (this.form.id) {
                this.form.put(
                    this.route(
                        "staff.admin.hire-site-categories.update",
                        this.form.id
                    ),
                    {
                        onSuccess: () => {
                            this.modal = false;
                        },
                    }
                );

                return;
            }

            this.form.post(
                this.route("staff.admin.hire-site-categories.store"),
                {
                    onSuccess: () => {
                        this.modal = false;
                    },
                }
            );
        },
    },
};
</script>
