<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <div class="d-flex align-center">
                <v-icon class="mr-2">{{ icon }}</v-icon>
                {{ header }}
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-divider class="mb-4"></v-divider>
            <slot />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    name: "ExpansionPanel",
    props: {
        icon: String,
        header: String,
    },
};
</script>

<style scoped></style>
