<template>
    <div>
        <accent-button
            v-if="!uploadProgress"
            color="primary"
            do-not-stretch
            @click="attachFile"
            :small="smallButton"
        >
            <v-icon>mdi-paperclip</v-icon>
            {{ label }}
        </accent-button>

        <v-progress-linear
            v-if="uploadProgress"
            v-model="uploadProgress"
            height="25"
        >
            <strong class="white--text">
                {{ Math.ceil(uploadProgress) }}%
            </strong>
        </v-progress-linear>

        <div v-if="file && !hidePreview" class="d-flex flex-wrap">
            <display-raw-file
                :key="file.url"
                class="ma-2"
                :max-height="maxHeight"
                :max-width="maxWidth"
                :width="width"
                :height="height"
                :file="file"
                @delete="deleteAttachment"
            />
        </div>

        <input
            type="file"
            ref="fileInput"
            hidden
            @change="newFileAdded"
            :accept="allowedExtensions.join(',')"
        />
    </div>
</template>

<script>
import DisplayRawFile from "./DisplayRawFile";
import FileHelpers from "../../Mixins/FileHelpers";
import AccentButton from "../Buttons/AccentButton";
import Vapor from "laravel-vapor";

export default {
    name: "SelectFileAndDisplaySingle",
    components: { AccentButton, DisplayRawFile },
    mixins: [FileHelpers],

    props: {
        /**
         * Allowed file extensions array
         * e.g - ['.jpg', '.jpeg', '.png', '.mp4']
         */
        allowedExtensions: {
            type: Array,
            default: () => [],
        },

        /**
         * The max file size in kb
         */
        maxFileSize: {
            type: Number,
            default: null,
        },

        value: {
            required: true,
        },

        maxWidth: String,
        maxHeight: String,
        width: String,
        height: String,
        smallButton: {
            type: Boolean,
            default: false,
        },

        label: {
            type: String,
            default: "Choose File",
        },
        hidePreview: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            uploadProgress: 0,
        };
    },

    methods: {
        /**
         * Click the file input
         */
        attachFile() {
            this.$refs.fileInput.click();
        },

        /**
         * A new file has been added
         * @return {Promise<void>}
         */
        async newFileAdded() {
            const file = this.$refs.fileInput.files[0];

            if (!file) {
                alert("Please ensure the file is valid");
                return;
            }

            if (!(await this.checkExtension(file))) {
                alert("Please ensure the file is a supported type");
                return;
            }

            if (!(await this.checkFileSize(file))) {
                alert("Please ensure the file is of the correct size");
                return;
            }

            const upload = await Vapor.store(file, {
                progress: (p) => {
                    this.uploadProgress = p * 100;
                },
                bucket:
                    this.$page.props.uploadBucketName ||
                    process.env.MIX_AWS_UPLOADS_BUCKET,
                visibility: "public-read",
            });

            this.file = {
                tmp: upload.key,
                hash: upload.key,
                url: upload.url.split("?")[0],
                name: file.name,
                extension: this.getExtension(file),
            };

            this.resetFileInput();
        },

        /**
         * Check that the file type is what we want
         */
        checkExtension(file) {
            if (!this.allowedExtensions.length) {
                return true;
            }

            return this.allowedExtensions.includes(
                "." + this.getExtension(file)
            );
        },

        /**
         * Check the files size
         * @param {File} file
         */
        checkFileSize(file) {
            return !this.maxFileSize || file.size / 1000 < this.maxFileSize;
        },

        /**
         * Reset the file input
         */
        resetFileInput() {
            this.$refs.fileInput.value = "";
            this.uploadProgress = 0;
        },

        /**
         * Remove the attachment from local state
         */
        deleteAttachment() {
            this.file = null;
        },
    },

    computed: {
        file: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>

<style scoped></style>
