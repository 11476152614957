<template>
    <v-sheet v-if="hasErrors" class="rounded-lg error pa-4 mb-8">
        <div>
            <div class="d-flex align-center">
                <v-icon small>mdi-alert-circle</v-icon>
                <h3 class="text-body-1 font-weight-bold text-white ml-2">
                    There {{ errorCount > 1 ? "are" : "is" }} {{ errorCount }}
                    {{ errorCount > 1 ? "errors" : "error" }} with your
                    submission
                </h3>
            </div>
            <div>
                <div class="mt-2 text-body-2">
                    <ul role="list">
                        <li v-for="(error, index) in errors" :key="index">
                            {{ error }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </v-sheet>
</template>

<script>
export default {
    name: "ErrorBanner",

    computed: {
        errors() {
            return this.$page.props.errors;
        },
        hasErrors() {
            return !!Object.values(this.errors).length;
        },
        errorCount() {
            return Object.values(this.errors).length;
        },
    },
};
</script>

<style scoped></style>
