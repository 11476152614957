export default {
    methods: {
        /**
         *
         * Takes an array of tasks to find a clash against
         *
         * @param {moment.Moment} day
         */
        getDaysHolidays(day) {
            if (!this.$page.props.approvedHolidays) {
                return [];
            }

            return this.$page.props.approvedHolidays.filter((holiday) => {
                let start = this.moment.utc(holiday.start_date);
                let end = this.moment.utc(holiday.end_date);

                if (start.isSame(day)) {
                    return true;
                }

                if (end.isSame(day)) {
                    return true;
                }

                if (start.isBefore(day) && end.isAfter(day)) {
                    return true;
                }
            });
        },
    },
};
