<template>
    <v-text-field :type="show ? 'text' : 'password'" readonly :value="password">
        <template v-slot:append>
            <v-btn
                @click="show = !show"
                v-tooltip="show ? 'Hide' : 'Show'"
                icon
            >
                <v-icon> mdi-eye </v-icon>
            </v-btn>
            <v-btn @click="copyPassword(password)" v-tooltip="'Copy'" icon>
                <v-icon> mdi-content-copy </v-icon>
            </v-btn>
        </template>
    </v-text-field>
</template>

<script>
import CopyToClipboard from "../../../../Mixins/CopyToClipboard";

export default {
    name: "PasswordItem",
    props: {
        password: String,
    },

    mixins: [CopyToClipboard],

    data() {
        return {
            show: false,
        };
    },

    methods: {
        copyPassword(password) {
            this.copy(password);
            this.$toast.open({
                message: "Password copied!",
                type: "success",
                duration: 2000,
            });
        },
    },
};
</script>

<style scoped></style>
