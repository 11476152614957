<template>
    <v-icon
        v-tooltip="{ trigger: 'hover click focus', content: category.name }"
        :color="color"
        >{{ category.icon }}</v-icon
    >
</template>

<script>
export default {
    name: "TaskCategoryIcon",
    props: {
        category: {
            type: Object,
            required: true,
        },
        color: {
            type: String,
            required: false,
        },
    },
};
</script>
