<template>
    <form novalidate @submit.prevent="updateEarningRate">
        <div class="mb-3 text-body-1" v-text="title" />
        <validation-observer ref="form">
            <v-row dense>
                <v-col cols="12" md="3" sm="6">
                    <auto-complete
                        :items="$page.props.earningRateTypes"
                        name="Pay Type"
                        v-model="earningRate.pay_type"
                        required
                        rules="required"
                    />
                </v-col>
                <v-col
                    v-if="earningRate.pay_type === 'PAYE'"
                    cols="12"
                    md="3"
                    sm="6"
                >
                    <text-input
                        v-model="earningRate.standard_hours"
                        name="Standard Hours"
                        required
                        rules="required"
                        prepend-inner-icon="mdi-clock"
                    />
                </v-col>
                <v-col
                    v-if="earningRate.pay_type === 'PAYE'"
                    cols="12"
                    md="3"
                    sm="6"
                >
                    <currency-input
                        v-model="earningRate.standard_pay"
                        name="Standard Pay"
                        label="Standard Pay (£)"
                        required
                        rules="required"
                    />
                </v-col>
                <v-col cols="12" md="3" sm="6">
                    <currency-input
                        v-model="earningRate.overtime_pay"
                        name="Overtime Pay"
                        label="Overtime Pay (£)"
                        required
                        rules="required"
                    />
                </v-col>
            </v-row>
        </validation-observer>

        <accent-button
            type="submit"
            :loading="earningRate.processing"
            :disabled="!earningRate.isDirty"
        >
            <slot name="buttonText">Save</slot>
        </accent-button>
    </form>
</template>

<script>
import AutoComplete from "../../../Forms/AutoComplete.vue";
import TextInput from "../../../Forms/TextInput.vue";
import AccentButton from "../../../Buttons/AccentButton.vue";
import CurrencyInput from "../../../General/Forms/CurrencyInput.vue";
export default {
    name: "UserEarningRateForm",
    components: { CurrencyInput, AccentButton, TextInput, AutoComplete },

    props: {
        earningRoute: {
            type: String,
            required: true,
        },
        initialRates: {
            validator: (prop) => typeof prop === "object" || prop === null,
            required: true,
        },
        title: {
            type: String,
            default: "User Earning Rates",
        },
    },

    data() {
        return {
            earningRate: this.$inertia.form(null),
        };
    },

    mounted() {
        if (this.initialRates) {
            this.earningRate = this.$inertia.form(this.initialRates);
        } else {
            this.earningRate = this.$inertia.form({
                pay_type: "PAYE",
                overtime_pay: 0,
                standard_pay: 0,
                standard_hours: null,
            });
        }
    },

    methods: {
        updateEarningRate() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.earningRate.put(this.earningRoute, {
                    onSuccess: () => {
                        this.$emit("close-modal");
                    },
                });
            });
        },
    },
};
</script>

<style scoped></style>
