<template>
    <v-app>
        <head-title title="Logistics" />
        <v-container>
            <div class="text-h5 text--secondary">
                Logistics for {{ freelancer.name }}
            </div>
            <h4 class="mb-2 text--secondary">
                Showing all tasks assigned to you or everyone
            </h4>
            <div class="d-flex align-center">
                <h3 class="text--secondary" v-if="logistic">
                    {{ momentFormatDate(thisWeek) }} - Version {{ version }}
                </h3>
                <v-fade-transition>
                    <v-icon
                        v-tooltip="'You have accepted this version'"
                        color="success"
                        large
                        class="ml-2"
                        v-if="
                            logistic.freelancers.length &&
                            logistic.freelancers[0].pivot.accepted
                        "
                    >
                        mdi-check-outline
                    </v-icon>
                </v-fade-transition>
            </div>

            <section class="mt-4">
                <v-fade-transition>
                    <v-alert
                        v-if="
                            logistic.freelancers.length &&
                            !logistic.freelancers[0].pivot.accepted
                        "
                        border="top"
                        prominent
                        colored-border
                        type="info"
                        elevation="2"
                    >
                        <v-row align="center">
                            <v-col class="grow">
                                <span v-if="logistic.version > 1"
                                    >Please accept this version of logistics to
                                    inform HR you have read and understood the
                                    changes</span
                                >
                                <span v-else
                                    >Please accept this first version of
                                    logistics to inform HR you have read and
                                    understood the current week</span
                                >
                            </v-col>
                            <v-col class="shrink">
                                <v-btn
                                    @click.prevent="accept"
                                    class="ml-auto"
                                    color="success"
                                >
                                    I Accept
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-fade-transition>
            </section>

            <section v-if="logistic">
                <logistics-calendar
                    :this-week="thisWeek"
                    :logistic="logistic"
                    freelancer
                    user
                >
                </logistics-calendar>
            </section>
            <div v-else class="mt-10 text-center">
                <h4 class="mb-3">
                    No logistics have been found for this week yet
                </h4>
            </div>
        </v-container>
    </v-app>
</template>

<script>
import LogisticsMixin from "../../Mixins/LogisticsMixin";
import HeadTitle from "../../Comps/Master/HeadTitle";
import LogisticsCalendar from "../../Comps/Staff/Logistics/LogisticsCalendar.vue";

export default {
    name: "PublicLogistics",
    components: {
        LogisticsCalendar,
        HeadTitle,
    },
    props: ["logistic", "thisWeekProp", "onlyMe", "freelancer", "acceptUrl"],
    mixins: [LogisticsMixin],
    layout: null,

    data() {
        return {
            onlyMeData: this.onlyMe,
        };
    },

    methods: {
        accept() {
            this.$inertia.put(
                this.acceptUrl,
                {},
                {
                    onSuccess: () => {
                        this.$toast.open({
                            message: "Accepted!",
                            type: "success",
                            duration: 4000,
                        });
                    },
                }
            );
        },
    },
};
</script>

<style scoped></style>
