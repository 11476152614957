<template>
    <v-container>
        <header-name>Stock Transfer</header-name>
        <stock-transfer-form
            transfer-stock-route-name="stock-transfer.transfer"
        />
    </v-container>
</template>

<script>
import ProcessesController from "../../../Mixins/ProcessesController";
import HeaderName from "../../../Comps/General/HeaderName";
import StockTransferForm from "../../../Comps/Processes/StockTransferForm";

export default {
    name: "StockTransfer",
    components: { StockTransferForm, HeaderName },
    mixins: [ProcessesController],
};
</script>

<style scoped></style>
