var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fixed"},[_c('header-name',[_vm._v("Users")]),_vm._v(" "),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"label":"Filter","items":['Active', 'Deleted'],"dense":"","outlined":""},on:{"change":_vm.updateData},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1),_vm._v(" "),(_vm.hasPermission('staffAdminUsers'))?_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"ml-auto mb-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.$refs.createUserModal.create()}}},[_vm._v("\n            Create User\n        ")])],1):_vm._e(),_vm._v(" "),_c('create-user-modal',{ref:"createUserModal"}),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":[
            { text: '', value: 'avatar' },
            { text: 'Name', value: 'name' },
            { text: 'Email', value: 'email' },
            { text: 'Status', value: 'active' },
            { text: '', value: 'options' },
        ],"search":_vm.searchString,"items":_vm.users,"no-results-text":"No Users have been found","footer-props":{ 'items-per-page-options': [10, 25, 50, -1] },"items-per-page":25},on:{"click:row":_vm.gotoUser},scopedSlots:_vm._u([{key:"item.active",fn:function({ item }){return [(item.deleted_at)?_c('span',{staticClass:"red--text"},[_vm._v("Deleted")]):_c('span',{staticClass:"green--text"},[_vm._v("Active")])]}},{key:"item.avatar",fn:function({ item }){return [_c('v-avatar',{attrs:{"size":"40px"}},[_c('v-img',{attrs:{"src":item.avatar}})],1)]}},{key:"item.options",fn:function({ item }){return [(_vm.hasPermission('staffAdminUsers'))?_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('View User'),expression:"'View User'"}],staticClass:"mr-2",attrs:{"elevation":"2","color":"primary","icon":""},on:{"click":function($event){return _vm.gotoUser(item)}}},[_c('v-icon',[_vm._v("mdi-account")])],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }