<template>
    <li v-on="$listeners" :style="[taskLength, squareCorners]">
        {{ name }} <span v-if="whenItsDone">🚀</span>
    </li>
</template>

<script>
export default {
    name: "LogisticsChartTask",

    props: {
        perSection: {
            type: Number,
            required: true,
        },
        sections: {
            type: Number,
            required: true,
        },
        starts_at: {
            type: String,
            required: true,
        },
        ends_at: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        rangeStart: {
            type: Object,
            required: true,
        },
        whenItsDone: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        taskLength() {
            let maxLength = this.maxLength;

            if (maxLength - this.sectionsFromStart < 10) {
                maxLength = this.sectionsFromStart + 10;
            }

            return {
                "grid-column": `${this.sectionsFromStart}/${maxLength}`,
                "background-color": this.color,
            };
        },

        minimumStart() {
            return this.rangeStart.isAfter(this.starts_at)
                ? this.rangeStart
                : this.starts_at;
        },

        totalSections() {
            const end = this.moment(this.ends_at);
            const duration = this.moment.duration(end.diff(this.minimumStart));
            return duration.asMinutes() / 5;
        },

        fromStart() {
            const start = this.moment(this.starts_at);
            const duration = this.moment.duration(start.diff(this.rangeStart));
            return Math.ceil(duration.asMinutes() / 5);
        },

        sectionsFromStart() {
            return this.fromStart > 0 ? this.fromStart : 1;
        },

        maxLength() {
            return Math.ceil(this.totalSections + this.sectionsFromStart);
        },

        maxSections() {
            return this.sections * this.perSection;
        },

        squareCorners() {
            const corners = {};
            if (this.fromStart < 0) {
                corners["border-top-left-radius"] = 0;
                corners["border-bottom-left-radius"] = 0;
            }

            if (this.maxLength > this.maxSections) {
                corners["border-top-right-radius"] = 0;
                corners["border-bottom-right-radius"] = 0;
            }
            return corners;
        },
    },
};
</script>

<style scoped lang="scss"></style>
