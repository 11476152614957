<template>
    <authentication-layout>
        <head-title
            do-not-append
            title="Freelancer Login | PYTCH Freelancer Portal"
        />
        <template #text> Enter your email below to get started </template>
        <error-banner />
        <notification-controller />
        <validation-observer v-slot="{ handleSubmit }" ref="form">
            <form novalidate @submit.prevent="handleSubmit(checkEmail)">
                <text-input
                    v-model="form.email"
                    label="Email Address"
                    name="email"
                    required
                    rules="required"
                />

                <accent-button
                    class="mt-2"
                    :loading="form.processing"
                    type="submit"
                >
                    Next
                </accent-button>
            </form>
        </validation-observer>
    </authentication-layout>
</template>

<script>
import AuthenticationLayout from "../../Layouts/AuthenticationLayout";
import HeadTitle from "../../Comps/Master/HeadTitle";
import AccentButton from "../../Comps/Buttons/AccentButton";
import NotificationController from "../../Comps/Master/NotificationController";
import ErrorBanner from "../../Comps/Forms/ErrorBanner";
import TextInput from "../../Comps/Forms/TextInput";

export default {
    name: "SignupForm",
    layout: null,
    components: {
        TextInput,
        ErrorBanner,
        NotificationController,
        AccentButton,
        HeadTitle,
        AuthenticationLayout,
    },

    data() {
        return {
            form: this.$inertia.form({
                email: null,
            }),
            errors: null,
        };
    },

    methods: {
        checkEmail() {
            this.form.post(this.route("freelancer.send-code"), {
                onError: (errors) => {
                    this.errors = errors;
                },
            });
        },
    },
};
</script>
