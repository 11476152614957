<script>
import { defineComponent } from "vue";
import ThingsController from "../../../../Mixins/ThingsController.js";
import HeaderName from "../../../../Comps/General/HeaderName.vue";
import SupplierCreateModal from "./Partials/SupplierCreateModal.vue";
import Permissions from "../../../../Mixins/Permissions.js";
import SupplierViewModal from "./Partials/SupplierViewModal.vue";

export default defineComponent({
    name: "SuppliersIndex",
    components: { SupplierViewModal, SupplierCreateModal, HeaderName },

    mixins: [ThingsController, Permissions],

    data() {
        return {
            form: this.$inertia.form({
                "filter[search]": "",
                page: 1,
            }),
        };
    },

    computed: {
        isAdmin() {
            return this.hasPermission("staffAdminSuppliers");
        },
    },
    methods: {
        removeSupplier(supplier) {
            if (!confirm("Are you sure you want to delete this supplier?")) {
                return;
            }
            this.$inertia.delete(
                this.route("staff.suppliers.destroy", supplier.id)
            );
        },

        filter() {
            this.form.page = 1;
            this.laodResults();
        },
        selectPage() {
            this.form.page = this.$page.props.suppliers.current_page;
            this.laodResults();
        },
        laodResults() {
            this.form.get(this.route("staff.suppliers.index"), {
                preserveState: true,
                preserveScroll: true,
                only: ["suppliers", "flash"],
            });
        },
    },
});
</script>

<template>
    <v-container class="fixed">
        <v-row>
            <v-col cols="12" md="6">
                <header-name>Approved Suppliers</header-name>
            </v-col>
            <v-col cols="12" md="6" class="text-right mb-4">
                <v-btn
                    v-if="isAdmin"
                    color="success"
                    small
                    @click="$refs.modal.create()"
                >
                    New Supplier
                </v-btn>
            </v-col>
        </v-row>

        <v-sheet elevation="2" class="pa-4 mt-8">
            <form @submit.prevent="filter">
                <v-row>
                    <v-col cols="12" class="d-flex">
                        <v-text-field
                            dense
                            outlined
                            label="Search"
                            v-model="form['filter[search]']"
                        />
                        <v-btn class="ml-2" type="submit"> Search </v-btn>
                    </v-col>
                </v-row>
            </form>
            <v-divider class="my-4" />

            <v-data-table
                disable-pagination
                hide-default-footer
                :headers="[
                    { text: 'Name', value: 'name' },
                    { text: 'Tags', value: 'supplier_tags' },
                    { text: 'Average rating', value: 'averageRating' },
                    { text: '', value: 'options' },
                ]"
                :items="$page.props.suppliers.data"
                @click:row="$refs.viewModal.view($event)"
            >
                <template v-slot:footer>
                    <v-pagination
                        v-model="$page.props.suppliers.current_page"
                        @input="selectPage"
                        :length="$page.props.suppliers.last_page"
                    />
                </template>
                <template v-slot:item.supplier_tags="{ value }">
                    <v-chip
                        v-for="tag in value"
                        :key="tag.id"
                        small
                        class="mr-1"
                        >{{ tag.name }}</v-chip
                    >
                </template>
                <template v-slot:item.averageRating="{ value }">
                    <v-rating
                        v-if="value !== 0"
                        small
                        :value="value"
                        readonly
                        style="margin-left: -8px"
                    />
                    <div v-else>No rating</div>
                </template>
                <template v-slot:item.options="{ item }">
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <div class="d-flex">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ml-auto"
                                >
                                    mdi-dots-vertical
                                </v-icon>
                            </div>
                        </template>

                        <v-list dense>
                            <v-list-item
                                @click="$refs.viewModal.view(item)"
                                link
                            >
                                <v-list-item-title class="primary--text">
                                    View
                                </v-list-item-title>
                            </v-list-item>
                            <template v-if="isAdmin">
                                <v-divider class="my-3" />
                                <v-list-item
                                    v-if="isAdmin"
                                    @click="$refs.modal.edit(item)"
                                    link
                                >
                                    <v-list-item-title class="primary--text">
                                        Edit
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="removeSupplier(item)" link>
                                    <v-list-item-title class="error--text">
                                        Delete
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-sheet>
        <supplier-create-modal ref="modal" />
        <supplier-view-modal ref="viewModal" />
    </v-container>
</template>

<style scoped></style>
