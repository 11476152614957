<template>
    <v-container class="fixed">
        <header-name>Training</header-name>

        <v-card>
            <v-card-text>
                <training-comp :trainings="trainings"> </training-comp>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import HeaderName from "../../../Comps/General/HeaderName";
import TrainingComp from "../../../Comps/Staff/Personal/TrainingComp";
import PeopleController from "../../../Mixins/PeopleController";

export default {
    name: "Training",
    components: { TrainingComp, HeaderName },
    props: ["trainings"],
    mixins: [PeopleController],
};
</script>

<style lang="scss" scoped></style>
