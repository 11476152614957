<template>
    <v-sheet elevation="2" class="pa-4">
        <div class="text-h6 text-center mb-4">Files</div>
        <hr-files-table
            :delete-route="(file) => route('staff.info.files.destroy', file.id)"
            :edit-route="(file) => route('staff.info.files.update', file.id)"
            :items="$page.props.infoFiles"
        />

        <v-divider class="my-4" />

        <user-hr-files
            v-if="hasPermission('staffAdminHr')"
            :store-route="route('staff.info.files.store')"
        />
    </v-sheet>
</template>

<script>
import HrFilesTable from "../../../../Comps/Staff/Personal/HrFilesTable";
import UserHrFiles from "../../../../Comps/Staff/Personal/UserHrFiles";
import Permissions from "../../../../Mixins/Permissions";
export default {
    name: "InfoFiles",
    components: { UserHrFiles, HrFilesTable },
    mixins: [Permissions],
};
</script>

<style scoped></style>
