<template>
    <v-row>
        <v-col
            cols="12"
            md="6"
            xl="4"
            v-for="{ key, name, fields } in $page.props.personalDetailsFields"
            :key="key"
        >
            <v-card>
                <v-card-title v-text="name" />
                <v-simple-table>
                    <tbody>
                        <tr v-for="field in fields" :key="field.key">
                            <td v-text="`${field.name}:`" />
                            <td
                                v-if="field.type === 'date'"
                                v-text="
                                    momentFormatDateYear(
                                        details[key][field.key]
                                    )
                                "
                            />
                            <td
                                v-else
                                class="pre-wrap"
                                v-text="details[key][field.key]"
                            />
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "PersonalDetailsViewer",

    props: {
        details: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped></style>
