export default {
    methods: {
        localStorageSet(key, value) {
            try {
                return localStorage.setItem(key, value);
            } catch (e) {
                return null;
            }
        },

        localStorageGet(key) {
            try {
                return localStorage.getItem(key);
            } catch (e) {
                return null;
            }
        },

        localStorageRemove(key) {
            try {
                return localStorage.removeItem(key);
            } catch (e) {
                return null;
            }
        },
    },
};
