<template>
    <modal-template v-model="show">
        <div class="text-h6 text--secondary mb-2 mt-2">
            Create a new timesheet line
        </div>

        <form novalidate @submit.prevent="submit">
            <validation-observer ref="form">
                <v-row>
                    <v-col cols="6">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Type"
                            rules="required|max:255"
                        >
                            <v-select
                                :items="lineTypes"
                                v-model="line.type"
                                label="Type"
                                dense
                                outlined
                                :error-messages="errors"
                            />
                        </validation-provider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="isShift" cols="12" md="6">
                        <date-time-picker
                            v-model="line.starts_at"
                            label="Starts At"
                            name="Starts At"
                            mode="datetime"
                            :max="new Date()"
                            :minute-increment="5"
                            required
                        />
                    </v-col>

                    <v-col v-if="isShift" cols="12" md="6">
                        <date-time-picker
                            v-model="line.ends_at"
                            label="Ends At"
                            name="Ends At"
                            mode="datetime"
                            :max="new Date()"
                            :minute-increment="5"
                            required
                        />
                    </v-col>

                    <v-col v-if="isShift" cols="12" md="6">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Minutes Break"
                            rules="required|max:255"
                        >
                            <v-text-field
                                v-model="line.minutes_break"
                                type="number"
                                :error-messages="errors"
                                label="Minutes Break"
                                dense
                                outlined
                                hint="The Number of Minutes you were on break this shift"
                                required
                                class="required"
                            >
                            </v-text-field>
                        </validation-provider>
                    </v-col>

                    <v-col cols="12" sm="6" v-if="isShift && hoursBelowMinimum">
                        <v-checkbox
                            class="mt-1"
                            v-model="minimumShift"
                            :label="`Claim Minimum Shift Time (${minimumHours} hours)`"
                            hint="Please enter the actual hours worked, the minimum hours will automatically be applied"
                            persistent-hint
                        />
                    </v-col>

                    <v-col v-if="!isShift && !isDeduction" cols="12" md="6">
                        <currency-input
                            v-model="line.amount"
                            label="Amount (£)"
                            hint="The Amount this Bonus or Commission is for"
                        />
                    </v-col>

                    <v-col v-if="isDeduction" cols="12" md="6">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Number of Hours"
                            rules="required|max:255"
                        >
                            <v-text-field
                                v-model="line.amount"
                                type="number"
                                :error-messages="errors"
                                label="Number of Hours"
                                dense
                                outlined
                                hint="The number of hours to deduct for this line"
                                required
                                class="required"
                            >
                            </v-text-field>
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6">
                        <autocomplete-search-current
                            v-model="line.company_job_id"
                            :disabled="line.warehouse"
                            :load-items="[line.job]"
                            clearable
                            dense
                            outlined
                            hide-icon
                            :required="!line.warehouse"
                            :return-object="false"
                            route-name="staff.current.search-all-live-jobs-past-three"
                        />
                    </v-col>

                    <v-col cols="12" sm="6">
                        <v-checkbox
                            class="mt-1"
                            v-model="line.warehouse"
                            label="Warehouse?"
                        />
                    </v-col>

                    <v-col cols="12">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Notes"
                            rules="max:2000"
                        >
                            <v-textarea
                                v-model="line.notes"
                                :error-messages="errors"
                                label="Notes"
                                hint="Notes about this line"
                                dense
                                outlined
                            >
                            </v-textarea>
                        </validation-provider>
                    </v-col>
                </v-row>
            </validation-observer>

            <v-btn
                color="success"
                type="submit"
                class="mt-6"
                :loading="line.processing"
            >
                Save
            </v-btn>
            <v-btn
                color="success"
                @click="submitThenMore"
                v-if="!isEditing"
                class="mt-6"
                :loading="line.processing"
            >
                Save and Add Another
            </v-btn>
        </form>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../Master/ModalTemplate.vue";
import DateTimePicker from "../../Master/DateTimePicker.vue";
import AutocompleteSearchCurrent from "../../General/AutocompleteSearchCurrent.vue";
import CurrencyInput from "../../General/Forms/CurrencyInput.vue";

export default {
    name: "TimesheetLineCreateModal",
    components: {
        CurrencyInput,
        AutocompleteSearchCurrent,
        DateTimePicker,
        ModalTemplate,
    },

    props: {
        admin: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            show: false,
            line: this.$inertia.form({}),
            addMore: false,
        };
    },

    mounted() {
        this.reset();
    },

    watch: {
        "line.warehouse"(value) {
            if (value) {
                this.line.company_job_id = null;
            }
        },
    },

    methods: {
        getBlankStartDate() {
            return this.moment().startOf("hour").toISOString();
        },

        getBlankEndDate() {
            return this.moment()
                .subtract(this.moment().minute() % 5, "minutes")
                .toISOString();
        },

        create() {
            this.show = true;
            this.reset();
        },

        edit(line) {
            this.addMore = false;
            line.warehouse = !line.company_job_id;
            if (line.company_job_id) {
                line.job = line.company_job;
            }
            this.line = this.$inertia.form(line);
            this.$refs.form?.reset();
            this.show = true;
        },

        submit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                if (this.isEditing) {
                    this.line.put(
                        this.route("staff.timesheets.timesheet-lines.update", [
                            this.$page.props.timesheet,
                            this.line,
                        ]),
                        {
                            onSuccess: () => {
                                this.show = false;
                            },
                        }
                    );

                    return;
                }

                this.line.post(
                    this.route("staff.timesheets.timesheet-lines.store", [
                        this.$page.props.timesheet,
                    ]),
                    {
                        onSuccess: this.finished,
                    }
                );
            });
        },

        finished() {
            if (this.addMore) {
                this.addMore = false;
                this.line.minutes_break = 0;
                this.line.starts_at = this.getBlankStartDate();
                this.line.ends_at = this.getBlankEndDate();
                return;
            }

            this.reset();
            this.show = false;
        },

        reset() {
            this.line = this.$inertia.form({
                type: "Shift",
                starts_at: this.getBlankStartDate(),
                ends_at: this.getBlankEndDate(),
                amount: 0,
                minutes_break: 0,
                minimum_hours: 0,
                company_job_id: null,
                warehouse: false,
                notes: "",
            });
            this.addMore = false;
            this.$refs.form?.reset();
        },

        submitThenMore() {
            this.addMore = true;
            this.submit();
        },
    },

    computed: {
        lineTypes() {
            if (this.admin) {
                return this.$page.props.lineTypes;
            }

            return this.$page.props.lineTypes.filter(
                (type) => type !== "Deduction"
            );
        },

        isEditing() {
            return this.line.id;
        },

        isShift() {
            return this.line.type === "Shift";
        },

        isDeduction() {
            return this.line.type === "Deduction";
        },

        hoursBelowMinimum() {
            return this.moment(this.line.starts_at)
                .add(this.minimumHours, "hours")
                .isSameOrAfter(this.line.ends_at);
        },

        minimumShift: {
            get() {
                return !!this.line.minimum_hours;
            },
            set(val) {
                this.line.minimum_hours = val ? this.minimumHours : 0;
            },
        },

        minimumHours() {
            return this.$page.props.timesheetOptions.minimum_shift_hours;
        },
    },
};
</script>

<style scoped></style>
