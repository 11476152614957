<template>
    <v-container class="fixed">
        <header-name>Job Reports</header-name>

        <div class="d-flex">
            <v-btn
                class="ml-auto"
                small
                @click="newReportModal = true"
                color="primary"
            >
                New Report
            </v-btn>
        </div>
        <v-sheet elevation="2" class="pa-4 mt-4">
            <v-data-table
                :items="reports"
                :headers="headers"
                :search="search"
                sort-by="created_at"
                sort-desc
            >
                <template v-slot:top>
                    <div style="max-width: 400px">
                        <v-text-field
                            outlined
                            dense
                            v-model="search"
                            label="Search..."
                        />
                    </div>
                </template>
                <template v-slot:item.created_at="{ item }">
                    {{ momentFormatDateTime(item.created_at) }}
                </template>
                <template v-slot:item.job_name="{ item }">
                    <inertia-link
                        :href="route('staff.job-report.show', item.job_id)"
                        class="intertia-link"
                    >
                        {{ item.job_name }}
                    </inertia-link>
                </template>
            </v-data-table>
        </v-sheet>

        <job-report-create-modal v-model="newReportModal" />
    </v-container>
</template>

<script>
import HeaderName from "../../../Comps/General/HeaderName";
import ModalTemplate from "../../../Comps/Master/ModalTemplate";
import JobReportCreateModal from "../../../Comps/Things/JobReportCreateModal";
import ThingsController from "../../../Mixins/ThingsController";

export default {
    name: "JobReport",
    components: { JobReportCreateModal, ModalTemplate, HeaderName },
    props: ["reports"],
    mixins: [ThingsController],

    data() {
        return {
            newReportModal: false,
            search: "",
        };
    },

    computed: {
        headers() {
            return [
                { text: "Job", value: "job_name" },
                { text: "Number", value: "job_number" },
                { text: "Created By", value: "user.name" },
                { text: "Created On", value: "created_at" },
            ];
        },
    },
};
</script>

<style scoped></style>
