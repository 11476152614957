export default {
    data() {
        return {
            selectedTab: null,
            tabGroupName: this.$options.name,
        };
    },

    mounted() {
        /**
         * On Page load, check for a previously selected tab
         */
        this.getPreviousTab();
    },
    methods: {
        /**
         * Make the selected tab active, and the others inactive
         * @param selectedTab
         */
        selectTab(selectedTab) {
            // Store in local storage
            localStorage.setItem(this.tabGroupName, selectedTab.value);
            this.selectedTab = selectedTab.value;
        },

        /**
         * Check for a previously selected tab and select it
         */
        getPreviousTab() {
            this.$nextTick(() => {
                // check that we have tabs
                if (this.tabs.length) {
                    // Check if we should select a previous tab
                    let lastTab = localStorage.getItem(this.tabGroupName);
                    if (lastTab) {
                        // find the previously selected one
                        let index = this.tabs.findIndex(
                            (tab) => tab.value == lastTab
                        );
                        // check the previously selected one is available
                        if (index !== -1) {
                            // make the previously selected tab active
                            this.selectTab(this.tabs[index]);
                            return;
                        }
                    }
                    // Select the first if we dont find one
                    this.selectTab(this.tabs[0]);
                }
            });
        },
    },
};
