<template>
    <v-app>
        <v-main>
            <slot></slot>
            <error-controller></error-controller>
            <notification-controller></notification-controller>
        </v-main>
    </v-app>
</template>

<script>
import ErrorController from "./ErrorController";
import NotificationController from "./NotificationController";
import GlobalControllerMethods from "../../Mixins/GlobalControllerMethods";

export default {
    name: "WarehouseController",
    components: {
        NotificationController,
        ErrorController,
    },

    mixins: [GlobalControllerMethods],
};
</script>
