<template>
    <div>
        <v-list-item-content>
            <v-list-item-title>
                {{ vehicle.name }} | {{ vehicle.reg }}
                <v-icon
                    v-tooltip="'Has a resource clash'"
                    v-if="hasClash"
                    color="error"
                    >mdi-close</v-icon
                >
            </v-list-item-title>
        </v-list-item-content>
    </div>
</template>

<script>
import ClashFinder from "../../../../Mixins/Logistics/ClashFinder";

export default {
    name: "TaskVehicleSelectVehicle",
    mixins: [ClashFinder],
    props: {
        vehicle: {
            type: Object,
            required: true,
        },
        task: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            hasClash: null,
        };
    },

    watch: {
        task: {
            immediate: true,
            deep: true,
            handler() {
                this.triggerFindClash();
            },
        },
    },

    computed: {
        vehiclesOtherTasks() {
            let thisTaskTime = this.moment(this.task.date).utc(true);
            return _.filter(this.$page.props.allTasks, (task) => {
                return (
                    _.find(task.vehicles, { id: this.vehicle.id }) &&
                    this.moment(task.date).isSame(thisTaskTime)
                );
            });
        },
    },

    methods: {
        triggerFindClash() {
            this.hasClash = this.findClash(
                this.vehiclesOtherTasks,
                this.task.time_from_original,
                this.task.time_to_original
            );
        },
    },
};
</script>

<style scoped></style>
