<template>
    <v-container class="fixed">
        <header-name>Surveys</header-name>

        <v-data-table
            :headers="[
                { text: 'Job Name', value: 'job_name' },
                { text: 'Client Name', value: 'client_name' },
                { text: 'Created On', value: 'created_at' },
            ]"
            :items="surveys"
            :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
            @click:row="clickRow"
            sort-by="created_at"
            sort-desc
            no-data-text="No surveys have been found"
            class="elevation-1 row-pointer"
        >
            <template v-slot:item.created_at="{ item }">
                {{ momentFormatDateTimeYear(item.created_at) }}
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import HeaderName from "../../../../Comps/General/HeaderName";
import AdminController from "../../../../Mixins/AdminController";
export default {
    name: "SurveysIndex",
    components: { HeaderName },
    mixins: [AdminController],
    props: ["surveys"],

    methods: {
        clickRow(survey) {
            this.$inertia.visit(
                this.route("staff.admin.surveys.show", survey.id)
            );
        },
    },
};
</script>

<style>
.row-pointer > .v-data-table__wrapper > table > tbody > tr {
    cursor: pointer;
}
</style>
