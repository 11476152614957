<template>
    <v-container fluid>
        <head-title title="Warehouse Dashboard" />

        <div class="text-h4 mb-4">Jobs Summary</div>
        <job-overview-columns sticky-top="48px" />
    </v-container>
</template>

<script>
import HeadTitle from "../../../Comps/Master/HeadTitle";
import JobOverviewColumns from "./Dashboard/JobOverviewColumns.vue";

export default {
    name: "WarehouseDashboard",
    components: { JobOverviewColumns, HeadTitle },
    data() {
        return {
            interval: null,
        };
    },

    mounted() {
        this.interval = setInterval(() => {
            this.getData();
        }, 60 * 1000);
    },

    beforeDestroy() {
        clearInterval(this.interval);
    },

    methods: {
        getData() {
            this.$inertia.reload({ only: ["companyJobs"] });
        },
    },
};
</script>

<style scoped></style>
