export default {
    data() {
        return {
            timeNow: this.moment(),
            timeNowInterval: null,
        };
    },

    created() {
        this.timeNowInterval = setInterval(() => {
            this.timeNow = this.moment();
        }, 1000);
    },

    beforeDestroy() {
        clearInterval(this.timeNowInterval);
    },
};
