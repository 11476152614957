<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :loading="deleteForm.processing"
        >
            <template v-slot:top>
                <v-text-field
                    outlined
                    dense
                    v-model="search"
                    label="Search..."
                />
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ momentFormatDateTimeYear(item.created_at) }}
            </template>
            <template v-slot:item.download="{ item }">
                <v-btn
                    v-if="item.file_upload"
                    icon
                    elevation="1"
                    :href="item.file_upload.url"
                    target="_blank"
                    v-tooltip="'Download'"
                    color="primary"
                    download
                >
                    <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn
                    v-if="canEdit"
                    icon
                    elevation="1"
                    @click.prevent="editFile(item)"
                    v-tooltip="'Edit'"
                    color="primary"
                    class="ml-2"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    v-if="canEdit"
                    icon
                    elevation="1"
                    @click.prevent="deleteFile(item)"
                    v-tooltip="'Delete'"
                    color="error"
                    class="ml-2"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <modal-template v-model="modal">
            <form @submit.prevent="save" novalidate>
                <validation-observer ref="form">
                    <div class="text-h6 mb-4">Edit File Name</div>
                    <validation-provider
                        name="Title"
                        v-slot="{ errors }"
                        rules="required"
                    >
                        <v-text-field
                            v-model="editForm.title"
                            outlined
                            dense
                            label="Title"
                            :error-messages="errors"
                            required
                            :loading="editForm.processing"
                        />
                    </validation-provider>
                    <v-btn
                        small
                        :loading="editForm.processing"
                        color="success"
                        type="submit"
                    >
                        Save
                    </v-btn>
                </validation-observer>
            </form>
        </modal-template>
    </div>
</template>

<script>
import Permissions from "../../../Mixins/Permissions";
import ModalTemplate from "../../Master/ModalTemplate.vue";

export default {
    name: "HrFilesTable",
    components: { ModalTemplate },

    mixins: [Permissions],

    props: {
        items: {
            type: Array,
            required: true,
        },
        user: {
            default: null,
        },
        deleteRoute: {
            default: null,
        },
        editRoute: {
            default: null,
        },
    },

    data() {
        return {
            search: "",
            headers: [
                {
                    text: "Title",
                    value: "title",
                },
                {
                    text: "Uploaded At",
                    value: "created_at",
                },
                {
                    text: "",
                    value: "download",
                    sortable: false,
                },
            ],
            deleteForm: this.$inertia.form({}),
            editForm: this.$inertia.form({}),
            modal: false,
        };
    },

    methods: {
        editFile(file) {
            this.editForm = this.$inertia.form({
                id: file.id,
                title: file.title,
            });

            this.modal = true;
        },
        deleteFile(hrFile) {
            if (!confirm(`Are you sure you want to delete ${hrFile.title}`)) {
                return;
            }

            this.deleteForm.delete(this.deleteRouteMethod(hrFile), {
                preserveScroll: true,
            });
        },

        deleteRouteMethod(hrFile) {
            if (this.deleteRoute) {
                return this.deleteRoute(hrFile);
            }

            return this.route("staff.admin.users.hrFiles.destroy", [
                this.user.id,
                hrFile.id,
            ]);
        },

        editRouteMethod(hrFile) {
            if (this.editRoute) {
                return this.editRoute(hrFile);
            }

            return this.route("staff.admin.users.hrFiles.update", [
                this.user.id,
                hrFile.id,
            ]);
        },

        async save() {
            if (!(await this.$refs.form.validate())) {
                return;
            }

            this.editForm.put(this.editRouteMethod(this.editForm), {
                preserveScroll: true,
                onSuccess: () => {
                    this.modal = false;
                },
            });
        },
    },

    computed: {
        canEdit() {
            return this.hasPermission("staffAdminHr");
        },
    },
};
</script>

<style scoped></style>
