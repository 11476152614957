<template>
    <v-data-table
        class="elevation-2 my-2"
        disable-pagination
        hide-default-footer
        disable-sort
        :items="[timesheet]"
        :headers="totalsHeaders"
    >
        <template v-slot:item.standard_pay="{ value }">
            £{{ formatMoney(value) }}
        </template>
        <template v-slot:item.totals.standard_pay="{ value }">
            £{{ formatMoney(value) }}
        </template>
        <template v-slot:item.overtime_pay="{ value }">
            £{{ formatMoney(value) }}
        </template>
        <template v-slot:item.totals.overtime_pay="{ value }">
            £{{ formatMoney(value) }}
        </template>
        <template v-slot:item.totals.total_pay="{ value }">
            £{{ formatMoney(value) }}
        </template>
        <template v-slot:item.totals.bonus_pay="{ value }">
            £{{ formatMoney(value) }}
        </template>
        <template v-slot:item.totals.commission_pay="{ value }">
            £{{ formatMoney(value) }}
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "TimesheetTotals",
    props: {
        timesheet: {
            type: Object,
            required: true,
        },
    },

    computed: {
        isPaye() {
            return this.timesheet.pay_type === "PAYE";
        },

        totalsHeaders() {
            const headers = [{ text: "Pay Type", value: "pay_type" }];

            if (this.isPaye) {
                headers.push(
                    { text: "Standard Monthly Hours", value: "standard_hours" },
                    { text: "Holiday Hours", value: "totals.holiday_hours" },
                    {
                        text: "Standard Hours Worked (Includes Holiday)",
                        value: "totals.standard_hours",
                    },
                    { text: "Standard Rate", value: "standard_pay" },
                    {
                        text: "Standard Pay Total",
                        value: "totals.standard_pay",
                    }
                );
            }

            if (!this.isPaye) {
                headers.push({
                    text: "Unpaid Holiday Hours",
                    value: "totals.holiday_deductions",
                });
            }

            headers.push(
                {
                    text: "Hours Deducted",
                    value: "totals.deducted_hours",
                },
                {
                    text: "Overtime Hours Worked",
                    value: "totals.overtime_hours",
                },
                { text: "Overtime Rate", value: "overtime_pay" },
                { text: "Overtime Pay Total", value: "totals.overtime_pay" }
            );

            if (this.isPaye) {
                headers.push(
                    { text: "Total Hours Worked", value: "totals.total_hours" },
                    { text: "Total Hourly Pay", value: "totals.total_pay" }
                );
            }

            headers.push(
                { text: "Bonus Pay", value: "totals.bonus_pay" },
                { text: "Commission Pay", value: "totals.commission_pay" }
            );

            return headers;
        },
    },
};
</script>

<style scoped></style>
