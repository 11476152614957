<template>
    <modal-template v-model="modal">
        <div class="text-h6 text--secondary mb-2 mt-2">
            {{ form.id ? "Edit" : "Create" }} Announcement
        </div>
        <form novalidate @submit.prevent="submit">
            <validation-observer ref="form">
                <v-row dense>
                    <v-col cols="12">
                        <text-input
                            name="Message"
                            v-model="form.message"
                            required
                            rules="required|max:255"
                            counter="255"
                            autofocus
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <date-time-picker
                            label="Expires At"
                            name="Expires At"
                            required
                            v-model="form.expires_at"
                            hint="When the announcement will be removed from screens"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-checkbox
                            name="Send Notifications"
                            label="Send Notifications"
                            :hint="
                                form.id
                                    ? 'via email and slack.  Notifications will not be sent again when editing the announcement.'
                                    : 'via email and slack'
                            "
                            persistent-hint
                            v-model="form.notify"
                        />
                    </v-col>
                </v-row>
            </validation-observer>

            <v-btn
                color="success"
                type="submit"
                class="mt-6"
                :loading="form.processing"
            >
                Save
            </v-btn>
        </form>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../../../../Comps/Master/ModalTemplate.vue";
import TextInput from "../../../../../Comps/Forms/TextInput.vue";
import DateTimePicker from "../../../../../Comps/Master/DateTimePicker.vue";

export default {
    name: "AnnouncementModal",
    components: { DateTimePicker, TextInput, ModalTemplate },

    data() {
        return {
            modal: false,
            form: this.$inertia.form({}),
        };
    },

    methods: {
        create() {
            this.form = this.$inertia.form({
                message: "",
                expires_at: this.moment().add(1, "day").toISOString(),
                notify: true,
            });

            this.modal = true;
        },
        edit(ann) {
            this.form = this.$inertia.form(ann);
            this.modal = true;
        },

        async submit() {
            if (!(await this.$refs.form.validate())) {
                this.$toast.error("Please check for errors", {
                    duration: 5000,
                });

                return;
            }

            if (this.form.id) {
                this.form.put(
                    this.route(
                        "staff.admin.announcements.update",
                        this.form.id
                    ),
                    {
                        onSuccess: () => {
                            this.modal = false;
                        },
                    }
                );
                return;
            }

            this.form.post(this.route("staff.admin.announcements.store"), {
                onSuccess: () => {
                    this.modal = false;
                },
            });
        },
    },
};
</script>

<style scoped></style>
