<template>
    <div>
        <modal-template ref="testref" v-model="modal" fullscreen>
            <v-toolbar dark color="primary">
                <div class="text-h6">New Job Report</div>
            </v-toolbar>
            <validation-observer ref="form">
                <form novalidate @submit.prevent="save">
                    <div v-if="modal" class="pa-6">
                        <v-row>
                            <v-col cols="12" md="6">
                                <autocomplete-search-current
                                    v-model="currentJob"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Status"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="report.status"
                                        label="Status"
                                        :error-messages="errors"
                                        class="required"
                                        required
                                        :items="[
                                            'Prep',
                                            'Build',
                                            'Show',
                                            'Derig',
                                            'Update',
                                            'Complete',
                                        ]"
                                    ></v-select>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Parking"
                                    rules="required"
                                >
                                    <v-text-field
                                        v-model="report.parking"
                                        name="Parking"
                                        :error-messages="errors"
                                        label="Parking"
                                        class="required"
                                        required
                                    >
                                    </v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Faulty Equipment"
                                    rules="required"
                                >
                                    <v-text-field
                                        v-model="report.faulty"
                                        name="Faulty Equipment"
                                        :error-messages="errors"
                                        label="Faulty Equipment"
                                        class="required"
                                        required
                                    >
                                    </v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Held Up"
                                    rules="required"
                                >
                                    <v-textarea
                                        v-model="report.held_you_up"
                                        name="Held Up"
                                        outlined
                                        :error-messages="errors"
                                        label="Held You Up"
                                        hint="Anything that held you up?"
                                        class="required"
                                        required
                                    >
                                    </v-textarea>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Comments on Prep"
                                    rules="required"
                                >
                                    <v-textarea
                                        v-model="report.prep"
                                        name="Comments on Prep"
                                        outlined
                                        :error-messages="errors"
                                        label="Comments on Prep"
                                        hint="Any comments on the prep?"
                                        class="required"
                                        required
                                    >
                                    </v-textarea>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Derig Notes"
                                    rules="required"
                                >
                                    <v-textarea
                                        v-model="report.derig"
                                        name="Derig Notes"
                                        outlined
                                        :error-messages="errors"
                                        label="Derig Notes"
                                        hint="Any notes on the derig?"
                                        class="required"
                                        required
                                    >
                                    </v-textarea>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Case Storage"
                                    rules="required"
                                >
                                    <v-textarea
                                        v-model="report.case_storage"
                                        name="Case Storage"
                                        outlined
                                        :error-messages="errors"
                                        label="Case Storage"
                                        hint="Any notes on the case storage?"
                                        class="required"
                                        required
                                    >
                                    </v-textarea>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Positive or Negative Feedback"
                                    rules="required"
                                >
                                    <v-textarea
                                        v-model="report.feedback"
                                        name="Positive or Negative Feedback"
                                        outlined
                                        :error-messages="errors"
                                        label="Positive or Negative Feedback"
                                        hint="Any positive or negative feedback onsite?"
                                        class="required"
                                        required
                                    >
                                    </v-textarea>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Follow-on notes"
                                    rules="required"
                                >
                                    <v-textarea
                                        v-model="report.agreements"
                                        name="Follow-on notes"
                                        outlined
                                        :error-messages="errors"
                                        label="Follow-on notes"
                                        hint="Any agreements with third parties or follow-on notes?"
                                        class="required"
                                        required
                                    >
                                    </v-textarea>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Full names of anyone you dealt with"
                                    rules="required"
                                >
                                    <v-textarea
                                        v-model="report.names"
                                        name="Full names of anyone you dealt with"
                                        outlined
                                        :error-messages="errors"
                                        label="Full names of anyone you dealt with"
                                        class="required"
                                        required
                                    >
                                    </v-textarea>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="General notes"
                                    rules="required"
                                >
                                    <v-textarea
                                        v-model="report.notes"
                                        name="General notes"
                                        outlined
                                        :error-messages="errors"
                                        label="General notes"
                                        hint="General notes about the job"
                                        class="required"
                                        required
                                    >
                                    </v-textarea>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <div
                            class="text-body-1 text--secondary text-center mt-4"
                        >
                            Two things we did really well
                        </div>
                        <v-row>
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="First Thing"
                                    rules="required"
                                >
                                    <v-text-field
                                        v-model="report.twothings_1"
                                        name="Thing 1"
                                        :error-messages="errors"
                                        label="First Thing"
                                        class="required"
                                        required
                                    >
                                    </v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Second Thing"
                                    rules="required"
                                >
                                    <v-text-field
                                        v-model="report.twothings_2"
                                        name="Second Thing"
                                        :error-messages="errors"
                                        label="Second Thing"
                                        class="required"
                                        required
                                    >
                                    </v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Star of the Job"
                                    rules="required"
                                >
                                    <v-text-field
                                        v-model="report.star"
                                        name="Star of the Job"
                                        :error-messages="errors"
                                        label="Star of the Job"
                                        hint="Who was the star of the job?"
                                        class="required"
                                        required
                                    >
                                    </v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-switch
                                    v-if="report.status === 'Complete'"
                                    v-model="report.send_feedback"
                                    label="Send Survey Request"
                                    hint="Send a survey request email to the client details below"
                                    persistent-hint
                                />
                            </v-col>
                        </v-row>

                        <v-row
                            v-if="
                                report.send_feedback &&
                                report.status === 'Complete'
                            "
                        >
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Client Email"
                                    rules="required|email"
                                >
                                    <v-text-field
                                        v-model="report.feedback_email"
                                        name="Client Email"
                                        :error-messages="errors"
                                        label="Client Email"
                                        hint="The email to send the survey request email to"
                                        class="required"
                                        required
                                    >
                                    </v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Client Name"
                                    rules="required"
                                >
                                    <v-text-field
                                        v-model="report.feedback_name"
                                        name="Client Name"
                                        :error-messages="errors"
                                        label="Client Name"
                                        hint="The name of the client you are sending the survey request to"
                                        class="required"
                                        required
                                    >
                                    </v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="6">
                                <v-btn
                                    class="ml-auto"
                                    :loading="loadingForm"
                                    color="success"
                                    type="submit"
                                >
                                    Save
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </form>
            </validation-observer>
        </modal-template>
    </div>
</template>

<script>
import ModalTemplate from "../Master/ModalTemplate";
import { required, email } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import AutocompleteSearchCurrent from "../General/AutocompleteSearchCurrent";

extend("required", required);
extend("email", email);

export default {
    name: "JobReportCreateModal",
    components: { AutocompleteSearchCurrent, ModalTemplate },

    data() {
        return {
            report: this.freshModel(),
            currentJob: null,
            loadingForm: false,
        };
    },

    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },

    watch: {
        currentJob(value) {
            if (!value) {
                return;
            }
            this.report.job_name = value.name;
            this.report.job_number = value.number;
            this.report.job_id = value.id;
            this.report.feedback_email = value.custom_fields.client_email;
            this.report.feedback_name = value.custom_fields.client_name;
        },
        "report.status"(value) {
            this.report.send_feedback = value === "Complete";
        },
    },

    computed: {
        modal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },

    methods: {
        freshModel() {
            return {
                send_feedback: true,
                feedback_email: "",
                feedback_name: "",
                status: "",
            };
        },

        save() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                if (
                    !this.report.job_id ||
                    !this.report.job_name ||
                    !this.report.job_number
                ) {
                    this.$toast.error("Please search for a job again", {
                        duration: 5000,
                    });
                    this.$refs.form.setErrors({
                        search: "Please search for a job again",
                    });
                    return;
                }

                this.loadingForm = true;

                this.$inertia.post(
                    this.route("staff.job-report.store"),
                    this.report,
                    {
                        onSuccess: () => {
                            this.report = this.freshModel();
                            this.$refs.form.reset();
                            this.modal = false;
                            this.currentJob = null;
                        },
                        onFinish: () => {
                            this.loadingForm = false;
                        },
                    }
                );
            });
        },
    },
};
</script>

<style scoped></style>
