<template>
    <v-container class="fixed">
        <training comp="modules"></training>

        <div class="d-flex">
            <div class="text-h6 text--secondary mb-2 mt-2">Modules</div>
            <div class="ml-auto">
                <v-btn color="success" small @click="modal = true">
                    Create Module
                </v-btn>
            </div>
        </div>

        <v-switch
            v-model="deleted"
            class="d-inline-block"
            label="Show Deleted Modules"
        ></v-switch>

        <v-data-table
            :headers="[
                { text: 'Name', value: 'name' },
                { text: 'Created By', value: 'user.name' },
                { text: '', value: 'options' },
            ]"
            :items="modules"
            no-data-text="No modules found"
            class="elevation-1"
        >
            <template v-slot:item.options="{ item }">
                <inertia-link
                    :href="route('staff.admin.training.modules.show', item)"
                    class="inertia-link"
                >
                    <v-btn
                        v-if="!item.deleted_at"
                        class="mr-2"
                        elevation="2"
                        v-tooltip="'View Module'"
                        color="primary"
                        icon
                    >
                        <v-icon>mdi-view-module</v-icon>
                    </v-btn>
                </inertia-link>
                <v-btn
                    v-if="!item.deleted_at"
                    class="mr-2"
                    elevation="2"
                    v-tooltip="'Create Session'"
                    @click="createSession(item)"
                    color="success"
                    icon
                >
                    <v-icon>mdi-school</v-icon>
                </v-btn>
                <v-btn
                    elevation="2"
                    v-tooltip="
                        item.deleted_at ? 'Restore Module' : 'Delete Module'
                    "
                    :color="item.deleted_at ? 'success' : 'error'"
                    @click="destroy(item)"
                    icon
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <!--Create Modal-->
        <modules-create-modal v-model="modal"></modules-create-modal>
    </v-container>
</template>

<script>
import ModulesCreateModal from "../../../../Comps/Staff/Training/ModulesCreateModal";
import Training from "../../../../Comps/Staff/Training/Training";
import AdminController from "../../../../Mixins/AdminController";

export default {
    name: "Modules",
    components: { Training, ModulesCreateModal },
    props: ["modules", "deletedProp"],
    mixins: [AdminController],

    data() {
        return {
            deleted: this.deletedProp,
            modal: false,
        };
    },

    watch: {
        deleted() {
            this.$inertia.visit(
                this.route("staff.admin.training.modules.index"),
                {
                    preserveState: true,
                    data: {
                        deleted: this.deleted,
                    },
                }
            );
        },
    },

    methods: {
        destroy(module) {
            if (!confirm("Are you sure?")) return false;
            this.$inertia.delete(
                this.route("staff.admin.training.modules.destroy", module.id),
                {},
                {
                    preserveState: true,
                }
            );
        },
        createSession(module) {
            localStorage.setItem("createSession", module.id);
            this.$inertia.visit(
                this.route("staff.admin.training.sessions.index")
            );
        },
    },
};
</script>

<style scoped></style>
