var debounce = require("lodash/debounce");

let store = {
    store: {
        user: {},
        metaTitle: null,
        sideMenu: [],
        sideMenuTitle: "",
        get title() {
            return this.metaTitle;
        },
        set title(value) {
            this.metaTitle = value;
        },
        vehicleNotificationToast: null,
    },
};

export default store;
