<template>
    <authentication-layout>
        <head-title
            do-not-append
            title="Freelancer Code | PYTCH Freelancer Portal"
        />
        <template #text>
            A code has been emailed to you, enter it below to continue
        </template>
        <error-banner />
        <div class="d-flex justify-center">
            <v-otp-input
                v-model="form.code"
                style="max-width: 500px"
                ref="code"
                type="number"
                :disabled="form.processing"
                @finish="submitCode"
                autocomplete="it-is-a-code"
            />
        </div>
        <accent-button type="submit"> Next </accent-button>

        <inertia-link
            :href="route('freelancer.enter-email', {}, true)"
            class="inertia-link"
        >
            <div class="grey--text mt-3">
                Having problems? Send the code again
            </div>
        </inertia-link>
    </authentication-layout>
</template>

<script>
import AuthenticationLayout from "../../Layouts/AuthenticationLayout";
import HeadTitle from "../../Comps/Master/HeadTitle";
import ErrorBanner from "../../Comps/Forms/ErrorBanner";
import AccentButton from "../../Comps/Buttons/AccentButton";
export default {
    name: "EnterCode",
    layout: null,
    components: {
        AccentButton,
        ErrorBanner,
        HeadTitle,
        AuthenticationLayout,
    },

    data() {
        return {
            form: this.$inertia.form({
                code: null,
                email: this.$page.props.email,
            }),
        };
    },

    mounted() {
        this.$nextTick(() => {
            this.$refs.code.focus();
        });
    },

    methods: {
        submitCode() {
            this.form.post(this.route("freelancer.check-code"), {
                onError: () => {
                    this.$nextTick(() => {
                        this.$refs.code.focus();
                    });
                },
            });
        },
    },
};
</script>

<style scoped></style>
