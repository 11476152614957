<template>
    <div>
        <head-title :title="$slots.default[0].text" />
        <div class="text-h5 text--secondary mb-2">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import HeadTitle from "../Master/HeadTitle";
export default {
    name: "HeaderName",
    components: { HeadTitle },
    mounted() {
        this.$root.store.title = this.$slots.default[0].text;
    },
};
</script>

<style scoped></style>
