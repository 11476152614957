<template>
    <modal-template native v-model="show">
        <v-card>
            <v-card-title>
                {{ momentFormatDate(logistic.week) }} - Version
                {{ logistic.version }}
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="mt-2 pa-6">
                <h4 class="mb-4">
                    Ready to make this version live and notify users?<br />
                    Choose which users to notify below and hit 'Make Live'
                </h4>

                <v-chip-group
                    active-class="primary--text"
                    class="mb-4"
                    :value="selectedChip"
                    column
                >
                    <v-chip @click="selectUsers(thisUsers)" label>
                        Included Users
                    </v-chip>
                    <v-chip @click="selectUsers(allUsers)" label>
                        All Users
                    </v-chip>
                    <v-chip @click="selectUsers([])" label> Clear All </v-chip>
                </v-chip-group>

                <validation-observer ref="form">
                    <validation-provider
                        v-slot="{ errors }"
                        name="Selected Users"
                        rules="required"
                    >
                        <select-multiple-users-chip-truncate
                            v-model="selectedUsers"
                            :users="allUsersSelect"
                            label="Select Users"
                            class="mt-3"
                            :errors="errors"
                        >
                        </select-multiple-users-chip-truncate>
                    </validation-provider>
                </validation-observer>

                <v-btn
                    class="success mt-5 text-center"
                    :loading="loading"
                    @click="makeLive"
                >
                    Make Live!
                </v-btn>
            </v-card-text>
        </v-card>
    </modal-template>
</template>

<script>
import SelectMultipleUsersChipTruncate from "../../General/SelectMultipleUsersChipTruncate";
import ModalTemplate from "../../Master/ModalTemplate";

export default {
    name: "MakeLiveModal",
    components: { ModalTemplate, SelectMultipleUsersChipTruncate },
    props: {
        logistic: {
            type: Object,
            required: true,
        },
        allUsers: {
            type: Array,
            required: true,
        },
        allUsersSelect: {
            type: Array,
            required: true,
        },
        value: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            selectedUsers: [],
            loading: false,
        };
    },

    mounted() {
        this.selectedUsers = this.thisUsers;
    },

    computed: {
        thisUsers() {
            return _.filter(this.allUsers, (user) => {
                return _.find(this.logistic.tasks, (task) => {
                    if (user.freelancer) {
                        return _.find(task.freelancers, { id: user.id });
                    }
                    return _.find(task.users, { id: user.id });
                });
            });
        },
        selectedChip() {
            if (!this.selectedUsers.length) {
                return 2;
            } else if (_.isEqual(this.selectedUsers, this.allUsers)) {
                return 1;
            } else if (_.isEqual(this.selectedUsers, this.thisUsers)) {
                return 0;
            } else {
                return null;
            }
        },
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },

    methods: {
        reset() {
            this.selectedUsers = [];
            this.$refs.form.reset();
            this.show = false;
        },
        extractIds(users) {
            return _.map(users, (user) => {
                return user.id;
            });
        },
        selectUsers(users) {
            this.selectedUsers = users;
        },
        makeLive() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.loading = true;

                this.$inertia.post(
                    this.route(
                        "staff.admin.logistics.live",
                        this.logistic.slug
                    ),
                    {
                        users: this.selectedUsers,
                    },
                    {
                        onSuccess: () => {
                            this.reset();
                            this.show = false;
                        },
                        onFinish: () => {
                            this.loading = false;
                        },
                    }
                );
            });
        },
    },
};
</script>

<style scoped></style>
