<template>
    <validation-provider
        v-slot="{ errors }"
        name="Search Job"
        vid="search"
        :rules="{ required: required }"
    >
        <v-autocomplete
            v-model="job"
            v-bind="$attrs"
            :error-messages="errors"
            :items="items"
            :loading="loading"
            :class="{ required: required }"
            cache-items
            :search-input.sync="search"
            item-text="name"
            hide-no-data
            item-value="id"
            :label="label"
            placeholder="Start typing to Search"
            :prepend-icon="hideIcon ? '' : 'mdi-text-box-search'"
            :return-object="returnObject"
        ></v-autocomplete>
    </validation-provider>
</template>

<script>
export default {
    name: "AutocompleteSearchCurrent",

    props: {
        value: [Number, Object],
        returnObject: {
            type: Boolean,
            default: true,
        },
        routeName: {
            type: String,
            default: "staff.current.search-all-live-jobs",
        },
        required: {
            type: Boolean,
            default: true,
        },
        hideIcon: {
            type: Boolean,
            default: false,
        },
        loadItems: {
            type: Array,
            default: () => [],
        },
        debounceTime: {
            type: Number,
            default: 500,
        },
        label: {
            type: String,
            default: "Search for a job",
        },
    },

    data() {
        return {
            items: [],
            loading: false,
            search: null,
        };
    },

    created() {
        this.items = this.loadItems;
    },

    mounted() {
        this.$watch(
            "search",
            _.debounce(function () {
                if (!this.search) {
                    return;
                }

                this.loading = true;
                this.$http
                    .get(
                        this.route(this.routeName, {
                            search: this.search,
                        })
                    )
                    .then((response) => {
                        this.items = _.sortBy(response.data, "name");
                        this.loading = false;
                    });
            }, this.debounceTime)
        );
    },

    computed: {
        job: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>

<style scoped></style>
