<template>
    <div>
        <accent-button
            v-if="!uploadProgress"
            do-not-stretch
            color="primary"
            @click="attachFile"
        >
            <v-icon>mdi-paperclip</v-icon>
            Attach File
        </accent-button>
        <v-progress-linear
            v-if="uploadProgress"
            v-model="uploadProgress"
            height="25"
        >
            <strong class="white--text">
                {{ Math.ceil(uploadProgress) }}%
            </strong>
        </v-progress-linear>

        <div class="text-h6 mt-4">Files:</div>
        <div v-if="filesToShow.length" class="d-flex flex-wrap">
            <div v-for="(file, index) in files" :key="keyToUse(file)">
                <display-raw-file
                    v-if="!file.deleted"
                    class="ma-2"
                    :max-height="maxHeight"
                    :max-width="maxWidth"
                    :width="width"
                    :height="height"
                    :file="file"
                    @delete="deleteAttachment(index)"
                />
            </div>
        </div>

        <div v-else>No files attached</div>

        <input
            type="file"
            ref="fileInput"
            hidden
            @change="newFileAdded"
            :accept="allowedExtensions"
        />
    </div>
</template>

<script>
import FileHelpers from "../../Mixins/FileHelpers";
import DisplayRawFile from "./DisplayRawFile";
import AccentButton from "../Buttons/AccentButton";
import Vapor from "laravel-vapor";
import ImageReducer from "../../Mixins/ImageReducer.js";

export default {
    name: "SelectFileAndDisplay",
    components: { AccentButton, DisplayRawFile },
    mixins: [FileHelpers, ImageReducer],

    props: {
        /**
         * Allowed file extensions array
         * e.g - ['.jpg', '.jpeg', '.png', '.mp4']
         */
        allowedExtensions: {
            type: Array,
            default: null,
        },

        /**
         * The max file size in kb
         */
        maxFileSize: {
            type: Number,
            default: null,
        },

        compressedWidth: {
            type: Number,
            default: null,
        },

        value: {
            type: Array,
            required: true,
        },

        maxWidth: String,
        maxHeight: String,
        width: String,
        height: String,
    },

    data() {
        return {
            uploadProgress: 0,
        };
    },

    methods: {
        /**
         * Click the file input
         */
        attachFile() {
            this.$refs.fileInput.click();
        },

        async compressImageSize(file) {
            if (!this.compressedWidth) {
                return file;
            }

            if (this.getFileType(this.getExtension(file)) !== "image") {
                return file;
            }

            return await this.reduceImage(file, this.compressedWidth);
        },

        /**
         * A new file has been added
         * @return {Promise<void>}
         */
        async newFileAdded() {
            const file = this.$refs.fileInput.files[0];

            if (!(await this.checkExtension(file))) {
                alert("Please ensure the file is a supported type");
                return;
            }

            if (!(await this.checkFileSize(file))) {
                alert("Please ensure the file is of the correct size");
                return;
            }

            const upload = await Vapor.store(
                await this.compressImageSize(file),
                {
                    progress: (p) => {
                        this.uploadProgress = p * 100;
                    },
                    bucket: process.env.MIX_AWS_UPLOADS_BUCKET,
                    visibility: "public-read",
                }
            );

            this.files.push({
                tmp: upload.key,
                hash: upload.key,
                url: upload.url.split("?")[0],
                name: file.name,
                extension: this.getExtension(file),
            });

            this.resetFileInput();
        },

        /**
         * Check that the file type is what we want
         */
        checkExtension(file) {
            if (!this.allowedExtensions) {
                return true;
            }

            return this.allowedExtensions.includes(
                "." + this.getExtension(file)
            );
        },
        /**
         * Check the files size
         * @param {File} file
         */
        checkFileSize(file) {
            return !this.maxFileSize || file.size / 1000 < this.maxFileSize;
        },
        /**
         * Reset the file input
         */
        resetFileInput() {
            this.$refs.fileInput.value = "";
            this.uploadProgress = 0;
        },
        /**
         * Remove the attachment from local state
         * @param {string|number} index
         */
        deleteAttachment(index) {
            let file = this.files[index];

            if (!file.id) {
                this.files.splice(index, 1);
                return;
            }

            this.$set(file, "deleted", true);
        },
        keyToUse(file) {
            return file.id ? file.id : file.hash;
        },
    },

    computed: {
        files: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        filesToShow() {
            return _.filter(this.files, (file) => !file.deleted);
        },
    },
};
</script>

<style scoped></style>
