<template>
    <v-container>
        <header-name>
            Timesheet Lines for
            <a-tag-with-external-icon
                classes="inertia-link"
                :text="job.name"
                :url="`${$page.props.currentBaseUrl}/opportunities/${job.id}`"
            />
        </header-name>

        <breadcrumbs :items="breadItems" />

        <v-data-table
            @click:row="viewSheet"
            :items="lines"
            :headers="[
                {
                    text: 'User',
                    value: 'timesheet.user.name',
                },
                {
                    text: 'Timesheet',
                    value: 'timesheet.start_date',
                },
                {
                    text: 'Type',
                    value: 'type',
                },
                {
                    text: 'Start',
                    value: 'starts_at',
                },
                {
                    text: 'End',
                    value: 'ends_at',
                },
                {
                    text: 'Hours Worked',
                    value: 'total_hours',
                },
                {
                    text: 'Minutes Break',
                    value: 'minutes_break',
                },
                {
                    text: 'Notes',
                    value: 'notes',
                },
            ]"
            class="row-pointer elevation-2"
        >
            <template v-slot:item.timesheet.start_date="{ value }">
                {{ momentFormatDateYear(value) }}
            </template>
            <template v-slot:item.starts_at="{ item, value }">
                <div v-if="item.type === 'Shift'">
                    {{ momentFormatShortDateTime(value) }}
                </div>
            </template>
            <template v-slot:item.ends_at="{ item, value }">
                <div v-if="item.type === 'Shift'">
                    {{ momentFormatShortDateTime(value) }}
                </div>
            </template>
            <template v-slot:item.total_hours="{ item }">
                <div v-if="item.type === 'Shift'">
                    {{ item.total_hours }}
                </div>
                <div
                    v-if="item.type !== 'Shift'"
                    v-text="`£ ${formatMoney(item.amount)}`"
                />
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import HeaderName from "../../../../Comps/General/HeaderName.vue";
import Breadcrumbs from "../../../../Comps/General/Breadcrumbs.vue";
import AdminController from "../../../../Mixins/AdminController.js";
import ATagWithExternalIcon from "../../../../Comps/General/ATagWithExternalIcon.vue";

export default {
    name: "ShowJob",
    components: { ATagWithExternalIcon, Breadcrumbs, HeaderName },

    mixins: [AdminController],

    methods: {
        viewSheet(line) {
            this.$inertia.visit(
                this.route("staff.admin.timesheets.show", line.timesheet)
            );
        },
    },

    computed: {
        job() {
            return this.$page.props.job;
        },
        lines() {
            return this.$page.props.lines;
        },
        breadItems() {
            return [
                {
                    text: "Timesheets",
                    disabled: false,
                    link: this.route("staff.admin.timesheets.index"),
                },
                {
                    text: `${this.job.name}`,
                    disabled: true,
                },
            ];
        },
    },
};
</script>

<style scoped></style>
