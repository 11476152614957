<template>
    <v-container class="fixed">
        <v-row class="justify-center">
            <v-col cols="12" md="11">
                <div class="d-flex">
                    <div>
                        <header-name
                            >PAT Records -
                            <span v-if="pats.length">{{
                                pats[0].asset
                            }}</span></header-name
                        >
                        <div class="text-body-1">
                            {{ pats[0].product.name }}
                        </div>
                    </div>
                    <v-btn
                        class="ml-auto"
                        color="primary"
                        small
                        @click="searchAgain"
                    >
                        Search Again
                    </v-btn>
                </div>

                <v-sheet class="pa-6 mt-4" elevation="2">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>Asset</th>
                                <th>Status</th>
                                <th>User</th>
                                <th>Timestamp</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="pat in pats">
                                <td>{{ pat.asset }}</td>
                                <td>
                                    <v-chip
                                        label
                                        small
                                        :color="
                                            pat.overall ? 'success' : 'error'
                                        "
                                    >
                                        {{ pat.overall ? "Passed" : "Failed" }}
                                    </v-chip>
                                </td>
                                <td>{{ pat.user.name }}</td>
                                <td>
                                    {{
                                        momentFormatDateTimeYear(pat.created_at)
                                    }}
                                </td>
                                <td>
                                    <div>
                                        <v-btn
                                            @click="
                                                showModal = true;
                                                assetModal = pat;
                                            "
                                            v-tooltip="'Show details'"
                                            icon
                                        >
                                            <v-icon color="primary"
                                                >mdi-information</v-icon
                                            >
                                        </v-btn>
                                        <v-btn
                                            @click="deletePat(pat)"
                                            v-tooltip="'Delete'"
                                            icon
                                        >
                                            <v-icon color="error"
                                                >mdi-delete</v-icon
                                            >
                                        </v-btn>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-sheet>
            </v-col>
        </v-row>

        <!--Information modal-->
        <modal-template v-model="showModal">
            <div v-if="assetModal">
                <div class="text-h6">Test information</div>
                <v-divider class="mb-4" />
                <div>
                    <strong>Timestamp</strong>:
                    {{ momentFormatDateTimeYear(assetModal.create_at) }}
                </div>
                <div><strong>Type</strong>: {{ assetModal.type }}</div>
                <div><strong>Class</strong>: {{ assetModal.class }}</div>
                <div>
                    <strong>Next Test</strong>:
                    {{ momentFormatDateTimeYear(assetModal.next_date) }}
                </div>
                <div>
                    <strong>Overall</strong>:
                    {{ assetModal.overall ? "Passed" : "Failed" }}
                </div>

                <div v-if="assetModal.distro">
                    <div class="my-4">
                        <div><strong>Circuits:</strong></div>
                        <v-simple-table class="elevation-1">
                            <thead>
                                <tr>
                                    <th>Circuit</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>IR (MΩ)</th>
                                    <th>EC (Ωa)</th>
                                    <th>1x</th>
                                    <th>2x</th>
                                    <th>Set mA</th>
                                    <th>Set Time</th>
                                    <th>Trip Time</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="circuit in assetModal.distro">
                                    <td>{{ circuit.number }}</td>
                                    <td>{{ circuit.type }}</td>
                                    <td>
                                        <v-chip
                                            label
                                            small
                                            :color="
                                                !circuit.failed
                                                    ? 'success'
                                                    : 'error'
                                            "
                                        >
                                            {{
                                                !circuit.failed
                                                    ? "Passed"
                                                    : "Failed"
                                            }}
                                        </v-chip>
                                    </td>
                                    <td>{{ circuit.insulation }}</td>
                                    <td>{{ circuit.earth }}</td>
                                    <td>{{ circuit.one }}</td>
                                    <td>{{ circuit.five }}</td>
                                    <td>{{ circuit.variable_ma }}</td>
                                    <td>{{ circuit.variable_time }}</td>
                                    <td>{{ circuit.variable_trip_time }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                </div>
                <div v-else>
                    <div><strong>Earth</strong>: {{ assetModal.earth }}</div>
                    <div>
                        <strong>Insulation</strong>: {{ assetModal.insulation }}
                    </div>
                    <div>
                        <strong>Leakage</strong>: {{ assetModal.leakage }}
                    </div>
                    <div>
                        <strong>Visual</strong>:
                        {{ assetModal.visual ? "Passed" : "Failed" }}
                    </div>
                    <div>
                        <strong>Polarity</strong>:
                        {{ assetModal.polarity ? "Passed" : "Failed" }}
                    </div>
                    <div>
                        <strong>Functional</strong>:
                        {{ assetModal.functional ? "Passed" : "Failed" }}
                    </div>
                </div>
                <div v-if="!assetModal.overall">
                    <strong>Description</strong>:
                    <div
                        style="white-space: pre"
                        v-text="assetModal.description"
                    ></div>
                </div>
            </div>
        </modal-template>
    </v-container>
</template>

<script>
import ProcessesController from "../../../Mixins/ProcessesController";
import HeaderName from "../../../Comps/General/HeaderName";
import ModalTemplate from "../../../Comps/Master/ModalTemplate";

export default {
    name: "PatShow",
    components: { ModalTemplate, HeaderName },
    mixins: [ProcessesController],
    props: ["pats"],

    data() {
        return {
            showModal: null,
            assetModal: null,
        };
    },

    methods: {
        searchAgain() {
            this.$inertia.visit(this.route("staff.pat.records"));
        },

        deletePat(pat) {
            if (!confirm("Are you sure you want to delete this PAT record?")) {
                return;
            }

            this.$inertia.delete(this.route("staff.pat.delete", pat));
        },
    },
};
</script>

<style scoped></style>
