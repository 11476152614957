<template>
    <v-container class="fixed">
        <div class="d-flex mb-5">
            <header-name>Categories</header-name>
            <div class="ml-auto">
                <v-btn small color="success" @click="modal = true">
                    Create Category
                </v-btn>
            </div>
        </div>

        <v-data-table
            :headers="[
                { text: 'Name', value: 'name' },
                { text: 'Colour', value: 'color' },
                { text: 'Icon', value: 'icon' },
                { text: '', value: 'options' },
            ]"
            :items="categories"
            no-data-text="No categories have been found"
            class="elevation-1"
        >
            <template v-slot:item.color="{ item }">
                <v-chip :color="item.color" dark>{{ item.color }}</v-chip>
            </template>
            <template v-slot:item.icon="{ item }">
                <v-icon>{{ item.icon }}</v-icon>
            </template>
            <template v-slot:item.options="{ item }">
                <v-btn
                    class="mr-2"
                    elevation="2"
                    v-tooltip="'Edit'"
                    color="primary"
                    @click="$refs.modal.edit(item)"
                    icon
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <!--Create Modal-->
        <categories-create-modal
            ref="modal"
            v-model="modal"
        ></categories-create-modal>
    </v-container>
</template>

<script>
import HeaderName from "../../../Comps/General/HeaderName";
import CategoriesCreateModal from "../../../Comps/Staff/CategoriesCreateModal";
import AdminController from "../../../Mixins/AdminController";

export default {
    name: "Categories",
    components: { CategoriesCreateModal, HeaderName },
    props: ["categories"],
    mixins: [AdminController],

    data() {
        return {
            modal: false,
        };
    },

    methods: {},
};
</script>

<style scoped></style>
