var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal-template',{attrs:{"native":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v("\n            Stats - "+_vm._s(_vm.momentFormatDate(_vm.logistic.week))+" - Version\n            "+_vm._s(_vm.logistic.version)+"\n        ")]),_vm._v(" "),_c('v-alert',{attrs:{"type":"info","elevation":"2"}},[_vm._v("\n            The below table shows all staff that have an assigned task in\n            this version of logistics and their status\n        ")]),_vm._v(" "),_c('v-card-text',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Staff Member")]),_vm._v(" "),_c('th',[_vm._v("Viewed")]),_vm._v(" "),_c('th',[_vm._v("Accepted")])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.allStaff),function(user,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(user.name))]),_vm._v(" "),_c('td',[_vm._v("\n                            "+_vm._s(user.pivot.viewed
                                    ? _vm.momentFormatDateTime(
                                          user.pivot.viewed
                                      )
                                    : "N/A")+"\n                        ")]),_vm._v(" "),_c('td',[_vm._v("\n                            "+_vm._s(user.pivot.accepted
                                    ? _vm.momentFormatDateTime(
                                          user.pivot.accepted
                                      )
                                    : "N/A")+"\n                        ")])])}),0)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }