<template>
    <v-autocomplete
        required
        v-model="selected"
        :items="users"
        return-object
        :error-messages="errors"
        item-text="name"
        :item-value="(item) => item.id + item.name"
        :label="label"
        v-on="$listeners"
        multiple
    >
        <template v-slot:selection="{ item, index }">
            <div v-if="!truncate">
                <v-chip v-if="index < 3">
                    <span v-if="item.freelancer">{{ item.name }} (F)</span>
                    <span v-else>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 3" class="grey--text caption">
                    (+{{ selected.length - 3 }} others)
                </span>
            </div>
            <div v-else>
                <span class="grey--text caption" v-if="index === 0">
                    {{ selected.length }} selected
                </span>
            </div>
        </template>
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: "SelectMultipleUsersChipTruncate",
    props: {
        value: {
            type: Array,
            required: true,
        },
        users: {
            type: Array,
            required: true,
        },
        errors: {
            type: Array,
            required: true,
        },
        truncate: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>

<style scoped></style>
