<template>
    <v-container>
        <header-name>Timesheets</header-name>

        <v-alert v-if="!hasEarningRate" color="orange" type="warning">
            You do not have Timesheets setup, please speak to HR
        </v-alert>

        <v-sheet elevation="2">
            <timesheet-table :items="$page.props.timesheets" />
        </v-sheet>

        <div class="mt-8">Earning Rate Details</div>
        <v-sheet elevation="2" v-if="hasEarningRate">
            <v-simple-table class="elevation-2 my-2">
                <thead>
                    <tr>
                        <th>Pay Type</th>
                        <th>Standard Monthly Hours</th>
                        <th>Standard Rate</th>
                        <th>Overtime Rate</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{ earningRate.pay_type }}
                        </td>
                        <td>
                            {{ earningRate.standard_hours }}
                        </td>

                        <td>£{{ formatMoney(earningRate.standard_pay) }}</td>

                        <td>£{{ formatMoney(earningRate.overtime_pay) }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-sheet>
    </v-container>
</template>

<script>
import PeopleController from "../../../Mixins/PeopleController.js";
import HeaderName from "../../../Comps/General/HeaderName.vue";
import TimesheetTable from "../../../Comps/Staff/Timesheets/TimesheetTable.vue";

export default {
    name: "Timesheets",
    components: { TimesheetTable, HeaderName },
    mixins: [PeopleController],

    computed: {
        hasEarningRate() {
            return !!this.earningRate;
        },

        earningRate() {
            return this.$page.props.earningRate;
        },
    },
};
</script>

<style scoped></style>
