<template>
    <modal-template small v-model="show">
        <div class="text-h6 text--secondary mb-2 mt-1">
            <span v-if="!edit">Create a new vehicle</span>
            <span v-else>Edit {{ edit.name }}</span>
        </div>

        <form>
            <validation-observer ref="form">
                <v-row>
                    <v-col cols="12">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Name"
                            rules="required|max:255"
                        >
                            <v-text-field
                                v-model="data.name"
                                :counter="255"
                                autofocus
                                :error-messages="errors"
                                label="Name"
                                required
                            >
                            </v-text-field>
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Registration Number"
                            rules="required|max:50"
                        >
                            <v-text-field
                                v-model="data.reg"
                                class="uppercase"
                                :counter="50"
                                :error-messages="errors"
                                label="Registration Number"
                                required
                            >
                            </v-text-field>
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-switch
                            v-model="data.hired"
                            class="d-inline-block"
                            label="Hired Vehicle?"
                        ></v-switch>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-btn
                            color="success"
                            @click.prevent="submit"
                            type="submit"
                            :loading="loading"
                        >
                            {{ edit ? "Update" : "Create" }}
                        </v-btn>
                    </v-col>
                </v-row>
            </validation-observer>
        </form>
    </modal-template>
</template>

<script>
import { required, email, max, numeric } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import ModalTemplate from "../../Master/ModalTemplate";

extend("required", required);
extend("max", max);
extend("email", email);
extend("numeric", numeric);

export default {
    name: "VehiclesCreateModal",
    components: { ModalTemplate },
    props: {
        edit: {
            type: Object,
            default: null,
        },
        value: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            data: this.newData(),
            loading: false,
        };
    },

    watch: {
        show(value) {
            if (value) {
                if (this.edit) {
                    this.data = this.edit;
                }
            }
        },
        "data.reg"(value) {
            this.data.reg = _.replace(_.toUpper(value), " ", "");
        },
    },

    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },

    methods: {
        reset() {
            this.data = this.newData();
            this.$refs.form.reset();
        },

        newData() {
            return {
                hired: false,
            };
        },

        submit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.loading = true;

                let url;
                let type;
                if (this.edit) {
                    url = this.route(
                        "staff.admin.vehicles.update",
                        this.edit.id
                    );
                    type = "put";
                } else {
                    url = this.route("staff.admin.vehicles.store");
                    type = "post";
                }

                this.$inertia.visit(url, {
                    data: this.data,
                    method: type,
                    preserveState: true,
                    onSuccess: () => {
                        this.show = false;
                        this.reset();
                    },
                    onFinish: () => {
                        this.loading = false;
                    },
                });
            });
        },
    },
};
</script>

<style>
.uppercase input {
    text-transform: uppercase !important;
}
</style>
