<template>
    <v-container>
        <header-name>Asset Swap</header-name>
        <v-sheet elevation="2" class="pa-4">
            <div class="text-subtitle-2">
                Scan the current asset and the new asset to swap them
            </div>
            <div class="text-caption text--secondary mb-4">
                This is used for swapping an asset label on a cable or piece of
                equipment.
            </div>
            <form novalidate @submit.prevent="swap">
                <validation-observer ref="form">
                    <validation-provider
                        v-slot="{ errors }"
                        name="Current Asset"
                        rules="required"
                    >
                        <v-text-field
                            v-model="form.old_asset_number"
                            ref="oldAssetNumber"
                            dense
                            outlined
                            name="Current Asset"
                            autofocus
                            :error-messages="errors"
                            label="Current Asset"
                            class="required"
                            @keydown.prevent.enter="$refs.assetNumber.focus()"
                            required
                        >
                        </v-text-field>
                    </validation-provider>
                    <validation-provider
                        v-slot="{ errors }"
                        name="New Asset"
                        rules="required"
                    >
                        <v-text-field
                            v-model="form.asset_number"
                            ref="assetNumber"
                            dense
                            outlined
                            name="New Asset"
                            :error-messages="errors"
                            label="New Asset"
                            class="required"
                            required
                        >
                        </v-text-field>
                    </validation-provider>
                </validation-observer>

                <v-btn color="success" type="submit" :loading="form.processing">
                    Swap
                </v-btn>
            </form>
        </v-sheet>
    </v-container>
</template>

<script>
import HeaderName from "../../../Comps/General/HeaderName";
import ProcessesController from "../../../Mixins/ProcessesController";
export default {
    name: "AssetSwap",
    components: { HeaderName },
    mixins: [ProcessesController],

    data() {
        return {
            form: this.$inertia.form({
                asset_number: null,
                old_asset_number: null,
            }),
        };
    },

    methods: {
        swap() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.form.post(this.route("asset-swap.update"), {
                    onSuccess: () => {
                        this.$refs.oldAssetNumber.focus();
                        this.form.reset();
                        this.$refs.form?.reset();
                    },
                    onError: () => {
                        this.form.reset("asset_number");
                        this.$refs.assetNumber.focus();
                    },
                });
            });
        },
    },
};
</script>

<style scoped></style>
