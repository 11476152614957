<script>
import { defineComponent } from "vue";
import ModalTemplate from "../../../../../Comps/Master/ModalTemplate.vue";
import TextArea from "../../../../../Comps/Forms/TextArea.vue";
import { min_value, max_value } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import Permissions from "../../../../../Mixins/Permissions";

extend("min_value", min_value);
extend("max_value", max_value);

export default defineComponent({
    name: "SupplierViewModal",
    components: { TextArea, ModalTemplate },
    mixins: [Permissions],
    data() {
        return {
            supplier: { tags: [] },
            modal: false,
            form: this.$inertia.form({
                comment: "",
                rating: 0,
            }),
        };
    },

    methods: {
        view(supplier) {
            this.modal = true;
            this.setSupplier(supplier);
        },
        getSupplier(supplierId) {
            return this.$page.props.suppliers.data.findIndex(
                (supplier) => supplier.id === supplierId
            );
        },
        setSupplier(supplier) {
            this.supplier =
                this.$page.props.suppliers.data[this.getSupplier(supplier.id)];
        },
        async submitNote() {
            if (!(await this.$refs.validation.validate())) {
                return;
            }

            this.form.post(
                this.route("staff.suppliers.notes.store", this.supplier),
                {
                    onSuccess: () => {
                        this.form.reset();
                        this.$refs.validation.reset();
                        this.setSupplier(this.supplier);
                    },
                }
            );
        },
        deleteNote(note) {
            if (!confirm(`Are you sure you want to delete this note?`)) {
                return;
            }

            this.$inertia.delete(
                this.route("staff.suppliers.notes.destroy", [
                    this.supplier,
                    note,
                ]),
                {
                    onSuccess: () => {
                        this.setSupplier(this.supplier);
                    },
                }
            );
        },
        canDeleteNote(note) {
            if (this.hasPermission("Super Admin")) {
                return true;
            }
            return note.user.id === this.$page.props.auth.user.id;
        },
    },
});
</script>

<template>
    <modal-template v-model="modal">
        <div class="text-h6 text--secondary mb-2 mt-2">
            {{ supplier.name }}
        </div>

        <v-sheet class="pa-4">
            <v-row>
                <v-col cols="12">
                    <div class="text-caption text--secondary">Xero Contact</div>
                    <div class="d-flex mt-1 mb-4">
                        <v-sheet class="d-flex">
                            <a
                                :href="`https://go.xero.com/Contacts/View/${supplier.xero_id}`"
                                target="_blank"
                                class="h2"
                            >
                                {{ supplier.name }}
                                <v-icon x-small>mdi-launch</v-icon>
                            </a>
                        </v-sheet>
                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="text-caption text--secondary">Company Name</div>
                    <div class="d-flex mt-1 mb-4">
                        {{ supplier.name }}
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="text-caption text--secondary">Contact Name</div>
                    <div class="d-flex mt-1 mb-4">
                        {{ supplier.contact_name }}
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="text-caption text--secondary">Email</div>
                    <a
                        :href="`mailto:${supplier.email}`"
                        class="d-flex mt-1 mb-4"
                    >
                        {{ supplier.email }}
                    </a>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="text-caption text--secondary">Phone</div>
                    <a :href="`tel:${supplier.phone}`" class="d-flex mt-1 mb-4">
                        {{ supplier.phone }}
                    </a>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="text-caption text--secondary">Website</div>
                    <a
                        :href="supplier.website"
                        target="_blank"
                        class="d-flex mt-1 mb-4"
                    >
                        {{ supplier.website }}
                    </a>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <div class="text-caption text--secondary">
                        Street Address
                    </div>
                    <div class="d-flex mt-1 mb-4">
                        {{ supplier.street }}
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="text-caption text--secondary">City</div>
                    <div class="d-flex mt-1 mb-4">
                        {{ supplier.city }}
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="text-caption text--secondary">Postcode</div>
                    <div class="d-flex mt-1 mb-4">
                        {{ supplier.postcode }}
                    </div>
                </v-col>
            </v-row>

            <v-divider class="my-6" />

            <v-row>
                <v-col cols="12">
                    <div class="text-caption text--secondary">Tags</div>
                    <v-chip
                        v-for="tag in supplier.supplier_tags"
                        :key="tag.id"
                        class="mr-1"
                        small
                        >{{ tag.name }}
                    </v-chip>
                </v-col>
                <v-col cols="12">
                    <div class="text-caption text--secondary">Description</div>
                    <div
                        class="d-flex mt-1 mb-4 text-pre-wrap"
                        v-text="supplier.notes"
                    />
                </v-col>
            </v-row>

            <v-divider class="my-6" />

            <div class="mb-6 font-weight-bold text-decoration-underline">
                Notes
            </div>

            <template v-if="supplier?.supplier_notes?.length">
                <v-divider class="mb-4" />
                <div
                    v-for="note in supplier.supplier_notes"
                    :key="note.id"
                    class="d-flex flex-column"
                >
                    <div class="mb-4" style="position: relative">
                        <div class="d-flex" style="word-break: break-all">
                            <div>
                                <div class="caption grey--text text--darken-1">
                                    <div>Created by: {{ note.user.name }}</div>
                                    <div>
                                        Created on:
                                        {{
                                            momentFormatDateTimeYear(
                                                note.created_at
                                            )
                                        }}
                                    </div>
                                </div>
                                <v-rating
                                    style="margin-left: -8px"
                                    readonly
                                    color="blue"
                                    background-color="grey"
                                    :value="note.rating"
                                />
                                <div
                                    style="white-space: pre-line"
                                    class="black--text"
                                >
                                    Note:
                                    <span
                                        v-html="$autolinker.link(note.comment)"
                                    />
                                </div>
                            </div>
                            <div class="ml-auto">
                                <v-btn
                                    v-if="canDeleteNote(note)"
                                    icon
                                    @click.stop="deleteNote(note)"
                                    small
                                    color="error"
                                >
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <v-divider class="mt-4" />
                    </div>
                </div>
            </template>
            <div v-else>No notes created</div>

            <template v-if="hasPermission('staffAdminSuppliers')">
                <form novalidate @submit.prevent="submitNote">
                    <validation-observer ref="validation">
                        <div class="mt-4">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <text-area
                                        name="Comment"
                                        v-model="form.comment"
                                        rules="required"
                                        required
                                    />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <div class="body-1">Rating</div>
                                    <validation-provider
                                        v-slot="{ errors }"
                                        rules="required|min_value:1|max_value:5"
                                        name="rating"
                                    >
                                        <v-rating
                                            hover
                                            color="blue"
                                            :background-color="
                                                errors.length ? 'red' : 'grey'
                                            "
                                            v-model="form.rating"
                                            large
                                        />
                                        <div
                                            v-for="error in errors"
                                            class="red--text text-body-2"
                                        >
                                            {{ error }}
                                        </div>
                                    </validation-provider>
                                </v-col>
                            </v-row>

                            <v-btn
                                color="success"
                                type="submit"
                                class="mt-6"
                                :loading="form.processing"
                            >
                                Submit Note
                            </v-btn>
                        </div>
                    </validation-observer>
                </form>
            </template>
        </v-sheet>
    </modal-template>
</template>

<style scoped></style>
