<template>
    <v-sheet
        elevation="2"
        class="pa-4 my-2 d-flex justify-space-between"
        rounded
        :style="style"
    >
        <div>
            <div class="d-flex justify-space-between">
                <div class="text-caption" v-text="job.number" />
                <v-chip
                    v-if="isPrepared"
                    x-small
                    outlined
                    color="#4caf50"
                    class="ml-2"
                >
                    Prepared</v-chip
                >
            </div>
            <div v-text="job.name" />
            <div class="text--secondary d-flex align-center flex-wrap">
                <v-icon v-if="user" small class="mr-1">mdi-account</v-icon>
                <div v-if="user" class="text-caption" v-text="user" />
                <v-chip
                    v-if="isDryHire"
                    x-small
                    outlined
                    color="orange"
                    class="ml-2"
                >
                    Dry Hire</v-chip
                >
                <v-chip
                    v-if="isPaintworks"
                    x-small
                    outlined
                    color="light-blue"
                    class="ml-2"
                >
                    Paintworks</v-chip
                >
                <v-chip
                    v-if="isTheMountWithout"
                    x-small
                    outlined
                    color="teal"
                    class="ml-2"
                >
                    The Mount Without</v-chip
                >

                <v-chip
                    v-if="isAshtonGate"
                    x-small
                    outlined
                    color="purple"
                    class="ml-2"
                >
                    Ashton Gate</v-chip
                >
            </div>
        </div>
        <div v-if="showLinks">
            <v-menu offset-y absolute>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon
                        ><v-icon>mdi-dots-vertical</v-icon></v-btn
                    >
                </template>
                <v-list>
                    <v-list-item
                        :href="job.url"
                        target="_blank"
                        class="text-decoration-none"
                    >
                        <v-list-item-title> Open in Current </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :href="route('warehouse.prepare-delivery-note', job.id)"
                        target="_blank"
                        class="text-decoration-none"
                    >
                        <v-list-item-title>
                            Sign Delivery Note
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </v-sheet>
</template>

<script>
export default {
    name: "JobTile",

    props: {
        job: {
            type: Object,
            required: true,
        },
        showLinks: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        style() {
            return this.isPrepared
                ? "border-left: 3px solid; border-right: 3px solid; border-color: #4caf50"
                : "";
        },

        isPrepared() {
            return this.job.prepared && this.job.status === 0;
        },

        isDryHire() {
            return this.job.name.includes("DH");
        },

        isVirtualVenue() {
            return this.job.name.includes("VV");
        },

        isPaintworks() {
            return this.job.name.includes("PW");
        },

        isTheMountWithout() {
            return this.job.name.includes("TMW");
        },

        isAshtonGate() {
            return this.job.name.includes("AGS");
        },

        user() {
            return this.job.current_user?.name;
        },
    },
};
</script>

<style scoped></style>
