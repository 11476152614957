<template>
    <modal-template v-model="show">
        <div v-if="line">
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Type</v-list-item-title>
                        <v-list-item-subtitle>{{
                            line.type
                        }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Date</v-list-item-title>
                        <v-list-item-subtitle>{{
                            momentFormatDateTimeYear(line.date)
                        }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Supplier</v-list-item-title>
                        <v-list-item-subtitle>{{
                            line.supplier
                        }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Total</v-list-item-title>
                        <v-list-item-subtitle>
                            £{{ formatMoney(line.total) }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Description</v-list-item-title>
                        <v-list-item-subtitle>
                            <div style="white-space: pre-line">
                                {{
                                    line.description ? line.description : "N/A"
                                }}
                            </div>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider />

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Attachments</v-list-item-title>
                        <v-list-item-subtitle>
                            <div
                                v-if="line.uploads.length"
                                class="d-flex flex-wrap my-4 mx-1"
                            >
                                <file-sheet
                                    v-for="upload in line.uploads"
                                    :key="upload.id"
                                    :type="getFileType(upload.extension)"
                                    :name="upload.extension"
                                    :src="upload.url"
                                    width="200px"
                                    max-width="100%"
                                    max-height="300px"
                                    class="ma-4"
                                />
                            </div>
                            <div v-else>No Attachments</div>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <accent-button
                v-if="!sheet.submitted_on"
                do-not-stretch
                @click="editLine"
            >
                Edit Line
            </accent-button>
        </div>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../Master/ModalTemplate";
import FileSheet from "../../General/FileDisplay/FileSheet";
import FileHelpers from "../../../Mixins/FileHelpers";
import AccentButton from "../../Buttons/AccentButton";

export default {
    name: "ExpenseSheetLineViewModal",
    components: { AccentButton, FileSheet, ModalTemplate },
    mixins: [FileHelpers],

    data() {
        return {
            show: false,
            line: null,
        };
    },

    methods: {
        view(line) {
            this.line = line;
            this.show = true;
        },

        editLine() {
            this.show = false;
            this.$emit("edit", this.line);
        },
    },

    computed: {
        sheet() {
            return this.$page.props.sheet.data;
        },
    },
};
</script>

<style scoped></style>
