<template>
    <div>
        <head-title title="Invoice Payment | Pytch" />
        <div
            class="d-flex align-center flex-wrap justify-sm-start justify-center"
        >
            <v-img max-width="200" :src="$page.props.files.pytchLogo" />

            <div class="ml-4">
                <div class="text-h6">Invoice payment</div>
                <div class="text-h6 font-weight-bold">
                    Invoice: {{ invoiceData.number }}
                </div>
                <div class="text-h6 font-weight-bold">
                    Amount: £{{ total }}
                    <span class="text--secondary text-caption">inc VAT</span>
                </div>
            </div>
        </div>

        <v-divider class="my-8" />

        <div v-if="!errorPage">
            <validation-observer ref="form">
                <validation-provider
                    v-slot="{ errors }"
                    name="Name on card"
                    rules="required|max:255"
                >
                    <v-text-field
                        v-model="nameOnCard"
                        outlined
                        autofocus
                        name="Name on card"
                        :counter="255"
                        :error-messages="errors"
                        label="Name on card"
                        hint="The name as written on the card"
                        class="required mb-6"
                        required
                    >
                    </v-text-field>
                </validation-provider>
            </validation-observer>

            <errors-banner class="my-4" :errors-prop="stripeSubmitErrors" />

            <div class="stripe-elements mb-10">
                <div class="row">
                    <div class="col">
                        <div class="stripe" ref="cardNumber" />
                        <div
                            v-if="stripeErrors"
                            class="text-body-2 error--text"
                        >
                            {{ stripeErrors }}
                        </div>
                    </div>
                </div>
            </div>

            <v-btn color="success" @click="validatePayment" class="mb-4">
                Pay £{{ total }}
            </v-btn>

            <a target="_blank" href="https://stripe.com">
                <v-img max-width="250" :src="stripeImage" />
            </a>
        </div>

        <div v-else>
            <div class="text-center">
                <div class="error--text text-h6">
                    Something has gone a little wrong
                </div>
                <div>
                    Your payment may have been successful, please get in touch
                    with us for more information
                </div>
            </div>
        </div>

        <!--Loading modal-->
        <modal-template persistent v-model="loadingModal" small middle>
            <div class="d-flex align-center flex-column">
                <v-btn loading icon color="primary" x-large />
                <div class="text-h6">Processing...</div>
                <div class="text-subtitle-1">
                    Please do not refresh or press back
                </div>
            </div>
        </modal-template>
    </div>
</template>

<script>
import SecureLayout from "../../Comps/Secure/SecureLayout";
import { extend } from "vee-validate";
import { max, required } from "vee-validate/dist/rules";
import ErrorsBanner from "../../Comps/General/ErrorsBanner";
import ModalTemplate from "../../Comps/Master/ModalTemplate";
import { loadStripe } from "@stripe/stripe-js";
import HeadTitle from "../../Comps/Master/HeadTitle";

extend("max", max);
extend("required", required);

export default {
    name: "SecurePayment",
    components: { HeadTitle, ModalTemplate, ErrorsBanner },
    layout: SecureLayout,

    props: {
        invoiceData: Object,
        xeroInvoice: Object,
        total: String,
        xeroAmount: Number,
        paymentIntentSecret: String,
        paymentIntentId: String,
        stripeImage: String,
        stripeKey: String,
    },

    data() {
        return {
            nameOnCard: null,
            stripeErrors: null,
            stripe: null,
            cardNumber: null,
            cardExpiry: null,
            cardCvc: null,
            stripeSubmitErrors: [],
            loadingModal: false,
            paymentProcessing: false,
            errorPage: false,
        };
    },

    mounted() {
        this.initStripe();
    },

    methods: {
        async initStripe() {
            this.stripe = await loadStripe(this.stripeKey);

            // Create an instance of Elements.
            let elements = this.stripe.elements();

            //Mount the stripe elements
            this.cardNumber = elements.create("card");
            await this.cardNumber.mount(this.$refs.cardNumber);

            // Listen for errors
            this.cardNumber.on("change", (event) => {
                this.stripeElementsChange(event, "cardNumber");
            });
        },

        stripeElementsChange(e, key) {
            if (e.error) {
                this.stripeErrors = e.error.message;
            } else {
                this.stripeErrors = null;
            }
        },

        validatePayment() {
            this.$refs.form.validate().then((success) => {
                //Check validation
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                if (this.stripeErrors) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.stripeSubmitPayment();
            });
        },

        stripeSubmitPayment() {
            this.loadingModal = true;
            this.stripeSubmitErrors = [];
            this.paymentProcessing = true;

            let data = {
                payment_method: {
                    card: this.cardNumber,
                    billing_details: {
                        name: this.nameOnCard,
                    },
                },
            };

            if (this.invoiceData.member?.emails?.length) {
                data.receipt_email = this.invoiceData.member.emails[0].address;
            }

            this.stripe
                .confirmCardPayment(this.paymentIntentSecret, data)
                .then((result) => {
                    //If stripe error
                    if (result.error) {
                        this.stripeSubmitErrors.push([result.error.message]);
                        this.paymentProcessing = false;
                        setTimeout(() => {
                            this.loadingModal = false;
                        }, 500);
                        return;
                    }

                    //Check payment intent
                    if (result.paymentIntent.status !== "succeeded") {
                        this.loadingModal = false;
                        this.errorPage = true;
                        return;
                    }

                    //Payment complete
                    this.sendToCompletePage();
                });
        },

        sendToCompletePage() {
            this.$http
                .post(this.route("secure.complete"), {
                    invoiceId: this.invoiceData.id,
                    xeroInvoiceGuid: this.xeroInvoice.InvoiceID,
                })
                .then(() => {
                    this.$inertia.visit(
                        this.route("secure.complete.render", {
                            invoiceId: this.invoiceData.id,
                            memberUuid: this.invoiceData.member.uuid,
                        })
                    );
                })
                .catch(() => {
                    this.loadingModal = false;
                    this.errorPage = true;
                });
        },
    },
};
</script>

<style>
.stripe {
    border: 1px solid rgba(0, 0, 0, 0.38);
    padding: 20px;
    border-radius: 5px;
}
</style>
