export default {
    methods: {
        hasPermission(permission) {
            if (this.isSuperAdmin) {
                return true;
            }

            return this.$page.props.auth?.user?.permissions.includes(
                permission
            );
        },
    },

    computed: {
        isSuperAdmin() {
            return this.$page.props.auth?.user?.permissions.includes(
                "Super Admin"
            );
        },
    },
};
