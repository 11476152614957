<template>
    <v-container>
        <breadcrumbs :items="bread"></breadcrumbs>
        <header-name>Asset - {{ asset.asset_number }}</header-name>
        <asset-search-show-information
            search-information-route-name="asset-search.search.information"
            stock-transfer-route-name="asset-search.search.stock-transfer"
            edit-info-route-name="asset-search.search.update-info"
            quarantine-route-name="asset-search.search.quarantine"
        />
    </v-container>
</template>

<script>
import HeaderName from "../../../Comps/General/HeaderName";
import ProcessesController from "../../../Mixins/ProcessesController";
import Breadcrumbs from "../../../Comps/General/Breadcrumbs";
import AssetSearchShowInformation from "../../../Comps/Processes/AssetSearch/AssetSearchShowInformation";
export default {
    name: "AssetSearchShow",
    components: {
        AssetSearchShowInformation,
        Breadcrumbs,
        HeaderName,
    },
    mixins: [ProcessesController],

    data() {
        return {
            bread: [
                {
                    text: "Asset Search",
                    disabled: false,
                    link: this.route("asset-search.index"),
                },
                {
                    text: this.$page.props.asset.asset_number,
                    disabled: true,
                },
            ],
        };
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },
    },
};
</script>

<style scoped></style>
