<template>
    <div style="position: relative">
        <v-list
            style="max-height: 300px; overflow-y: auto"
            ref="inner"
            two-line
            @scroll.native="scrolled"
        >
            <v-icon
                v-if="!atBottom"
                large
                style="position: absolute; bottom: 0; right: 0"
            >
                mdi-chevron-down
            </v-icon>

            <v-list-item-group v-model="selected">
                <template v-for="(user, i) in usersComputed">
                    <task-users-panel-user
                        :key="`item-${i}`"
                        :task="task"
                        :user="user"
                        @remove="removeUser(i, user)"
                    >
                    </task-users-panel-user>
                </template>
            </v-list-item-group>
        </v-list>
    </div>
</template>

<script>
import TaskUsersPanelUser from "./TaskUsersPanelUser";
export default {
    name: "TaskUsersPanel",
    components: { TaskUsersPanelUser },
    props: {
        users: {
            type: Array,
            required: true,
        },
        value: {
            type: Object,
        },
        task: {
            type: Object,
            required: true,
        },
    },

    watch: {
        users() {
            this.scrolled();
        },
    },

    data() {
        return {
            atBottom: true,
            allUsers: this.$page.props.allTaskUsers,
        };
    },

    methods: {
        scrolled: _.debounce(function () {
            let el = this.$refs.inner.$el;
            this.atBottom = el.scrollTop === el.scrollHeight - el.offsetHeight;
        }, 200),

        removeUser(index, user) {
            this.usersComputed.splice(index, 1);

            if (this.selected?.id === user.id) {
                this.selected = null;
            }
        },
    },

    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        usersComputed: {
            get() {
                return this.users;
            },
            set(value) {
                this.$emit("update:users", value);
            },
        },
    },
};
</script>

<style lang="scss">
.custom .v-expansion-panel-content__wrap {
    padding: 0 4px 16px;
}
</style>
