<template>
    <v-app>
        <head-title :title="store.title" />
        <v-navigation-drawer
            app
            mini-variant-width="73"
            :mini-variant="!showSideMenu"
            v-bind="properties"
            v-model="drawer"
            :color="adminSideMenuColor"
            :class="{ 'nav-no-border': !showSideMenu }"
        >
            <v-row class="fill-height" no-gutters>
                <v-navigation-drawer
                    v-model="drawer"
                    v-bind="properties"
                    mini-variant-width="73"
                    :mini-variant="!miniNav"
                    color="primary"
                    dark
                    class="d-flex flex-column no-opacity"
                >
                    <v-list>
                        <v-list-item class="px-1">
                            <v-img :src="$page.props.files.pytchLogoWhite" />
                        </v-list-item>
                    </v-list>

                    <v-divider style="border-color: white"></v-divider>

                    <!--Top Menu-->
                    <v-list-item-group nav dense v-model="selected">
                        <v-list-item
                            v-for="item in items"
                            :key="item.title"
                            v-if="!item.bottom"
                            :value="item.route"
                            @click.prevent="goTo(item)"
                            v-tooltip.right="item.title"
                            link
                            active-class="amber"
                        >
                            <v-list-item-icon class="ml-0">
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{
                                item.title
                            }}</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>

                    <!--Bottom Menu-->
                    <template v-slot:append>
                        <v-list-item-group
                            nav
                            dense
                            class="mt-auto"
                            v-model="selected"
                        >
                            <v-list-item
                                v-for="item in items"
                                :key="item.title"
                                v-if="item.bottom"
                                :value="item.route"
                                @click.prevent="goTo(item)"
                                v-tooltip.right="item.title"
                                link
                                active-class="amber"
                            >
                                <v-list-item-icon class="ml-0">
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{
                                    item.title
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>

                        <!--Avatar-->
                        <v-list>
                            <v-list-item class="px-4">
                                <v-list-item-avatar
                                    class="ml-0"
                                    v-tooltip.right="store.user.name"
                                >
                                    <v-img :src="store.user.avatar"></v-img>
                                </v-list-item-avatar>
                            </v-list-item>
                        </v-list>
                    </template>
                </v-navigation-drawer>

                <section
                    v-if="store.sideMenu.length"
                    class="grow pa-2"
                    style="max-width: 183px"
                >
                    <div class="text-h5 text-center mb-2 text--secondary">
                        {{ $root.store.sideMenuTitle }}
                    </div>

                    <v-divider></v-divider>

                    <!--Side menu-->
                    <v-list-item-group
                        nav
                        v-model="selectedSideMenu"
                        class="mt-3 no-opacity"
                    >
                        <div
                            v-for="item in store.sideMenu"
                            :key="item.title"
                            class="inertia-link"
                        >
                            <inertia-link
                                v-if="!item.external"
                                :href="route(item.route)"
                            >
                                <v-list-item
                                    class="list-a"
                                    :value="item.route"
                                    :id="item.id ? item.id : null"
                                    link
                                    dense
                                    active-class="amber"
                                >
                                    <v-list-item-icon class="mr-1">
                                        <v-icon>{{ item.icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        {{ item.title }}
                                    </v-list-item-title>
                                </v-list-item>
                            </inertia-link>
                            <a v-else target="_blank" :href="item.route">
                                <v-list-item
                                    class="list-a"
                                    :value="item.route"
                                    :id="item.id ? item.id : null"
                                    link
                                    dense
                                >
                                    <v-list-item-icon class="mr-1">
                                        <v-icon>{{ item.icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        {{ item.title }}
                                    </v-list-item-title>
                                </v-list-item>
                            </a>
                        </div>
                    </v-list-item-group>
                </section>
            </v-row>
        </v-navigation-drawer>
        <v-app-bar dense app>
            <v-app-bar-nav-icon
                v-if="$vuetify.breakpoint.mobile"
                @click="drawer = !drawer"
                class="mr-1"
            />

            <v-app-bar-nav-icon
                @click="sideMenu = !sideMenu"
                v-if="showSideMenuButton"
                v-tooltip="'Expand Sidebar'"
                class="mr-1 ml-0 pl-0"
            >
            </v-app-bar-nav-icon>
            {{ store.title }}
        </v-app-bar>

        <!-- Sizes your content based upon application components -->
        <v-main>
            <!-- Provides the application the proper gutter -->
            <v-container fluid>
                <slot></slot>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { Inertia } from "@inertiajs/inertia";
import HeadTitle from "./HeadTitle";

export default {
    name: "Layout",
    components: { HeadTitle },
    data() {
        return {
            drawer: null,
            sideMenu: true,
            items: [
                {
                    title: "Dashboard",
                    icon: "mdi-view-dashboard",
                    route: "staff.index",
                },
                {
                    title: "My Profile",
                    icon: "mdi-account-details",
                    route: "staff.myDetails",
                },
                {
                    title: "People",
                    icon: "mdi-account-group",
                    route: "staff.logistics",
                },
                {
                    title: "Processes",
                    icon: "mdi-hammer-screwdriver",
                    route: "asset-search.index",
                },
                {
                    title: "Things",
                    icon: "mdi-calendar-text",
                    route: "staff.info.index",
                },
            ],
            mini: true,
            store: this.$root.store,
            selected: "",
            selectedSideMenu: "",
            closeNavRoutes: ["staff.myDetails", "staff.index"],
        };
    },

    created() {
        this.selected = this.$page.props.route;
        this.selectedSideMenu = this.$page.props.route;

        Inertia.on("navigate", (event) => {
            this.selected = this.$page.props.route;
            this.selectedSideMenu = this.$page.props.route;
            this.checkSelectedSideMenu();
            if (this.$vuetify.breakpoint.mobile && this.drawer) {
                this.$nextTick(() => {
                    this.drawer = !this.closeNavRoutes.includes(
                        this.$page.props.route
                    );
                });
            }
        });
    },

    mounted() {
        if (this.$page.props.admin) {
            this.items.push({
                title: "Admin",
                icon: "mdi-cog",
                route: "staff.admin.users.index",
                bottom: true,
            });
        }
        this.items.push({
            title: "Logout",
            icon: "mdi-lock",
            route: "logout",
            bottom: true,
            logout: true,
        });

        this.checkSelectedSideMenu();
    },
    methods: {
        goTo(item) {
            if (item.logout) {
                this.$http
                    .post(this.route(item.route, [], false))
                    .then(() => {
                        location.reload();
                    })
                    .catch(() => {
                        location.reload();
                    });
            } else {
                let url = this.route(item.route);

                if (!url) {
                    url = "/";
                }

                this.$inertia.visit(url, {
                    method: "get",
                    replace: false,
                });
            }
        },

        checkSelectedSideMenu() {
            let routeToIdMap = [
                { route: "staff.pat.show", id: "staff.pat.records" },
                { route: "staff.loler.show", id: "staff.loler.records" },
            ];

            let route = this.$page.props.route;

            let find = _.find(routeToIdMap, (map) => {
                return map.route === route;
            });

            if (find) {
                this.selectedSideMenu = this.route(find.id);
            }
        },
    },
    computed: {
        properties() {
            return {
                "expand-on-hover": false,
            };
        },
        title() {
            return this.$meta();
        },
        showSideMenu() {
            return (
                (this.store.sideMenu.length && this.sideMenu) ||
                this.$vuetify.breakpoint.mobile
            );
        },
        showSideMenuButton() {
            return (
                !this.$vuetify.breakpoint.mobile && this.store.sideMenu.length
            );
        },
        miniNav() {
            return (
                this.$vuetify.breakpoint.mobile && !this.store.sideMenu.length
            );
        },
        adminSideMenuColor() {
            if (this.showSideMenu && !this.store.sideMenu.length) {
                return "rgba(0,0,0,0)";
            } else {
                return "white";
            }
        },
    },
};
</script>

<style lang="scss">
.v-navigation-drawer__content {
    overflow: visible !important;
}

.theme--light {
    .no-opacity {
        .v-list-item--active:before,
        .v-list-item--active:hover:before,
        .v-list-item--active:focus:before {
            opacity: 0;
        }
    }
}
</style>

<style scoped lang="scss">
.list-a {
    a {
        display: flex;
        text-decoration: none;
        color: inherit;
    }
}
</style>

<style>
.page-enter-active,
.page-leave-active {
    transition: all 0.2s;
}

.page-enter,
.page-leave-active {
    opacity: 0;
}
</style>
