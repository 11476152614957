var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fixed":""}},[_c('header-name',[_vm._v("Hire Site Categories")]),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
            { text: 'Name', value: 'name' },
            { text: '', value: 'options', align: 'end' },
        ],"items":_vm.$page.props.productCategories,"no-data-text":"No categories have been found","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex pa-3 text-h6"},[_vm._v("\n                Main Categories\n                "),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.$refs.mainCatModal.create()}}},[_vm._v("\n                        Create Main Category\n                    ")])],1)])]},proxy:true},{key:"item.options",fn:function({ item }){return [_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Edit'),expression:"'Edit'"}],staticClass:"mr-2",attrs:{"elevation":"2","color":"primary","icon":""},on:{"click":function($event){return _vm.$refs.mainCatModal.edit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_vm._v(" "),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Delete'),expression:"'Delete'"}],staticClass:"mr-2",attrs:{"elevation":"2","color":"error","icon":""},on:{"click":function($event){return _vm.deleteCategory(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_vm._v(" "),_c('main-category-modal',{ref:"mainCatModal"}),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1 mt-8",attrs:{"headers":[
            { text: 'Name', value: 'name' },
            {
                text: 'Main Category',
                value: 'product_category.name',
            },
            { text: 'Active', value: 'show_on_hire_site' },
            { text: '', value: 'options', align: 'end' },
        ],"items":_vm.$page.props.productSubCategories,"no-data-text":"No categories have been found","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex pa-3 text-h6"},[_vm._v("Sub Categories")])]},proxy:true},{key:"item.show_on_hire_site",fn:function({ value }){return [(value)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success","small":""}},[_vm._v("\n                mdi-check\n            ")]):_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error","small":""}},[_vm._v("\n                mdi-close\n            ")])]}},{key:"item.options",fn:function({ item }){return [_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Edit'),expression:"'Edit'"}],staticClass:"mr-2",attrs:{"elevation":"2","color":"primary","icon":""},on:{"click":function($event){return _vm.$refs.subCatModal.edit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])}),_vm._v(" "),_c('sub-category-modal',{ref:"subCatModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }