<script>
export default {
    name: "AudioPlayback",
    props: {
        src: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            audio: new Audio(this.src),
        };
    },

    render() {
        return this.$slots.default;
    },

    mounted() {
        // Listen for any user interaction
        document.body.addEventListener("click", this.unlockAutoplay);
        document.body.addEventListener("touchstart", this.unlockAutoplay);
    },

    methods: {
        /**
         * play muted audio as soon as we get user interaction, then remove listener's
         */
        unlockAutoplay() {
            this.audio.muted = true;
            this.audio.play().then(() => {
                // pause, reset and unmute
                this.audio.pause();
                this.audio.currentTime = 0;
                this.audio.muted = false;
                // remove listener's
                document.body.removeEventListener("click", this.unlockAutoplay);
                document.body.removeEventListener(
                    "touchstart",
                    this.unlockAutoplay
                );
            });
        },
        /**
         * PLay the Audio
         */
        play() {
            this.audio.pause();
            this.audio.currentTime = 0;
            this.audio.play();
        },
    },
};
</script>

<style scoped></style>
