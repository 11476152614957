<template>
    <!--Information-->
    <expansion-panel icon="mdi-cog" header="Information">
        <template v-if="user.xero_id && !changeXeroContact">
            <v-chip color="success" label> Linked to Xero </v-chip>
            <a
                :href="`https://go.xero.com/Contacts/View/${user.xero_id}`"
                target="_blank"
                style="cursor: pointer"
                class="text-caption text--secondary mt-1 d-block inertia-link"
            >
                View Xero contact
                <v-icon small>mdi-open-in-new</v-icon>
            </a>
            <div
                style="cursor: pointer"
                @click="changeXeroContact = true"
                class="text-caption error--text text--lighten-1 mt-1"
            >
                Relink Xero
            </div>
        </template>
        <template v-if="!user.xero_id || changeXeroContact">
            <div v-if="!user.xero_id" class="text-body-1 mb-2">
                This user does not have a linked Xero contact, this will stop
                them being able to use certain modules, please link them to a
                contact
            </div>
            <v-autocomplete
                v-model="xeroId"
                :items="xeroContacts"
                :loading="loadingXeroContacts"
                :search-input.sync="searchXeroContact"
                hide-selected
                outlined
                dense
                no-data-text="No contacts found"
                item-text="Name"
                item-value="ContactID"
                label="Xero Contact"
                placeholder="Start typing to Search"
            />

            <v-btn
                small
                color="success"
                :disabled="!xeroId"
                @click="updateXeroContact"
                :loading="loadingUpdateXeroContact"
            >
                Update Xero Contact
            </v-btn>
        </template>
    </expansion-panel>
</template>

<script>
import ExpansionPanel from "./ExpansionPanel";
export default {
    name: "InformationPanel",
    components: { ExpansionPanel },

    data() {
        return {
            xeroContacts: [],
            loadingXeroContacts: false,
            searchXeroContact: null,
            xeroId: null,
            loadingUpdateXeroContact: false,
            changeXeroContact: false,
        };
    },

    watch: {
        searchXeroContact(value) {
            if (!value) {
                this.xeroContacts = [];
                return;
            }
            this.getXeroContacts(value);
        },
    },

    computed: {
        user() {
            return this.$page.props.user;
        },
    },

    methods: {
        getXeroContacts: _.debounce(function (search) {
            this.loadingXeroContacts = true;
            this.$http
                .get(
                    this.route("staff.admin.users.get-xero-contacts", {
                        search: search,
                    })
                )
                .then((response) => {
                    this.xeroContacts = response.data;
                })
                .finally(() => {
                    this.loadingXeroContacts = false;
                });
        }),
        updateXeroContact() {
            if (!this.xeroId) {
                return;
            }

            this.loadingUpdateXeroContact = true;
            this.$inertia.put(
                this.route("staff.admin.users.update-xero-id", this.user),
                {
                    xero_id: this.xeroId,
                },
                {
                    onSuccess: () => {
                        this.xeroId = null;
                        this.changeXeroContact = false;
                    },
                    onFinish: () => {
                        this.loadingUpdateXeroContact = false;
                    },
                }
            );
        },
    },
};
</script>

<style scoped></style>
