var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.trainings.data.length)?_c('div',[_c('div',{staticClass:"d-flex"},[_c('v-pagination',{attrs:{"length":_vm.trainings.last_page},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_vm._v(" "),_c('v-list',_vm._l((_vm.trainings.data),function(training){return _c('div',{key:training.id},[_c('v-divider'),_vm._v(" "),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{attrs:{"target":"_blank","href":_vm.route(
                                    'staff.admin.training.sessions.show',
                                    training.training_session
                                )}},[_vm._v(_vm._s(training.training_session.module.name)+" |\n                            "+_vm._s(_vm.momentFormatDate(
                                    training.training_session.created_at
                                )))])]),_vm._v(" "),(_vm.expired(_vm.expires(training)) && training.passed)?_c('v-list-item-subtitle',{staticClass:"red--text"},[_vm._v("\n                        Expired On:\n                        "+_vm._s(_vm.expires(training).format("ddd Do MMM YYYY"))+"\n                    ")]):_vm._e(),_vm._v(" "),(
                            !_vm.expired(_vm.expires(training)) && training.passed
                        )?_c('v-list-item-subtitle',{staticClass:"green--text"},[_vm._v("\n                        Expires:\n                        "+_vm._s(_vm.expires(training).format("ddd Do MMM YYYY"))+"\n                    ")]):_vm._e(),_vm._v(" "),_c('v-list-item-subtitle',[(training.passed)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("\n                            mdi-emoticon-happy-outline\n                        ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-emoticon-sad-outline\n                        ")]),_vm._v(" "),(training.passed)?_c('span',{staticClass:"green--text"},[_vm._v("Passed")]):_c('span',{staticClass:"red--text"},[_vm._v("Failed")])],1)],1)],1)],1)}),0)],1):_c('div',[(!_vm.admin)?_c('span',{staticClass:"text-h6"},[_vm._v("You have not participated in any training sessions yet")]):_c('span',{staticClass:"text-h6"},[_vm._v("This user has not participated in any training sessions yet")])])
}
var staticRenderFns = []

export { render, staticRenderFns }