import { render, staticRenderFns } from "./ExpensesPanel.vue?vue&type=template&id=1baeeef6&scoped=true"
import script from "./ExpensesPanel.vue?vue&type=script&lang=js"
export * from "./ExpensesPanel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1baeeef6",
  null
  
)

export default component.exports