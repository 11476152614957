<template>
    <v-row>
        <v-col cols="12" md="9">
            <header-name>Logistics</header-name>

            <logistics-date-version-picker
                class="mt-5"
                :this-week.sync="thisWeek"
                :versions="versions"
                :version.sync="version"
            >
            </logistics-date-version-picker>

            <section v-if="logistic">
                <v-sheet outlined class="pa-3">
                    <div class="d-flex flex-wrap align-center">
                        <h4
                            class="mr-3"
                            :class="{
                                'success--text': logisticLive,
                                'warning--text': logisticDraft,
                                'error--text': logisticDeleted,
                            }"
                        >
                            {{ momentFormatDate(thisWeek) }} - Version
                            {{ version }}
                            <span v-if="logisticDraft">(Draft)</span>
                            <span v-if="logisticLive">(Live)</span>
                            <span v-if="logisticDeleted">(Deleted)</span>
                        </h4>

                        <!--New task-->
                        <inertia-link
                            class="inertia-link"
                            :href="
                                route(
                                    'staff.admin.logistics.tasks.create',
                                    this.logistic.slug
                                )
                            "
                        >
                            <v-btn
                                color="success"
                                class="mr-2"
                                v-if="!logisticLive && !logisticDeleted"
                                small
                            >
                                New Task
                            </v-btn>
                        </inertia-link>

                        <!--Make Live-->
                        <v-btn
                            v-if="logisticDraft"
                            color="primary"
                            class="mr-2"
                            @click="makeLiveModal = true"
                            small
                        >
                            Make Live
                        </v-btn>

                        <!--Restore-->
                        <v-btn
                            v-if="logisticDeleted"
                            color="success"
                            class="mr-2"
                            small
                            @click="deleteLogistic"
                            v-tooltip="'Restore this version'"
                        >
                            Restore
                        </v-btn>

                        <!--Clone-->
                        <v-btn
                            color="primary"
                            class="mr-2"
                            v-if="!logisticDeleted && logisticLive"
                            small
                            @click="cloneLogistic"
                            v-tooltip="'Clone this version to a new version'"
                        >
                            Clone
                        </v-btn>

                        <!--View Stats-->
                        <v-btn
                            class="mr-2"
                            v-if="canShowStatsButton"
                            color="blue-grey"
                            id="statsButton"
                            dark
                            small
                            @click="viewStatsModal = true"
                            v-tooltip="'View the stats for this version'"
                        >
                            View Stats
                        </v-btn>

                        <!--Delete-->
                        <v-btn
                            color="error"
                            fab
                            v-if="!onlyVersion && !logisticDeleted"
                            class="ml-auto"
                            x-small
                            @click="deleteLogistic"
                            v-tooltip="'Delete this version'"
                        >
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                    </div>
                    <div v-if="!logisticLive">
                        <v-switch
                            class="d-inline-block"
                            v-model="deletedTasks"
                            label="Show deleted tasks"
                            @change="changeDeletedTasks"
                        >
                        </v-switch>
                    </div>
                </v-sheet>

                <logistics-viewer
                    :this-week="thisWeek"
                    :logistic="logistic"
                    :logistic-live="logisticLive"
                >
                </logistics-viewer>
            </section>
            <div v-else class="mt-10 text-center">
                <h4 class="mb-3">
                    No logistics have been found for this week, create one now?
                </h4>
                <v-btn color="success" @click="createNewLogistic">
                    Create Logistic
                </v-btn>
            </div>
        </v-col>

        <v-col cols="12" md="3">
            <v-sheet>
                <h5 class="text--secondary mb-4">Information</h5>

                <v-expansion-panels v-model="information">
                    <category-key :categories="categories"></category-key>
                </v-expansion-panels>
            </v-sheet>
        </v-col>

        <make-live-modal
            v-model="makeLiveModal"
            v-if="logistic"
            :logistic="logistic"
            :all-users="usersAndFreelancersAll"
            :all-users-select="usersAndFreelancersSelect"
        >
        </make-live-modal>

        <view-stats-modal
            v-model="viewStatsModal"
            v-if="logistic"
            :logistic="logistic"
        >
        </view-stats-modal>
    </v-row>
</template>

<script>
import HeaderName from "../../../../Comps/General/HeaderName";
import MakeLiveModal from "../../../../Comps/Staff/Logistics/MakeLiveModal";
import logisticsViewer from "../../../../Comps/Staff/Logistics/LogisticsViewer.vue";
import LogisticsDateVersionPicker from "../../../../Comps/Staff/Logistics/LogisticsDateVersionPicker";
import LogisticsMixin from "../../../../Mixins/LogisticsMixin";
import CategoryKey from "../../../../Comps/Staff/Logistics/CategoryKey";
import ViewStatsModal from "../../../../Comps/Staff/Logistics/ViewStatsModal";
import AdminController from "../../../../Mixins/AdminController";

export default {
    name: "Logistics",
    components: {
        ViewStatsModal,
        CategoryKey,
        LogisticsDateVersionPicker,
        logisticsViewer,
        MakeLiveModal,
        HeaderName,
    },
    props: [
        "logistics",
        "thisWeekProp",
        "logistic",
        "deletedTasksProp",
        "categories",
    ],
    mixins: [LogisticsMixin, AdminController],

    data() {
        return {
            deletedTasks: this.deletedTasksProp,
            information: 0,
            makeLiveModal: false,
            viewStatsModal: false,
        };
    },

    computed: {
        logisticDeleted() {
            return this.logistic && this.logistic.deleted_at;
        },
        logisticLive() {
            return !!(
                this.logistic &&
                !this.logisticDeleted &&
                this.logistic.sent
            );
        },
        logisticDraft() {
            return this.logistic && !this.logisticDeleted && !this.logisticLive;
        },
        onlyVersion() {
            return (
                _.filter(this.logistics, (logistic) => {
                    return !logistic.deleted_at;
                }).length <= 1
            );
        },
        usersAndFreelancersSelect() {
            let array = [];
            array.push({ header: "Users" });
            array.push(...this.$page.props.users);
            array.push({ divider: true });
            array.push({ header: "Freelancers" });
            array.push(...this.$page.props.freelancers);
            return array;
        },
        usersAndFreelancersAll() {
            let array = [];
            array.push(...this.$page.props.users);
            array.push(...this.$page.props.freelancers);
            return array;
        },
        canShowStatsButton() {
            if (!this.logisticLive) {
                return false;
            }
            return (
                this.logistic.users.length || this.logistic.freelancers.length
            );
        },
    },

    methods: {
        createNewLogistic() {
            this.$inertia.post(
                this.route("staff.admin.logistics.store"),
                {
                    week: this.weekForLaravel,
                },
                {
                    preserveState: false,
                }
            );
        },

        changeDeletedTasks() {
            let data = {
                deletedTasks: this.deletedTasks,
            };
            this.updateData(true, data);
        },
        updateData(dateOnly = false, data = {}) {
            let week = this.weekForLaravel;
            let version;
            if (dateOnly) {
                version = null;
            } else {
                version = this.version;
            }
            this.$inertia.get(
                this.route("staff.admin.logistics.index", [week, version]),
                data
            );
        },
        deleteLogistic() {
            this.$inertia.delete(this.route("staff.admin.logistics.destroy"), {
                data: {
                    id: this.logistic.id,
                },
                preserveState: false,
            });
        },
        cloneLogistic() {
            if (!confirm("Are you sure?")) {
                return;
            }
            this.$inertia.post(
                this.route("staff.admin.logistics.clone", this.logistic.slug),
                {},
                {
                    preserveState: false,
                }
            );
        },
    },
};
</script>

<style scoped></style>
