var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('head-title',{attrs:{"title":"Logistics"}}),_vm._v(" "),_c('v-container',[_c('div',{staticClass:"text-h5 text--secondary"},[_vm._v("\n            Logistics for "+_vm._s(_vm.freelancer.name)+"\n        ")]),_vm._v(" "),_c('h4',{staticClass:"mb-2 text--secondary"},[_vm._v("\n            Showing all tasks assigned to you or everyone\n        ")]),_vm._v(" "),_c('div',{staticClass:"d-flex align-center"},[(_vm.logistic)?_c('h3',{staticClass:"text--secondary"},[_vm._v("\n                "+_vm._s(_vm.momentFormatDate(_vm.thisWeek))+" - Version "+_vm._s(_vm.version)+"\n            ")]):_vm._e(),_vm._v(" "),_c('v-fade-transition',[(
                        _vm.logistic.freelancers.length &&
                        _vm.logistic.freelancers[0].pivot.accepted
                    )?_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('You have accepted this version'),expression:"'You have accepted this version'"}],staticClass:"ml-2",attrs:{"color":"success","large":""}},[_vm._v("\n                    mdi-check-outline\n                ")]):_vm._e()],1)],1),_vm._v(" "),_c('section',{staticClass:"mt-4"},[_c('v-fade-transition',[(
                        _vm.logistic.freelancers.length &&
                        !_vm.logistic.freelancers[0].pivot.accepted
                    )?_c('v-alert',{attrs:{"border":"top","prominent":"","colored-border":"","type":"info","elevation":"2"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[(_vm.logistic.version > 1)?_c('span',[_vm._v("Please accept this version of logistics to\n                                inform HR you have read and understood the\n                                changes")]):_c('span',[_vm._v("Please accept this first version of\n                                logistics to inform HR you have read and\n                                understood the current week")])]),_vm._v(" "),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return _vm.accept.apply(null, arguments)}}},[_vm._v("\n                                I Accept\n                            ")])],1)],1)],1):_vm._e()],1)],1),_vm._v(" "),(_vm.logistic)?_c('section',[_c('logistics-calendar',{attrs:{"this-week":_vm.thisWeek,"logistic":_vm.logistic,"freelancer":"","user":""}})],1):_c('div',{staticClass:"mt-10 text-center"},[_c('h4',{staticClass:"mb-3"},[_vm._v("\n                No logistics have been found for this week yet\n            ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }