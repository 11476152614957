<template>
    <v-container>
        <header-name>Expense Sheet {{ sheet.number }}</header-name>
        <breadcrumbs :items="breadItems" />

        <v-alert
            v-if="sheet.submitted_on"
            border="top"
            color="green"
            outlined
            type="success"
            class="mt-4"
        >
            <div class="d-flex">
                <div class="mr-1">Submitted On:</div>
                <div class="font-weight-bold">
                    {{ momentFormatDateTimeYear(sheet.submitted_on) }}
                </div>
            </div>
        </v-alert>

        <v-sheet elevation="2" class="pa-4">
            <div
                class="d-flex flex-wrap flex-column flex-sm-row align-sm-center align-start"
            >
                <expense-sheet-status-chip large :status="sheet.status" />

                <v-chip label class="ml-sm-2 mt-2 mt-sm-0">
                    Total: £{{ formatMoney(sheet.sheetTotal) }}
                </v-chip>

                <v-btn
                    v-if="!sheet.submitted_on && !admin"
                    @click="submitSheet"
                    small
                    class="ml-sm-auto mt-sm-0 mt-2"
                    color="warning"
                    :disabled="!sheet.lines.length"
                >
                    Submit Sheet
                </v-btn>
            </div>
        </v-sheet>

        <v-menu offset-y eager v-if="admin">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    small
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    class="mt-6"
                    :disabled="sheet.status !== 'Pending'"
                >
                    Actions
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-list dense>
                <v-list-item @click="approveSheet">
                    <v-list-item-title class="success--text">
                        Approve
                    </v-list-item-title>
                </v-list-item>
                <v-list-item @click="$refs.denyModal.openModal(sheet)">
                    <v-list-item-title class="warning--text">
                        Deny
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <div class="mt-2" />

        <v-btn
            v-if="!sheet.submitted_on && !admin"
            @click="$refs.createModal.create()"
            small
            class="my-2"
            color="success"
        >
            Add Expense
        </v-btn>

        <v-simple-table class="elevation-2">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Supplier</th>
                    <th>Type</th>
                    <th>Total</th>
                    <th>Linked Job</th>
                    <th>Attachments</th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                <tr
                    v-for="line in sheet.lines"
                    :key="line.id"
                    @click="viewLine(line)"
                    style="cursor: pointer"
                >
                    <td>{{ momentFormatDateTimeYear(line.date) }}</td>
                    <td>{{ line.supplier }}</td>
                    <td>{{ line.type }}</td>
                    <td>£{{ formatMoney(line.total) }}</td>
                    <td>
                        <div v-if="line.job">
                            <a-tag-with-external-icon
                                :text="line.job.name"
                                :url="`${$page.props.currentBaseUrl}/opportunities/${line.job.id}`"
                            />
                        </div>
                        <div v-else>Warehouse</div>
                    </td>
                    <td>{{ line.uploads.length }}</td>
                    <td>
                        <v-menu>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on">
                                    mdi-dots-vertical
                                </v-icon>
                            </template>

                            <v-list dense>
                                <v-list-item @click="viewLine(line)" link>
                                    <v-list-item-title class="primary--text">
                                        View
                                    </v-list-item-title>
                                </v-list-item>
                                <template v-if="!sheet.submitted_on">
                                    <v-list-item @click="editLine(line)" link>
                                        <v-list-item-title
                                            class="primary--text"
                                        >
                                            Edit
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-divider />
                                    <v-list-item link @click="deleteLine(line)">
                                        <v-list-item-title class="error--text">
                                            Delete
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </tbody>

            <div v-if="!sheet.lines.length" class="pa-4 text-body-1">
                Sheet is empty
            </div>
        </v-simple-table>

        <expense-sheet-line-create-modal v-if="!admin" ref="createModal" />
        <expense-sheet-line-view-modal @edit="editLine" ref="viewModal" />
        <deny-expense-sheet-modal ref="denyModal" />
    </v-container>
</template>

<script>
import ExpenseSheetStatusChip from "./ExpenseSheetStatusChip";
import ExpenseSheetLineViewModal from "./ExpenseSheetLineViewModal";
import ExpenseSheetLineCreateModal from "./ExpenseSheetLineCreateModal";
import ATagWithExternalIcon from "../../General/ATagWithExternalIcon";
import Breadcrumbs from "../../General/Breadcrumbs";
import HeaderName from "../../General/HeaderName";
import DenyExpenseSheetModal from "../../../Pages/Staff/Admin/Expenses/Partials/DenyExpenseSheetModal";

export default {
    name: "ExpenseShow",
    components: {
        DenyExpenseSheetModal,
        ExpenseSheetStatusChip,
        ExpenseSheetLineViewModal,
        ExpenseSheetLineCreateModal,
        ATagWithExternalIcon,
        Breadcrumbs,
        HeaderName,
    },

    props: {
        admin: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        submitSheet() {
            if (
                !confirm(
                    "Are you sure you want to submit this sheet for approval?"
                )
            ) {
                return;
            }

            this.$inertia.put(
                this.route("staff.expense-sheets.submit", this.sheet)
            );
        },
        deleteLine(line) {
            if (!confirm("Are you sure you want to delete this line?")) {
                return;
            }

            const route = "expense-sheets.expense-sheet-lines.destroy";

            this.$inertia.delete(this.route(route, [this.sheet, line]));
        },
        viewLine(line) {
            this.$refs.viewModal.view(line);
        },
        editLine(line) {
            this.$refs.createModal.edit(line);
        },
        approveSheet() {
            if (
                !confirm(
                    `Are you sure you want to change the status to Approved for this sheet?`
                )
            ) {
                return;
            }

            this.$inertia.put(this.route("staff.admin.expenses.changeStatus"), {
                sheets: [this.sheet.id],
                status: "Approved",
            });
        },
    },

    computed: {
        sheet() {
            return this.$page.props.sheet.data;
        },
        breadItems() {
            return [
                {
                    text: "Expense Sheets",
                    disabled: false,
                    link: this.admin
                        ? this.route("staff.admin.expenses.index")
                        : this.route("staff.expense-sheets.index"),
                },
                {
                    text: `Sheet ${this.sheet.number}`,
                    disabled: true,
                },
            ];
        },
    },
};
</script>

<style scoped></style>
