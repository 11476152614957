<template>
    <div
        :style="{ 'background-color': backgroundColor }"
        class="pa-2"
        style="height: 100%"
    >
        <!--Chip-->
        <div>
            <v-chip
                v-if="showChip"
                class="mb-1"
                dark
                x-small
                label
                :color="chipColor"
            >
                {{ chipText }}
            </v-chip>
        </div>

        <div>{{ task.name }}</div>
        <div>
            {{ formatForEvent(task.start) }} -
            {{ task.when_its_done ? "🚀" : formatForEvent(task.end) }}
        </div>
        <div v-if="countPeople">
            {{ countPeople }} {{ countPeople > 1 ? "People" : "Person" }}
        </div>
        <div v-else>Everyone</div>
        <div v-if="task.vehicles.length">
            {{ task.vehicles.length }}
            {{ task.vehicles.length > 1 ? "Vehicles" : "Vehicle" }}
        </div>
    </div>
</template>

<script>
import LogisticTaskMixin from "../../../../Mixins/LogisticTaskMixin";

export default {
    name: "CalenderTaskEvent",
    props: {
        task: {
            type: Object,
            required: true,
        },
        logistic: {
            type: Object,
            required: true,
        },
        user: {
            type: Boolean,
        },
    },

    mixins: [LogisticTaskMixin],

    computed: {
        otherTasksWithSameDay() {
            return _.filter(this.logistic.tasks, (task) => {
                return task.date === this.task.date && task.id !== this.task.id;
            });
        },
    },

    methods: {
        formatTime(time, to = false) {
            if (!time) {
                return to ? "23:59" : "00:00";
            }
            return this.moment(time).format("HH:mm");
        },
    },
};
</script>

<style scoped></style>
