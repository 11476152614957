<template>
    <v-container class="fixed">
        <breadcrumbs :items="bread"></breadcrumbs>

        <header-name>Survey | {{ survey.job_name }}</header-name>

        <v-sheet elevation="2" class="pa-4 mb-6" rounded>
            <v-row>
                <v-col class="text-center" cols="12" md="6">
                    <div class="font-weight-bold">Client Name</div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="ml-md-3 ml-0 text-md-left text-center">
                        {{ survey.client_name }}
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col class="text-center" cols="12" md="6">
                    <div class="font-weight-bold">Reporter Name</div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="ml-md-3 ml-0 text-md-left text-center">
                        {{
                            survey.survey.reporter_name
                                ? survey.survey.reporter_name
                                : "N/A"
                        }}
                    </div>
                </v-col>
            </v-row>

            <div v-for="rating in ratings" :key="rating.index">
                <v-divider v-if="$vuetify.breakpoint.smAndDown" class="my-2" />
                <v-row class="justify-center">
                    <v-col
                        class="d-flex align-center justify-center"
                        cols="12"
                        md="6"
                    >
                        <strong>{{ rating.name }}</strong>
                    </v-col>
                    <v-col
                        class="d-flex flex-column align-md-start align-center"
                        cols="12"
                        md="6"
                    >
                        <v-rating
                            :value="toInt(survey.survey[rating.index])"
                            readonly
                            hover
                            size="32"
                            length="5"
                        />
                        <div class="ml-3">
                            <template v-if="survey.survey.additional">
                                {{
                                    survey.survey.additional[rating.index]
                                        ? survey.survey.additional[rating.index]
                                        : "No additional comment"
                                }}
                            </template>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <v-divider class="my-4" />

            <div
                v-for="field in fields"
                :key="field.index"
                class="text-center pa-4"
            >
                <div class="text-body-1 font-weight-bold">{{ field.name }}</div>
                <div style="white-space: pre-wrap">
                    {{ survey.survey[field.index] }}
                </div>
            </div>
        </v-sheet>
    </v-container>
</template>

<script>
import Breadcrumbs from "../../../../Comps/General/Breadcrumbs";
import HeaderName from "../../../../Comps/General/HeaderName";
import AdminController from "../../../../Mixins/AdminController";

export default {
    name: "SurveysIndex",
    components: { HeaderName, Breadcrumbs },
    mixins: [AdminController],
    props: ["survey"],

    data() {
        return {
            bread: [
                {
                    text: "Surveys",
                    disabled: false,
                    link: this.route("staff.admin.surveys.index"),
                },
                {
                    text: this.survey.job_name,
                    disabled: true,
                },
            ],
            ratings: [
                { name: "Quality of delivery", index: "quality" },
                { name: "Level of customer service", index: "service" },
                { name: "Value for money", index: "value_for_money" },
                { name: "Creativity", index: "creativity" },
                { name: "Flexibility", index: "flexibility" },
                { name: "Quality of on-site staff", index: "on_site_staff" },
            ],
            fields: [
                { name: "Best Parts", index: "best_parts" },
                { name: "Improve On", index: "bad_parts" },
                { name: "Make us the perfect partner", index: "partner" },
                { name: "Additional Comments", index: "comments" },
            ],
        };
    },

    methods: {
        toInt(value) {
            return parseInt(value);
        },
    },
};
</script>

<style scoped></style>
