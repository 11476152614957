<template>
    <validation-provider
        v-slot="{ errors }"
        :name="name"
        :rules="rules"
        class="custom-form"
    >
        <v-autocomplete
            v-model="model"
            :name="name"
            outlined
            :background-color="backgroundClasses"
            dense
            :error-messages="errors"
            v-bind="$attrs"
            v-on="$listeners"
            rounded
            count
            :items="items"
            :label="computedLabel"
            :class="{ required: required }"
            :required="required"
        />
    </validation-provider>
</template>

<script>
import VModel from "../../Mixins/VModel";
import FormInput from "../../Mixins/FormInput";

export default {
    name: "AutoComplete",
    mixins: [VModel, FormInput],
    props: {
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
        },
        rules: {
            type: String,
            default: "",
        },
        required: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            required: true,
        },
    },

    computed: {
        computedLabel() {
            return this.label ? this.label : this.name;
        },
    },
};
</script>

<style lang="scss"></style>
