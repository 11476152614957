<template>
    <div>
        <section>
            <div v-if="task.time_from">
                <span>{{ getStartTime(task.time_from) }}</span>
                <span v-if="task.time_to">
                    -
                    {{
                        task.when_its_done
                            ? "When its done 🚀"
                            : getStartTime(task.time_to)
                    }}</span
                >
            </div>
            <div v-else>All Day</div>
        </section>

        <section class="my-2 d-flex align-center">
            <v-icon class="mr-2">
                {{ task.dress_code_data.icon }}
            </v-icon>
            <span>
                {{ task.dress_code_data.description }}
            </span>
        </section>

        <section v-if="task.job">
            <a-tag-with-external-icon
                :url="task.job.url"
                :text="task.job.name"
                styles="color: inherit"
            >
            </a-tag-with-external-icon>
        </section>

        <section v-if="!task.users.length && !task.freelancers.length">
            <div class="font-weight-bold">Everyone</div>
        </section>

        <section v-if="task.users.length" class="mt-2">
            <h6 class="body-2">
                {{ task.users.length }}
                {{ task.users.length > 1 ? "Users" : "User" }}
            </h6>
            <div class="d-flex mt-2 flex-wrap">
                <task-info-modal-user
                    v-for="user in task.users"
                    :key="user.id"
                    :user="user"
                    :logistic="user ? null : logistic"
                    :task="task"
                />
            </div>
        </section>

        <section v-if="task.freelancers.length" class="mt-2">
            <h6 class="body-2">
                {{ task.freelancers.length }}
                {{ task.freelancers.length > 1 ? "Freelancers" : "Freelancer" }}
            </h6>
            <div class="d-flex mt-2 flex-wrap">
                <div v-for="user in task.freelancers" :key="user.id">
                    <task-info-modal-user
                        :user="user"
                        freelancer
                        :logistic="user ? null : logistic"
                        :task="task"
                    />
                </div>
            </div>
        </section>

        <section v-if="task.vehicles.length" class="mt-2">
            <h6 class="body-2">
                {{ task.vehicles.length }}
                {{ task.vehicles.length > 1 ? "Vehicles" : "Vehicle" }}
            </h6>

            <div class="d-flex flex-wrap">
                <div v-for="vehicle in task.vehicles" :key="vehicle.id">
                    <task-info-modal-vehicle
                        :vehicle="vehicle"
                        :logistic="user ? null : logistic"
                        :task="task"
                    />
                </div>
            </div>
        </section>

        <section class="mt-4 font-weight-bold" v-if="leadPerson">
            Project lead: {{ leadPerson.name }}
        </section>

        <v-divider class="mt-4" />

        <section class="mt-4 black--text">
            <div v-if="task.text" v-html="$autolinker.link(task.text)"></div>
            <div v-else>No task info</div>
        </section>

        <v-divider class="mt-4" />

        <section class="mt-4">
            <div class="text-subtitle-2 font-weight-bold">Notes</div>
            <div v-if="!taskNotes || !taskNotes.length" class="mt-3">
                No notes
            </div>
            <div v-else class="mt-2">
                <div v-for="note in taskNotes" :key="note.id" class="mt-1">
                    <div class="d-flex" style="word-break: break-all">
                        <div>
                            <div>Created by: {{ note.user.name }}</div>
                            <div>
                                Created on:
                                {{ momentFormatDateTimeYear(note.created_at) }}
                            </div>
                            <div
                                style="white-space: pre-line"
                                class="black--text"
                            >
                                Note:
                                <span v-html="$autolinker.link(note.note)" />
                            </div>
                        </div>
                        <div
                            v-if="
                                hasPermission('staffAdminLogistics') &&
                                logistic.sent
                            "
                            class="ml-auto"
                        >
                            <v-btn
                                icon
                                @click.stop="deleteNote(note)"
                                small
                                color="error"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <v-divider class="mt-2" />
                </div>
            </div>

            <div v-if="canShowNoteCreate" class="mt-4">
                <form novalidate @submit.prevent="addNote">
                    <validation-observer ref="form">
                        <text-area
                            name="Note"
                            v-model="noteForm.note"
                            rules="required"
                            rows="2"
                            label="New Note"
                        />
                    </validation-observer>

                    <div class="d-flex justify-end">
                        <v-btn
                            color="success"
                            type="submit"
                            small
                            :disabled="!noteForm.note"
                            :loading="noteForm.processing"
                        >
                            Add Note
                        </v-btn>
                    </div>
                </form>
            </div>
        </section>

        <v-divider class="mt-4" />

        <div class="font-weight-bold text-subtitle-2 mt-4 mb-2">Activity</div>
        <template v-if="activities && activities.length">
            <task-info-modal-activity
                v-for="activity in activities"
                :key="activity.id"
                :activity="activity"
            />
        </template>

        <task-info-modal-activity
            v-if="task.user"
            :activity="{
                description: 'created',
                causer: { name: task.user.name },
                created_at: task.created_at,
            }"
        />
    </div>
</template>

<script>
import ATagWithExternalIcon from "../../../../General/ATagWithExternalIcon";
import TaskInfoModalUser from "./TaskInfoModalUser";
import TaskInfoModalVehicle from "./TaskInfoModalVehicle";
import TextArea from "../../../../Forms/TextArea.vue";
import Permissions from "../../../../../Mixins/Permissions.js";
import TaskInfoModalActivity from "./TaskInfoModalActivity.vue";

export default {
    name: "TaskInfoModalInfo",
    components: {
        TaskInfoModalActivity,
        TextArea,
        TaskInfoModalVehicle,
        TaskInfoModalUser,
        ATagWithExternalIcon,
    },
    props: ["task", "logistic", "user"],
    mixins: [Permissions],

    data() {
        return {
            noteForm: this.$inertia.form({
                note: "",
            }),
            activities: null,
        };
    },

    mounted() {
        this.getActivity();
    },

    methods: {
        getStartTime(time) {
            return this.moment(time).utc().format("HH:mm");
        },
        addNote() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.noteForm.post(
                    this.route("staff.logistics.task.note.store", this.task),
                    {
                        onSuccess: () => {
                            this.noteForm.reset();
                            this.$refs.form.reset();
                            this.updateTask();
                        },
                    }
                );
            });
        },
        deleteNote(note) {
            if (!confirm(`Are you sure you want to delete this note?`)) {
                return;
            }

            this.$inertia.delete(
                this.route("staff.logistics.task.note.destroy", note.id),
                {
                    onSuccess: () => {
                        this.updateTask();
                    },
                }
            );
        },
        updateTask() {
            if (!this.logistic?.tasks) {
                return;
            }
            const task = this.logistic.tasks.find(
                (task) => task.id === this.task.id
            );

            this.$emit("newNote", task);
        },
        getActivity() {
            this.$http
                .get(this.route("staff.logistics.task.activity", this.task))
                .then((response) => {
                    this.activities = response.data;
                })
                .catch(() => {
                    this.activities = null;
                });
        },
    },

    computed: {
        leadPerson() {
            let find = _.find(this.task.users, (user) => {
                return user.pivot.lead;
            });

            if (find) {
                return find;
            } else {
                return _.find(this.task.freelancers, (user) => {
                    return user.pivot.lead;
                });
            }
        },

        canShowNoteCreate() {
            if (!this.logistic.sent) {
                return false;
            }

            return true;
        },

        taskNotes() {
            if (!this.logistic.sent) {
                return this.task.previous_notes;
            }
            return this.task.notes;
        },
    },
};
</script>

<style scoped></style>
