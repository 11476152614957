var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('div',{staticClass:"d-flex"},[_c('header-name',[_vm._v("Logistics")]),_vm._v(" "),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Subscribe to all logistics'),expression:"'Subscribe to all logistics'"}],staticClass:"ml-2 subscribe-ical",attrs:{"icon":"","small":""},on:{"click":function($event){$event.preventDefault();_vm.icalModal = true}}},[_c('v-icon',[_vm._v("mdi-calendar-export")])],1)],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-center ml-2 mb-2"},[(_vm.logistic)?_c('h3',{class:_vm.nameClasses},[_vm._v("\n                "+_vm._s(_vm.momentFormatDate(_vm.thisWeek))+" - Version "+_vm._s(_vm.version)+" ("+_vm._s(_vm.logistic.sent ? "Live" : "Draft")+")\n            ")]):_vm._e(),_vm._v(" "),_c('v-fade-transition',[(
                        _vm.logistic &&
                        _vm.logistic.users.length &&
                        _vm.logistic.users[0].pivot.accepted
                    )?_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('You have accepted this version'),expression:"'You have accepted this version'"}],staticClass:"ml-2",attrs:{"color":"success","large":""}},[_vm._v("\n                    mdi-check-outline\n                ")]):_vm._e()],1)],1),_vm._v(" "),_c('logistics-date-version-picker',{attrs:{"this-week":_vm.thisWeek,"versions":_vm.versions,"version":_vm.version},on:{"update:thisWeek":function($event){_vm.thisWeek=$event},"update:this-week":function($event){_vm.thisWeek=$event},"update:version":function($event){_vm.version=$event}}}),_vm._v(" "),(_vm.logistic)?_c('section',[_c('v-fade-transition',[(
                        _vm.logistic.users.length &&
                        !_vm.logistic.users[0].pivot.accepted
                    )?_c('v-alert',{attrs:{"border":"top","prominent":"","colored-border":"","type":"info","elevation":"2"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[(_vm.logistic.version > 1)?_c('span',[_vm._v("\n                                Please accept this version of logistics to\n                                inform HR you have read and understood the\n                                changes\n                            ")]):_c('span',[_vm._v("\n                                Please accept this first version of\n                                logistics to inform HR you have read and\n                                understood the current week\n                            ")])]),_vm._v(" "),_c('v-col',{staticClass:"shrink"},[_c('inertia-link',{staticClass:"inertia-link",attrs:{"href":_vm.route(
                                        'staff.logistics.accept',
                                        _vm.logistic.slug
                                    ),"method":"put","as":"div"}},[_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"success"}},[_vm._v("\n                                    I Accept\n                                ")])],1)],1)],1)],1):_vm._e()],1),_vm._v(" "),(_vm.logistic && !_vm.logistic.sent)?_c('v-alert',{attrs:{"border":"top","colored-border":"","type":"warning","elevation":"2"}},[_c('div',[_vm._v("\n                    This is a draft version of logistics and subject to\n                    change\n                ")])]):_vm._e(),_vm._v(" "),_c('logistics-viewer',{attrs:{"this-week":_vm.thisWeek,"logistic":_vm.logistic,"user":""}})],1):_c('div',{staticClass:"mt-10 text-center"},[_c('h4',{staticClass:"mb-3"},[_vm._v("\n                No logistics have been found for this week yet\n            ")])])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-sheet',[_c('h5',{staticClass:"text--secondary mb-4"},[_vm._v("Information")]),_vm._v(" "),_c('v-expansion-panels',{model:{value:(_vm.information),callback:function ($$v) {_vm.information=$$v},expression:"information"}},[_c('category-key',{attrs:{"categories":_vm.categories}})],1)],1)],1),_vm._v(" "),_c('modal-template',{model:{value:(_vm.icalModal),callback:function ($$v) {_vm.icalModal=$$v},expression:"icalModal"}},[_c('h2',[_vm._v("Subscribe to logistics")]),_vm._v(" "),_c('p',[_vm._v("\n            Below is the subscription link for your calendar application."),_c('br'),_vm._v("\n            In your chosen calendar application create a new calendar\n            subscription and paste the link in below.\n        ")]),_vm._v(" "),_c('v-text-field',{attrs:{"readonly":"","value":_vm.icalUrl},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Copy to clipboard'),expression:"'Copy to clipboard'"}],on:{"click":_vm.clickCopy}},[_vm._v("mdi-content-copy")])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }