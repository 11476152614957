<script>
import ModalTemplate from "../../../../../Comps/Master/ModalTemplate.vue";
import AutocompleteSearchCurrent from "../../../../../Comps/General/AutocompleteSearchCurrent.vue";
import CurrencyInput from "../../../../../Comps/General/Forms/CurrencyInput.vue";
import DateTimePicker from "../../../../../Comps/Master/DateTimePicker.vue";
import TextInput from "../../../../../Comps/Forms/TextInput.vue";

export default {
    name: "VisitorLogModal",
    components: {
        TextInput,
        DateTimePicker,
        CurrencyInput,
        AutocompleteSearchCurrent,
        ModalTemplate,
    },
    data() {
        return {
            form: this.$inertia.form({}),
            modal: false,
        };
    },

    methods: {
        edit(visitorLog) {
            this.modal = true;
            this.form = this.$inertia.form(visitorLog);
        },

        create() {
            this.modal = true;
            this.form = this.$inertia.form({
                id: "",
                name: "",
                email: "",
                arrived_at: new Date(),
                departed_at: "",
            });
        },

        async submit() {
            if (!(await this.$refs.validation.validate())) {
                return;
            }

            if (this.form.id) {
                this.form.put(
                    this.route("staff.admin.visitor-logs.update", this.form.id),
                    {
                        onSuccess: () => {
                            this.modal = false;
                        },
                    }
                );

                return;
            }

            this.form.post(this.route("staff.admin.visitor-logs.store"), {
                onSuccess: () => {
                    this.modal = false;
                },
            });
        },
    },
};
</script>

<template>
    <modal-template v-model="modal">
        <div class="text-h6 text--secondary mb-2 mt-2">
            {{ form.id ? "Edit" : "Create" }} a Visitor Log
        </div>

        <form novalidate @submit.prevent="submit">
            <validation-observer ref="validation">
                <v-row>
                    <v-col cols="12" md="6">
                        <text-input
                            autofocus
                            name="Name"
                            label="Name"
                            required
                            rules="required"
                            v-model="form.name"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-input
                            name="Email"
                            label="Email"
                            required
                            type="email"
                            rules="required"
                            v-model="form.email"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <date-time-picker
                            v-model="form.arrived_at"
                            label="Arrived At"
                            name="Arrived At"
                            mode="datetime"
                            :minute-increment="5"
                            required
                        />
                    </v-col>

                    <v-col cols="12" md="6">
                        <date-time-picker
                            v-model="form.departed_at"
                            label="Departed At"
                            name="Departed At"
                            mode="datetime"
                            :minute-increment="5"
                        />
                    </v-col>
                </v-row>
            </validation-observer>

            <v-btn
                color="success"
                type="submit"
                class="mt-6"
                :loading="form.processing"
            >
                Save
            </v-btn>
        </form>
    </modal-template>
</template>
