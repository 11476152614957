<template>
    <div>
        <h4>Select the days below to clone this task to</h4>

        <v-checkbox
            v-for="day in days"
            :key="day.value"
            :value="day.value"
            :label="day.label"
            :disabled="day.value == taskDayNumber"
            v-model="selectedDays"
        >
        </v-checkbox>

        <v-btn color="primary" light @click="cloneTask" :loading="loading">
            Clone task
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "TaskInfoModalClone",
    props: ["task", "logistic"],

    data() {
        return {
            days: [
                { label: "Monday", value: 1 },
                { label: "Tuesday", value: 2 },
                { label: "Wednesday", value: 3 },
                { label: "Thursday", value: 4 },
                { label: "Friday", value: 5 },
                { label: "Saturday", value: 6 },
                { label: "Sunday", value: 7 },
            ],
            selectedDays: [],
            loading: false,
        };
    },

    computed: {
        taskDayNumber() {
            return this.moment(this.task.date).day();
        },
    },

    methods: {
        cloneTask() {
            if (!this.selectedDays.length) {
                this.$toast.open({
                    message: "Please select at least 1 day",
                    type: "error",
                    duration: 4000,
                });
                return;
            }

            this.loading = true;

            this.$inertia.post(
                this.route("staff.admin.logistics.tasks.clone", [
                    this.logistic.slug,
                    this.task.id,
                ]),
                {
                    days: this.selectedDays,
                },
                {
                    preserveState: false,
                }
            );
        },
    },
};
</script>

<style scoped></style>
