<template>
    <div v-if="holidays.data.length">
        <div class="d-flex">
            <v-pagination v-model="page" :length="holidays.last_page">
            </v-pagination>
        </div>
        <holiday-table :holidays="holidays.data" />
    </div>
</template>

<script>
import HolidayTable from "./HolidayTable";

export default {
    name: "HolidayComp",
    components: { HolidayTable },
    props: {
        holidays: {
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            page: this.holidays.current_page,
        };
    },

    watch: {
        page(page) {
            this.$inertia.visit(
                this.route("staff.admin.users.show", this.user.id),
                {
                    method: "get",
                    data: {
                        holidayPage: page,
                    },
                    only: ["holidays", "flash"],
                    preserveScroll: true,
                    preserveState: true,
                }
            );
        },
    },
};
</script>

<style scoped></style>
