<template>
    <v-container class="fixed">
        <v-row class="justify-center">
            <v-col cols="12" md="11">
                <div class="d-flex">
                    <div>
                        <header-name
                            >LOLER Records -
                            <span v-if="lolers.length">{{
                                lolers[0].asset
                            }}</span>
                        </header-name>
                        <div class="text-body-1">
                            {{ lolers[0].product.name }}
                        </div>
                    </div>
                    <v-btn
                        class="ml-auto"
                        color="primary"
                        small
                        @click="searchAgain"
                    >
                        Search Again
                    </v-btn>
                </div>

                <v-sheet class="pa-6 mt-4" elevation="2">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>Asset</th>
                                <th>Status</th>
                                <th>User</th>
                                <th>Timestamp</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="loler in lolers">
                                <td>{{ loler.asset }}</td>
                                <td>
                                    <v-chip
                                        label
                                        small
                                        :color="
                                            loler.overall ? 'success' : 'error'
                                        "
                                    >
                                        {{
                                            loler.overall ? "Passed" : "Failed"
                                        }}
                                    </v-chip>
                                </td>
                                <td>{{ loler.user.name }}</td>
                                <td>
                                    {{
                                        momentFormatDateTimeYear(
                                            loler.created_at
                                        )
                                    }}
                                </td>
                                <td>
                                    <div v-if="loler.status !== 1">
                                        <v-btn icon loading />
                                    </div>
                                    <div v-else>
                                        <v-btn
                                            @click="deleteLoler(loler)"
                                            v-tooltip="'Delete'"
                                            icon
                                        >
                                            <v-icon color="error"
                                                >mdi-delete</v-icon
                                            >
                                        </v-btn>
                                        <a
                                            target="_blank"
                                            class="inertia-link"
                                            :href="
                                                route(
                                                    'staff.loler.records.download-report',
                                                    loler.id
                                                )
                                            "
                                        >
                                            <v-btn
                                                v-tooltip="'Download Report'"
                                                icon
                                            >
                                                <v-icon color="primary"
                                                    >mdi-file-download</v-icon
                                                >
                                            </v-btn>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ProcessesController from "../../../Mixins/ProcessesController";
import HeaderName from "../../../Comps/General/HeaderName";

export default {
    name: "LolerSearch",
    components: { HeaderName },
    mixins: [ProcessesController],
    props: ["lolers"],

    methods: {
        searchAgain() {
            this.$inertia.visit(this.route("staff.loler.records"));
        },

        deleteLoler(loler) {
            if (
                !confirm("Are you sure you want to delete this LOLER record?")
            ) {
                return;
            }

            this.$inertia.delete(this.route("staff.loler.delete", loler));
        },
    },
};
</script>

<style scoped></style>
