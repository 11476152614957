<template>
    <div>
        <div v-if="showDownloader" class="d-flex justify-space-between pa-2">
            <v-menu v-if="hasAdminPermission" offset-y eager>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        small
                        :disabled="!selectedHolidays.length || loadingBatch"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        class="ma-2"
                    >
                        Actions
                        <v-chip
                            class="action-chip mx-1"
                            x-small
                            v-if="selectedHolidays.length"
                        >
                            {{ selectedHolidays.length }}
                        </v-chip>
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item @click="bulkApprove">
                        <v-list-item-title class="success--text"
                            >Approve</v-list-item-title
                        >
                    </v-list-item>
                    <v-list-item @click="bulkDeny">
                        <v-list-item-title class="error--text"
                            >Deny</v-list-item-title
                        >
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-btn icon @click="getCsv">
                <v-icon>mdi-download</v-icon>
            </v-btn>
        </div>

        <v-data-table
            v-if="holidays.length"
            :items-per-page="-1"
            hide-default-footer
            :show-select="hasAdminPermission"
            v-model="selectedHolidays"
            :headers="headers"
            :items="holidays"
            @click:row="clickRow"
        >
            <template v-slot:item.user="{ item }">
                {{ item.user.name }}
            </template>
            <template v-slot:item.date="{ item }">
                <div class="d-flex align-end">
                    <div>{{ holidayDateCalc(item) }}</div>
                    <div class="text-caption ml-2">({{ item.type }})</div>
                </div>
            </template>
            <template v-slot:item.status="{ item }">
                <div>
                    <v-chip x-small label v-if="!item.approved && !item.denied">
                        Pending
                    </v-chip>
                    <v-chip
                        x-small
                        label
                        color="error"
                        v-if="!item.approved && item.denied"
                    >
                        Denied
                    </v-chip>
                    <v-chip
                        x-small
                        label
                        color="success"
                        v-if="item.approved && !item.denied"
                    >
                        Approved
                    </v-chip>
                    <v-chip x-small label color="primary" v-if="item.half_day">
                        {{ item.morning ? "Morning" : "Afternoon" }}
                    </v-chip>
                    <v-chip x-small label color="primary" v-if="item.evening">
                        Evening
                    </v-chip>
                </div>
            </template>
            <template v-slot:item.created_at="{ value }">
                <div class="d-flex align-end">
                    <div>{{ momentFormatDateTimeYear(value) }}</div>
                </div>
            </template>
        </v-data-table>

        <div v-else class="pa-6 text-center">
            <slot>No future holidays found</slot>
        </div>

        <holiday-view-modal ref="viewModal" />
    </div>
</template>

<script>
import HolidayViewModal from "../../Holiday/HolidayViewModal";
import Permissions from "../../../../Mixins/Permissions";
import Papa from "papaparse";

export default {
    name: "HolidayTable",
    components: { HolidayViewModal },
    mixins: [Permissions],
    props: {
        holidays: {
            type: Array,
            required: true,
        },
        showUser: {
            type: Boolean,
            default: false,
        },
        showRequestedAt: {
            type: Boolean,
            default: false,
        },
        showDownloader: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            selectedHolidays: [],
            loadingBatch: false,
        };
    },

    methods: {
        getCsv() {
            const csv = Papa.unparse(this.dataForCsv);
            const blob = new Blob([csv], { type: "octet/stream" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
            a.href = url;
            a.download = "Pending Holiday.csv";
            a.click();
            window.URL.revokeObjectURL(url);
        },
        holidayDateCalc(holiday) {
            if (holiday.start_date === holiday.end_date) {
                return this.momentFormatDateYear(holiday.start_date);
            }

            return (
                this.momentFormatDateYear(holiday.start_date) +
                " - " +
                this.momentFormatDateYear(holiday.end_date)
            );
        },
        clickRow(holiday) {
            this.$refs.viewModal.open(holiday);
        },
        bulkApprove() {
            if (
                !confirm(
                    `Are you sure you want to approve ${
                        this.selectedHolidays.length
                    } ${
                        this.selectedHolidays.length === 1
                            ? "holiday"
                            : "holidays"
                    }?`
                )
            ) {
                return;
            }

            this.loadingBatch = true;

            this.$inertia.put(
                this.route("staff.admin.holidays.bulk.approve"),
                {
                    ids: _.map(this.selectedHolidays, "id"),
                },
                this.bulkOptions
            );
        },
        bulkDeny() {
            if (
                !confirm(
                    `Are you sure you want to deny ${
                        this.selectedHolidays.length
                    } ${
                        this.selectedHolidays.length === 1
                            ? "holiday"
                            : "holidays"
                    }?`
                )
            ) {
                return;
            }

            this.loadingBatch = true;

            this.$inertia.put(
                this.route("staff.admin.holidays.bulk.deny"),
                {
                    ids: _.map(this.selectedHolidays, "id"),
                },
                this.bulkOptions
            );
        },
    },

    computed: {
        dataForCsv() {
            return this.holidays.map((item) => ({
                Name: item.user.name,
                Date: this.holidayDateCalc(item),
                Type: item.type,
                Status: item.approved
                    ? "Approved"
                    : item.denied
                    ? "Denied"
                    : "Pending",
                "Half Day": item.half_day
                    ? item.morning
                        ? "Morning"
                        : "Afternoon"
                    : "Full Day",
                "Requested At": this.momentFormatDateTimeYear(item.created_at),
            }));
        },

        headers() {
            let headers = [
                { text: "Date", value: "date", sortable: false },
                { text: "Status", value: "status", sortable: false },
            ];

            if (this.showUser) {
                headers.unshift({
                    text: "User",
                    value: "user",
                    sortable: false,
                });
            }

            if (this.showRequestedAt) {
                headers.push({
                    text: "Requested At",
                    value: "created_at",
                    sortable: false,
                });
            }

            return headers;
        },
        bulkOptions() {
            return {
                onSuccess: () => {
                    this.selectedHolidays = [];
                },
                onFinish: () => {
                    this.loadingBatch = false;
                },
            };
        },
        hasAdminPermission() {
            return this.hasPermission("staffAdminHolidays");
        },
    },
};
</script>

<style scoped></style>
