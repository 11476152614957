<template>
    <v-container>
        <div class="d-flex mb-5">
            <header-name>Screens</header-name>
        </div>
        <div class="d-flex justify-center">
            <v-sheet class="text--secondary pa-10 my-4" elevation="2" rounded>
                <strong> Display URLs: </strong>
                <br />
                <br />
                Warehouse:
                <a
                    @click.prevent="
                        copy(
                            route(
                                'screens.warehouse',
                                { api_token: 'YOUR_TOKEN_HERE' },
                                true
                            )
                        )
                    "
                    href="#"
                >
                    {{
                        route(
                            "screens.warehouse",
                            { api_token: "YOUR_TOKEN_HERE" },
                            true
                        )
                    }}
                    <v-icon class="ml-3" v-tooltip="'Copy to clipboard'">
                        mdi-content-copy
                    </v-icon>
                </a>
                <br />
                <br />
                Office:
                <a
                    @click.prevent="
                        copy(
                            route(
                                'screens.office',
                                { api_token: 'YOUR_TOKEN_HERE' },
                                true
                            )
                        )
                    "
                    href="#"
                >
                    {{
                        route(
                            "screens.office",
                            { api_token: "YOUR_TOKEN_HERE" },
                            true
                        )
                    }}
                    <v-icon class="ml-3" v-tooltip="'Copy to clipboard'">
                        mdi-content-copy
                    </v-icon>
                </a>
            </v-sheet>
        </div>
    </v-container>
</template>

<script>
import AdminController from "../../../../Mixins/AdminController.js";
import HeaderName from "../../../../Comps/General/HeaderName.vue";
import CopyToClipboard from "../../../../Mixins/CopyToClipboard.js";

export default {
    name: "ScreensIndex",
    computed: {
        CopyToClipboard() {
            return CopyToClipboard;
        },
    },
    components: { HeaderName },
    mixins: [AdminController, CopyToClipboard],

    data() {
        return {
            deleteForm: this.$inertia.form({}),
        };
    },
    methods: {
        refresh() {
            this.$inertia.reload({ only: ["screens"] });
        },

        deleteScreen(screen) {
            if (!confirm("Are you sure you want to delete this screen?")) {
                return;
            }

            this.deleteForm.delete(
                this.route("staff.admin.screens.destroy", screen.id)
            );
        },
    },
};
</script>

<style scoped></style>
