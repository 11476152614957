<template>
    <div>
        <div>
            <div>Holiday</div>
            <br />
            <div>
                {{ holidays.length ? `${holidays.length} People` : "None" }}
            </div>
        </div>
    </div>
</template>

<script>
import LogisticsHolidayHelpers from "../../../../../Mixins/Logistics/LogisticsHolidayHelpers";

export default {
    name: "TimelineHolidayTask",
    mixins: [LogisticsHolidayHelpers],
    props: {
        task: Object,
    },
    computed: {
        holidays() {
            return this.getDaysHolidays(this.day);
        },

        day() {
            return this.moment.utc(this.task.date);
        },
    },

    methods: {
        clickHoliday() {
            if (!this.holidays.length) {
                return;
            }

            this.$refs.modal.open(this.holidays);
        },
    },
};
</script>

<style scoped></style>
