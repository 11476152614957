import { render, staticRenderFns } from "./PerformanceLogs.vue?vue&type=template&id=b7b01992&scoped=true"
import script from "./PerformanceLogs.vue?vue&type=script&lang=js"
export * from "./PerformanceLogs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7b01992",
  null
  
)

export default component.exports