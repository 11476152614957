<template>
    <v-container>
        <header-name>Holiday</header-name>

        <v-sheet v-if="!hasHolidaysSetup" elevation="2" class="pa-8">
            <div class="text-body-1">
                You do not have an holiday allowance, please speak with HR to
                get started
            </div>
        </v-sheet>

        <div v-else>
            <holiday-stats-grid :stats="stats" />
        </div>

        <v-btn
            color="success"
            class="mt-8 mb-2"
            small
            @click="$refs.holidayCreate.create()"
            v-if="hasHolidaysSetup"
        >
            Request Holiday
        </v-btn>

        <div class="d-flex justify-center mb-4 mt-4">
            <v-btn-toggle
                :value="selectedTab"
                color="primary"
                borderless
                mandatory
            >
                <v-btn
                    elevation="1"
                    small
                    v-for="tab in tabs"
                    :key="tab.value"
                    :value="tab.value"
                    @click="selectTab(tab)"
                >
                    {{ tab.text }}
                </v-btn>
            </v-btn-toggle>
        </div>

        <v-fade-transition group>
            <v-sheet v-if="selectedTab === 'list'" key="list" elevation="2">
                <holiday-table :holidays="stats.future_holiday" />
            </v-sheet>

            <holiday-calendar
                v-if="selectedTab === 'calendar'"
                key="calendar"
                ref="holidayCalendar"
                :holidays="holidays"
                :has-holidays-setup="hasHolidaysSetup"
            />
        </v-fade-transition>

        <holiday-create-modal ref="holidayCreate" />
    </v-container>
</template>

<script>
import PeopleController from "../../../Mixins/PeopleController";
import HeaderName from "../../../Comps/General/HeaderName";
import HolidayCreateModal from "../../../Comps/Staff/Holiday/HolidayCreateModal";
import HolidayCalendar from "../../../Comps/Staff/Holiday/HolidayCalendar";
import TabMixin from "../../../Mixins/TabMixin";
import HolidayTable from "../../../Comps/Staff/Personal/Holiday/HolidayTable";
import HolidayStatsGrid from "../../../Comps/Staff/Personal/Holiday/HolidayStatsGrid";

export default {
    name: "Holiday",
    components: {
        HolidayStatsGrid,
        HolidayTable,
        HolidayCalendar,
        HolidayCreateModal,
        HeaderName,
    },
    mixins: [PeopleController, TabMixin],
    props: {
        holidays: {
            type: Array,
            default: null,
        },
        stats: Object,
        hasHolidaysSetup: Boolean,
    },

    data() {
        return {
            tabs: [
                { value: "calendar", text: "Calendar" },
                { value: "list", text: "Future Holiday" },
            ],
        };
    },
};
</script>

<style lang="scss">
.holiday-calendar {
    .v-event {
        height: auto !important;
    }
}
</style>
