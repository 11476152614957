<template>
    <v-container class="fixed">
        <breadcrumbs :items="bread"></breadcrumbs>
        <header-name>{{ reports[0].job_name }}</header-name>

        <v-expansion-panels class="mt-6">
            <v-expansion-panel v-for="report in reports" :key="report.id">
                <v-expansion-panel-header>
                    {{ momentFormatDateTime(report.created_at) }} - By:
                    {{ report.user.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-divider class="mb-4" />
                    <div class="text-body-1">
                        <strong>Status:</strong> {{ report.status }}
                    </div>
                    <div class="text-body-1">
                        <strong>Parking:</strong> {{ report.parking }}
                    </div>
                    <div class="text-body-1">
                        <strong>Faulty equipment:</strong> {{ report.faulty }}
                    </div>
                    <br />
                    <div class="text-body-1">
                        <strong>Anything that held you up?</strong><br />
                        {{ report.held_you_up }}
                    </div>
                    <br />
                    <div class="text-body-1">
                        <strong>Comments on prep:</strong><br />
                        {{ report.prep }}
                    </div>
                    <br />
                    <div class="text-body-1">
                        <strong>Derig notes:</strong><br />
                        {{ report.derig }}
                    </div>
                    <br />
                    <div class="text-body-1">
                        <strong>Case Storage:</strong><br />
                        {{ report.case_storage }}
                    </div>
                    <br />
                    <div class="text-body-1">
                        <strong>
                            Any positive or negative feedback onsite?
                        </strong>
                        <br />
                        {{ report.feedback }}
                    </div>
                    <br />
                    <div class="text-body-1">
                        <strong
                            >Any agreements with third parties or follow-on
                            notes?</strong
                        ><br />
                        {{ report.agreements }}
                    </div>
                    <br />
                    <div class="text-body-1">
                        <strong> Full names of anyone you dealt with: </strong
                        ><br />
                        {{ report.names }}
                    </div>
                    <br />
                    <div class="text-body-1">
                        <strong>General notes:</strong><br />
                        {{ report.notes }}
                    </div>
                    <br />
                    <div class="text-body-1">
                        <strong>Two things we did really well:</strong><br />
                        {{ report.twothings_1 }}<br />{{ report.twothings_2 }}
                    </div>
                    <br />
                    <div class="text-body-1">
                        <strong>Star of the job:</strong> {{ report.star }}
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script>
import Breadcrumbs from "../../../Comps/General/Breadcrumbs";
import HeaderName from "../../../Comps/General/HeaderName";
import ThingsController from "../../../Mixins/ThingsController";

export default {
    name: "JobReportShow",
    components: { HeaderName, Breadcrumbs },
    props: ["reports"],
    mixins: [ThingsController],

    data() {
        return {
            bread: [
                {
                    text: "Job Reports",
                    disabled: false,
                    link: this.route("staff.job-report.index"),
                },
                {
                    text: this.reports[0].job_name,
                    disabled: true,
                },
            ],
        };
    },
};
</script>

<style scoped></style>
