<script>
import { defineComponent } from "vue";
import TextInput from "../../../../../Comps/Forms/TextInput.vue";
import ModalTemplate from "../../../../../Comps/Master/ModalTemplate.vue";
import TextArea from "../../../../../Comps/Forms/TextArea.vue";
import AutoComplete from "../../../../../Comps/Forms/AutoComplete.vue";
import ComboBox from "../../../../../Comps/Forms/ComboBox.vue";

export default defineComponent({
    name: "SupplierCreateModal",
    components: { ComboBox, AutoComplete, TextArea, ModalTemplate, TextInput },
    data() {
        return {
            form: this.$inertia.form({}),
            modal: false,
            xeroSearch: "",
            searchLoading: false,
            xeroItems: [],
        };
    },

    watch: {
        xeroSearch() {
            this.searchRequest();
        },
    },

    methods: {
        searchRequest: _.debounce(function () {
            if (String(this.xeroSearch || "").length < 3) {
                return;
            }

            this.searchLoading = true;

            this.$http
                .post(
                    this.route("staff.suppliers.xero-contact-search", {
                        search: this.xeroSearch,
                    })
                )
                .then((response) => {
                    this.xeroItems = response.data;
                })
                .finally(() => {
                    this.searchLoading = false;
                });
        }, 500),

        edit(supplier) {
            this.modal = true;
            this.xeroSearch = "";
            this.xeroItems = [];
            this.form = this.$inertia.form(supplier);
            this.form.supplier_tags =
                supplier.supplier_tags?.map((tag) => tag.name) || [];
        },

        create() {
            this.modal = true;
            this.xeroItems = [];
            this.xeroSearch = "";
            this.form = this.$inertia.form({
                id: "",
                name: "",
                email: "",
                phone: "",
                contact_name: "",
                website: "",
                street: "",
                city: "",
                postcode: "",
                notes: "",
                xero_id: "",
                supplier_tags: [],
            });
        },

        async submit() {
            if (!(await this.$refs.validation.validate())) {
                return;
            }

            if (this.form.id) {
                this.form.put(
                    this.route("staff.suppliers.update", this.form.id),
                    {
                        onSuccess: () => {
                            this.modal = false;
                        },
                    }
                );

                return;
            }

            this.form.post(this.route("staff.suppliers.store"), {
                onSuccess: () => {
                    this.modal = false;
                },
            });
        },

        contactPicked(contact) {
            if (!contact) {
                return;
            }

            this.form.name = contact?.Name;
            this.form.email = contact?.EmailAddress;
            if (contact?.FirstName || contact?.LastName) {
                this.form.contact_name =
                    (contact?.FirstName || "") +
                    " " +
                    (contact?.LastName || "");
            }

            this.form.xero_id = contact?.ContactID;

            this.form.phone = contact?.Phones?.find(
                (phone) => phone.PhoneType === "DEFAULT"
            )?.PhoneNumber;

            const address = contact?.Addresses?.find(
                (address) => address.AddressType === "POBOX"
            );

            this.form.street = address?.AddressLine1;
            this.form.city = address?.City;
            this.form.postcode = address?.PostalCode;
        },
    },
});
</script>

<template>
    <modal-template v-model="modal">
        <div class="text-h6 text--secondary mb-2 mt-2">
            {{ form.id ? "Edit" : "Create" }} a Supplier
        </div>

        <form novalidate @submit.prevent="submit">
            <validation-observer ref="validation">
                <v-row>
                    <v-col cols="12">
                        <auto-complete
                            v-if="!form.xero_id"
                            autofocus
                            :items="xeroItems"
                            name="Xero Contact"
                            v-model="form.xero_id"
                            hint="Search for a xero contact or leave blank to create a new one"
                            persistent-hint
                            :search-input.sync="xeroSearch"
                            placeholder="Start typing to Search"
                            hide-no-data
                            hide-selected
                            :loading="searchLoading"
                            item-text="Name"
                            item-value="ContactID"
                            return-object
                            @change="contactPicked"
                        />
                        <div v-if="form.xero_id">
                            <div class="text-caption">Xero Contact</div>
                            <div class="d-flex mt-1 mb-4">
                                <v-card class="d-flex">
                                    <v-card-title class="mr-2">
                                        <a
                                            :href="`https://go.xero.com/Contacts/View/${form.xero_id}`"
                                            target="_blank"
                                        >
                                            {{ form.name }}
                                            <v-icon x-small>mdi-launch</v-icon>
                                        </a>
                                    </v-card-title>
                                    <v-btn
                                        class="ml-auto"
                                        small
                                        icon
                                        @click="form.xero_id = ''"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-card>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <text-input
                            :autofocus="!!form.xero_id"
                            name="Company Name"
                            required
                            rules="required"
                            v-model="form.name"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-input
                            name="Contact Name"
                            v-model="form.contact_name"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-input
                            name="Email"
                            type="email"
                            v-model="form.email"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-input name="Phone" v-model="form.phone" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-input name="Website" v-model="form.website" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <text-area
                            name="Street Address"
                            v-model="form.street"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-input name="City" v-model="form.city" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-input name="Postcode" v-model="form.postcode" />
                    </v-col>
                </v-row>

                <v-divider class="my-6" />

                <v-row>
                    <v-col cols="12">
                        <combo-box
                            v-model="form.supplier_tags"
                            name="Tags"
                            :items="$page.props.supplierTags"
                            chips
                            small-chips
                            multiple
                            persistent-hint
                            hint="Tag this supplier with the services they can provide.  To add a new tag, type the name and press enter."
                        />
                    </v-col>
                    <v-col cols="12">
                        <text-area name="Notes" v-model="form.notes" />
                    </v-col>
                </v-row>
            </validation-observer>

            <v-btn
                color="success"
                type="submit"
                class="mt-6"
                :loading="form.processing"
            >
                Save
            </v-btn>
        </form>
    </modal-template>
</template>

<style scoped></style>
