<template>
    <v-container class="fixed">
        <header-name>Vehicles</header-name>

        <div class="d-flex mb-6">
            <v-switch
                v-model="deleted"
                @change="showDeleted"
                class="d-inline-block"
                label="Show Deleted Vehicles"
            ></v-switch>

            <div class="ml-auto">
                <v-btn color="success" small @click="modal = true">
                    Create Vehicle
                </v-btn>
            </div>
        </div>

        <vehicles-create-modal v-model="modal" />

        <v-data-table
            :headers="[
                { text: 'Name', value: 'name' },
                { text: 'Reg', value: 'reg' },
                { text: 'Hired', value: 'hired' },
                { text: '', value: 'options' },
            ]"
            :items="vehicles"
            no-data-text="No vehicles have been found"
            class="elevation-1"
        >
            <template v-slot:item.hired="{ item }">
                <v-chip small label :color="item.hired ? 'success' : 'primary'">
                    <span v-if="item.hired">Yes</span>
                    <span v-else>No</span>
                </v-chip>
            </template>
            <template v-slot:item.options="{ item }">
                <inertia-link
                    class="inertia-link"
                    :href="route('staff.admin.vehicles.show', item)"
                >
                    <v-btn
                        v-if="!item.deleted_at"
                        class="mr-2"
                        elevation="2"
                        v-tooltip="'View Vehicle'"
                        color="primary"
                        icon
                    >
                        <v-icon>mdi-car-estate</v-icon>
                    </v-btn>
                </inertia-link>
                <v-btn
                    class="mr-2"
                    elevation="2"
                    v-tooltip="
                        item.deleted_at ? 'Restore Vehicle' : 'Delete Vehicle'
                    "
                    :color="item.deleted_at ? 'success' : 'error'"
                    @click="item.deleted_at ? restore(item) : destroy(item)"
                    icon
                >
                    <v-icon v-if="item.deleted_at">mdi-restore</v-icon>
                    <v-icon v-else>mdi-delete-alert</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </v-container>
</template>
<script>
import HeaderName from "../../../../Comps/General/HeaderName";
import VehiclesCreateModal from "../../../../Comps/Staff/Vehicles/VehiclesCreateModal";
import AdminController from "../../../../Mixins/AdminController";

export default {
    name: "Vehicles",
    components: { VehiclesCreateModal, HeaderName },
    props: ["vehicles", "deletedProp"],
    mixins: [AdminController],

    data() {
        return {
            freelancer: null,
            modal: false,
            deleted: this.deletedProp,
        };
    },
    methods: {
        destroy(vehicle) {
            if (!confirm("Are you sure?")) {
                return;
            }

            this.$inertia.delete(
                this.route("staff.admin.vehicles.destroy", vehicle)
            );
        },
        restore(vehicle) {
            if (!confirm("Are you sure?")) {
                return;
            }

            this.$inertia.put(
                this.route("staff.admin.vehicles.restore", vehicle)
            );
        },
        showDeleted() {
            this.$inertia.visit(
                this.route("staff.admin.vehicles.index", {
                    deleted: this.deleted,
                })
            );
        },
    },
};
</script>
<style scoped></style>
