<template>
    <v-app>
        <v-container>
            <v-row class="justify-center">
                <v-col cols="12" md="6">
                    <v-sheet elevation="4" class="pa-md-10 pa-4 pb-10">
                        <v-img
                            max-height="150"
                            contain
                            :src="$page.props.files.pytchLogo"
                        />

                        <v-alert
                            border="right"
                            v-if="Object.keys($page.props.errors).length"
                            colored-border
                            type="error"
                            elevation="2"
                            class="mt-5"
                        >
                            <ul>
                                <li
                                    v-for="(error, index) in $page.props.errors"
                                    :key="index"
                                >
                                    {{ error }}
                                </li>
                            </ul>
                        </v-alert>

                        <slot />
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
export default {
    name: "LoginLayout",
};
</script>

<style scoped></style>
