<template>
    <v-container fixed>
        <header-name>Hire Site Users</header-name>

        <!--Main Categories-->
        <v-data-table
            :headers="[
                { text: 'Name', value: 'name' },
                { text: 'Email', value: 'email' },
                { text: 'Organisation', value: 'organisation_name' },
                { text: 'Organisation ID', value: 'organisation_id' },
                { text: 'Discount %', value: 'discount' },
                { text: 'Created At', value: 'created_at' },
                { text: '', value: 'options', align: 'end' },
            ]"
            :search="search"
            sort-by="organisation_id"
            :items="$page.props.hireSiteUsers"
            no-data-text="No users have been found"
            class="elevation-1"
        >
            <template v-slot:top>
                <div class="pa-4">
                    <text-input name="Search" v-model="search" />
                </div>
            </template>
            <template v-slot:item.options="{ item }">
                <v-btn
                    class="mr-2"
                    elevation="2"
                    v-tooltip="'Edit'"
                    color="primary"
                    @click="$refs.usersModal.edit(item)"
                    icon
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.created_at="{ value }">
                {{ momentFormatDateTimeYear(value) }}
            </template>
            <template v-slot:item.organisation_id="{ value }">
                <div v-if="value">
                    {{ value }}
                </div>
                <div v-else><v-chip x-small label color="red" class="white--text">Needs Onboarding</v-chip></div>
            </template>
        </v-data-table>
        <users-modal ref="usersModal" />
    </v-container>
</template>

<script>
import { defineComponent } from "vue";
import HeaderName from "../../../../../Comps/General/HeaderName.vue";
import AdminController from "../../../../../Mixins/AdminController.js";
import UsersModal from "./Partials/UsersModal.vue";
import TextInput from "../../../../../Comps/Forms/TextInput.vue";
export default defineComponent({
    name: "UsersIndex",
    components: { TextInput, HeaderName, UsersModal },
    mixins: [AdminController],

    data() {
        return {
            search: "",
        };
    },
});
</script>

<style scoped></style>
