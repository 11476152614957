<template>
    <v-container>
        <header-name>Add Stock</header-name>
        <add-stock-form
            product-search-route-name="staff.add-stock.search"
            change-stock-route-name="staff.add-stock.change"
            add-stock-route-name="staff.add-stock.add"
            get-stock-levels-route-name="staff.add-stock.stock-level"
        />
    </v-container>
</template>

<script>
import HeaderName from "../../../Comps/General/HeaderName";
import ProcessesController from "../../../Mixins/ProcessesController";
import AddStockForm from "../../../Comps/Processes/AddStockForm";

export default {
    name: "AddStock",
    components: { AddStockForm, HeaderName },
    mixins: [ProcessesController],
};
</script>

<style scoped></style>
