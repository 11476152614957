export default {
    data() {
        return {
            thisWeek: this.moment(),
            version: null,
        };
    },

    created() {
        this.thisWeek = this.moment(this.thisWeekProp);
    },

    mounted() {
        this.setVersion();
    },

    methods: {
        setVersion() {
            if (this.logistic) {
                this.version = this.logistic.version;
            }
        },
    },

    computed: {
        allVersions() {
            return this.$page.props.versions || this.$page.props.logistics;
        },

        versions() {
            return this.allVersions.map((logistic) => ({
                ...logistic,
                name: `Version ${logistic.version}`,
            }));
        },

        weekForLaravel() {
            if (this.thisWeek) {
                return this.thisWeek.format("DD-MM-YYYY");
            } else {
                return this.moment().format("DD-MM-YYYY");
            }
        },
    },
};
