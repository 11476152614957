<template>
    <v-container class="fixed">
        <breadcrumbs :items="bread"></breadcrumbs>

        <head-title :title="`Freelancer | ${freelancer.name}`"></head-title>
        <accent-button
            do-not-stretch
            color="primary"
            small
            @click="$refs.editModal.edit(freelancer)"
        >
            Edit Freelancer
        </accent-button>

        <div class="text-body-1 mt-4">Internal Tags</div>
        <div class="mt-2 d-flex flex-wrap">
            <v-chip
                v-for="(tag, index) in freelancer.tags"
                :key="index"
                label
                class="mx-1"
            >
                {{ tag.name }}
            </v-chip>
        </div>

        <v-sheet elevation="2" class="mt-8">
            <profile-details :freelancer="freelancer" full-width />
        </v-sheet>

        <v-sheet elevation="2" class="pa-4 mt-6">
            <div class="text-h6">Internal Notes</div>
            <div style="white-space: pre-line">
                {{ freelancer.notes ? freelancer.notes : "N/A" }}
            </div>
        </v-sheet>

        <freelancers-create-modal ref="editModal" />
    </v-container>
</template>
<script>
import FreelancersCreateModal from "../../../../Comps/Staff/Freelancers/FreelancersCreateModal";
import Breadcrumbs from "../../../../Comps/General/Breadcrumbs";
import AdminController from "../../../../Mixins/AdminController";
import ProfileDetails from "../../../Freelancer/Partials/ProfileDetails";
import HeadTitle from "../../../../Comps/Master/HeadTitle";
import AccentButton from "../../../../Comps/Buttons/AccentButton";

export default {
    name: "FreelancersShow",
    components: {
        AccentButton,
        HeadTitle,
        ProfileDetails,
        Breadcrumbs,
        FreelancersCreateModal,
    },
    props: ["freelancer", "tags"],
    mixins: [AdminController],

    data() {
        return {
            bread: [
                {
                    text: "Freelancers",
                    disabled: false,
                    link: this.route("staff.admin.freelancers.index"),
                },
                {
                    text: this.freelancer.name,
                    disabled: true,
                },
            ],
            createModal: false,
        };
    },
    computed: {
        list() {
            return [
                {
                    name: "Name",
                    value: this.freelancer.name,
                    icon: "mdi-form-textbox",
                    type: "standard",
                },
                {
                    name: "Email",
                    value: this.freelancer.email,
                    icon: "mdi-email",
                    type: "email",
                },
                {
                    name: "Telephone Number",
                    value: this.freelancer.tel_number,
                    icon: "mdi-phone",
                    type: "phone",
                },
                {
                    name: "Street",
                    value: this.freelancer.address.street,
                    icon: "mdi-map-marker",
                    type: "standard",
                },
                {
                    name: "City",
                    value: this.freelancer.address.city,
                    icon: "mdi-map-marker",
                    type: "standard",
                },
                {
                    name: "County",
                    value: this.freelancer.address.county,
                    icon: "mdi-map-marker",
                    type: "standard",
                },
                {
                    name: "Postcode",
                    value: this.freelancer.address.postcode,
                    icon: "mdi-map-marker",
                    type: "standard",
                },
                {
                    name: "Notes",
                    value: this.freelancer.notes,
                    icon: "mdi-text",
                    type: "standard",
                },
            ];
        },
    },
};
</script>
<style scoped></style>
