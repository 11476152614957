<template>
    <v-row class="justify-center">
        <v-col class="d-flex align-center justify-center" cols="12" md="6">
            <div class="text-body-1 font-weight-bold">{{ name }}</div>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center justify-md-start">
            <validation-provider
                v-slot="{ errors }"
                :name="'Rating ' + name"
                rules="required|min_value:1"
            >
                <v-rating
                    v-model="form.survey[index]"
                    hover
                    size="32"
                    length="5"
                />
                <v-slide-y-transition>
                    <div class="error--text" v-if="errors.length">
                        {{ errors[0] }}
                    </div>
                </v-slide-y-transition>
            </validation-provider>
        </v-col>
        <v-fade-transition>
            <v-row v-if="form.survey[index]">
                <v-col class="d-flex justify-center">
                    <validation-provider
                        style="max-width: 400px; width: 100%"
                        v-slot="{ errors }"
                        :name="'Rating additional-' + index"
                        rules="max:255"
                    >
                        <v-text-field
                            v-model="form.survey.additional[index]"
                            :counter="255"
                            outlined
                            dense
                            class="mt-3"
                            :error-messages="errors"
                            label="Optional comment"
                        >
                        </v-text-field>
                    </validation-provider>
                </v-col>
            </v-row>
        </v-fade-transition>
    </v-row>
</template>

<script>
import { extend } from "vee-validate";
import { required, max, min_value } from "vee-validate/dist/rules";

extend("required", required);
extend("max", max);
extend("min_value", min_value);

export default {
    name: "SurveyRating",
    props: {
        value: {
            type: Object,
            required: true,
        },
        index: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
    },

    computed: {
        form: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>

<style scoped></style>
