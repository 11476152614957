<script>
export default {
    name: "VehicleNotificationController",
    render() {
        return this.$slots.default;
    },

    data() {
        return {
            store: this.$root.store,
        };
    },

    computed: {
        vehicleBookings() {
            return this.$page.props.auth.user.vehicle_bookings;
        },
    },

    methods: {
        dismissOld() {
            this.store.vehicleNotificationToast?.dismiss();
        },
    },

    watch: {
        vehicleBookings: {
            immediate: true,
            handler(bookings, oldBookings) {
                if (!bookings.length) {
                    this.dismissOld();
                    return;
                }

                if (bookings.length === oldBookings?.length) {
                    return;
                }

                if (!this.store.vehicleNotificationToast) {
                    this.store.vehicleNotificationToast = this.$toast.open({
                        message: "You have a vehicle booked out",
                        type: "info",
                        position: "bottom",
                        duration: 0,
                        onClick: () => {
                            this.store.vehicleNotificationToast = null;
                        },
                    });
                }
            },
        },
    },
};
</script>
