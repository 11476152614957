<template>
    <v-dialog
        v-model="modal"
        :eager="eager"
        :persistent="persistent"
        :content-class="classes"
        :fullscreen="fullscreen"
        :no-click-animation="fullscreen"
        :hide-overlay="fullscreen"
        :light="light"
        :transition="
            fullscreen ? 'dialog-bottom-transition' : 'dialog-transition'
        "
        :width="width"
    >
        <v-card v-if="!native && !fullscreen" rounded="lg" class="pa-6 card">
            <v-btn
                v-if="!persistent"
                fab
                x-small
                elevation="0"
                absolute
                style="top: 7px; right: 7px"
                @click="modal = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <slot></slot>
        </v-card>
        <div class="card" v-if="native">
            <slot></slot>
        </div>
        <div v-if="fullscreen">
            <v-card style="height: 100%">
                <v-btn
                    v-if="!persistent"
                    fab
                    x-small
                    elevation="0"
                    absolute
                    style="top: 7px; right: 7px"
                    @click="modal = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <slot></slot>
            </v-card>
        </div>
    </v-dialog>
</template>

<script>
import { Inertia } from "@inertiajs/inertia";

export default {
    name: "ModalTemplate",
    props: {
        middle: {
            type: Boolean,
            default: false,
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        eager: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Boolean,
            default: false,
        },
        native: {
            type: Boolean,
            default: false,
        },
        fullscreen: {
            type: Boolean,
            default: false,
        },
        light: {
            type: Boolean,
            default: false,
        },
    },

    mounted() {
        this.$once(
            "hook:destroyed",
            Inertia.on("navigate", (event) => {
                this.modal = false;
            })
        );
    },

    methods: {
        show() {
            this.modal = true;
        },
        hide() {
            this.modal = false;
        },
    },

    computed: {
        classes() {
            let classes = "modal";
            if (!this.middle && !this.fullscreen) {
                classes = classes + " align-self-start mt-10";
            }
            return classes;
        },
        width() {
            if (this.$vuetify.breakpoint.xsOnly) {
                return "100%";
            }
            if (this.small) {
                return "40%";
            }
            return "70%";
        },
        modal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>

<style lang="scss">
.v-dialog {
    box-shadow: none;
}
</style>

<style lang="scss" scoped>
.card {
    border-radius: 1rem 0 1rem 0;
    box-shadow: none !important;
}
</style>
