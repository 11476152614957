<template>
    <div>
        <head-title title="Payment Error | PYTCH" do-not-append />
        <div class="text-h6 text-center">{{ errorMessage }}</div>
    </div>
</template>

<script>
import SecureLayout from "../../Comps/Secure/SecureLayout";
import HeadTitle from "../../Comps/Master/HeadTitle";

export default {
    name: "SecureError",
    layout: SecureLayout,
    components: { HeadTitle },

    props: {
        error: String | null,
    },

    computed: {
        errorMessage() {
            if (this.error) {
                return this.error;
            }

            return "Oh dear, something has gone wrong with that payment. Please try again";
        },
    },
};
</script>

<style scoped></style>
