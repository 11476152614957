export default {
    name: "ProcessesController",

    data() {
        return {
            menu: [
                {
                    title: "Asset Search",
                    icon: "mdi-magnify-expand",
                    route: "asset-search.index",
                },
                {
                    title: "Asset Swap",
                    icon: "mdi-swap-horizontal",
                    route: "asset-swap.index",
                },
                {
                    title: "Add Stock",
                    icon: "mdi-information",
                    route: "staff.add-stock.index",
                },
                {
                    title: "Stock Transfer",
                    icon: "mdi-transfer",
                    route: "stock-transfer.index",
                },
                {
                    title: "PAT Testing",
                    icon: "mdi-hammer",
                    route: "staff.pat.index",
                },
                {
                    title: "LOLER Testing",
                    icon: "mdi-tools",
                    route: "staff.loler.index",
                },
                {
                    title: "PAT Records",
                    icon: "mdi-file-document",
                    route: "staff.pat.records",
                },
                {
                    title: "LOLER Records",
                    icon: "mdi-file-document",
                    route: "staff.loler.records",
                },
            ],
        };
    },
    mounted() {
        this.generateMenu();
        this.$root.store.sideMenuTitle = "Processes";
    },

    methods: {
        generateMenu() {
            this.$root.store.sideMenu = this.menu;
        },
    },
};
