<template>
    <v-card
        :width="width"
        :height="height"
        class="text-center align-self-start"
        elevation="0"
        style="position: relative"
        v-on="type !== 'other' ? { click: showFile } : {}"
    >
        <v-btn
            v-if="showDelete"
            fab
            x-small
            color="error"
            @click.stop="$emit('delete')"
            absolute
            style="top: -14px; right: -14px"
        >
            <v-icon>mdi-delete</v-icon>
        </v-btn>
        <img
            v-if="type === 'image' && src"
            :src="src"
            :alt="name"
            :style="{
                'max-width': maxWidth,
                'max-height': maxHeight,
                objectFit: 'contain',
            }"
        />
        <video
            v-if="type === 'video' && src"
            :src="src"
            :style="{
                'max-width': maxWidth,
                'max-height': maxHeight,
                objectFit: 'contain',
            }"
            autoplay
            loop
            muted
            playsinline
        />
        <v-sheet
            elevation="2"
            class="my-3 pa-2"
            :class="{ 'grey darken-3': $vuetify.theme.isDark }"
            v-if="type === 'other' || type === 'pdf'"
        >
            <v-icon x-large>mdi-file</v-icon>
            <div class="text-caption font-weight-bold text-uppercase my-1">
                {{ name ? name : "File" }}
            </div>
        </v-sheet>

        <file-display-modal ref="displayModal" />
    </v-card>
</template>

<script>
import FileDisplayModal from "./FileDisplayModal";
export default {
    name: "FileSheet",
    components: { FileDisplayModal },
    props: {
        type: {
            type: String,
            required: true,
        },
        src: String,
        name: String,
        maxHeight: String,
        maxWidth: String,
        height: String,
        width: String,
    },

    computed: {
        showDelete() {
            return !!this.$listeners["delete"];
        },
    },

    methods: {
        showFile() {
            if (this.type === "other") {
                return;
            }
            this.$refs.displayModal.showFile(this.src, this.type);
        },
    },
};
</script>

<style scoped></style>
