var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gantt__row"},[_c('div',{staticClass:"text-center",staticStyle:{"position":"sticky","left":"0","z-index":"1"}},[_c('div',{staticClass:"d-flex align-center pa-1",staticStyle:{"background-color":"white","height":"100%","width":"100%","border-width":"1px 1px 0 1px","border-color":"rgba(0, 0, 0, 0.2)","border-style":"solid"}},[(_vm.row.avatar)?_c('div',{staticClass:"mr-1",staticStyle:{"width":"40px"}},[_c('v-avatar',{attrs:{"size":"40"}},[_c('v-img',{attrs:{"src":_vm.row.avatar}})],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-center",staticStyle:{"width":"100%"}},[_c('div',[_vm._v("\n                    "+_vm._s(_vm.row.name)+"\n                ")]),_vm._v(" "),(_vm.admin)?_c('div',{staticClass:"text-caption",class:{ 'red--text': _vm.timeWorked.asHours() > 60 }},[_vm._v("\n                    "+_vm._s(Math.floor(_vm.timeWorked.asHours()).toLocaleString(
                            undefined,
                            {
                                minimumIntegerDigits: 2,
                            }
                        ))+":"+_vm._s(_vm.timeWorked.minutes().toLocaleString(undefined, {
                            minimumIntegerDigits: 2,
                        }))+"\n                ")]):_vm._e()])])]),_vm._v(" "),_c('ul',{staticClass:"gantt__row-bars",style:(_vm.gridRowBars)},[_vm._l((_vm.row.tasks),function(task){return _c('logistics-chart-task',{key:`${_vm.$vnode.key}-task-${task.id}`,attrs:{"sections":_vm.sections,"per-section":_vm.perSection,"range-start":_vm.rangeStart,"starts_at":task.starts_at,"ends_at":task.ends_at,"name":task.name,"when-its-done":task.when_its_done,"color":(_vm.highlightChanges &&
                    !task.changed &&
                    !task.newTask &&
                    !task.firstVersion) ||
                task.deleted
                    ? 'grey'
                    : task.category.color},on:{"click":function($event){return _vm.$emit('taskClick', task)}}})}),_vm._v(" "),_vm._l((_vm.row.holidays),function(holiday){return _c('logistics-chart-task',{key:`${_vm.$vnode.key}-holiday-${holiday.id}`,attrs:{"sections":_vm.sections,"per-section":_vm.perSection,"range-start":_vm.rangeStart,"starts_at":holiday.starts_at,"ends_at":holiday.ends_at,"name":"Holiday","color":"#FF9800"},on:{"click":function($event){return _vm.$emit('holidayClick', holiday)}}})})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }