<template>
    <v-container>
        <head-title title="Stock Transfer" />
        <div class="text-h4 mb-4">Stock Transfer</div>
        <stock-transfer-form
            transfer-stock-route-name="warehouse.stock-transfer.transfer"
        />
    </v-container>
</template>

<script>
import HeadTitle from "../../../Comps/Master/HeadTitle";
import StockTransferForm from "../../../Comps/Processes/StockTransferForm";
export default {
    name: "StockTransfer",
    components: { StockTransferForm, HeadTitle },
};
</script>

<style scoped></style>
