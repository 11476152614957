<template>
    <v-container class="fixed">
        <header-name>Performance Logs</header-name>

        <user-performance-logs :user="user" />
    </v-container>
</template>

<script>
import PeopleController from "../../../Mixins/PeopleController";
import HeaderName from "../../../Comps/General/HeaderName.vue";
import UserPerformanceLogs from "../../../Comps/Staff/Personal/UserPerformanceLogs.vue";

export default {
    name: "PerformanceLogs",
    components: { UserPerformanceLogs, HeaderName },
    mixins: [PeopleController],

    props: {
        user: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped></style>
