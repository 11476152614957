export default {
    methods: {
        async reduceImage(photo, maxSize = 1024, quality = 0.9) {
            let canvas = await imageToCanvas(photo);

            if (canvas.width > canvas.height) {
                while (canvas.width >= 2 * maxSize) {
                    canvas = scaleCanvas(canvas, 0.5);
                }

                if (canvas.width > maxSize) {
                    canvas = scaleCanvas(canvas, maxSize / canvas.width);
                }
            } else {
                while (canvas.height >= 2 * maxSize) {
                    canvas = scaleCanvas(canvas, 0.5);
                }

                if (canvas.height > maxSize) {
                    canvas = scaleCanvas(canvas, maxSize / canvas.height);
                }
            }

            const blob = new Promise((resolve) => {
                canvas.toBlob(resolve, "image/png", quality);
            });

            return new File([await blob], photo.name);
        },
    },
};

async function imageToCanvas(photo) {
    const canvas = document.createElement("canvas");
    const img = document.createElement("img");

    // create img element from File object
    img.src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.readAsDataURL(photo);
    });
    await new Promise((resolve) => {
        img.onload = resolve;
    });

    // draw image in canvas element
    canvas.width = img.width;
    canvas.height = img.height;
    canvas.getContext("2d").drawImage(img, 0, 0, canvas.width, canvas.height);

    return canvas;
}
function scaleCanvas(canvas, scale) {
    const scaledCanvas = document.createElement("canvas");
    scaledCanvas.width = canvas.width * scale;
    scaledCanvas.height = canvas.height * scale;

    scaledCanvas
        .getContext("2d")
        .drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);

    return scaledCanvas;
}
