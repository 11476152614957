var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('header-name',[_vm._v("\n        Timesheet Lines for\n        "),_c('a-tag-with-external-icon',{attrs:{"classes":"inertia-link","text":_vm.job.name,"url":`${_vm.$page.props.currentBaseUrl}/opportunities/${_vm.job.id}`}})],1),_vm._v(" "),_c('breadcrumbs',{attrs:{"items":_vm.breadItems}}),_vm._v(" "),_c('v-data-table',{staticClass:"row-pointer elevation-2",attrs:{"items":_vm.lines,"headers":[
            {
                text: 'User',
                value: 'timesheet.user.name',
            },
            {
                text: 'Timesheet',
                value: 'timesheet.start_date',
            },
            {
                text: 'Type',
                value: 'type',
            },
            {
                text: 'Start',
                value: 'starts_at',
            },
            {
                text: 'End',
                value: 'ends_at',
            },
            {
                text: 'Hours Worked',
                value: 'total_hours',
            },
            {
                text: 'Minutes Break',
                value: 'minutes_break',
            },
            {
                text: 'Notes',
                value: 'notes',
            },
        ]},on:{"click:row":_vm.viewSheet},scopedSlots:_vm._u([{key:"item.timesheet.start_date",fn:function({ value }){return [_vm._v("\n            "+_vm._s(_vm.momentFormatDateYear(value))+"\n        ")]}},{key:"item.starts_at",fn:function({ item, value }){return [(item.type === 'Shift')?_c('div',[_vm._v("\n                "+_vm._s(_vm.momentFormatShortDateTime(value))+"\n            ")]):_vm._e()]}},{key:"item.ends_at",fn:function({ item, value }){return [(item.type === 'Shift')?_c('div',[_vm._v("\n                "+_vm._s(_vm.momentFormatShortDateTime(value))+"\n            ")]):_vm._e()]}},{key:"item.total_hours",fn:function({ item }){return [(item.type === 'Shift')?_c('div',[_vm._v("\n                "+_vm._s(item.total_hours)+"\n            ")]):_vm._e(),_vm._v(" "),(item.type !== 'Shift')?_c('div',{domProps:{"textContent":_vm._s(`£ ${_vm.formatMoney(item.amount)}`)}}):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }