<template>
    <div>
        <v-container fluid>
            <v-row class="justify-center">
                <v-col cols="12" md="8">
                    <v-img
                        contain
                        max-height="150"
                        :src="$page.props.files.pytchLogo"
                    />

                    <v-sheet elevation="2" class="pa-4" rounded>
                        <div class="text-center">
                            <div class="text-h4 primary--text font-weight-bold">
                                Client Feedback
                            </div>
                            <div class="text-h6">{{ survey.job_name }}</div>
                        </div>
                        <v-divider class="my-4" />

                        <complete-loader>
                            <div class="text-center">
                                <div class="text-h3 success--text">
                                    Feedback Submitted
                                </div>
                                <div class="text-h6">
                                    Thank you for participating
                                </div>
                            </div>
                        </complete-loader>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import PublicController from "../../../Comps/Master/PublicController";
import CompleteLoader from "../../../Comps/General/CompleteLoader";

export default {
    name: "SurveyComplete",
    components: { CompleteLoader },
    layout: PublicController,

    props: {
        survey: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped></style>
