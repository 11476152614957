<template>
    <v-sheet class="pa-4" elevation="2">
        <div class="text-h6 text-center">Staff</div>

        <v-data-table
            :headers="headers"
            :items="$page.props.users"
            sort-by="name"
            :search="search"
        >
            <template v-slot:top>
                <v-text-field
                    dense
                    outlined
                    v-model="search"
                    label="Search"
                    class="mx-4 mt-4"
                />
            </template>
            <template v-slot:item.email="{ item }">
                <a :href="`mailto:${item.email}`" v-text="item.email" />
            </template>
            <template v-slot:item.tel_number="{ item }">
                <a :href="`tel:${item.tel_number}`" v-text="item.tel_number" />
            </template>
        </v-data-table>
    </v-sheet>
</template>

<script>
export default {
    name: "AllStaff",

    data() {
        return {
            search: "",
        };
    },

    computed: {
        headers() {
            return [
                { text: "Name", value: "name" },
                { text: "Email", value: "email" },
                { text: "Phone", value: "tel_number", sortable: false },
                { text: "Job Title", value: "department", sortable: false },
            ];
        },
    },
};
</script>

<style scoped></style>
