<template>
    <modal-template v-model="modal">
        <div class="text-h6">Add to Quarantine</div>

        <div class="text-body-1 mt-4">
            Fill in the info below to add this asset to quarantine
        </div>

        <form novalidate @submit.prevent="submit">
            <validation-observer ref="form">
                <validation-provider
                    v-slot="{ errors }"
                    name="Reference"
                    rules="required|max:255"
                >
                    <v-text-field
                        v-model="form.reference"
                        name="Reference"
                        :counter="255"
                        autofocus
                        :error-messages="errors"
                        outlined
                        dense
                        label="Reference"
                        hint="A reference for this quarantine"
                        class="required mt-2"
                        required
                    >
                    </v-text-field>
                </validation-provider>
                <validation-provider
                    v-slot="{ errors }"
                    name="Description"
                    rules="max:2000"
                >
                    <v-textarea
                        v-model="form.description"
                        name="Description"
                        outlined
                        :counter="2000"
                        :error-messages="errors"
                        label="Description"
                        hint="A description for this quarantine"
                    >
                    </v-textarea>
                </validation-provider>
            </validation-observer>
            <v-btn
                color="success"
                :loading="form.processing"
                class="mt-2"
                type="submit"
            >
                Create
            </v-btn>
        </form>
    </modal-template>
</template>

<script>
import ModalTemplate from "../Master/ModalTemplate";
export default {
    name: "AddToQuarantineModal",
    components: { ModalTemplate },

    props: {
        routeName: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            modal: false,
            form: this.$inertia.form({
                asset_id: null,
                product_id: null,
                store_id: null,
                reference: null,
                description: null,
            }),
        };
    },

    watch: {
        modal(value) {
            if (!value) {
                this.resetForm();
            }
        },
    },

    methods: {
        open(asset) {
            this.modal = true;
            this.form.asset_id = asset.id;
            this.form.product_id = asset.item_id;
            this.form.store_id = asset.store_id;
        },

        submit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.form.post(this.route(this.routeName), {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.resetForm();
                        this.$emit("newQuarantine");
                    },
                });
            });
        },

        resetForm() {
            this.form.reset();
            this.$refs.form?.reset();
            this.modal = false;
        },
    },

    computed: {
        stores() {
            return this.$page.props.stores;
        },
    },
};
</script>

<style scoped></style>
