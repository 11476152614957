<template>
    <div class="mx-1 d-flex flex-column align-center">
        <v-badge
            v-if="user.pivot.lead"
            avatar
            bordered
            overlap
            color="amber accent-2"
            icon="mdi-crown"
        >
            <v-avatar
                class="mr-1"
                style="border: 2px solid white"
                v-tooltip="{ trigger: 'hover click focus', content: user.name }"
            >
                <v-img :src="user.avatar"></v-img>
            </v-avatar>
        </v-badge>
        <v-avatar
            v-else
            class="mr-1"
            style="border: 2px solid white"
            v-tooltip="{ trigger: 'hover click focus', content: user.name }"
        >
            <v-img :src="freelancer ? user.photo.url : user.avatar"></v-img>
        </v-avatar>
        <div v-if="hasClash || hasHolidayClash">
            <v-chip
                v-if="hasClash"
                color="error"
                small
                v-tooltip="`Clash with ${hasClash.name}`"
            >
                <div>
                    <v-icon>mdi-alert</v-icon>
                    <strong>Clash</strong>
                </div>
            </v-chip>
            <v-chip v-if="!hasClash && hasHolidayClash" color="warning" small>
                <div>
                    <v-icon>mdi-alert</v-icon>
                    <strong>On Holiday</strong>
                </div>
            </v-chip>
        </div>
    </div>
</template>

<script>
import ClashFinder from "../../../../../Mixins/Logistics/ClashFinder";

export default {
    name: "TaskInfoModalUser",
    mixins: [ClashFinder],
    props: {
        user: {
            type: Object,
            required: true,
        },
        logistic: Object,
        task: {
            type: Object,
            required: true,
        },
        freelancer: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            hasClash: false,
            hasHolidayClash: false,
        };
    },

    mounted() {
        if (this.logistic) {
            this.hasClash = this.findClash(
                this.usersOtherTasks,
                this.task.time_from,
                this.task.time_to
            );
        }

        if (this.logistic && !this.freelancer) {
            this.hasHolidayClash = this.findHolidayClashForUser(
                this.task,
                this.user
            );
        }
    },

    computed: {
        usersOtherTasks() {
            if (!this.logistic) {
                return;
            }

            if (this.freelancer) {
                return _.filter(this.logistic.tasks, (task) => {
                    return (
                        _.find(task.freelancers, { id: this.user.id }) &&
                        task.date === this.task.date &&
                        task.id !== this.task.id
                    );
                });
            }

            return _.filter(this.logistic.tasks, (task) => {
                return (
                    _.find(task.users, { id: this.user.id }) &&
                    task.date === this.task.date &&
                    task.id !== this.task.id
                );
            });
        },
    },
};
</script>

<style scoped></style>
