<template>
    <v-data-table
        class="elevation-2 mt-2 timesheet-table"
        :items="timesheet.lines"
        sort-by="starts_at"
        :headers="headers"
        disable-pagination
        hide-default-footer
    >
        <template v-slot:header>
            <div
                class="px-2"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
                v-if="$vuetify.breakpoint.mobile"
            >
                <v-switch label="Show Details" v-model="showDetails" />
            </div>
        </template>
        <template v-slot:item.starts_at="{ item, value }">
            <div>
                <span
                    v-if="item.type === 'Shift'"
                    v-text="momentFormatShortDateTime(value)"
                />
                <span
                    v-if="item.type === 'Commission' || item.type === 'Bonus'"
                    v-text="`£ ${formatMoney(item.amount)}`"
                />
            </div>
        </template>
        <template v-slot:item.ends_at="{ item, value }">
            <div>
                <span
                    v-if="item.type === 'Shift'"
                    v-text="momentFormatShortDateTime(value)"
                />
            </div>
        </template>
        <template v-slot:item.total_hours="{ item, value }">
            <div>
                <span v-if="item.type === 'Shift'" v-text="value" />
                <span
                    v-if="item.type === 'Deduction'"
                    v-text="`-${item.amount}`"
                />
            </div>
        </template>
        <template v-slot:item.minutes_break="{ item, value }">
            <div>
                <span v-if="item.type === 'Shift'" v-text="value" />
            </div>
        </template>
        <template v-slot:item.company_job.name="{ item, value }">
            <div>
                <a-tag-with-external-icon
                    v-if="item.company_job_id && !admin"
                    :text="value"
                    :url="`${$page.props.currentBaseUrl}/opportunities/${item.company_job.id}`"
                />
                <inertia-link
                    v-if="item.company_job_id && admin"
                    :href="
                        route('staff.admin.timesheets.job', item.company_job)
                    "
                >
                    {{ value }}
                </inertia-link>
            </div>
        </template>

        <template v-slot:item.actions="{ item }">
            <template v-if="timesheet.status === 'Open'">
                <v-menu v-if="admin || item.type !== 'Deduction'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon>
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense>
                        <v-list-item @click="editLine(item)" link>
                            <v-list-item-title class="primary--text">
                                Edit
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item link @click="deleteLine(item)">
                            <v-list-item-title class="error--text">
                                Delete
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </template>

        <template v-slot:item.notes="{ item }">
            <div
                style="white-space: pre-line"
                v-html="$autolinker.link(item.notes)"
            />
        </template>
    </v-data-table>
</template>

<script>
import ATagWithExternalIcon from "../../General/ATagWithExternalIcon.vue";

export default {
    name: "TimesheetLinesTable",
    components: { ATagWithExternalIcon },
    props: {
        timesheet: {
            type: Object,
            required: true,
        },
        admin: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            showDetails: false,
        };
    },

    methods: {
        deleteLine(line) {
            if (!confirm("Are you sure you want to delete this line?")) {
                return;
            }

            this.$inertia.delete(
                this.route("staff.timesheets.timesheet-lines.destroy", [
                    this.timesheet,
                    line,
                ])
            );
        },

        editLine(line) {
            this.$emit("editLine", line);
        },
    },

    computed: {
        showDetailsComputed() {
            return this.$vuetify.breakpoint.mobile ? this.showDetails : true;
        },

        headers() {
            const headers = [];

            if (this.showDetailsComputed) {
                headers.push({ text: "Type", value: "type" });
            }

            headers.push(
                { text: "Start", value: "starts_at" },
                { text: "End", value: "ends_at" }
            );

            if (this.showDetailsComputed) {
                headers.push(
                    { text: "Hours Worked", value: "total_hours" },
                    { text: "Minutes Break", value: "minutes_break" }
                );
            }

            headers.push({ text: "Linked Job", value: "company_job.name" });

            if (this.showDetailsComputed) {
                headers.push(
                    {
                        text: "Notes",
                        value: "notes",
                        sortable: false,
                    },
                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                    }
                );
            }
            return headers;
        },
    },
};
</script>

<style lang="scss">
.timesheet-table.v-data-table {
    .v-data-table__wrapper .v-data-table__mobile-row {
        min-height: 0;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .v-data-table__wrapper td:first-child.v-data-table__mobile-row {
        padding-top: 8px;
    }
    .v-data-table__wrapper td:last-child.v-data-table__mobile-row {
        padding-bottom: 8px;
    }
}
</style>
