<template>
    <modal-template v-model="modal">
        <div class="text-h6 text--secondary mt-1">
            Reset {{ user.name }}'s password
        </div>

        <div
            class="text-caption text--secondary mb-4"
            v-if="$page.props.user.google_id"
        >
            This will change the users google password to the choice below and
            then require them to change it when they login
        </div>

        <form @submit.prevent="submit">
            <validation-observer ref="form">
                <v-row>
                    <v-col>
                        <validation-provider
                            v-slot="{ errors }"
                            name="New Password"
                            rules="required|min:8"
                        >
                            <v-text-field
                                v-model="new_password"
                                autofocus
                                dense
                                outlined
                                readonly
                                :error-messages="errors"
                                label="New Password"
                                required
                            >
                                <template v-slot:append>
                                    <v-btn
                                        v-if="canCopy"
                                        text
                                        icon
                                        @click="copyPassword"
                                    >
                                        <v-icon link v-tooltip="'Copy Password'"
                                            >mdi-content-copy</v-icon
                                        >
                                    </v-btn>
                                    <v-btn
                                        text
                                        icon
                                        @click="generateNewPassword"
                                    >
                                        <v-icon link v-tooltip="'Generate New'"
                                            >mdi-lock-reset</v-icon
                                        >
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-btn color="success" type="submit" :loading="loading">
                            Reset
                        </v-btn>
                    </v-col>
                </v-row>
            </validation-observer>
        </form>
    </modal-template>
</template>

<script>
import { required, min } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import CopyToClipboard from "../../../Mixins/CopyToClipboard";
import ModalTemplate from "../../Master/ModalTemplate";

extend("required", required);
extend("min", min);

export default {
    name: "UsersResetPassword",
    components: { ModalTemplate },
    mixins: [CopyToClipboard],
    props: {
        user: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            new_password: "",
            loading: false,
            modal: false,
        };
    },

    methods: {
        reset() {
            this.new_password = "";
            this.$refs.form.reset();
            this.modal = false;
        },

        display(x = true) {
            this.modal = x;
            this.generateNewPassword();
        },

        copyPassword() {
            this.copy(this.new_password);
            this.$toast.success("Password copied to clipboard", {
                duration: 2000,
            });
        },

        generateNewPassword() {
            this.new_password = this.generateRandomPassword(12);
        },

        generateRandomPassword(size) {
            let charactersArray = "a-z,A-Z,0-9,#";
            let CharacterSet = "";
            let password = "";

            if (charactersArray.indexOf("a-z") >= 0) {
                CharacterSet += "abcdefghijklmnopqrstuvwxyz";
            }
            if (charactersArray.indexOf("A-Z") >= 0) {
                CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            }
            if (charactersArray.indexOf("0-9") >= 0) {
                CharacterSet += "0123456789";
            }
            if (charactersArray.indexOf("#") >= 0) {
                CharacterSet += "![]{}()%&*$#^<>~@|";
            }

            for (let i = 0; i < size; i++) {
                password += CharacterSet.charAt(
                    Math.floor(Math.random() * CharacterSet.length)
                );
            }

            return password;
        },

        submit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.loading = true;

                this.$inertia.post(
                    this.route("staff.admin.users.resetPassword", this.user.id),
                    { new_password: this.new_password },
                    {
                        onSuccess: () => {
                            this.reset();
                        },
                        onFinish: () => {
                            this.loading = false;
                        },
                    }
                );
            });
        },
    },
};
</script>

<style scoped></style>
