<template>
    <modal-template v-model="show">
        <div class="text-h6 text--secondary mb-2 mt-2">
            Create a new expense
        </div>

        <form novalidate @submit.prevent="submit">
            <validation-observer ref="form">
                <v-row dense>
                    <v-col cols="12" md="6">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Type"
                            rules="required|max:255"
                        >
                            <v-select
                                :items="$page.props.lineTypes"
                                v-model="line.type"
                                label="Type"
                                dense
                                outlined
                                :error-messages="errors"
                            />
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="6">
                        <date-time-picker
                            v-model="line.date"
                            label="Date"
                            name="Date"
                            mode="date"
                            close-on-select
                            :max="new Date()"
                            :min="minDateForPicker"
                            required
                        />
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" sm="6">
                        <autocomplete-search-current
                            v-model="line.company_job_id"
                            :disabled="warehouse"
                            :load-items="[line.job]"
                            clearable
                            dense
                            outlined
                            hide-icon
                            :required="!warehouse"
                            :return-object="false"
                            route-name="staff.current.search-all-live-jobs-past-three"
                        />
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-checkbox
                            class="mt-1"
                            v-model="warehouse"
                            label="Warehouse?"
                        />
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="6">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Supplier"
                            rules="required|max:255"
                        >
                            <v-text-field
                                v-model="line.supplier"
                                :counter="255"
                                :error-messages="errors"
                                label="Supplier"
                                dense
                                outlined
                                hint="The name of the supplier"
                                required
                                class="required"
                            >
                            </v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="6">
                        <currency-input
                            v-model="line.total"
                            label="Total (£)"
                            hint="The total amount of this expense"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Description"
                            rules="max:2000"
                        >
                            <v-textarea
                                v-model="line.description"
                                :error-messages="errors"
                                label="Description"
                                hint="A description of this expense"
                                dense
                                outlined
                            >
                            </v-textarea>
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <div
                            class="mb-1 text-subtitle-2"
                            v-if="!excludedFromAttachments.includes(line.type)"
                        >
                            You are required to attach at least one file with
                            proof of your expense (e.g. Receipt or Invoice)
                        </div>
                        <select-file-and-display
                            v-model="line.files"
                            width="200px"
                            max-width="100%"
                            max-height="400px"
                            :max-file-size="20000"
                            :compressed-width="1920"
                            :allowed-extensions="[
                                '.jpg',
                                '.jpeg',
                                '.png',
                                '.bmp',
                                '.pdf',
                                '.gif',
                            ]"
                        />
                    </v-col>
                </v-row>
            </validation-observer>

            <v-btn
                color="success"
                type="submit"
                class="mt-6"
                :loading="line.processing"
            >
                {{ isEditing ? "Update" : "Add" }} Line
            </v-btn>
            <v-btn
                color="success"
                @click="submitThenMore"
                v-if="!isEditing"
                class="mt-6"
                :loading="line.processing"
            >
                Add and Add Another
            </v-btn>
        </form>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../Master/ModalTemplate";
import AutocompleteSearchCurrent from "../../General/AutocompleteSearchCurrent";
import DateTimePicker from "../../Master/DateTimePicker";
import SelectFileAndDisplay from "../../General/SelectFileAndDisplay";
import CurrencyInput from "../../General/Forms/CurrencyInput";

export default {
    name: "ExpenseSheetLineCreateModal",
    components: {
        CurrencyInput,
        SelectFileAndDisplay,
        DateTimePicker,
        AutocompleteSearchCurrent,
        ModalTemplate,
    },

    data() {
        return {
            show: false,
            line: {},
            warehouse: false,
            excludedFromAttachments: ["Mileage"],
            addMore: false,
        };
    },

    watch: {
        warehouse(value) {
            if (value) {
                this.line.company_job_id = null;
            }
        },
    },

    methods: {
        create() {
            this.show = true;
            this.line = this.$inertia.form({
                company_job_id: null,
                type: null,
                date: null,
                description: null,
                supplier: null,
                total: 0,
                files: [],
            });
            this.$refs.form?.reset();
        },
        edit(line) {
            line.files = line.uploads;
            this.line = this.$inertia.form(line);
            this.warehouse = !this.line.company_job_id;
            this.$refs.form?.reset();
            this.show = true;
        },
        submitThenMore() {
            this.addMore = true;
            this.submit();
        },
        submit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                if (
                    !this.excludedFromAttachments.includes(this.line.type) &&
                    !this.line.files.length
                ) {
                    this.$toast.error("Please attach at least one file", {
                        duration: 5000,
                    });
                    return;
                }

                if (this.isEditing) {
                    this.putForm();
                    return;
                }

                this.postForm();
            });
        },

        postForm() {
            this.line.post(
                this.route(
                    "expense-sheets.expense-sheet-lines.store",
                    this.$page.props.sheet.data
                ),
                {
                    onSuccess: () => this.onSuccess(),
                }
            );
        },

        putForm() {
            this.line
                .transform((data) => ({
                    ...data,
                    _method: "put",
                }))
                .post(
                    this.route("expense-sheets.expense-sheet-lines.update", [
                        this.$page.props.sheet.data,
                        this.line,
                    ]),
                    {
                        onSuccess: () => this.onSuccess(),
                    }
                );
        },

        onSuccess() {
            if (!this.addMore) {
                this.show = false;
                return;
            }

            this.resetForMore();
        },

        resetForMore() {
            this.line.defaults("company_job_id", this.line.company_job_id);
            this.line.defaults("date", this.line.date);
            this.line.defaults("type", this.line.type);
            this.line.reset();
            this.$refs.form.reset();
            this.addMore = false;
        },
    },

    computed: {
        minDateForPicker() {
            return this.moment().subtract(2, "months").toDate();
        },
        isEditing() {
            return this.line.id;
        },
    },
};
</script>

<style scoped></style>
