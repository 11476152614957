<template>
    <v-container class="fixed">
        <v-row class="justify-center">
            <v-col cols="12" md="11">
                <header-name>PAT Testing</header-name>

                <v-sheet class="pa-6" elevation="2">
                    <v-fade-transition mode="out-in">
                        <div key="search" v-if="!this.asset">
                            <form @submit.prevent="searchForAsset" novalidate>
                                <validation-observer ref="assetSearch">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Asset"
                                        rules="required|max:255"
                                    >
                                        <v-text-field
                                            v-model="assetSearch"
                                            name="Asset"
                                            :error-messages="errors"
                                            :loading="isSearching"
                                            ref="assetSearchInput"
                                            label="Asset"
                                            hint="Scan or type an asset number and press enter"
                                            persistent-hint
                                            autofocus
                                            class="required"
                                            required
                                        >
                                        </v-text-field>
                                    </validation-provider>
                                </validation-observer>
                            </form>
                        </div>

                        <div key="add" v-if="asset">
                            <div class="d-flex">
                                <div>
                                    <div class="subtitle-1 font-weight-bold">
                                        {{ asset.asset_number }}
                                    </div>
                                    <div class="subtitle-1">
                                        {{ product.name }}
                                    </div>
                                </div>
                                <v-btn
                                    small
                                    color="primary"
                                    v-tooltip="
                                        'Search for another asset to test'
                                    "
                                    @click="fresh"
                                    class="ml-auto"
                                >
                                    Start Over
                                </v-btn>
                            </div>

                            <v-divider class="my-4" />

                            <div class="d-flex justify-center">
                                <v-btn-toggle
                                    v-model="type"
                                    tile
                                    mandatory
                                    color="primary"
                                >
                                    <v-btn value="combined"> Combined </v-btn>

                                    <v-btn value="visual"> Visual </v-btn>
                                </v-btn-toggle>
                            </div>

                            <div
                                style="overflow-x: auto"
                                class="d-flex justify-center mt-4 mb-8"
                            >
                                <v-btn-toggle
                                    v-model="patClass"
                                    tile
                                    mandatory
                                    color="primary"
                                >
                                    <v-btn value="class1"> Class 1 </v-btn>
                                    <v-btn value="class2"> Class 2 </v-btn>
                                    <v-btn value="class3"> Class 3 </v-btn>
                                    <v-btn value="extension"> Extension </v-btn>
                                    <v-btn value="distro"> Distro </v-btn>
                                </v-btn-toggle>
                            </div>

                            <pat-test-circuit-single-modal
                                @save="circuitSaved"
                                v-model="addCircuitModal"
                                :all-circuits="patDistro.distro"
                            />

                            <validation-observer ref="assetTest">
                                <!--Distro-->
                                <div v-if="patClass === 'distro'">
                                    <div class="d-flex mb-2">
                                        <div class="text-body-1text-center">
                                            Circuits
                                        </div>
                                        <v-btn
                                            small
                                            color="primary"
                                            @click="addCircuitModal = true"
                                            class="ml-auto"
                                        >
                                            Add Circuit
                                        </v-btn>
                                    </div>

                                    <v-simple-table class="elevation-1 mb-8">
                                        <thead>
                                            <tr>
                                                <th>Circuit</th>
                                                <th>Type</th>
                                                <th>Status</th>
                                                <th>IR (MΩ)</th>
                                                <th>EC (Ωa)</th>
                                                <th>1x</th>
                                                <th>2x</th>
                                                <th>Set mA</th>
                                                <th>Set Time</th>
                                                <th>Trip Time</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr
                                                v-for="circuit in circuitsSorted"
                                            >
                                                <td>
                                                    {{ circuit.circuit_number }}
                                                </td>
                                                <td>{{ circuit.rcd_type }}</td>
                                                <td>
                                                    <v-chip
                                                        label
                                                        small
                                                        :color="
                                                            !circuit.failed
                                                                ? 'success'
                                                                : 'error'
                                                        "
                                                    >
                                                        {{
                                                            !circuit.failed
                                                                ? "Passed"
                                                                : "Failed"
                                                        }}
                                                    </v-chip>
                                                </td>
                                                <td>
                                                    {{ circuit.insulation }}
                                                </td>
                                                <td>{{ circuit.earth }}</td>
                                                <td>{{ circuit.one }}</td>
                                                <td>{{ circuit.five }}</td>
                                                <td>
                                                    {{ circuit.variable_ma }}
                                                </td>
                                                <td>
                                                    {{ circuit.variable_time }}
                                                </td>
                                                <td>
                                                    {{
                                                        circuit.variable_trip_time
                                                    }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>

                                    <form
                                        @submit.prevent="testDistro"
                                        novalidate
                                    >
                                        <v-row>
                                            <v-col>
                                                <div
                                                    class="text-body-1 text-center"
                                                >
                                                    Overall
                                                </div>
                                                <div
                                                    class="d-flex justify-center"
                                                >
                                                    <v-btn-toggle
                                                        v-model="overall"
                                                        tile
                                                        mandatory
                                                        color="white"
                                                    >
                                                        <v-btn
                                                            :color="
                                                                !overall
                                                                    ? 'error'
                                                                    : ''
                                                            "
                                                            :value="false"
                                                        >
                                                            Fail
                                                        </v-btn>

                                                        <v-btn
                                                            :color="
                                                                overall
                                                                    ? 'success'
                                                                    : ''
                                                            "
                                                            :value="true"
                                                        >
                                                            Pass
                                                        </v-btn>
                                                    </v-btn-toggle>
                                                </div>
                                            </v-col>
                                            <v-col>
                                                <v-col>
                                                    <validation-provider
                                                        v-slot="{ errors }"
                                                        name="Date of next inspection"
                                                        rules="required"
                                                    >
                                                        <v-select
                                                            v-model="next_test"
                                                            :items="
                                                                nextTestItems
                                                            "
                                                            label="Date of next inspection"
                                                        />
                                                    </validation-provider>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-8">
                                            <v-col class="text-center">
                                                <v-btn
                                                    :loading="isSavingTest"
                                                    color="primary"
                                                    large
                                                    type="submit"
                                                >
                                                    Save Test
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </form>
                                </div>

                                <!--Non distro-->
                                <div v-else>
                                    <form
                                        @submit.prevent="testAsset"
                                        novalidate
                                    >
                                        <div v-if="type !== 'visual'">
                                            <v-row>
                                                <v-col md="4">
                                                    <validation-provider
                                                        v-slot="{ errors }"
                                                        name="Earth Leakage"
                                                        rules="required|max_value:9.99|min_value:0.00"
                                                    >
                                                        <v-text-field
                                                            v-model="
                                                                pat.leakage
                                                            "
                                                            :disabled="
                                                                pat.no_leakage
                                                            "
                                                            type="number"
                                                            step=".01"
                                                            name="Earth Leakage"
                                                            :error-messages="
                                                                errors
                                                            "
                                                            label="Earth Leakage (mA)"
                                                            class="required"
                                                            required
                                                        >
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                                <v-col md="2">
                                                    <v-checkbox
                                                        v-model="pat.no_leakage"
                                                        label="Not Applicable"
                                                    />
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <validation-provider
                                                        v-slot="{ errors }"
                                                        name="Insulation Resistance"
                                                        rules="required|max_value:19.99|min_value:0.01"
                                                    >
                                                        <v-text-field
                                                            v-model="
                                                                pat.insulation
                                                            "
                                                            type="number"
                                                            step=".01"
                                                            name="Insulation Resistance"
                                                            :error-messages="
                                                                errors
                                                            "
                                                            label="Insulation Resistance (MΩ)"
                                                            class="required"
                                                            required
                                                        >
                                                        </v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <v-row>
                                            <v-col v-if="type !== 'visual'">
                                                <validation-provider
                                                    v-slot="{ errors }"
                                                    name="Earth Continuity"
                                                    rules="required|max_value:9.99|min_value:0.00"
                                                >
                                                    <v-text-field
                                                        v-model="pat.earth"
                                                        type="number"
                                                        step=".01"
                                                        autofocus
                                                        name="Earth Continuity"
                                                        :error-messages="errors"
                                                        label="Earth Continuity (Ωs)"
                                                        class="required"
                                                        required
                                                    >
                                                    </v-text-field>
                                                </validation-provider>
                                            </v-col>
                                            <v-col>
                                                <validation-provider
                                                    v-slot="{ errors }"
                                                    name="Date of next inspection"
                                                    rules="required"
                                                >
                                                    <v-select
                                                        v-model="next_test"
                                                        :items="nextTestItems"
                                                        label="Date of next inspection"
                                                    />
                                                </validation-provider>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col v-if="type !== 'visual'">
                                                <div
                                                    class="text-body-1 text-center"
                                                >
                                                    Visual
                                                </div>
                                                <div
                                                    class="d-flex justify-center"
                                                >
                                                    <v-btn-toggle
                                                        v-model="pat.visual"
                                                        tile
                                                        mandatory
                                                        color="white"
                                                    >
                                                        <v-btn
                                                            :color="
                                                                !pat.visual
                                                                    ? 'error'
                                                                    : ''
                                                            "
                                                            :value="false"
                                                        >
                                                            Fail
                                                        </v-btn>

                                                        <v-btn
                                                            :color="
                                                                pat.visual
                                                                    ? 'success'
                                                                    : ''
                                                            "
                                                            :value="true"
                                                        >
                                                            Pass
                                                        </v-btn>
                                                    </v-btn-toggle>
                                                </div>
                                            </v-col>
                                            <v-col>
                                                <div
                                                    class="text-body-1 text-center"
                                                >
                                                    Polarity
                                                </div>
                                                <div
                                                    class="d-flex justify-center"
                                                >
                                                    <v-btn-toggle
                                                        v-model="pat.polarity"
                                                        tile
                                                        mandatory
                                                        color="white"
                                                    >
                                                        <v-btn
                                                            :color="
                                                                !pat.polarity
                                                                    ? 'error'
                                                                    : ''
                                                            "
                                                            :value="false"
                                                        >
                                                            Fail
                                                        </v-btn>

                                                        <v-btn
                                                            :color="
                                                                pat.polarity
                                                                    ? 'success'
                                                                    : ''
                                                            "
                                                            :value="true"
                                                        >
                                                            Pass
                                                        </v-btn>
                                                    </v-btn-toggle>
                                                </div>
                                            </v-col>
                                            <v-col>
                                                <div
                                                    class="text-body-1 text-center"
                                                >
                                                    Functional Test
                                                </div>
                                                <div
                                                    class="d-flex justify-center"
                                                >
                                                    <v-btn-toggle
                                                        v-model="pat.functional"
                                                        tile
                                                        mandatory
                                                        color="white"
                                                    >
                                                        <v-btn
                                                            :color="
                                                                !pat.functional
                                                                    ? 'error'
                                                                    : ''
                                                            "
                                                            :value="false"
                                                        >
                                                            Fail
                                                        </v-btn>

                                                        <v-btn
                                                            :color="
                                                                pat.functional
                                                                    ? 'success'
                                                                    : ''
                                                            "
                                                            :value="true"
                                                        >
                                                            Pass
                                                        </v-btn>
                                                    </v-btn-toggle>
                                                </div>
                                            </v-col>
                                            <v-col>
                                                <div
                                                    class="text-body-1 text-center"
                                                >
                                                    Overall
                                                </div>
                                                <div
                                                    class="d-flex justify-center"
                                                >
                                                    <v-btn-toggle
                                                        v-model="overall"
                                                        tile
                                                        mandatory
                                                        color="white"
                                                    >
                                                        <v-btn
                                                            :color="
                                                                !overall
                                                                    ? 'error'
                                                                    : ''
                                                            "
                                                            :value="false"
                                                        >
                                                            Fail
                                                        </v-btn>

                                                        <v-btn
                                                            :color="
                                                                overall
                                                                    ? 'success'
                                                                    : ''
                                                            "
                                                            :value="true"
                                                        >
                                                            Pass
                                                        </v-btn>
                                                    </v-btn-toggle>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row class="mt-8">
                                            <v-col class="text-center">
                                                <v-btn
                                                    :loading="isSavingTest"
                                                    color="primary"
                                                    large
                                                    type="submit"
                                                >
                                                    Save Test
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </form>
                                </div>
                            </validation-observer>
                        </div>
                    </v-fade-transition>
                </v-sheet>

                <!--Recent Tests-->
                <v-fade-transition>
                    <v-sheet class="pa-6 mt-6" elevation="2">
                        <div class="text-h6">
                            <div v-if="!asset">Latest Tests</div>
                            <div v-if="asset">
                                <strong>{{ asset.asset_number }}</strong> tests
                            </div>
                        </div>

                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th>Asset</th>
                                    <th>Product</th>
                                    <th>Status</th>
                                    <th>User</th>
                                    <th>Timestamp</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-if="!asset" v-for="pat in pats">
                                    <td>
                                        <inertia-link
                                            :href="
                                                route(
                                                    'staff.pat.show',
                                                    pat.asset
                                                )
                                            "
                                        >
                                            {{ pat.asset }}
                                        </inertia-link>
                                    </td>
                                    <td>{{ pat.product.name }}</td>
                                    <td>
                                        <v-chip
                                            label
                                            small
                                            :color="
                                                pat.overall
                                                    ? 'success'
                                                    : 'error'
                                            "
                                        >
                                            {{
                                                pat.overall
                                                    ? "Passed"
                                                    : "Failed"
                                            }}
                                        </v-chip>
                                    </td>
                                    <td>{{ pat.user.name }}</td>
                                    <td>
                                        {{
                                            momentFormatDateTimeYear(
                                                pat.created_at
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr v-if="asset" v-for="pat in previousTests">
                                    <td>
                                        <inertia-link
                                            :href="
                                                route(
                                                    'staff.pat.show',
                                                    pat.asset
                                                )
                                            "
                                        >
                                            {{ pat.asset }}
                                        </inertia-link>
                                    </td>
                                    <td>{{ pat.product.name }}</td>
                                    <td>
                                        <v-chip
                                            label
                                            small
                                            :color="
                                                pat.overall
                                                    ? 'success'
                                                    : 'error'
                                            "
                                        >
                                            {{
                                                pat.overall
                                                    ? "Passed"
                                                    : "Failed"
                                            }}
                                        </v-chip>
                                    </td>
                                    <td>{{ pat.user.name }}</td>
                                    <td>
                                        {{
                                            momentFormatDateTimeYear(
                                                pat.created_at
                                            )
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-sheet>
                </v-fade-transition>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ProcessesController from "../../../Mixins/ProcessesController";
import HeaderName from "../../../Comps/General/HeaderName";
import { required, max, min_value, max_value } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import PatTestCircuitSingleModal from "../../../Comps/Processes/PatTestCircuitSingleModal";

extend("required", required);
extend("max", max);
extend("min_value", min_value);
extend("max_value", max_value);

export default {
    name: "PatIndex",
    components: { PatTestCircuitSingleModal, HeaderName },
    mixins: [ProcessesController],
    props: ["pats"],

    data() {
        return {
            isSearching: false,
            asset: null,
            product: null,
            previousTests: null,
            assetSearch: "",
            isSavingTest: false,
            pat: null,
            patDistro: null,
            overall: false,
            next_test: 12,
            type: "combined",
            patClass: "class2",
            description: "",
            addCircuitModal: false,
        };
    },

    watch: {
        "pat.no_leakage"() {
            this.pat.leakage = "0.00";
        },
    },

    mounted() {
        this.fresh();
    },

    methods: {
        fresh() {
            this.isSearching = false;
            this.asset = null;
            this.assetSearch = "";
            this.previousTests = null;
            this.product = null;
            this.isSavingTest = false;
            this.overall = false;
            this.next_test = 12;
            this.description = "";
            this.pat = {
                leakage: "0.00",
                no_leakage: true,
                insulation: "19.99",
                earth: "0.0",
                visual: true,
                polarity: true,
                functional: true,
            };
            this.patDistro = {
                distro: [],
            };
        },

        circuitSaved(circuit) {
            this.patDistro.distro.push(circuit);
        },

        searchForAsset() {
            this.$refs.assetSearch.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.isSearching = true;

                this.$http
                    .get(
                        this.route("staff.pat.search", {
                            asset: this.assetSearch,
                        })
                    )
                    .then((response) => {
                        this.asset = response.data.current_asset;
                        this.product = response.data.product;
                        this.previousTests = response.data.previous;
                    })
                    .catch((error) => {
                        let errorText;
                        if (error.response.data.errors) {
                            errorText = Object.values(
                                error.response.data.errors
                            )[0][0];
                        } else {
                            errorText =
                                "There has been an error finding that asset";
                        }

                        this.$toast.error(errorText, {
                            duration: 5000,
                        });

                        this.$nextTick(() => {
                            let el =
                                this.$refs.assetSearchInput.$el.querySelector(
                                    "input"
                                );
                            el.focus();
                            el.select();
                        });
                    })
                    .finally(() => {
                        this.isSearching = false;
                    });
            });
        },

        overallCheck() {
            return new Promise(async (resolve, reject) => {
                if (this.overall) {
                    resolve();
                    return;
                }
                let fail = await prompt(
                    "This asset has failed, please give a reason below"
                );

                if (!fail) {
                    reject("Error");
                }

                this.description = fail;

                resolve();
            });
        },

        async testDistro() {
            this.$refs.assetTest.validate().then(async (success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                if (!this.patDistro.distro.length) {
                    this.$toast.error("Please add at least one circuit", {
                        duration: 5000,
                    });
                    return;
                }

                if (
                    await _.find(this.patDistro.distro, (circuit) => {
                        return circuit.failed;
                    })
                ) {
                    this.overall = false;
                }

                this.overallCheck()
                    .then(() => {
                        this.submitDistro();
                    })
                    .catch(() => {
                        this.$toast.error(
                            "You need to give a reason for failing this asset",
                            {
                                duration: 5000,
                            }
                        );
                        this.description = "";
                    });
            });
        },

        async testAsset() {
            this.$refs.assetTest.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                if (
                    this.overall &&
                    (!this.pat.visual ||
                        !this.pat.polarity ||
                        !this.pat.functional)
                ) {
                    this.overall = false;
                }

                this.overallCheck()
                    .then(() => {
                        this.submitAsset();
                    })
                    .catch(() => {
                        this.$toast.error(
                            "You need to give a reason for failing this asset",
                            {
                                duration: 5000,
                            }
                        );
                        this.description = "";
                    });
            });
        },

        submitAsset() {
            this.isSavingTest = true;

            let data = this.pat;
            data.asset = this.asset.asset_number;
            data.asset_id = this.asset.id;
            data.product_id = this.asset.item_id;
            data.type = this.type;
            data.class = this.patClass;
            data.overall = this.overall;
            data.description = this.description;
            data.next_test = this.next_test;

            this.$inertia.post(this.route("staff.pat.store"), data, {
                onSuccess: () => {
                    this.fresh();
                },

                onError: () => {
                    this.isSavingTest = false;
                },
            });
        },

        submitDistro() {
            this.isSavingTest = true;

            let data = this.patDistro;
            data.asset = this.asset.asset_number;
            data.asset_id = this.asset.id;
            data.product_id = this.asset.item_id;
            data.type = this.type;
            data.class = this.patClass;
            data.overall = this.overall;
            data.description = this.description;
            data.next_test = this.next_test;

            this.$inertia.post(this.route("staff.pat.storeDistro"), data, {
                onSuccess: () => {
                    this.fresh();
                },

                onError: () => {
                    this.isSavingTest = false;
                },
            });
        },
    },

    computed: {
        nextTestItems() {
            return [
                { text: "6 Months", value: 6 },
                { text: "12 Months", value: 12 },
            ];
        },

        circuitsSorted() {
            return _.sortBy(this.patDistro.distro, "circuit_number");
        },
    },
};
</script>

<style scoped></style>
