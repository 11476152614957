<template>
    <v-container>
        <head-title title="Add Stock" />
        <div class="text-h4 mb-4">Add Stock</div>
        <add-stock-form
            product-search-route-name="warehouse.add-stock.search"
            change-stock-route-name="warehouse.add-stock.change"
            add-stock-route-name="warehouse.add-stock.add"
            get-stock-levels-route-name="warehouse.add-stock.stock-level"
        />
    </v-container>
</template>

<script>
import HeadTitle from "../../../Comps/Master/HeadTitle";
import AddStockForm from "../../../Comps/Processes/AddStockForm";
export default {
    name: "AddStock",
    components: { AddStockForm, HeadTitle },
};
</script>

<style scoped></style>
