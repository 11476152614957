var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-2 my-2",attrs:{"headers":[
        { text: 'Start', value: 'start_date' },
        { text: 'End', value: 'end_date' },
        { text: 'Notes', value: 'notes' },
        { text: 'Hours', value: 'hours' },
        { text: '', value: 'actions', sortable: false },
    ],"items":_vm.$page.props.approvedHolidays,"disable-pagination":"","hide-default-footer":"","sort-by":"start_date"},scopedSlots:_vm._u([{key:"item.start_date",fn:function({ value }){return [_vm._v("\n        "+_vm._s(_vm.momentFormatDateYear(value))+"\n    ")]}},{key:"item.end_date",fn:function({ value }){return [_vm._v("\n        "+_vm._s(_vm.momentFormatDateYear(value))+"\n    ")]}},{key:"item.actions",fn:function({ item }){return [_c('div',[(item.half_day && item.morning)?_c('v-chip',{attrs:{"x-small":"","label":""}},[_vm._v("\n                Morning\n            ")]):_vm._e(),_vm._v(" "),(item.half_day && !item.morning)?_c('v-chip',{attrs:{"x-small":"","label":""}},[_vm._v("\n                Afternoon\n            ")]):_vm._e(),_vm._v(" "),(_vm.spansTwoSheets(item))?_c('v-chip',{attrs:{"x-small":"","label":""}},[_vm._v("\n                Spans two timesheets\n            ")]):_vm._e(),_vm._v(" "),(item.type !== 'Paid')?_c('v-chip',{attrs:{"x-small":"","label":""}},[_vm._v("\n                "+_vm._s(item.type)+"\n            ")]):_vm._e()],1)]}},{key:"item.hours",fn:function({ item }){return [(item.half_day)?_c('div',[_vm._v("4")]):_c('div',[_vm._v(_vm._s(_vm.calculateHours(item)))])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }