<template>
    <expansion-panel
        icon="mdi-calendar-range"
        header="Holiday"
        v-if="hasPermission('staffAdminHolidays')"
    >
        <div v-if="!$page.props.hasHolidaysSetup">
            <div>This user has no holiday allowance</div>
            <v-btn
                color="primary"
                v-if="!addHolidayAllowance"
                @click="addHolidayAllowance = true"
                small
            >
                Add Allowance
            </v-btn>
        </div>

        <div v-if="addHolidayAllowance" class="mt-4">
            <div>
                <form @submit.prevent="submitHolidayAllowance" novalidate>
                    <validation-observer ref="holidayAllowanceForm">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Allowance"
                            rules="required|numeric"
                        >
                            <v-text-field
                                v-model="form.holiday_allowance"
                                name="Allowance"
                                dense
                                outlined
                                type="number"
                                min-value="1"
                                :error-messages="errors"
                                label="Holiday Allowance"
                                hint="The number of days paid holiday this user can take each year"
                                class="required"
                                required
                            >
                            </v-text-field>
                        </validation-provider>
                    </validation-observer>

                    <v-btn
                        color="success"
                        type="submit"
                        small
                        :disabled="!form.isDirty"
                        :loading="form.processing"
                    >
                        Save
                    </v-btn>
                </form>
            </div>
        </div>

        <div v-if="$page.props.hasHolidaysSetup" class="mt-4">
            <v-btn
                class="mb-4"
                color="primary"
                v-if="!addHolidayAllowance"
                @click="addHolidayAllowance = true"
                small
            >
                Edit Allowance
            </v-btn>

            <holiday-stats-grid :stats="$page.props.holidayStats" />

            <v-divider class="my-8" />

            <holiday-comp
                :user="$page.props.user"
                :holidays="$page.props.holidays"
            />
        </div>
    </expansion-panel>
</template>

<script>
import ExpansionPanel from "./ExpansionPanel";
import Permissions from "../../../../Mixins/Permissions";
import HolidayStatsGrid from "../../Personal/Holiday/HolidayStatsGrid";
import HolidayComp from "../../Personal/Holiday/HolidayComp";
import { extend } from "vee-validate";
import { required, numeric } from "vee-validate/dist/rules";
extend("required", required);
extend("numeric", numeric);

export default {
    name: "HolidayPanel",
    components: { HolidayComp, HolidayStatsGrid, ExpansionPanel },
    mixins: [Permissions],

    data() {
        return {
            addHolidayAllowance: false,
            form: this.$inertia.form({
                holiday_allowance: this.$page.props.user.holiday_allowance,
            }),
        };
    },

    methods: {
        submitHolidayAllowance() {
            this.$refs.holidayAllowanceForm.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.form.put(
                    this.route(
                        "staff.admin.users.holidayAllowance",
                        this.$page.props.user
                    ),
                    {
                        preserveScroll: true,
                        preserveState: true,
                        onSuccess: () => {
                            this.addHolidayAllowance = false;
                        },
                    }
                );
            });
        },
    },
};
</script>

<style scoped></style>
