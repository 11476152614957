<template>
    <v-app>
        <div
            style="position: fixed; top: 0; left: 0; width: 100%; z-index: 2"
            class="white px-4"
            :style="{ height: topHeight }"
        >
            <logistics-week-day-selector
                :this-week="thisWeek"
                :type.sync="type"
                :day.sync="day"
            />
            <v-select
                v-model="filters"
                :items="availableFilters"
                label="Filter"
                multiple
                chips
                dense
                outlined
                small-chips
                deletable-chips
                :menu-props="{ closeOnContentClick: true }"
            />
        </div>

        <div :style="{ height: topHeight }"></div>

        <logistics-chart
            v-if="ready"
            :this-week="thisWeek"
            @taskClick="clickTask"
            :filters="filters"
            :type="type"
            :day="day"
            :top-height="topHeight"
        />
    </v-app>
</template>

<script>
import LogisticsChart from "../../../Comps/Staff/Logistics/GanttChart/LogisticsChart.vue";
import LocalStorage from "../../../Mixins/LocalStorage.js";
import LogisticsWeekDaySelector from "../../../Comps/Staff/Logistics/LogisticsWeekDaySelector.vue";
import Permissions from "../../../Mixins/Permissions.js";
export default {
    name: "LogisticsGantt",
    components: { LogisticsWeekDaySelector, LogisticsChart },
    layout: null,

    mixins: [LocalStorage, Permissions],

    data() {
        return {
            ready: false,
            filters: ["Users", "Vehicles", "Freelancers"],
            type: "week",
            day: "2022-01-01",
        };
    },

    watch: {
        filters(val) {
            this.localStorageSet(
                "logistics-gantt-filters",
                JSON.stringify(val)
            );
        },

        thisWeek(val) {
            if (val) {
                this.day = val.clone().format("YYYY-MM-DD");
            }
        },
    },

    created() {
        const filters = this.localStorageGet("logistics-gantt-filters");

        if (filters) {
            this.filters = JSON.parse(filters);
        }

        window.onmessage = ({ data }) => {
            if (data.props) {
                Object.keys(data.props).forEach((key) => {
                    this.$set(this.$page.props, key, data.props[key]);
                });

                this.ready = true;
            }
        };

        window.top.postMessage({ message: "pytch-gantt-chart-ready" });
    },

    methods: {
        clickTask(task) {
            window.top.postMessage({
                message: "pytch-gantt-chart-open-task-modal",
                data: task,
            });
        },
    },

    computed: {
        logistic() {
            return this.$page.props.logistic;
        },

        thisWeek() {
            return this.moment(this.logistic?.week);
        },

        topHeight() {
            return this.$vuetify.breakpoint.smAndUp ? "120px" : "200px";
        },

        availableFilters() {
            const filters = [
                "Users",
                "Vehicles",
                "Freelancers",
                "Hide Available Users",
                "Highlight Changes",
            ];

            if (this.$page.props.auth?.user) {
                filters.push("Only Me");
            }

            return filters;
        },
    },
};
</script>

<style>
html {
    overflow-x: initial !important;
}
</style>
