<template>
    <v-list-item>
        <template v-slot:default="{ active }">
            <v-list-item-content>
                <v-list-item-title>
                    {{ vehicle.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="success--text" v-if="!hasClash">
                    No clashes
                </v-list-item-subtitle>
                <v-list-item-subtitle
                    style="white-space: normal"
                    class="error--text"
                    v-else
                >
                    <div>
                        This vehicle is already assigned to task
                        <span class="font-weight-bold">
                            {{ hasClash.name }}
                        </span>
                    </div>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <div>
                    <v-btn
                        icon
                        @click.stop="$emit('remove')"
                        small
                        color="error"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </v-list-item-action>
        </template>
    </v-list-item>
</template>

<script>
import ClashFinder from "../../../../Mixins/Logistics/ClashFinder";

export default {
    name: "TaskVehicle",
    props: {
        vehicle: {
            type: Object,
            required: true,
        },
        task: {
            type: Object,
            required: true,
        },
    },

    mixins: [ClashFinder],

    data() {
        return {
            hasClash: null,
        };
    },

    watch: {
        task: {
            immediate: true,
            deep: true,
            handler() {
                this.triggerFindClash();
            },
        },
    },

    computed: {
        vehiclesOtherTasks() {
            let thisTaskTime = this.moment(this.task.date).utc(true);
            return _.filter(this.$page.props.allTasks, (task) => {
                return (
                    _.find(task.vehicles, { id: this.vehicle.id }) &&
                    this.moment(task.date).isSame(thisTaskTime)
                );
            });
        },
    },

    methods: {
        triggerFindClash() {
            this.hasClash = this.findClash(
                this.vehiclesOtherTasks,
                this.task.time_from_original,
                this.task.time_to_original
            );
        },
    },
};
</script>

<style lang="scss">
.tooltip-task-user {
    max-width: 150px;
}
</style>
