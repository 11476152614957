<template>
    <v-container class="fixed">
        <breadcrumbs :items="bread"></breadcrumbs>
        <div class="d-flex">
            <header-name>View Session</header-name>
            <div class="ml-auto">
                <v-btn small color="primary" @click="modal = true">
                    Edit Session
                </v-btn>
            </div>
        </div>

        <v-list two-line outlined>
            <!--Module-->
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>
                        mdi-view-module
                    </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Module</v-list-item-title>
                    <v-list-item-subtitle>
                        <inertia-link
                            :href="
                                route(
                                    'staff.admin.training.modules.show',
                                    session.module
                                )
                            "
                        >
                            {{ session.module.name }}
                        </inertia-link>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <!--Instructor-->
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>
                        mdi-shield-sun
                    </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Instructor</v-list-item-title>
                    <v-list-item-subtitle
                        v-text="session.instructor_name"
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <!--Date Completed-->
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>
                        mdi-calendar-range
                    </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Date Completed</v-list-item-title>
                    <v-list-item-subtitle
                        >{{ momentFormatDateYear(session.completed_at) }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <!--Location-->
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>
                        mdi-map-marker
                    </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Location</v-list-item-title>
                    <v-list-item-subtitle
                        v-text="session.location"
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <!--Followup-->
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>
                        mdi-message-reply-text
                    </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Followup</v-list-item-title>
                    <v-list-item-subtitle
                        v-text="session.followup"
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <!--Users-->
        <div class="text-h5 mt-5 text--secondary">Users</div>

        <v-list outlined>
            <div v-for="(user, index) in session.users" :key="user.id">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{
                            user.user.name
                        }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-subtitle>
                            <span v-if="user.passed" class="green--text">
                                Passed
                            </span>
                            <span v-else class="red--text">Failed</span>
                            <v-menu offset-y v-if="user.passed">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        :disabled="!!loading"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-chevron-down
                                    </v-icon>
                                </template>
                                <v-list>
                                    <v-list-item
                                        link
                                        :href="
                                            user.file_upload
                                                ? user.file_upload.url
                                                : route(
                                                      'staff.admin.training.sessions.downloadCertificate',
                                                      [session.id, user.user_id]
                                                  )
                                        "
                                        target="_blank"
                                    >
                                        <v-list-item-title
                                            >Download Certificate
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        link
                                        @click="resendCert(user.user, index)"
                                    >
                                        <v-list-item-title>
                                            Resend Certificate
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-list-item-subtitle>
                    </v-list-item-action>
                </v-list-item>
                <v-divider v-if="session.users.length > index + 1"></v-divider>
            </div>
        </v-list>

        <sessions-create-modal
            :edit="session"
            :users="users"
            :modules="modules"
            v-model="modal"
        >
        </sessions-create-modal>
    </v-container>
</template>

<script>
import HeaderName from "../../../../Comps/General/HeaderName";
import Breadcrumbs from "../../../../Comps/General/Breadcrumbs";
import SessionsCreateModal from "../../../../Comps/Staff/Training/SessionCreateModal";
import AdminController from "../../../../Mixins/AdminController";

export default {
    name: "SessionsShow",
    components: { SessionsCreateModal, Breadcrumbs, HeaderName },
    props: ["session", "users", "modules"],
    mixins: [AdminController],

    data() {
        return {
            bread: [
                {
                    text: "Sessions",
                    disabled: false,
                    link: this.route("staff.admin.training.sessions.index"),
                },
                {
                    text: "Session",
                    disabled: true,
                },
            ],
            loading: null,
            modal: false,
        };
    },
    methods: {
        resendCert(user, index) {
            this.loading = index;
            this.$inertia.post(
                this.route("staff.admin.training.sessions.resendCertificate", [
                    this.session.id,
                    user.id,
                ]),
                {},
                {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.loading = null;
                    },
                }
            );
        },
    },
};
</script>

<style scoped></style>
