import Moment from "moment";
import { extendMoment } from "moment-range";
import LogisticsHolidayHelpers from "./LogisticsHolidayHelpers";

const moment = extendMoment(Moment);

export default {
    data() {
        return {
            userClash: [],
            taskClash: false,
        };
    },

    mixins: [LogisticsHolidayHelpers],

    methods: {
        /**
         *
         * Takes an array of tasks to find a clash against the times passed in
         *
         * @param {array} tasks
         * @param {string} timeFrom
         * @param {string} timeTo
         * @return boolean
         */
        findClash(tasks, timeFrom, timeTo) {
            let clash = null;

            //Make a moment range from this task
            let r = this.getRange(timeFrom, timeTo);

            //Look through each of the users other tasks and find out if they overlap
            _.each(tasks, (task) => {
                let from = task.time_from
                    ? this.moment(task.time_from).format("HH:mm")
                    : "00:00";
                let to = task.time_to
                    ? this.moment(task.time_to).format("HH:mm")
                    : "23:59";
                let range = moment.range(
                    moment(from, "HH:mm"),
                    moment(to, "HH:mm")
                );
                if (range.overlaps(r)) {
                    clash = task;
                    return false;
                }
            });

            return clash;
        },

        /**
         * Finds any clash for a task
         *
         * @param {Object} task
         * @param {Array} tasks
         * @param {String} timeFrom
         * @param {String} timeTo
         * @return boolean
         */
        async findAnyTaskClash(task, tasks, timeFrom, timeTo) {
            if (this.checkUserClash(task, tasks, timeFrom, timeTo)) {
                return true;
            }

            if (this.checkFreelancerClash(task, tasks, timeFrom, timeTo)) {
                return true;
            }

            return this.checkVehicleClash(task, tasks, timeFrom, timeTo);
        },

        /**
         * Finds any clash for all users within a task
         *
         * @param {Object} task
         * @param {Array} tasks
         * @param {String} timeFrom
         * @param {String} timeTo
         * @return boolean
         */
        checkUserClash(task, tasks, timeFrom, timeTo) {
            //First get all the users and their tasks
            let userTasks = [];
            let taskUsers = _.cloneDeep(task.users);

            _.each(taskUsers, (user) => {
                user.tasks = _.filter(tasks, (task) => {
                    return _.find(task.users, { id: user.id });
                });
                userTasks.push(user);
            });

            //Check if the users are clashing
            let usersAreCLashing = false;
            userTasks.forEach((user) => {
                if (this.findClash(user.tasks, timeFrom, timeTo)) {
                    usersAreCLashing = true;
                    return false;
                }
            });

            if (usersAreCLashing) {
                return usersAreCLashing;
            }

            return this.findClashingHolidays(task);
        },

        /**
         * Finds any clash for all freelancers within a task
         *
         * @param {Object} task
         * @param {Array} tasks
         * @param {String} timeFrom
         * @param {String} timeTo
         * @return boolean
         */
        checkFreelancerClash(task, tasks, timeFrom, timeTo) {
            //First get all the freelancers and their tasks
            let freelancerTasks = [];
            let taskFreelancers = _.cloneDeep(task.freelancers);
            _.each(taskFreelancers, (freelancer) => {
                freelancer.tasks = _.filter(tasks, (task) => {
                    return _.find(task.freelancers, { id: freelancer.id });
                });
                freelancerTasks.push(freelancer);
            });

            //Check if the freelancers are clashing
            let freelancersAreClashing = false;
            freelancerTasks.forEach((freelancer) => {
                if (this.findClash(freelancer.tasks, timeFrom, timeTo)) {
                    freelancersAreClashing = true;
                    return false;
                }
            });

            return freelancersAreClashing;
        },

        /**
         * Finds any clash for all vehicles within a task
         *
         * @param {Object} task
         * @param {Array} tasks
         * @param {String} timeFrom
         * @param {String} timeTo
         * @return boolean
         */
        checkVehicleClash(task, tasks, timeFrom, timeTo) {
            //First get all the vehicles and their tasks
            let vehicleTasks = [];
            let taskVehicles = _.cloneDeep(task.vehicles);
            _.each(taskVehicles, (vehicle) => {
                vehicle.tasks = _.filter(tasks, (task) => {
                    return _.find(task.vehicles, { id: vehicle.id });
                });
                vehicleTasks.push(vehicle);
            });

            //Check if the vehicles are clashing
            let vehiclesAreClashing = false;
            vehicleTasks.forEach((vehicle) => {
                if (this.findClash(vehicle.tasks, timeFrom, timeTo)) {
                    vehiclesAreClashing = true;
                    return false;
                }
            });

            return vehiclesAreClashing;
        },

        /**
         * Finds any clashing holidays for a task
         *
         * @param {Object} task
         * @return boolean
         */
        findClashingHolidays(task) {
            let hasClash = false;

            //Loop through each user and check if they have a clashing holiday
            _.each(task.users, (user) => {
                if (this.findHolidayClashForUser(task, user)) {
                    hasClash = true;
                    return false;
                }
            });

            return hasClash;
        },

        /**
         * Finds a clashing holiday for a user within a task
         *
         * @param {Object} task
         * @param {Object} user
         * @return Array|null
         */
        findHolidayClashForUser(task, user) {
            return _.find(
                this.getDaysHolidays(this.moment.utc(task.date)),
                (holiday) => {
                    return holiday.user.id === user.id;
                }
            );
        },

        /**
         * Casts the time from of a task
         *
         * @param {string} timeFrom
         * @return string
         */
        timeFromCalc(timeFrom) {
            return timeFrom ? this.moment(timeFrom).format("HH:mm") : "00:00";
        },

        /**
         * Casts the time to of a task
         *
         * @param {string} timeTo
         * @return string
         */
        timeToCalc(timeTo) {
            return timeTo ? this.moment(timeTo).format("HH:mm") : "23:59";
        },

        /**
         * Gets the moment range of two times
         *
         * @param {string} timeFrom
         * @param {string} timeTo
         * @return DateRange
         */
        getRange(timeFrom, timeTo) {
            return moment.range(
                moment(this.timeFromCalc(timeFrom), "HH:mm"),
                moment(this.timeToCalc(timeTo), "HH:mm")
            );
        },
    },
};
