var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fixed":""}},[_c('header-name',[_vm._v("Visitor Logs")]),_vm._v(" "),_c('v-sheet',{staticClass:"pa-4 mt-8",attrs:{"elevation":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('date-time-picker',{attrs:{"mode":"date","name":"Date","label":"Date","hint":"Show logs for this date"},on:{"input":_vm.dateSearch},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_vm._v(" "),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.$refs.modal.create()}}},[_vm._v("\n                    New Visitor\n                ")])],1)],1),_vm._v(" "),_c('v-divider',{staticClass:"my-4"}),_vm._v(" "),_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","headers":[
                { text: 'Name', value: 'name' },
                { text: 'Email', value: 'email' },
                { text: 'Arrived', value: 'arrived_at' },
                { text: 'Departed', value: 'departed_at' },
                { text: '', value: 'options' },
            ],"items":_vm.$page.props.visitorLogs},scopedSlots:_vm._u([{key:"item.arrived_at",fn:function({ value }){return [(value)?_c('span',{domProps:{"textContent":_vm._s(_vm.momentFormatDateTimeYear(value))}}):_vm._e()]}},{key:"item.departed_at",fn:function({ value }){return [(value)?_c('span',{domProps:{"textContent":_vm._s(_vm.momentFormatDateTimeYear(value))}}):_vm._e()]}},{key:"item.options",fn:function({ item }){return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("\n                            mdi-dots-vertical\n                        ")])]}}],null,true)},[_vm._v(" "),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.modal.edit(item)}}},[_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v("\n                                Edit\n                            ")])],1),_vm._v(" "),(!item.departed_at)?[_c('v-divider'),_vm._v(" "),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.departed(item)}}},[_c('v-list-item-title',{staticClass:"info--text"},[_vm._v("\n                                    Mark as Departed\n                                ")])],1)]:_vm._e()],2)],1)]}}])})],1),_vm._v(" "),_c('visitor-log-modal',{ref:"modal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }