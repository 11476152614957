<template>
    <v-row class="align-center">
        <v-col cols="12" md="4">
            <p :class="{ 'required-label': required }" class="text-body-1">
                {{ label }}
            </p>
        </v-col>
        <v-col cols="12" md="8">
            <slot v-bind="{ label }" />
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "InputLayout",
    props: {
        label: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped></style>
