<template>
    <v-card>
        <logistics-week-day-selector
            :day.sync="day"
            :type.sync="type"
            :this-week="thisWeek"
        />

        <div style="overflow-x: auto">
            <v-calendar
                ref="calendar"
                :style="{ 'min-width': minWidth }"
                :value="day"
                :type="type"
                color="primary"
                :weekdays="weekday"
                :events="events"
                :event-overlap-mode="overlapMode"
                :event-overlap-threshold="30"
                :first-time="firstTime"
                :interval-count="getIntervalCount"
                event-color="transparent"
                @click:event="clickEvent"
                @click:date="clickDay"
            >
                <template v-slot:event="{ event }">
                    <calender-task-event
                        v-if="!event.holiday"
                        :user="user"
                        :task="event"
                        :logistic="logistic"
                    >
                    </calender-task-event>
                    <logistics-holiday-task
                        v-if="event.holiday"
                        :task="event"
                    />
                </template>
            </v-calendar>
        </div>
        <task-info-modal
            :logistic="logistic"
            :live="logisticLive"
            :user="user"
            ref="taskModal"
        >
        </task-info-modal>
    </v-card>
</template>

<script>
import TaskInfoModal from "./Tasks/TaskInfoModal/TaskInfoModal.vue";
import CalenderTaskEvent from "./Tasks/CalenderTaskEvent.vue";
import LogisticsHolidayTask from "./Tasks/LogisticsHolidayTask.vue";
import LogisticsWeekDaySelector from "./LogisticsWeekDaySelector.vue";

export default {
    name: "LogisticsCalendar",
    components: {
        LogisticsWeekDaySelector,
        LogisticsHolidayTask,
        CalenderTaskEvent,
        TaskInfoModal,
    },

    props: {
        thisWeek: {
            type: Object,
            required: true,
        },
        user: {
            type: Boolean,
            default: false,
        },
        logisticLive: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            type: "week",
            weekday: [1, 2, 3, 4, 5, 6, 0],
            events: [],
            day: null,
        };
    },
    mounted() {
        this.$once(
            "hook:destroyed",
            this.$inertia.on("success", () => {
                this.getEvents();
            })
        );
    },

    created() {
        this.getEvents();
    },

    methods: {
        clickDay(day) {
            this.type = "day";
            this.day = day.date;
        },

        clickTask(task) {
            this.$refs.taskModal.open(task);
        },

        clickEvent({ event }) {
            if (event.holiday) {
                return;
            }

            if (this.logistic.deleted_at) {
                alert("You cannot view this task as this version is deleted");
                return;
            }

            //Find the event in tasks
            let find = _.findIndex(this.events, (e) => {
                return event.id === e.id;
            });

            if (find !== -1) {
                this.clickTask(this.events[find]);
            }
        },

        getEvents() {
            this.events = [];
            if (!this.logistic) {
                this.events = [];
                return;
            }
            this.events = _.map(this.logistic.tasks, (task) => {
                task.start = this.getEventTime(
                    task.time_from,
                    task.date,
                    "from",
                    task
                );
                task.end = this.getEventTime(
                    task.time_to,
                    task.date,
                    "to",
                    task
                );
                return task;
            });

            this.getHoliday();
        },

        getEventTime(time, day, type, task) {
            if (type === "to" && task.when_its_done) {
                return this.moment(
                    day + " " + "23:59",
                    "YYYY-MM-DD HH:mm"
                ).format("YYYY-MM-DD HH:mm");
            }

            if (time) {
                return this.moment(
                    day + " " + this.convertTimeForMoment(time),
                    "YYYY-MM-DD HH:mm"
                ).format("YYYY-MM-DD HH:mm");
            } else {
                let timeToFrom = type === "from" ? "00:00" : "23:59";
                return this.moment(
                    day + " " + timeToFrom,
                    "YYYY-MM-DD HH:mm"
                ).format("YYYY-MM-DD HH:mm");
            }
        },

        convertTimeForMoment(time) {
            return this.moment(time).utc().format("HH:mm");
        },

        getHoliday() {
            if (this.freelancer) {
                return;
            }

            let week = this.moment(this.weekForVue);
            for (let i = 0; i < 7; i++) {
                this.events.push({
                    start: week.clone().add(i, "day").format("YYYY-MM-DD"),
                    allDay: true,
                    color: "orange",
                    holiday: true,
                    name: "Holiday",
                });
            }
        },

        formatForCalFirstTime(time) {
            let newTime = this.moment(time).utc();

            if (
                newTime.isBefore(
                    this.moment().utc().set({ hour: 1, minute: 0, second: 0 })
                )
            ) {
                return "00:00";
            }

            return newTime.subtract(1, "hour").startOf("hour").format("HH:mm");
        },
    },

    computed: {
        minWidth() {
            if (this.$vuetify.breakpoint.smAndDown && this.type === "week") {
                return "900px";
            }
            return "auto";
        },

        overlapMode() {
            if (this.type === "day" && this.$vuetify.breakpoint.smAndDown) {
                return "column";
            }

            return "stack";
        },

        firstTime() {
            let events = this.events;

            //If day view then filter events to this day
            if (this.type === "day") {
                events = _.filter(this.events, (event) => {
                    let day = this.moment(event.date).format("YYYY-MM-DD");
                    return day === this.day;
                });
            }

            //If no events then show from midnight
            if (!events.length) {
                return "00:00";
            }

            //Sort events by time from then get the first
            let time = _.sortBy(events, "time_from")[0].time_from;

            if (!time) {
                return "00:00";
            }

            return this.formatForCalFirstTime(time);
        },

        logistic() {
            return this.$page.props.logistic;
        },

        getIntervalCount() {
            let count =
                24 -
                parseInt(this.firstTime.substr(0, this.firstTime.indexOf(":")));
            return count === 24 ? count : count + 1;
        },

        weekForVue() {
            if (this.thisWeek) {
                return this.thisWeek.format("YYYY-MM-DD");
            }

            return this.moment().format("YYYY-MM-DD");
        },
    },

    watch: {
        weekForVue: {
            immediate: true,
            handler(value) {
                let startOfWeek = this.moment()
                    .startOf("isoWeek")
                    .format("YYYY-MM-DD");
                //See if this logistics is this week
                if (value === startOfWeek) {
                    this.day = this.moment().format("YYYY-MM-DD");
                    return;
                }
                //Not this week
                this.day = value;
            },
        },
    },
};
</script>

<style scoped></style>
