<template>
    <modal-template v-model="modal">
        <div class="text-h6">Vehicle Information</div>

        <validation-observer ref="form">
            <v-row class="mt-4">
                <v-col cols="12" md="6">
                    <validation-provider
                        v-slot="{ errors }"
                        name="Mileage"
                        rules="required|numeric|mileage"
                    >
                        <v-text-field
                            v-model="mileageData"
                            name="Mileage"
                            outlined
                            :error-messages="errors"
                            type="number"
                            label="Mileage"
                            hint="The current mileage of the vehicle"
                            class="required"
                            required
                        >
                        </v-text-field>
                    </validation-provider>
                </v-col>
                <v-col v-if="!bookOut" cols="12" md="6">
                    <date-time-picker
                        v-model="conditionData.bookIn"
                        :min="minBookInTime"
                        :dense="false"
                        label="Book In Time"
                        name="Book In Time"
                        required
                        class="required"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6">
                    <div class="text-subtitle-1">Exterior</div>
                    <v-divider class="mb-2" />

                    <div class="text-body-2 mt-4 required">Rating</div>
                    <v-rating hover v-model="conditionData.eCondition" large />
                    <validation-provider
                        v-slot="{ errors }"
                        name="Exterior Comments"
                        rules="max:255"
                    >
                        <v-text-field
                            v-model="conditionData.eComments"
                            name="Exterior Comments"
                            outlined
                            class="mt-4"
                            :counter="255"
                            :error-messages="errors"
                            label="Exterior Comments"
                            hint="Any comments about the exterior"
                        >
                        </v-text-field>
                    </validation-provider>

                    <validation-provider
                        v-slot="{ errors }"
                        name="Exterior Faults"
                        rules="max:255"
                    >
                        <v-textarea
                            v-model="conditionData.eFaults"
                            outlined
                            class="mt-4"
                            name="Exterior Faults"
                            :error-messages="errors"
                            label="Exterior Faults"
                            hint="Any faults with the exterior"
                        >
                        </v-textarea>
                    </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="text-subtitle-1">Interior</div>
                    <v-divider class="mb-2" />

                    <div class="text-body-2 mt-4 required">Rating</div>
                    <v-rating hover v-model="conditionData.iCondition" large />
                    <validation-provider
                        v-slot="{ errors }"
                        name="Interior Comments"
                        rules="max:255"
                    >
                        <v-text-field
                            v-model="conditionData.iComments"
                            outlined
                            name="Interior Comments"
                            class="mt-4"
                            :counter="255"
                            :error-messages="errors"
                            label="Interior Comments"
                            hint="Any comments about the interior"
                        >
                        </v-text-field>
                    </validation-provider>

                    <validation-provider
                        v-slot="{ errors }"
                        name="Interior Faults"
                        rules="max:255"
                    >
                        <v-textarea
                            v-model="conditionData.iFaults"
                            outlined
                            class="mt-4"
                            name="Interior Faults"
                            :error-messages="errors"
                            label="Interior Faults"
                            hint="Any faults with the interior"
                        >
                        </v-textarea>
                    </validation-provider>
                </v-col>
            </v-row>
        </validation-observer>

        <v-divider class="my-4" />

        <v-btn :loading="loading" @click="submit" color="success">
            {{ bookOut ? "Book Out" : "Book In" }}
        </v-btn>
    </modal-template>
</template>

<script>
import { max, numeric } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import ModalTemplate from "../Master/ModalTemplate";
import DateTimePicker from "../Master/DateTimePicker";

extend("max", max);
extend("numeric", numeric);

export default {
    name: "VehicleConditionModal",
    components: { DateTimePicker, ModalTemplate },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        condition: {
            type: Object,
            required: true,
        },
        mileage: {
            type: [Number, String],
        },
        booking: {
            type: Object,
        },
    },

    created() {
        extend("mileage", (value) => {
            if (this.bookOut) {
                return true;
            }

            if (value <= this.booking.mileage_out) {
                return (
                    "The mileage should be bigger than or equal to the booked out mileage of " +
                    this.booking.mileage_out
                );
            }

            return true;
        });
    },

    methods: {
        submit() {
            this.$refs.form.validate().then((success) => {
                if (!this.condition.eCondition || !this.condition.iCondition) {
                    success = false;
                }

                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }
                this.$emit("submit");
            });
        },
    },

    computed: {
        modal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },

        minBookInTime() {
            return this.moment(this.booking.booked_out).toDate();
        },

        conditionData: {
            get() {
                return this.condition;
            },
            set(value) {
                this.$emit("update:condition", value);
            },
        },

        mileageData: {
            get() {
                return this.mileage;
            },
            set(value) {
                this.$emit("update:mileage", value);
            },
        },

        bookOut() {
            return !this.booking;
        },
    },
};
</script>

<style lang="scss" scoped>
.required:after {
    content: "*";
    color: red;
    margin-left: 5px;
}
</style>
