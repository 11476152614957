<template>
    <v-btn
        v-bind="$attrs"
        v-on="$listeners"
        :color="color"
        :class="classObject"
        :style="styleObject"
    >
        <slot></slot>
    </v-btn>
</template>

<script>
export default {
    name: "AccentButton",
    props: {
        doNotStretch: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: "primary",
        },
    },

    computed: {
        styleObject() {
            if (this.doNotStretch) {
                return {};
            }

            return {
                width: "100%",
            };
        },
        classObject() {
            if (this.$vuetify.theme.isDark) {
                return "black--text text-subtitle-2";
            }

            return "";
        },
    },
};
</script>

<style scoped></style>
