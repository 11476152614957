<template>
    <v-expansion-panel>
        <v-expansion-panel-header> Training </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-list>
                <div
                    v-for="training in $page.props.training"
                    :key="training.id"
                >
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon v-if="training.passed" color="green">
                                mdi-emoticon-happy-outline
                            </v-icon>
                            <v-icon v-else color="red"
                                >mdi-emoticon-sad-outline</v-icon
                            >
                        </v-list-item-icon>
                        <v-list-item-title>{{
                            training.training_session.module.name
                        }}</v-list-item-title>
                    </v-list-item>
                </div>
            </v-list>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    name: "DashboardTraining",
};
</script>

<style scoped></style>
