<template>
    <v-container class="fixed">
        <training comp="sessions"></training>

        <div class="d-flex">
            <div class="text-h6 text--secondary mb-2 mt-2">Sessions</div>
            <div class="ml-auto">
                <v-btn
                    color="success"
                    small
                    @click="$refs.modal.createSession()"
                >
                    Run Session
                </v-btn>
            </div>
        </div>

        <v-data-table
            :headers="[
                { text: 'Module', value: 'module.name' },
                { text: 'Instructor', value: 'instructor_name' },
                { text: 'Trained Users', value: 'users' },
                { text: 'Users Passed', value: 'usersPassed' },
                { text: 'Expires', value: 'expires' },
                { text: '', value: 'options' },
            ]"
            :items="sessions"
            class="elevation-1"
        >
            <template v-slot:item.users="{ item }">
                {{ item.users.length }}
            </template>

            <template v-slot:item.usersPassed="{ item }">
                {{ usersPassed(item).length }}/{{ item.users.length }}
            </template>

            <template v-slot:item.expires="{ item }">
                <div class="red--text" v-if="expired(expires(item))">
                    Expired On:
                    {{ expires(item).format("ddd Do MMM YYYY") }}
                </div>
                <div class="green--text" v-if="!expired(expires(item))">
                    Expires:
                    {{ expires(item).format("ddd Do MMM YYYY") }}
                </div>
            </template>

            <template v-slot:item.options="{ item }">
                <v-btn
                    class="mr-2"
                    elevation="2"
                    v-tooltip="'View Session'"
                    @click="goto(item)"
                    color="primary"
                    icon
                >
                    <v-icon>mdi-school</v-icon>
                </v-btn>
                <v-btn
                    elevation="2"
                    v-tooltip="'Delete Session'"
                    color="error"
                    @click="destroy(item)"
                    icon
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <!--Create Modal-->
        <sessions-create-modal
            v-model="modal"
            :users="users"
            :modules="modules"
            ref="modal"
        />
    </v-container>
</template>

<script>
import SessionsCreateModal from "../../../../Comps/Staff/Training/SessionCreateModal";
import Training from "../../../../Comps/Staff/Training/Training";
import AdminController from "../../../../Mixins/AdminController";

export default {
    name: "Sessions",
    components: { Training, SessionsCreateModal },
    props: ["sessions", "users", "modules"],
    mixins: [AdminController],

    data() {
        return {
            modal: false,
        };
    },

    mounted() {
        const createSession = localStorage.getItem("createSession");

        if (createSession) {
            this.$refs.modal.createSession(_.toInteger(createSession));
            localStorage.removeItem("createSession");
        }
    },

    methods: {
        expires(training) {
            return this.moment(training.created_at).add(
                training.module.valid_for,
                "months"
            );
        },
        expired(expires) {
            return expires.isBefore(this.moment());
        },
        usersPassed(session) {
            return _.filter(session.users, (user) => {
                return user.passed;
            });
        },
        destroy(session) {
            if (!confirm("Are you sure?")) return false;

            this.$inertia.delete(
                this.route("staff.admin.training.sessions.destroy", session.id),
                {},
                {
                    preserveState: true,
                }
            );
        },
        goto(session) {
            this.$inertia.visit(
                this.route("staff.admin.training.sessions.show", session.id)
            );
        },
    },
};
</script>

<style scoped></style>
