<template>
    <div>
        <v-row class="justify-center">
            <v-col cols="12" :md="fullWidth ? 12 : 8">
                <v-alert v-if="newProfile" border="bottom" color="blue-grey">
                    <div class="text-h5">Thanks for creating a profile</div>
                    <div>
                        Your new profile is now created, you can pop back here
                        at any time to edit your information
                    </div>
                </v-alert>
                <v-sheet rounded="lg" class="pa-6" style="position: relative">
                    <slot />
                    <div
                        class="d-flex align-center flex-wrap justify-sm-start justify-center"
                    >
                        <v-avatar size="150">
                            <v-img :src="freelancer.photo.url" />
                        </v-avatar>
                        <div class="ml-4">
                            <div class="text-h5">
                                {{ freelancer.name }}
                            </div>
                            <div class="mt-2 d-flex flex-wrap">
                                <v-chip
                                    v-for="(skill, index) in freelancer.skills"
                                    :key="index"
                                    label
                                    class="mx-1"
                                >
                                    {{ skill }}
                                </v-chip>
                            </div>
                        </div>
                    </div>

                    <v-tabs class="mt-6">
                        <v-tab>Profile</v-tab>
                    </v-tabs>

                    <v-row class="mt-6">
                        <v-col cols="12" md="6">
                            <div class="text--secondary">Phone</div>
                            <div>{{ freelancer.tel_number }}</div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="text--secondary">Email</div>
                            <div>{{ freelancer.email }}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="text--secondary">Street</div>
                            <div>{{ freelancer.address.street }}</div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="text--secondary">City</div>
                            <div>{{ freelancer.address.city }}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="text--secondary">County</div>
                            <div>{{ freelancer.address.county }}</div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="text--secondary">Postcode</div>
                            <div>{{ freelancer.address.postcode }}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div class="text--secondary">About</div>
                            <div style="white-space: pre-line">
                                {{
                                    freelancer.about ? freelancer.about : "N/A"
                                }}
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider class="my-4" />
                    <v-row>
                        <v-col>
                            <div class="text--secondary text-h6">Files</div>
                            <div
                                v-if="freelancer.uploads.length"
                                class="d-flex flex-wrap my-4 mx-1"
                            >
                                <file-sheet
                                    v-for="upload in freelancer.uploads"
                                    :key="upload.id"
                                    :type="getFileType(upload.extension)"
                                    :name="upload.extension"
                                    :src="upload.url"
                                    width="200px"
                                    max-width="100%"
                                    max-height="300px"
                                    class="ma-4"
                                />
                            </div>
                            <div v-else>No files uploaded</div>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import FileHelpers from "../../../Mixins/FileHelpers";
import FileSheet from "../../../Comps/General/FileDisplay/FileSheet";

export default {
    name: "ProfileDetails",
    components: { FileSheet },
    mixins: [FileHelpers],

    props: {
        freelancer: {
            type: Object,
            required: true,
        },
        newProfile: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped></style>
