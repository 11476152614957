<template>
    <div class="d-flex">
        <select-file-and-display-single
            v-if="showUploader"
            v-model="fileModel"
            width="200px"
            max-width="200px"
            height="50px"
            max-height="50px"
            small-button
        />
        <div v-if="!showUploader">
            <span v-text="fileName" />
            <v-btn
                v-tooltip="'View File'"
                icon
                elevation="2"
                :href="userModel.file_upload ? userModel.file_upload.url : '/'"
                :disabled="!userModel.file_upload"
                target="_blank"
            >
                <v-icon>mdi-file</v-icon>
            </v-btn>
            <v-btn
                v-tooltip="'Delete File'"
                icon
                elevation="2"
                color="error"
                @click="removeCertificate"
            >
                <v-icon>mdi-file-cancel</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import VModel from "../../../Mixins/VModel";
import SelectFileAndDisplaySingle from "../../General/SelectFileAndDisplaySingle";
import FileHelpers from "../../../Mixins/FileHelpers";

export default {
    name: "TrainingSessionModalUserCertificate",
    components: { SelectFileAndDisplaySingle },

    mixins: [VModel, FileHelpers],

    props: {
        user: {
            required: true,
        },
    },

    methods: {
        removeCertificate() {
            this.userModel.file_upload = null;

            this.userModel.certificate = "delete";
        },
    },

    computed: {
        userModel: {
            get() {
                return this.user;
            },
            set(value) {
                const index = this.model.findIndex(
                    (user) => user.user_id === this.user.user_id
                );

                if (index !== -1) {
                    this.model.splice(index, 1, value);
                }
            },
        },

        fileModel: {
            get() {
                if (this.userModel.certificate === "delete") {
                    return null;
                }

                return this.userModel.certificate;
            },
            set(val) {
                this.userModel.certificate = val;
            },
        },

        showUploader() {
            return (
                !this.userModel.file_upload &&
                (!this.userModel.certificate ||
                    this.userModel.certificate === "delete")
            );
        },

        fileName() {
            return this.userModel.certificate?.name ?? "Uploaded File";
        },
    },
};
</script>

<style scoped></style>
