<template>
    <form>
        <validation-observer ref="form">
            <v-row>
                <v-col cols="12" sm="6">
                    <validation-provider
                        v-slot="{ errors }"
                        name="Name"
                        rules="required|max:255"
                    >
                        <v-text-field
                            v-model="name"
                            :counter="255"
                            outlined
                            dense
                            :error-messages="errors"
                            label="Display Name"
                            required
                        >
                        </v-text-field>
                    </validation-provider>
                </v-col>

                <v-col cols="12" sm="6">
                    <validation-provider
                        v-slot="{ errors }"
                        name="Job Title"
                        rules="required|max:255"
                    >
                        <v-text-field
                            v-model="department"
                            :counter="255"
                            outlined
                            dense
                            :error-messages="errors"
                            label="Job Title"
                            hint="This is what is displayed in your email footers"
                            :persistent-hint="true"
                            required
                        >
                        </v-text-field>
                    </validation-provider>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <validation-provider
                        v-slot="{ errors }"
                        name="Primary Email"
                        rules="required"
                    >
                        <v-text-field
                            :value="user.email"
                            :error-messages="errors"
                            label="Primary Email"
                            outlined
                            dense
                            hint="This is your primary email address when sending and receiving emails<br>You cannot change this"
                            :persistent-hint="true"
                            disabled
                        >
                            <template v-slot:message="{ message }">
                                <span v-html="message"></span>
                            </template>
                        </v-text-field>
                    </validation-provider>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="6">
                    <validation-provider
                        v-slot="{ errors }"
                        name="Phone Number"
                        rules="required|numeric|max:255"
                    >
                        <v-text-field
                            v-model="tel_number"
                            :counter="255"
                            outlined
                            dense
                            :error-messages="errors"
                            label="Phone Number"
                            required
                        >
                        </v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                    <validation-provider
                        v-slot="{ errors }"
                        name="Signature"
                        rules="max:255"
                    >
                        <v-text-field
                            v-model="signature_url"
                            :counter="255"
                            :error-messages="errors"
                            outlined
                            dense
                            label="Signature"
                            hint="This is a link to the .png of your signature - You need this for LOLER testing<br>
                                        Generate one <a target='_blank' href='https://www.mylivesignature.com/create-signature-step1'>here</a> and then upload it to current to get your url"
                            :persistent-hint="true"
                        >
                            <template v-slot:message="{ message }">
                                <span v-html="message"></span>
                            </template>
                        </v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12" v-if="admin">
                    <v-switch
                        label="Hide from Logistics"
                        v-model="hide_from_logistics"
                    />
                </v-col>
            </v-row>

            <v-btn color="success" :loading="loading" @click="submit">
                Save
            </v-btn>
        </validation-observer>
    </form>
</template>

<script>
import { required, email, max, numeric } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", required);
extend("max", max);
extend("email", email);
extend("numeric", numeric);
export default {
    name: "UserDetails",
    props: {
        user: {
            type: Object,
            required: true,
        },
        admin: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            name: this.user.name,
            department: this.user.department,
            tel_number: this.user.tel_number,
            signature_url: this.user.signature_url,
            hide_from_logistics: this.user.hide_from_logistics,
            loading: false,
        };
    },

    methods: {
        submit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.loading = true;

                this.$inertia.post(
                    this.route("staff.myDetails.store", this.user.id),
                    this.$data,
                    {
                        onFinish: () => {
                            this.loading = false;
                        },
                    }
                );
            });
        },
    },
};
</script>

<style scoped></style>
