<script>
export default {
    name: "ErrorController",
    render() {
        return this.$slots.default;
    },

    computed: {
        errors() {
            return this.$page.props.flash.errors;
        },
    },

    watch: {
        errors: {
            immediate: true,
            handler(errors) {
                if (Object.keys(errors).length) {
                    _.forEach(errors, (error) => {
                        this.$toast.error(error[0], {
                            duration: 5000,
                        });
                    });
                }
            },
        },
    },
};
</script>
