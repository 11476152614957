<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <div class="d-inline-block">
                <v-badge
                    v-if="holidayBookings.length"
                    :content="holidayBookings.length"
                >
                    Holiday
                </v-badge>
                <div v-else>Holiday</div>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <div class="text-caption text--secondary">
                Approved next 30 days
            </div>
            <v-list v-if="holidayBookings.length">
                <div v-for="booking in holidayBookings" :key="booking.id">
                    <v-divider></v-divider>
                    <inertia-link
                        class="inertia-link"
                        :href="route('staff.holidays.index')"
                    >
                        <v-list-item link>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{
                                        momentFormatDateYear(booking.start_date)
                                    }}
                                    ({{ booking.type }})
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-chip
                                    v-if="booking.half_day"
                                    label
                                    x-small
                                    color="primary"
                                >
                                    {{
                                        booking.morning
                                            ? "Morning"
                                            : "Afternoon"
                                    }}
                                </v-chip>
                            </v-list-item-action>
                        </v-list-item>
                    </inertia-link>
                </div>
            </v-list>
            <div class="text-body-2" v-else>No upcoming holiday</div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    name: "DashboardHoliday",

    computed: {
        holidayBookings() {
            return this.$page.props.holidayBookings;
        },
    },
};
</script>

<style scoped></style>
