<template>
    <Head :title="parsedTitle" />
</template>

<script>
import { Head } from "@inertiajs/inertia-vue";

export default {
    name: "HeadTitle",

    components: { Head },

    props: {
        title: {
            type: String,
            required: false,
        },
        doNotAppend: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        parsedTitle() {
            if (this.doNotAppend) {
                return this.title;
            }

            return this.title + " | PYTCH Staff Portal";
        },
    },
};
</script>

<style scoped></style>
