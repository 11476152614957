<template>
    <expansion-panel
        icon="mdi-cash"
        header="Expenses"
        v-if="hasPermission('staffAdminExpenses')"
    >
        <expense-allowances
            :user="$page.props.user"
            :allowance-left="$page.props.expenseAllowanceLeft"
            admin
        />

        <v-divider class="my-6" />

        <div class="d-flex mt-6">
            <v-pagination v-model="page" :length="sheets.meta.last_page" />
        </div>

        <div class="text-caption ml-3">
            Showing {{ sheets.meta.from }} to {{ sheets.meta.to }} of
            {{ sheets.meta.total }}
        </div>
        <expenses-table :sheets="sheets" own-user-page />
    </expansion-panel>
</template>

<script>
import ExpansionPanel from "./ExpansionPanel";
import Permissions from "../../../../Mixins/Permissions";
import ExpensesTable from "../../../../Pages/Staff/Admin/Expenses/ExpensesTable";
import ExpenseAllowances from "../../Expenses/ExpenseAllowances";
export default {
    name: "ExpensesPanel",
    components: { ExpenseAllowances, ExpensesTable, ExpansionPanel },
    mixins: [Permissions],

    data() {
        return {
            page: this.$page.props.expenseSheets.meta.current_page,
        };
    },

    watch: {
        page() {
            this.changePage();
        },
    },

    computed: {
        sheets() {
            return this.$page.props.expenseSheets;
        },
    },

    methods: {
        changePage() {
            this.$inertia.get(
                this.route("staff.admin.users.show", this.$page.props.user),
                {
                    expensePage: this.page,
                },
                {
                    only: ["expenseSheets", "flash"],
                    preserveScroll: true,
                    preserveState: true,
                }
            );
        },
    },
};
</script>

<style scoped></style>
