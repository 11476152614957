<template>
    <modal-template v-model="show">
        <div class="text-h6 text--secondary mb-2 mt-1">
            <span v-if="!isEditing">Create a new freelancer</span>
            <span v-else>Edit freelancer - {{ freelancer.name }}</span>
        </div>

        <form class="mt-6" novalidate @submit.prevent="submit">
            <validation-observer ref="form">
                <v-sheet class="pa-4 mb-4" style="border: dashed 1px grey">
                    <div class="text-h6">External information</div>
                    <div class="text-caption text--secondary">
                        Freelancers can see all information about them on the
                        "freelancer portal", only information marked as
                        "Internal" is private to PYTCH
                    </div>
                    <v-row class="mt-2">
                        <v-col cols="12" md="4">
                            <text-input
                                v-model="freelancer.name"
                                :counter="255"
                                autofocus
                                name="Name"
                                rules="required|max:255"
                                required
                            />
                        </v-col>

                        <v-col cols="12" md="4">
                            <text-input
                                v-model="freelancer.email"
                                rules="required|email|max:255"
                                :counter="255"
                                type="email"
                                name="Email"
                                required
                            />
                        </v-col>

                        <v-col cols="12" md="4">
                            <text-input
                                v-model="freelancer.tel_number"
                                rules="required|max:255"
                                :counter="255"
                                name="Telephone Number"
                                required
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="6">
                            <text-input
                                v-model="freelancer.address.street"
                                rules="max:255"
                                :counter="255"
                                name="Street"
                            />
                        </v-col>

                        <v-col cols="12" md="6">
                            <text-input
                                v-model="freelancer.address.city"
                                rules="max:255"
                                :counter="255"
                                name="City"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="6">
                            <text-input
                                v-model="freelancer.address.county"
                                :counter="255"
                                rules="max:255"
                                name="County"
                            />
                        </v-col>

                        <v-col cols="12" md="6">
                            <text-input
                                v-model="freelancer.address.postcode"
                                :counter="255"
                                rules="max:255"
                                name="Postcode"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <auto-complete
                                v-model="freelancer.skills"
                                name="Skills"
                                :items="$page.props.skills"
                                chips
                                small-chips
                                multiple
                                required
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <text-area
                                v-model="freelancer.about"
                                name="About"
                                :counter="2000"
                                rules="max:2000"
                            />
                        </v-col>
                    </v-row>
                </v-sheet>

                <v-sheet class="pa-4" style="border: dashed 1px grey">
                    <div class="text-h6">Internal information</div>
                    <div class="text-caption text--secondary">
                        Freelancers cannot see this information on their portal
                    </div>
                    <v-row class="mt-2">
                        <v-col>
                            <combo-box
                                v-model="freelancer.tags"
                                :items="$page.props.tags"
                                name="Tags"
                                return-object
                                item-text="name"
                                hint="Select a tag or create a new one"
                                :persistent-hint="true"
                                multiple
                                chips
                                small-chips
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <text-area
                                rules="max:2000"
                                v-model="freelancer.notes"
                                :counter="2000"
                                name="Notes"
                            />
                        </v-col>
                    </v-row>
                </v-sheet>

                <v-row>
                    <v-col>
                        <accent-button
                            color="success"
                            class="mt-4"
                            do-not-stretch
                            type="submit"
                            :loading="loading"
                        >
                            {{ isEditing ? "Update" : "Create" }}
                        </accent-button>
                    </v-col>
                </v-row>
            </validation-observer>
        </form>
    </modal-template>
</template>

<script>
import { required, email, max, numeric } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import ModalTemplate from "../../Master/ModalTemplate";
import TextInput from "../../Forms/TextInput";
import ComboBox from "../../Forms/ComboBox";
import TextArea from "../../Forms/TextArea";
import AccentButton from "../../Buttons/AccentButton";
import AutoComplete from "../../Forms/AutoComplete";

extend("required", required);
extend("max", max);
extend("email", email);
extend("numeric", numeric);

export default {
    name: "FreelancersCreateModal",
    components: {
        AutoComplete,
        AccentButton,
        TextArea,
        ComboBox,
        TextInput,
        ModalTemplate,
    },

    data() {
        return {
            show: false,
            freelancer: this.$inertia.form({ address: {} }),
            loading: false,
        };
    },

    computed: {
        isEditing() {
            return !!this.freelancer.id;
        },
    },

    methods: {
        reset() {
            this.$refs.form.reset();
        },

        create() {
            this.freelancer = this.$inertia.form({
                name: null,
                email: null,
                tel_number: null,
                about: null,
                skills: [],
                tags: [],
                address: {
                    street: null,
                    city: null,
                    county: null,
                    postcode: null,
                },
            });
            this.show = true;
        },

        edit(freelancer) {
            this.freelancer = this.$inertia.form(freelancer);
            this.show = true;
        },

        submit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.loading = true;

                let url;
                let type;
                if (this.isEditing) {
                    url = this.route(
                        "staff.admin.freelancers.update",
                        this.freelancer.id
                    );
                    type = "put";
                } else {
                    url = this.route("staff.admin.freelancers.store");
                    type = "post";
                }

                this.freelancer[type](url, {
                    method: type,
                    preserveState: true,
                    onSuccess: () => {
                        this.show = false;
                        this.reset();
                    },
                    onFinish: () => {
                        this.loading = false;
                    },
                });
            });
        },
    },
};
</script>

<style scoped></style>
