<template>
    <v-row class="mb-5">
        <v-col cols="12" :md="6">
            <date-time-picker
                name="Week"
                label="Week"
                class="mx-2"
                v-model="thisWeekProp"
                mode="date"
                :disabled-dates="disabledDates"
                hint="Choose a week starting"
                @input="changeLogistic(true)"
            >
            </date-time-picker>
        </v-col>
        <v-col cols="12" :md="6">
            <v-select
                :items="versions"
                class="mx-2"
                dense
                outlined
                v-model="versionProp"
                label="Version"
                hint="Choose a version"
                persistent-hint
                item-text="name"
                item-value="version"
                no-data-text="No versions found"
                @change="changeLogistic(false)"
            >
                <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                        <v-chip
                            class="mr-1"
                            label
                            x-small
                            :color="item.sent ? 'success' : 'warning'"
                        >
                            {{ item.sent ? "Live" : "Draft" }}
                        </v-chip>
                        <div>{{ item.name }}</div>
                    </div>
                </template>

                <template v-slot:selection="{ item }">
                    <div class="d-flex align-center">
                        <v-chip
                            class="mr-1"
                            label
                            x-small
                            :color="item.sent ? 'success' : 'warning'"
                        >
                            {{ item.sent ? "Live" : "Draft" }}
                        </v-chip>
                        <div>{{ item.name }}</div>
                    </div>
                </template>
            </v-select>
        </v-col>
    </v-row>
</template>

<script>
import DateTimePicker from "../../Master/DateTimePicker";

export default {
    name: "LogisticsDateVersionPicker",
    components: { DateTimePicker },
    props: {
        thisWeek: {
            type: Object,
            required: true,
        },
        versions: {
            type: Array,
            required: true,
        },
        version: {
            type: Number,
            required: false,
        },
    },

    computed: {
        versionProp: {
            get() {
                return this.version;
            },
            set(value) {
                this.$emit("update:version", value);
            },
        },
        thisWeekProp: {
            get() {
                return this.thisWeek;
            },
            set(value) {
                this.$emit("update:thisWeek", this.moment(value));
            },
        },
        disabledDates() {
            return { weekdays: [1, 3, 4, 5, 6, 7] };
        },
    },

    methods: {
        changeLogistic(dateOnly = false) {
            this.$parent.updateData(dateOnly);
        },
    },
};
</script>

<style scoped></style>
