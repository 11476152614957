<template>
    <v-container class="fixed">
        <breadcrumbs :items="bread"></breadcrumbs>
        <div class="d-flex">
            <header-name>View Module</header-name>
            <div class="ml-auto">
                <v-btn small color="primary" @click="modal = true">
                    Edit Module
                </v-btn>
            </div>
        </div>

        <v-list two-line outlined elevation="2">
            <!--Name-->
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>
                        mdi-view-module
                    </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Name</v-list-item-title>
                    <v-list-item-subtitle
                        v-text="module.name"
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <!--Objectives-->
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>
                        mdi-dots-hexagon
                    </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Objectives</v-list-item-title>
                    <div>
                        <v-chip
                            v-for="objective in module.objectives"
                            :key="objective"
                            small
                            label
                            outlined
                            color="primary"
                            class="ma-1"
                        >
                            {{ objective }}
                        </v-chip>
                    </div>
                </v-list-item-content>
            </v-list-item>

            <!--Skills-->
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>
                        mdi-dots-hexagon
                    </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Skills</v-list-item-title>
                    <div>
                        <v-chip
                            v-for="skill in module.skills"
                            :key="skill"
                            small
                            label
                            outlined
                            color="primary"
                            class="ma-1"
                        >
                            {{ skill }}
                        </v-chip>
                    </div>
                </v-list-item-content>
            </v-list-item>

            <!--Learning Resources-->
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark> mdi-school </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Learning Resources</v-list-item-title>
                    <div>
                        <v-chip
                            v-for="learning in module.learning_resources"
                            :key="learning"
                            small
                            label
                            outlined
                            color="primary"
                            class="ma-1"
                        >
                            {{ learning }}
                        </v-chip>
                    </div>
                </v-list-item-content>
            </v-list-item>

            <!--Department-->
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>
                        mdi-account-group
                    </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Department</v-list-item-title>
                    <v-list-item-subtitle
                        v-text="module.department"
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <!--Agenda-->
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>
                        mdi-list-status
                    </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Agenda</v-list-item-title>
                    <v-list-item-subtitle
                        v-html="module.agenda"
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <!--Valid-->
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>
                        mdi-calendar-range
                    </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Valid for</v-list-item-title>
                    <v-list-item-subtitle
                        >{{ module.valid_for }}
                        {{ module.valid_for <= 1 ? "Month" : "Months" }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <!--Created by-->
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark> mdi-account </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Created by</v-list-item-title>
                    <v-list-item-subtitle
                        v-text="module.user.name"
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <!--Sessions-->

        <div class="mt-5">
            <div v-if="module.sessions.length">
                <div class="text-h5 text--secondary">Sessions</div>
                <v-list outlined>
                    <div
                        v-for="(session, index) in module.sessions"
                        :key="session.id"
                    >
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <inertia-link
                                        :href="
                                            route(
                                                'staff.admin.training.sessions.show',
                                                session.id
                                            )
                                        "
                                    >
                                        {{
                                            momentFormatDate(session.created_at)
                                        }}
                                    </inertia-link>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-subtitle
                                    class="red--text"
                                    v-if="expired(expires(session))"
                                >
                                    Expired On:
                                    {{
                                        expires(session).format(
                                            "ddd Do MMM YYYY"
                                        )
                                    }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle
                                    class="green--text"
                                    v-if="!expired(expires(session))"
                                >
                                    Expires:
                                    {{
                                        expires(session).format(
                                            "ddd Do MMM YYYY"
                                        )
                                    }}
                                </v-list-item-subtitle>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider
                            v-if="module.sessions.length > index + 1"
                        ></v-divider>
                    </div>
                </v-list>
            </div>
            <v-sheet v-else>
                <div class="text-h6 text--secondary">
                    This module has not had any training sessions yet
                </div>
            </v-sheet>
        </div>

        <modules-create-modal :edit="module" v-model="modal">
        </modules-create-modal>
    </v-container>
</template>

<script>
import HeaderName from "../../../../Comps/General/HeaderName";
import Breadcrumbs from "../../../../Comps/General/Breadcrumbs";
import ModulesCreateModal from "../../../../Comps/Staff/Training/ModulesCreateModal";
import AdminController from "../../../../Mixins/AdminController";

export default {
    name: "ModulesShow",
    components: { ModulesCreateModal, Breadcrumbs, HeaderName },
    props: ["module"],
    mixins: [AdminController],

    data() {
        return {
            bread: [
                {
                    text: "Modules",
                    disabled: false,
                    link: this.route("staff.admin.training.modules.index"),
                },
                {
                    text: this.module.name,
                    disabled: true,
                },
            ],
            modal: false,
        };
    },
    methods: {
        expires(training) {
            return this.moment(training.created_at).add(
                training.module.valid_for,
                "months"
            );
        },
        expired(expires) {
            return expires.isBefore(this.moment());
        },
    },
};
</script>

<style scoped></style>
