<template>
    <div>
        <v-row>
            <v-col
                cols="12"
                md="3"
                v-for="stat in statGrid"
                :key="stat.key"
                class="d-flex justify-center"
            >
                <v-sheet
                    class="d-flex justify-center align-center flex-column"
                    width="100%"
                    height="100"
                    elevation="4"
                >
                    <div v-text="stat.label" />
                    <div class="d-flex align-end">
                        <number-animation
                            class="font-weight-bold text-h6"
                            :to="stats[stat.key]"
                        />
                        <span class="text-caption text--secondary ml-1">
                            Days
                        </span>
                    </div>
                </v-sheet>
            </v-col>
        </v-row>
        <div class="text-caption text--secondary mt-3">
            Allowance Resets: {{ holidayResets }}
        </div>
    </div>
</template>

<script>
import NumberAnimation from "../../../General/NumberAnimation";
import moment from "moment";
export default {
    name: "HolidayStatsGrid",
    components: { NumberAnimation },
    props: {
        stats: Object,
    },

    data() {
        return {
            statGrid: [
                { key: "days_total", label: "Allowance" },
                { key: "days_taken", label: "Taken (Paid)" },
                { key: "days_left", label: "Left (Paid)" },
                { key: "all_taken", label: "Total Taken (All Types)" },
            ],
        };
    },

    computed: {
        holidayResets() {
            return moment(this.$page.props.allowanceResets.holidays)
                .add(1, "days")
                .format("ddd Do MMM YYYY");
        },
    },
};
</script>

<style scoped></style>
