<template>
    <div>
        <label class="grey--text text--darken-1">{{ label }}</label>
        <vue-editor v-model="html" :editor-toolbar="customToolbar" />
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
    name: "HtmlEditor",
    props: {
        value: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    components: {
        VueEditor,
    },
    data() {
        return {
            customToolbar: [
                ["bold", "italic", "underline", "strike"],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["code-block", "link"],
            ],
        };
    },

    computed: {
        html: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>

<style scoped></style>
