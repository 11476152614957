<template>
    <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="time"
        persistent
        width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <validation-provider
                v-slot="{ errors }"
                :name="name"
                :class="{ 'd-inline-block': inline }"
                :rules="required ? 'required' : ''"
            >
                <v-text-field
                    v-model="time"
                    :label="name"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    :required="required"
                    :hint="hint"
                    :dense="dense"
                    :outlined="outlined"
                    persistent-hint
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    :clearable="!required"
                >
                    <template v-slot:append-outer>
                        <slot name="append-outer" />
                    </template>
                </v-text-field>
            </validation-provider>
        </template>
        <v-time-picker
            v-if="modal"
            v-model="time"
            format="ampm"
            :min="minTime"
            :max="maxTime"
            ampm-in-title
            full-width
        >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"> Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(time)">
                OK
            </v-btn>
        </v-time-picker>
    </v-dialog>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", required);

export default {
    name: "TimePicker",
    props: {
        value: {
            type: String,
        },
        name: {
            type: String,
        },
        hint: {
            type: String,
        },
        min: {
            type: String,
            required: false,
        },
        max: {
            type: String,
            required: false,
        },
        required: {
            type: Boolean,
            default: true,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            modal: false,
        };
    },
    computed: {
        time: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        minTime() {
            if (this.min) {
                return this.moment(this.min, "HH:mm")
                    .add(1, "minute")
                    .format("HH:mm");
            } else {
                return this.min;
            }
        },
        maxTime() {
            if (this.max) {
                return this.moment(this.max, "HH:mm")
                    .subtract(1, "minute")
                    .format("HH:mm");
            } else {
                return this.max;
            }
        },
    },
};
</script>

<style scoped></style>
