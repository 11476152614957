<template>
    <div style="height: 100%" class="d-flex flex-column">
        <div
            class="d-flex justify-space-between align-center pb-2"
            style="width: 100%"
        >
            <div style="width: 10%">
                <v-img :src="$page.props.pytchLogoWhite" />
            </div>
            <div class="d-flex flex-column align-end">
                <div v-text="timeNow.format('ddd Do MMM YYYY')" />
                <div v-text="timeNow.format('HH:mm')" />
            </div>
        </div>
        <div
            style="overflow-y: auto; height: 100%"
            class="pa-3 pt-0"
            ref="scroller"
        >
            <job-overview-columns />
        </div>
    </div>
</template>

<script>
import TimeNow from "../../Mixins/TimeNow.js";
import JobOverviewColumns from "../Staff/Warehouse/Dashboard/JobOverviewColumns.vue";

export default {
    name: "WarehouseScreen",
    components: { JobOverviewColumns },
    mixins: [TimeNow],
    data() {
        return {
            interval: null,
            scrollInterval: null,
        };
    },

    mounted() {
        this.interval = setInterval(() => {
            this.$inertia.reload();
        }, 60 * 1000);

        this.scrollInterval = setInterval(() => {
            this.scroll();
        }, 7500);
    },

    beforeDestroy() {
        clearInterval(this.interval);
        clearInterval(this.scrollInterval);
    },

    methods: {
        scroll() {
            const div = this.$refs.scroller;

            if (div.scrollHeight <= div.offsetHeight) {
                return;
            }

            const newPosition = div.scrollTop + div.offsetHeight;

            div.scrollTo({
                top: newPosition >= div.scrollHeight ? 0 : newPosition - 100,
                behavior: "smooth",
            });
        },
    },
};
</script>

<style scoped></style>
