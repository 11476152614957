<template>
    <v-container>
        <div class="d-flex mb-5">
            <header-name>Announcements</header-name>
            <div class="ml-auto">
                <v-btn color="success" @click="$refs.modal.create()" small>
                    Create Announcement
                </v-btn>
            </div>
        </div>

        <v-data-table
            :headers="[
                { text: 'Message', value: 'message' },
                { text: 'From', value: 'user.name' },
                { text: 'Notification Sent', value: 'notify' },
                { text: 'Expires At', value: 'expires_at' },
                { text: '', value: 'options' },
            ]"
            :items="$page.props.announcements"
            class="elevation-1"
            sort-by="expires_at"
            sort-desc
        >
            <template v-slot:item.notify="{ value }">
                <div>
                    <v-icon v-if="value" color="success">mdi-check</v-icon>
                </div>
            </template>
            <template v-slot:item.expires_at="{ value }">
                <div>
                    {{ momentFormatDateTimeYear(value) }}
                </div>
            </template>
            <template v-slot:item.options="{ item }">
                <v-btn
                    class="mr-2"
                    elevation="2"
                    v-tooltip="'Edit'"
                    color="primary"
                    @click="$refs.modal.edit(item)"
                    icon
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    class="mr-2"
                    elevation="2"
                    v-tooltip="'Delete'"
                    color="error"
                    @click="deleteAnnouncement(item)"
                    icon
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <announcement-modal ref="modal" />
    </v-container>
</template>

<script>
import HeaderName from "../../../../Comps/General/HeaderName.vue";
import AdminController from "../../../../Mixins/AdminController.js";
import AnnouncementModal from "./Partials/AnnouncementModal.vue";

export default {
    name: "AnnouncementsIndex",
    components: { AnnouncementModal, HeaderName },
    mixins: [AdminController],

    methods: {
        deleteAnnouncement(announcement) {
            if (
                !confirm("Are you sure you want to delete this announcement?")
            ) {
                return;
            }
            this.$inertia.delete(
                this.route("staff.admin.announcements.destroy", announcement.id)
            );
        },
    },
};
</script>

<style scoped></style>
