<template>
    <div>
        <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
                {{ user.name }}

                <v-icon
                    class="ml-1"
                    v-tooltip="'Has a driving license'"
                    v-if="user.has_driving_license"
                    color="grey lighten-1"
                >
                    mdi-steering
                </v-icon>

                <div v-if="hasHolidayClash">
                    <v-icon
                        class="ml-1"
                        v-if="
                            hasHolidayClash.half_day && hasHolidayClash.morning
                        "
                        v-tooltip="'Has a morning holiday clash'"
                        color="warning"
                    >
                        mdi-weather-sunset-up
                    </v-icon>
                    <v-icon
                        class="ml-1"
                        v-if="
                            hasHolidayClash.half_day && !hasHolidayClash.morning
                        "
                        v-tooltip="'Has an afternoon holiday clash'"
                        color="warning"
                    >
                        mdi-white-balance-sunny
                    </v-icon>
                    <v-icon
                        class="ml-1"
                        v-if="hasHolidayClash.evening"
                        v-tooltip="'Has an evening holiday clash'"
                        color="warning"
                    >
                        mdi-moon-waning-crescent
                    </v-icon>
                    <v-icon
                        class="ml-1"
                        v-if="
                            !hasHolidayClash.evening &&
                            !hasHolidayClash.half_day
                        "
                        v-tooltip="'Has a full day holiday clash'"
                        color="warning"
                    >
                        mdi-beach
                    </v-icon>
                </div>

                <v-icon
                    class="ml-1"
                    v-tooltip="'Has a resource clash'"
                    v-if="hasClash"
                    color="error"
                >
                    mdi-close
                </v-icon>
            </v-list-item-title>
        </v-list-item-content>
    </div>
</template>

<script>
import ClashFinder from "../../../../Mixins/Logistics/ClashFinder";

export default {
    name: "TaskUsersSelectUser",
    mixins: [ClashFinder],
    props: {
        user: {
            type: Object,
            required: true,
        },
        task: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            hasClash: null,
            hasHolidayClash: null,
        };
    },

    watch: {
        task: {
            immediate: true,
            deep: true,
            handler() {
                this.triggerFindClash();
            },
        },
    },

    computed: {
        usersOtherTasks() {
            let thisTaskTime = this.moment(this.task.date).utc(true);
            if (!this.user.freelancer) {
                return _.filter(this.$page.props.allTasks, (task) => {
                    return (
                        _.find(task.users, { id: this.user.id }) &&
                        this.moment(task.date).isSame(thisTaskTime)
                    );
                });
            }

            return _.filter(this.$page.props.allTasks, (task) => {
                return (
                    _.find(task.freelancers, { id: this.user.id }) &&
                    this.moment(task.date).isSame(thisTaskTime)
                );
            });
        },
    },

    methods: {
        triggerFindClash() {
            this.hasClash = this.findClash(
                this.usersOtherTasks,
                this.task.time_from_original,
                this.task.time_to_original
            );
            if (!this.user.freelancer) {
                this.hasHolidayClash = this.findHolidayClashForUser(
                    this.task,
                    this.user
                );
            }
        },
    },
};
</script>

<style scoped></style>
