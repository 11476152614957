<template>
    <modal-template v-model="modal">
        <div class="text-h6 text--secondary mb-2 mt-2">
            {{ form.id ? "Edit" : "Create" }} a Sub Category
        </div>

        <form novalidate @submit.prevent="submit">
            <validation-observer ref="validation">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-select
                            name="Main Category"
                            label="Main Category"
                            v-model="form.product_category_id"
                            :items="$page.props.productCategories"
                            item-text="name"
                            item-value="id"
                            clearable
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-switch
                            name="Active"
                            label="Active"
                            v-model="form.show_on_hire_site"
                            persistent-hint
                            hint="Only active categories will show on the hire site."
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="mb-2">Sub Category Image</div>
                        <SelectFileAndDisplaySingle
                            v-model="form.image"
                            max-width="300px"
                            :allowed-extensions="['.jpg', '.jpeg', '.png']"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-input
                            name="Sort Order"
                            hint="Higher number will be displayed first"
                            persistent-hint
                            v-model="form.sort"
                        />
                    </v-col>
                </v-row>
            </validation-observer>

            <v-btn
                color="success"
                type="submit"
                class="mt-6"
                :loading="form.processing"
            >
                Save
            </v-btn>
        </form>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../../../../../Comps/Master/ModalTemplate.vue";
import SelectFileAndDisplaySingle from "../../../../../../Comps/General/SelectFileAndDisplaySingle.vue";
import TextInput from "../../../../../../Comps/Forms/TextInput.vue";

export default {
    name: "SubCategoryModal",
    components: {
        TextInput,

        SelectFileAndDisplaySingle,
        ModalTemplate,
    },
    data() {
        return {
            form: this.$inertia.form({
                image: null,
            }),
            modal: false,
        };
    },

    methods: {
        edit(category) {
            this.modal = true;
            this.form = this.$inertia.form(category);
        },

        async submit() {
            if (!(await this.$refs.validation.validate())) {
                return;
            }

            this.form.put(
                this.route(
                    "staff.admin.hire-site-sub-categories.update",
                    this.form.id
                ),
                {
                    onSuccess: () => {
                        this.modal = false;
                    },
                }
            );
        },
    },
};
</script>
