<template>
    <div>
        <v-container fluid>
            <head-title
                :title="`Client Survey - ${job.subject}`"
                do-not-append
            />
            <v-row class="justify-center">
                <v-col cols="12" md="8">
                    <v-img
                        contain
                        max-height="150"
                        :src="$page.props.files.pytchLogo"
                    />

                    <v-sheet elevation="2" class="pa-4" rounded>
                        <div class="text-center">
                            <div class="text-h4 primary--text font-weight-bold">
                                Client Feedback
                            </div>
                            <div class="text-h6">{{ job.subject }}</div>
                        </div>
                        <v-divider class="my-4" />

                        <form novalidate @submit.prevent="submit">
                            <validation-observer ref="form">
                                <v-container>
                                    <survey-rating
                                        v-for="(rating, index) in ratings"
                                        :key="index"
                                        v-model="form"
                                        :name="rating.name"
                                        :index="rating.index"
                                    />

                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Best Parts"
                                        rules="max:2000"
                                    >
                                        <v-textarea
                                            v-model="form.survey.best_parts"
                                            name="Best Parts"
                                            outlined
                                            class="mt-4"
                                            :counter="2000"
                                            :error-messages="errors"
                                            label="The best parts of our work"
                                        >
                                        </v-textarea>
                                    </validation-provider>

                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Improve on"
                                        rules="max:2000"
                                    >
                                        <v-textarea
                                            v-model="form.survey.bad_parts"
                                            name="Improve on"
                                            outlined
                                            class="mt-4"
                                            :counter="2000"
                                            :error-messages="errors"
                                            label="Any parts we could improve on"
                                        >
                                        </v-textarea>
                                    </validation-provider>

                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Partner"
                                        rules="max:2000"
                                    >
                                        <v-textarea
                                            v-model="form.survey.partner"
                                            name="Partner"
                                            outlined
                                            class="mt-4"
                                            :counter="2000"
                                            :error-messages="errors"
                                            label="What would make Pytch the perfect production partner"
                                        >
                                        </v-textarea>
                                    </validation-provider>

                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Comments"
                                        rules="max:2000"
                                    >
                                        <v-textarea
                                            v-model="form.survey.comments"
                                            name="Comments"
                                            outlined
                                            class="mt-4"
                                            :counter="2000"
                                            :error-messages="errors"
                                            label="Optional comments"
                                        >
                                        </v-textarea>
                                    </validation-provider>

                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Your Name"
                                        rules="required|max:255"
                                    >
                                        <v-text-field
                                            v-model="form.survey.reporter_name"
                                            name="Your Name"
                                            :counter="255"
                                            outlined
                                            :error-messages="errors"
                                            label="Your Name"
                                            class="required mt-4"
                                            required
                                        >
                                        </v-text-field>
                                    </validation-provider>

                                    <v-btn
                                        :loading="form.processing"
                                        color="success"
                                        type="submit"
                                        large
                                    >
                                        Submit
                                    </v-btn>
                                </v-container>
                            </validation-observer>
                        </form>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import SurveyRating from "./SurveyRating";
import PublicController from "../../../Comps/Master/PublicController";
import HeadTitle from "../../../Comps/Master/HeadTitle";
export default {
    name: "SurveyIndex",
    components: { HeadTitle, SurveyRating },
    layout: PublicController,
    props: {
        job: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            form: this.$inertia.form({
                survey: {
                    quality: 0,
                    service: 0,
                    value_for_money: 0,
                    flexibility: 0,
                    creativity: 0,
                    on_site_staff: 0,
                    reporter_name: "",
                    best_parts: "",
                    bad_parts: "",
                    comments: "",
                    partner: "",
                    additional: {
                        quality: "",
                        service: "",
                        value_for_money: "",
                        flexibility: "",
                        creativity: "",
                        on_site_staff: "",
                    },
                },
            }),
            ratings: [
                { name: "Quality of delivery", index: "quality" },
                { name: "Level of customer service", index: "service" },
                { name: "Value for money", index: "value_for_money" },
                { name: "Creativity", index: "creativity" },
                { name: "Flexibility", index: "flexibility" },
                { name: "Quality of on-site staff", index: "on_site_staff" },
            ],
        };
    },

    methods: {
        submit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.form.post(
                    this.route("public.survey.store", { id: this.job.id })
                );
            });
        },
    },
};
</script>

<style scoped></style>
