<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <div class="d-inline-block">
                <v-badge
                    :value="timesheets.length"
                    :content="timesheets.length"
                >
                    Timesheets
                </v-badge>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <div class="text-caption text--secondary">Open Timesheets</div>
            <v-list v-if="timesheets.length">
                <div v-for="sheet in timesheets" :key="sheet.id">
                    <v-divider></v-divider>
                    <inertia-link
                        class="inertia-link"
                        :href="route('staff.timesheets.show', sheet)"
                    >
                        <v-list-item link>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ momentFormatDateYear(sheet.start_date) }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <timesheet-status-chip :status="sheet.status" />
                            </v-list-item-action>
                        </v-list-item>
                    </inertia-link>
                </div>
            </v-list>
            <div class="text-body-2" v-else>
                <div>No Open Timesheets found.</div>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import TimesheetStatusChip from "../Timesheets/TimesheetStatusChip.vue";

export default {
    name: "DashboardTimesheets",
    components: { TimesheetStatusChip },

    computed: {
        timesheets() {
            return this.$page.props.timesheets;
        },
    },
};
</script>

<style scoped></style>
