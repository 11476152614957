<template>
    <section>
        <slot></slot>
        <error-controller></error-controller>
        <notification-controller></notification-controller>
        <vehicle-notification-controller />
    </section>
</template>

<script>
import ErrorController from "./ErrorController";
import NotificationController from "./NotificationController";
import VehicleNotificationController from "./VehicleNotificationController";
import _ from "lodash";
import GlobalControllerMethods from "../../Mixins/GlobalControllerMethods";

export default {
    name: "Controller",
    components: {
        VehicleNotificationController,
        NotificationController,
        ErrorController,
    },

    mixins: [GlobalControllerMethods],

    mounted() {
        this.$root.store.user = this.$page.props.auth.user;
    },
};
</script>
