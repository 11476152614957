<template>
    <modal-template v-model="show">
        <user-earning-rate-form
            v-if="show"
            title="Timesheet Rates"
            :earning-route="
                route('staff.admin.timesheets.rates', $page.props.timesheet)
            "
            :initial-rates="$page.props.timesheet"
            @close-modal="show = false"
        />
    </modal-template>
</template>

<script>
import ModalTemplate from "../../Master/ModalTemplate.vue";
import UserEarningRateForm from "../Users/UsersShow/UserEarningRateForm.vue";
export default {
    name: "TimesheetEarningRateModal",
    components: { UserEarningRateForm, ModalTemplate },

    data() {
        return {
            show: false,
        };
    },

    methods: {
        open() {
            this.show = true;
        },
    },
};
</script>

<style scoped></style>
