<template>
    <v-container fluid>
        <head-title title="Logistics" />

        <div class="mb-4">
            <div class="text-h4">Logistics</div>
            <div class="text-h6 text--secondary">
                Week starting
                {{ momentFormatDateYear($page.props.thisWeekProp) }}
            </div>
        </div>

        <logistics-viewer
            v-if="logistic"
            :this-week="thisWeek"
            :logistic="logistic"
            :logistic-live="logisticLive"
        />
        <div v-if="!logistic" class="text-h5 text-center mt-4">
            There are no logistics for this week yet!
        </div>
    </v-container>
</template>

<script>
import LogisticsViewer from "../../../Comps/Staff/Logistics/LogisticsViewer.vue";
import HeadTitle from "../../../Comps/Master/HeadTitle.vue";
export default {
    name: "WarehouseLogistics",
    components: { HeadTitle, LogisticsViewer },
    computed: {
        thisWeek() {
            return this.moment(this.$page.props.thisWeekProp);
        },

        logistic() {
            return this.$page.props.logistic;
        },

        logisticDeleted() {
            return this.logistic && this.logistic.deleted_at;
        },

        logisticLive() {
            return !!(
                this.logistic &&
                !this.logisticDeleted &&
                this.logistic.sent
            );
        },
    },
};
</script>

<style scoped></style>
