export default {
    computed: {
        backgroundClasses() {
            if (!this.$vuetify.theme.isDark) {
                return "";
            }

            return "grey darken-3";
        },
    },
};
