<template>
    <v-app>
        <v-main>
            <head-title
                do-not-append
                :title="`${freelancer.name} | PYTCH Freelancer Portal`"
            />
            <notification-controller />
            <v-container fluid>
                <v-img
                    contain
                    max-height="100"
                    :src="$page.props.files.pytchLogoWhite"
                    alt="PYTCH Logo"
                />
                <profile-details
                    class="mt-8"
                    :freelancer="freelancer"
                    :new-profile="newProfile"
                >
                    <v-btn
                        icon
                        fab
                        absolute
                        style="top: 5px; right: 5px"
                        v-tooltip="'Edit Profile'"
                        @click="$refs.modal.open()"
                    >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </profile-details>
                <edit-profile-modal ref="modal" />
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import HeadTitle from "../../Comps/Master/HeadTitle";
import FileHelpers from "../../Mixins/FileHelpers";
import EditProfileModal from "./Partials/EditProfileModal";
import NotificationController from "../../Comps/Master/NotificationController";
import LocalStorage from "../../Mixins/LocalStorage";
import ProfileDetails from "./Partials/ProfileDetails";

export default {
    name: "ShowProfile",
    layout: null,
    components: {
        ProfileDetails,
        NotificationController,
        EditProfileModal,
        HeadTitle,
    },
    mixins: [FileHelpers, LocalStorage],

    data() {
        return {
            newProfile: false,
        };
    },

    created() {
        this.$vuetify.theme.dark = true;
    },

    mounted() {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        if (params.new_profile) {
            this.newProfile = true;
        }

        if (params.edit_profile) {
            setTimeout(() => {
                this.$refs.modal.open();
            }, 300);
        }
    },

    computed: {
        freelancer() {
            return this.$page.props.freelancer;
        },
    },
};
</script>

<style scoped></style>
