<template>
    <v-container>
        <header-name>Expenses</header-name>

        <v-sheet
            v-if="!$page.props.canAccessExpenses"
            elevation="2"
            class="pa-8 d-flex align-center flex-column"
        >
            <v-sheet max-width="400">
                <secure-login-svg />
            </v-sheet>
            <div class="text-body-1 text-center">
                Expenses are not currently available<br />Please speak with HR
                to get this setup
            </div>
            <div class="text-caption text--secondary">
                Code: no-xero-contact-linked
            </div>
        </v-sheet>

        <div v-else>
            <expense-allowances
                :user="$page.props.auth.user"
                :allowance-left="$page.props.allowanceLeft"
            />

            <div class="d-flex">
                <v-btn
                    class="success my-4 ml-sm-auto"
                    small
                    @click="createNewSheet"
                >
                    Create Sheet
                </v-btn>
            </div>
            <v-sheet class="pa-6" elevation="2">
                <template v-if="sheets.data.length">
                    <div class="d-flex mb-6">
                        <v-pagination
                            v-model="page"
                            :length="sheets.meta.last_page"
                        />
                    </div>

                    <div class="text-caption ml-3">
                        Showing {{ sheets.meta.from }} to
                        {{ sheets.meta.to }} of
                        {{ sheets.meta.total }}
                    </div>

                    <v-simple-table class="row-pointer">
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Lines</th>
                                <th>Total</th>
                                <th>Submitted</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="sheet in sheets.data"
                                :key="sheet.id"
                                @click="clickRow(sheet)"
                            >
                                <td>{{ sheet.number }}</td>
                                <td>{{ sheet.lines.length }}</td>
                                <td>£{{ formatMoney(sheet.sheetTotal) }}</td>
                                <td>
                                    <div v-if="sheet.submitted_on">
                                        {{
                                            momentFormatDateTimeYear(
                                                sheet.submitted_on
                                            )
                                        }}
                                    </div>
                                    <v-chip label small v-else> OPEN</v-chip>
                                </td>
                                <td>
                                    <expense-sheet-status-chip
                                        :status="sheet.status"
                                    />
                                </td>
                                <td>
                                    <v-menu v-if="!sheet.submitted_on">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon v-bind="attrs" v-on="on">
                                                mdi-dots-vertical
                                            </v-icon>
                                        </template>

                                        <v-list>
                                            <inertia-link
                                                :href="
                                                    route(
                                                        'staff.expense-sheets.show',
                                                        sheet
                                                    )
                                                "
                                                class="inertia-link"
                                            >
                                                <v-list-item link>
                                                    <v-list-item-title
                                                        class="primary--text"
                                                    >
                                                        View
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </inertia-link>
                                            <v-divider />
                                            <v-list-item
                                                link
                                                @click="deleteSheet(sheet)"
                                            >
                                                <v-list-item-title
                                                    class="error--text"
                                                >
                                                    Delete
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>

                    <div class="d-flex mt-6">
                        <v-pagination
                            v-model="page"
                            :length="sheets.meta.last_page"
                        />
                    </div>
                </template>

                <div v-else class="pa-6 text-center">
                    <slot>No expense sheets found</slot>
                </div>
            </v-sheet>
        </div>
    </v-container>
</template>

<script>
import PeopleController from "../../../Mixins/PeopleController";
import HeaderName from "../../../Comps/General/HeaderName";
import SecureLoginSvg from "../../../Comps/General/Svgs/SecureLoginSvg";
import ExpenseSheetStatusChip from "../../../Comps/Staff/Expenses/ExpenseSheetStatusChip";
import ExpenseAllowances from "../../../Comps/Staff/Expenses/ExpenseAllowances";

export default {
    name: "ExpenseSheets",
    components: {
        ExpenseAllowances,
        ExpenseSheetStatusChip,

        SecureLoginSvg,
        HeaderName,
    },
    mixins: [PeopleController],

    data() {
        return {
            page: this.$page.props.sheets.meta.current_page,
        };
    },

    computed: {
        sheets() {
            return this.$page.props.sheets;
        },
    },

    watch: {
        page(page) {
            this.$inertia.get(
                this.route("staff.expense-sheets.index"),
                {
                    page: page,
                },
                {
                    preserveScroll: true,
                    preserveState: true,
                }
            );
        },
    },

    methods: {
        deleteSheet(item) {
            if (
                !confirm(
                    `Are you sure you want to delete sheet ${item.number}?`
                )
            ) {
                return;
            }

            this.$inertia.delete(
                this.route("staff.expense-sheets.destroy", item)
            );
        },
        clickRow(item) {
            this.$inertia.visit(this.route("staff.expense-sheets.show", item));
        },
        createNewSheet() {
            if (
                !confirm("Are you sure you want to create a new expense sheet?")
            ) {
                return;
            }

            this.$inertia.post(this.route("staff.expense-sheets.store"));
        },
    },
};
</script>

<style scoped></style>
