<template>
    <v-container>
        <head-title title="Asset Search" />
        <div class="text-h4 mb-4">Asset Search</div>
        <asset-search-form route-name="warehouse.asset-search.search" />
    </v-container>
</template>

<script>
import HeadTitle from "../../../Comps/Master/HeadTitle";
import AssetSearchForm from "../../../Comps/Processes/AssetSearch/AssetSearchForm";
export default {
    name: "AssetSearch",
    components: { AssetSearchForm, HeadTitle },
};
</script>

<style scoped></style>
