<template>
    <div class="gantt__row">
        <div class="text-center" style="position: sticky; left: 0; z-index: 1">
            <div
                style="
                    background-color: white;
                    height: 100%;
                    width: 100%;
                    border-width: 1px 1px 0 1px;
                    border-color: rgba(0, 0, 0, 0.2);
                    border-style: solid;
                "
                class="d-flex align-center pa-1"
            >
                <div style="width: 40px" v-if="row.avatar" class="mr-1">
                    <v-avatar size="40"
                        ><v-img :src="row.avatar"></v-img
                    ></v-avatar>
                </div>
                <div class="text-center" style="width: 100%">
                    <div>
                        {{ row.name }}
                    </div>
                    <div
                        v-if="admin"
                        class="text-caption"
                        :class="{ 'red--text': timeWorked.asHours() > 60 }"
                    >
                        {{
                            Math.floor(timeWorked.asHours()).toLocaleString(
                                undefined,
                                {
                                    minimumIntegerDigits: 2,
                                }
                            )
                        }}:{{
                            timeWorked.minutes().toLocaleString(undefined, {
                                minimumIntegerDigits: 2,
                            })
                        }}
                    </div>
                </div>
            </div>
        </div>
        <ul :style="gridRowBars" class="gantt__row-bars">
            <logistics-chart-task
                v-for="task in row.tasks"
                :key="`${$vnode.key}-task-${task.id}`"
                :sections="sections"
                :per-section="perSection"
                :range-start="rangeStart"
                :starts_at="task.starts_at"
                :ends_at="task.ends_at"
                :name="task.name"
                :when-its-done="task.when_its_done"
                :color="
                    (highlightChanges &&
                        !task.changed &&
                        !task.newTask &&
                        !task.firstVersion) ||
                    task.deleted
                        ? 'grey'
                        : task.category.color
                "
                @click="$emit('taskClick', task)"
            />
            <logistics-chart-task
                v-for="holiday in row.holidays"
                :key="`${$vnode.key}-holiday-${holiday.id}`"
                :sections="sections"
                :per-section="perSection"
                :range-start="rangeStart"
                :starts_at="holiday.starts_at"
                :ends_at="holiday.ends_at"
                name="Holiday"
                color="#FF9800"
                @click="$emit('holidayClick', holiday)"
            />
        </ul>
    </div>
</template>

<script>
import LogisticsChartTask from "./LogisticsChartTask.vue";
export default {
    name: "LogisticChartRow",
    components: { LogisticsChartTask },
    props: {
        row: {
            type: Object,
            required: true,
        },
        sections: {
            type: Number,
            required: true,
        },
        perSection: {
            type: Number,
            required: true,
        },
        rangeStart: {
            type: Object,
            required: true,
        },
        filters: {
            type: Array,
            required: true,
        },
        admin: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        gridRowBars() {
            return {
                "grid-template-columns": `repeat(${
                    this.sections * this.perSection
                }, 1fr)`,
            };
        },

        highlightChanges() {
            return this.filters.includes("Highlight Changes");
        },

        weekStart() {
            return this.rangeStart.clone().startOf("week");
        },

        weekEnd() {
            return this.rangeStart.clone().endOf("week");
        },

        timeWorked() {
            return this.moment.duration(
                this.row.tasks.reduce((acc, task) => {
                    if (task.deleted) {
                        return acc;
                    }

                    const taskStart = this.getMinTime(
                        this.moment(task.starts_at)
                    );
                    const taskEnd = this.getMaxTime(this.moment(task.ends_at));
                    const taskDuration = taskEnd.diff(taskStart, "minutes");
                    return acc + taskDuration;
                }, 0),
                "minutes"
            );
        },
    },

    methods: {
        getMinTime(time) {
            return this.weekStart.isAfter(time) ? this.weekStart : time;
        },
        getMaxTime(time) {
            return this.weekEnd.isBefore(time) ? this.weekEnd : time;
        },
    },
};
</script>

<style scoped lang="scss">
.gantt__row {
    display: grid;
    grid-template-columns: 150px 1fr;
}

.gantt__row-bars {
    list-style: none;
    display: grid;
    padding: 4px 0;
    margin: 0;
    grid-gap: 4px 0;
    border-top: solid 1px rgba(0, 0, 0, 0.2);
}

.gantt__row-bars li {
    font-weight: 500;
    text-align: left;
    font-size: 12px;
    height: 40px;
    background-color: #55de84;
    padding: 2px 4px;
    color: #fff;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    align-self: start;
}

.gantt__row-bars li:after,
.gantt__row-bars li:before {
    content: "";
    height: 100%;
    top: 0;
    z-index: 4;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
}

.gantt__row-bars li:before {
    left: 0;
}

.gantt__row-bars li:after {
    right: 0;
}
</style>
