var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fixed"},[_c('header-name',[_vm._v("Vehicles")]),_vm._v(" "),_c('div',{staticClass:"d-flex mb-6"},[_c('v-switch',{staticClass:"d-inline-block",attrs:{"label":"Show Deleted Vehicles"},on:{"change":_vm.showDeleted},model:{value:(_vm.deleted),callback:function ($$v) {_vm.deleted=$$v},expression:"deleted"}}),_vm._v(" "),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":function($event){_vm.modal = true}}},[_vm._v("\n                Create Vehicle\n            ")])],1)],1),_vm._v(" "),_c('vehicles-create-modal',{model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}}),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
            { text: 'Name', value: 'name' },
            { text: 'Reg', value: 'reg' },
            { text: 'Hired', value: 'hired' },
            { text: '', value: 'options' },
        ],"items":_vm.vehicles,"no-data-text":"No vehicles have been found"},scopedSlots:_vm._u([{key:"item.hired",fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","label":"","color":item.hired ? 'success' : 'primary'}},[(item.hired)?_c('span',[_vm._v("Yes")]):_c('span',[_vm._v("No")])])]}},{key:"item.options",fn:function({ item }){return [_c('inertia-link',{staticClass:"inertia-link",attrs:{"href":_vm.route('staff.admin.vehicles.show', item)}},[(!item.deleted_at)?_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('View Vehicle'),expression:"'View Vehicle'"}],staticClass:"mr-2",attrs:{"elevation":"2","color":"primary","icon":""}},[_c('v-icon',[_vm._v("mdi-car-estate")])],1):_vm._e()],1),_vm._v(" "),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                    item.deleted_at ? 'Restore Vehicle' : 'Delete Vehicle'
                ),expression:"\n                    item.deleted_at ? 'Restore Vehicle' : 'Delete Vehicle'\n                "}],staticClass:"mr-2",attrs:{"elevation":"2","color":item.deleted_at ? 'success' : 'error',"icon":""},on:{"click":function($event){item.deleted_at ? _vm.restore(item) : _vm.destroy(item)}}},[(item.deleted_at)?_c('v-icon',[_vm._v("mdi-restore")]):_c('v-icon',[_vm._v("mdi-delete-alert")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }