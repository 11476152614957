var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('div',{staticClass:"d-flex mb-5"},[_c('header-name',[_vm._v("Screens")])],1),_vm._v(" "),_c('div',{staticClass:"d-flex justify-center"},[_c('v-sheet',{staticClass:"text--secondary pa-10 my-4",attrs:{"elevation":"2","rounded":""}},[_c('strong',[_vm._v(" Display URLs: ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v("\n            Warehouse:\n            "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.copy(
                        _vm.route(
                            'screens.warehouse',
                            { api_token: 'YOUR_TOKEN_HERE' },
                            true
                        )
                    )}}},[_vm._v("\n                "+_vm._s(_vm.route(
                        "screens.warehouse",
                        { api_token: "YOUR_TOKEN_HERE" },
                        true
                    ))+"\n                "),_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Copy to clipboard'),expression:"'Copy to clipboard'"}],staticClass:"ml-3"},[_vm._v("\n                    mdi-content-copy\n                ")])],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('br'),_vm._v("\n            Office:\n            "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.copy(
                        _vm.route(
                            'screens.office',
                            { api_token: 'YOUR_TOKEN_HERE' },
                            true
                        )
                    )}}},[_vm._v("\n                "+_vm._s(_vm.route(
                        "screens.office",
                        { api_token: "YOUR_TOKEN_HERE" },
                        true
                    ))+"\n                "),_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Copy to clipboard'),expression:"'Copy to clipboard'"}],staticClass:"ml-3"},[_vm._v("\n                    mdi-content-copy\n                ")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }