import { render, staticRenderFns } from "./WarehouseLayout.vue?vue&type=template&id=127b968f&scoped=true"
import script from "./WarehouseLayout.vue?vue&type=script&lang=js"
export * from "./WarehouseLayout.vue?vue&type=script&lang=js"
import style0 from "./WarehouseLayout.vue?vue&type=style&index=0&id=127b968f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "127b968f",
  null
  
)

export default component.exports