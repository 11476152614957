<template>
    <v-container class="fixed">
        <breadcrumbs :items="bread"></breadcrumbs>

        <div class="d-flex">
            <header-name>Vehicle | {{ vehicle.name }}</header-name>
            <div class="ml-auto">
                <v-btn color="primary" small @click="modal = true">
                    Edit Vehicle
                </v-btn>
            </div>
        </div>

        <vehicles-create-modal v-model="modal" :edit="vehicle" />

        <v-list two-line outlined elevation="2">
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>
                        mdi-car-estate
                    </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>Name</v-list-item-title>
                    <v-list-item-subtitle
                        v-text="vehicle.name"
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark> mdi-counter </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>Registration</v-list-item-title>
                    <v-list-item-subtitle
                        v-text="vehicle.reg"
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>
                        mdi-map-marker-distance
                    </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        <div>Total Mileage (Current Tax Year)</div>
                        <div class="text--secondary text-caption">
                            {{ momentFormatDateYear(taxYearDates.from) }} -
                            {{ momentFormatDateYear(taxYearDates.to) }}
                        </div>
                    </v-list-item-title>
                    <v-list-item-subtitle
                        v-text="vehicle.total_mileage"
                    ></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark> mdi-sale </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>Hired</v-list-item-title>
                    <v-list-item-subtitle>
                        <v-chip
                            small
                            label
                            :color="vehicle.hired ? 'success' : 'primary'"
                        >
                            <span v-if="vehicle.hired">Yes</span>
                            <span v-else>No</span>
                        </v-chip>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <!--Tasks-->
        <div class="mt-5">
            <div v-if="vehicle.tasks.length">
                <div class="text-h6 font-weight-regular text--secondary">
                    Upcoming tasks for <strong>{{ vehicle.name }}</strong>
                </div>
                <v-list outlined>
                    <div v-for="(task, index) in vehicle.tasks" :key="task.id">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ task.name }}
                                </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                                {{ momentFormatDateTime(getDateTime(task)) }}
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider
                            v-if="vehicle.tasks.length > index + 1"
                        ></v-divider>
                    </div>
                </v-list>
            </div>
            <v-sheet elevation="2" class="pa-4" v-else>
                <div class="text-subtitle-1 text--secondary">
                    This vehicle does not have any upcoming tasks
                </div>
            </v-sheet>
        </div>

        <!--Logs-->

        <v-alert
            border="bottom"
            colored-border
            class="mt-4"
            type="warning"
            elevation="2"
            v-if="vehicleBookedOut.length"
        >
            This vehicle is currently booked out by
            {{ vehicleBookedOut[0].user.name }}
        </v-alert>

        <v-sheet elevation="2" class="pa-4 mt-5">
            <div class="text-h6 font-weight-regular text--secondary">
                Logs for <strong>{{ vehicle.name }}</strong>
            </div>
            <v-data-table
                no-data-text="No logs available for this vehicle"
                :footer-props="{ 'items-per-page-options': [10, 50, 100, 200] }"
                sort-by="booked_in"
                sort-desc
                :headers="headers"
                :items="vehicle.logs"
            >
                <template v-slot:item.mileage="{ item }">
                    <div
                        v-if="
                            item.booked_in &&
                            item.mileage_out &&
                            item.mileage_in
                        "
                        v-tooltip.left="
                            `<div>Mileage</div>
                                    <div>Out: ${item.mileage_out}</div>
                                    <div>In: ${item.mileage_in}</div>`
                        "
                    >
                        {{ item.mileage_in - item.mileage_out }}
                    </div>
                    <div v-else>N/A</div>
                </template>
                <template v-slot:item.booked_out="{ item }">
                    {{ momentFormatDateTimeYear(item.booked_out) }}
                </template>
                <template v-slot:item.booked_in="{ item }">
                    <v-chip label small color="primary" v-if="!item.booked_in">
                        Booked Out
                    </v-chip>
                    <div v-else>
                        {{ momentFormatDateTimeYear(item.booked_in) }}
                    </div>
                </template>
            </v-data-table>
        </v-sheet>
    </v-container>
</template>
<script>
import HeaderName from "../../../../Comps/General/HeaderName";
import Breadcrumbs from "../../../../Comps/General/Breadcrumbs";
import VehiclesCreateModal from "../../../../Comps/Staff/Vehicles/VehiclesCreateModal";
import AdminController from "../../../../Mixins/AdminController";

export default {
    name: "VehiclesShow",
    components: { VehiclesCreateModal, Breadcrumbs, HeaderName },
    props: ["vehicle", "taxYearDates"],
    mixins: [AdminController],

    data() {
        return {
            bread: [
                {
                    text: "Vehicles",
                    disabled: false,
                    link: this.route("staff.admin.vehicles.index"),
                },
                {
                    text: this.vehicle.name,
                    disabled: true,
                },
            ],
            headers: [
                { text: "Vehicle", value: "vehicle.name" },
                { text: "REG", value: "vehicle.reg" },
                { text: "User", value: "user.name" },
                { text: "Mileage", value: "mileage" },
                { text: "Out", value: "booked_out" },
                { text: "In", value: "booked_in" },
            ],
            modal: false,
        };
    },
    methods: {
        destroy(vehicle) {
            if (!confirm("Are you sure?")) {
                return;
            }

            this.$inertia.delete(
                this.route("staff.admin.vehicles.destroy", vehicle)
            );
        },
        getDateTime(task) {
            let date = this.moment(task.date);
            let time = this.moment(task.time_from);

            date.set({
                hour: time.get("hour"),
                minute: time.get("minute"),
            });

            return date;
        },
    },

    computed: {
        vehicleBookedOut() {
            return this.vehicle.logs.filter((log) => {
                return !log.booked_in;
            });
        },
    },
};
</script>
<style scoped></style>
