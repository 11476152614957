<template>
    <modal-template native v-model="show">
        <div v-if="holiday">
            <v-card>
                <v-card-title :class="holiday.color" class="d-flex">
                    <div class="white--text">
                        <div>{{ holiday.user.name }}</div>
                        <div class="d-flex align-end">
                            <div>{{ dateCalc }}</div>
                            <div
                                v-if="isOwnHoliday || hasAdminPermission"
                                class="text-caption ml-2"
                            >
                                ({{ holiday.type }})
                            </div>
                        </div>
                    </div>

                    <v-spacer />

                    <v-menu
                        v-if="canDenyHoliday || canEditAndDeleteHoliday"
                        top
                        close-on-click
                        close-on-content-click
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" dark v-on="on">
                                mdi-dots-vertical
                            </v-icon>
                        </template>

                        <v-list>
                            <v-list-item
                                v-if="canDenyHoliday"
                                link
                                @click="denyHoliday(holiday)"
                            >
                                <v-list-item-title class="error--text">
                                    Deny
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="canEditAndDeleteHoliday"
                                link
                                @click="editHoliday"
                            >
                                <v-list-item-title class="primary--text">
                                    Edit
                                </v-list-item-title>
                            </v-list-item>
                            <div v-if="canEditAndDeleteHoliday">
                                <v-divider />
                                <v-list-item link @click="deleteHoliday">
                                    <v-list-item-title class="error--text">
                                        Delete
                                    </v-list-item-title>
                                </v-list-item>
                            </div>
                        </v-list>
                    </v-menu>
                </v-card-title>

                <div class="pa-6">
                    <div>
                        <v-chip
                            color="grey"
                            class="white--text"
                            label
                            v-if="!holiday.approved && !holiday.denied"
                        >
                            Pending
                        </v-chip>
                        <v-chip
                            color="success"
                            label
                            v-if="holiday.approved && !holiday.denied"
                        >
                            Approved
                        </v-chip>
                        <v-chip
                            color="error"
                            label
                            v-if="!holiday.approved && holiday.denied"
                        >
                            Denied
                        </v-chip>
                        <v-chip color="primary" label v-if="holiday.half_day">
                            {{ holiday.morning ? "Morning" : "Afternoon" }}
                        </v-chip>
                        <v-chip color="error" label v-if="holiday.evening">
                            Evening Only
                        </v-chip>
                    </div>

                    <div v-if="canApproveHoliday" class="mt-4">
                        <v-btn
                            small
                            color="success"
                            @click="approveHoliday(holiday)"
                        >
                            Approve
                        </v-btn>
                        <v-btn
                            small
                            color="error"
                            @click="denyHoliday(holiday)"
                        >
                            Deny
                        </v-btn>
                    </div>

                    <div
                        v-if="hasAdminPermission"
                        class="text-caption grey--text mt-2"
                    >
                        <div>
                            Requested on:
                            {{ momentFormatDateTimeYear(holiday.created_at) }}
                        </div>
                        <div v-if="holiday.approved && !holiday.denied">
                            <div>
                                Approved on:
                                {{ momentFormatDateTimeYear(holiday.approved) }}
                            </div>
                            <div>
                                Approved by:
                                {{ holiday.approved_by?.name ?? "N/A" }}
                            </div>
                        </div>

                        <div v-if="!holiday.approved && holiday.denied">
                            <div>
                                Denied on:
                                {{ momentFormatDateTimeYear(holiday.denied) }}
                            </div>
                            <div>
                                Denied by:
                                {{ holiday.denied_by?.name ?? "N/A" }}
                            </div>
                        </div>
                    </div>

                    <div v-if="hasAdminPermission || isOwnHoliday" class="mt-4">
                        <div class="text-body-1 text--secondary">Notes:</div>
                        <div>{{ holiday.notes }}</div>
                    </div>
                </div>

                <div class="pa-6" v-if="stats">
                    <v-divider class="my-4" />
                    <div class="text-subtitle-1 mb-4">User holiday stats</div>
                    <holiday-stats-grid :stats="stats" />
                </div>
            </v-card>
        </div>

        <holiday-create-modal @submitted="show = false" ref="editModal" />
    </modal-template>
</template>

<script>
import ModalTemplate from "../../Master/ModalTemplate";
import HolidayCreateModal from "./HolidayCreateModal";
import Permissions from "../../../Mixins/Permissions";
import HolidayStatsGrid from "../Personal/Holiday/HolidayStatsGrid.vue";

export default {
    name: "HolidayViewModal",
    components: { HolidayStatsGrid, HolidayCreateModal, ModalTemplate },

    mixins: [Permissions],

    data() {
        return {
            show: false,
            holiday: null,
            stats: null,
            inertiaActionConfig: {
                preserveState: true,
                preserveScroll: true,
                onSuccess: () => {
                    this.show = false;
                },
            },
        };
    },

    methods: {
        open(holiday) {
            this.holiday = holiday;
            this.show = true;
            this.getStats();
        },

        approveHoliday(holiday) {
            if (
                !confirm(
                    `Are you sure you want to approve this holiday for ${holiday.user.name}?`
                )
            ) {
                return;
            }

            this.$inertia.put(
                this.route("staff.admin.holidays.approve", holiday),
                {},
                this.inertiaActionConfig
            );
        },

        denyHoliday(holiday) {
            if (
                !confirm(
                    `Are you sure you want to deny this holiday for ${holiday.user.name}?`
                )
            ) {
                return;
            }

            this.$inertia.put(
                this.route("staff.admin.holidays.deny", holiday),
                {},
                this.inertiaActionConfig
            );
        },

        deleteHoliday() {
            if (
                !confirm(
                    `Are you sure you want to delete the holiday for ${this.holiday.user.name}?`
                )
            ) {
                return;
            }

            this.$inertia.delete(
                this.route("staff.holidays.destroy", this.holiday),
                this.inertiaActionConfig
            );
        },

        editHoliday() {
            this.$refs.editModal.edit(this.holiday);
        },

        getStats() {
            const user = this.holiday.user;

            this.$http
                .get(this.route("staff.admin.users.get-holiday-stats", user))
                .then((response) => {
                    this.stats = response.data;
                });
        },
    },

    computed: {
        dateCalc() {
            if (this.holiday.start_date === this.holiday.end_date) {
                return this.momentFormatDate(this.holiday.start_date);
            }

            return (
                this.momentFormatDate(this.holiday.start_date) +
                " - " +
                this.momentFormatDate(this.holiday.end_date)
            );
        },
        isOwnHoliday() {
            return this.holiday.user.id === this.$page.props.auth.user.id;
        },
        canEditAndDeleteHoliday() {
            return !this.holiday.approved && !this.holiday.denied;
        },
        canApproveHoliday() {
            return (
                this.hasAdminPermission &&
                !this.holiday.approved &&
                !this.holiday.denied
            );
        },
        canDenyHoliday() {
            return (
                this.hasAdminPermission &&
                !this.holiday.denied &&
                this.holiday.approved
            );
        },
        hasAdminPermission() {
            return this.hasPermission("staffAdminHolidays");
        },
    },
};
</script>

<style scoped></style>
