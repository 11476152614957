<template>
    <div>
        <v-row>
            <v-col cols="12" md="8">
                <div class="d-flex flex-column align-center mb-4">
                    <div class="text-h6">
                        <span class="text--secondary">Hi</span>
                        <strong>{{ user.name }}</strong>
                    </div>
                    <div class="d-flex align-center">
                        <v-icon class="mr-1" color="indigo" small>
                            mdi-at</v-icon
                        >
                        <div class="text-subtitle-2 text--secondary">
                            {{ user.email }}
                        </div>
                    </div>
                </div>
                <v-expansion-panels>
                    <div
                        class="d-flex flex-wrap flex-column flex-md-row panels"
                        style="width: 100%"
                    >
                        <div
                            v-if="!!$page.props.earningRate"
                            :style="panelStyle"
                        >
                            <dashboard-timesheets />
                        </div>
                        <div :style="panelStyle">
                            <dashboard-expenses />
                        </div>
                        <div :style="panelStyle">
                            <dashboard-holiday />
                        </div>
                        <div :style="panelStyle">
                            <dashboard-vehicle-logs />
                        </div>
                        <div :style="panelStyle">
                            <dashboard-training />
                        </div>
                    </div>
                </v-expansion-panels>
            </v-col>
            <v-col cols="12" md="4">
                <v-card class="pa-4" elevation="4">
                    <h5>Timeline</h5>
                    <timeline :tasks="tasks" :logistic="logistic" user>
                    </timeline>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Timeline from "../../../Comps/Staff/Logistics/Tasks/Timeline/Timeline";
import OtherController from "../../../Mixins/OtherController";
import DashboardTraining from "../../../Comps/Staff/Dashboard/DashboardTraining";
import DashboardVehicleLogs from "../../../Comps/Staff/Dashboard/DashboardVehicleLogs";
import DashboardHoliday from "../../../Comps/Staff/Dashboard/DashboardHoliday";
import DashboardExpenses from "../../../Comps/Staff/Dashboard/DashboardExpenses";
import DashboardTimesheets from "../../../Comps/Staff/Dashboard/DashboardTimesheets.vue";

export default {
    name: "Dashboard",
    components: {
        DashboardTimesheets,
        DashboardExpenses,
        DashboardHoliday,
        DashboardVehicleLogs,
        DashboardTraining,
        Timeline,
    },
    props: ["tasks", "logistic"],
    mixins: [OtherController],

    data() {
        return {
            store: this.$root.store,
            s: true,
            first_right: false,
        };
    },

    mounted() {
        this.$root.store.title = "Dashboard";
    },

    computed: {
        panelStyle() {
            return {
                width: this.$vuetify.breakpoint.mdAndUp ? "50%" : "100%",
            };
        },
        user() {
            return this.$page.props.auth.user;
        },
    },
};
</script>

<style lang="scss" scoped>
.panels {
    > div {
        padding: 10px;
    }
}
</style>
