<template>
    <v-sheet class="pa-4" elevation="2">
        <div class="text-h6 text-center">Phone Numbers</div>

        <v-data-table
            :headers="headers"
            :items="$page.props.phoneNumbers"
            sort-by="name"
            :search="search"
        >
            <template v-slot:top>
                <v-text-field
                    dense
                    outlined
                    v-model="search"
                    label="Search"
                    class="mx-4 mt-4"
                />
            </template>

            <template v-slot:item.number="{ item }">
                <a :href="`tel:${item.number}`" v-text="item.number" />
            </template>

            <template v-slot:item.options="{ item }">
                <v-menu top close-on-click close-on-content-click>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                            mdi-dots-vertical
                        </v-icon>
                    </template>

                    <v-list>
                        <v-list-item link @click="$refs.modal.edit(item)">
                            <v-list-item-title class="primary--text">
                                Edit
                            </v-list-item-title>
                        </v-list-item>
                        <div>
                            <v-divider />
                            <v-list-item link @click="deleteNumber(item)">
                                <v-list-item-title class="error--text">
                                    Delete
                                </v-list-item-title>
                            </v-list-item>
                        </div>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>

        <v-divider class="my-2" />
        <div class="text-right">
            <v-btn color="success" small @click="$refs.modal.create()">
                <v-icon small>mdi-plus</v-icon> Add Number
            </v-btn>
        </div>
        <phone-number-edit-modal ref="modal" />
    </v-sheet>
</template>

<script>
import Permissions from "../../../../Mixins/Permissions";
import PhoneNumberEditModal from "./PhoneNumberEditModal";

export default {
    name: "AllPhoneNumbers",
    components: { PhoneNumberEditModal },
    mixins: [Permissions],

    data() {
        return {
            search: "",
        };
    },

    computed: {
        headers() {
            const headers = [
                { text: "Name", value: "name" },
                { text: "Number", value: "number" },
            ];

            if (this.hasPermission("staffAdminHr")) {
                headers.push({ text: "", value: "options" });
            }

            return headers;
        },
    },

    methods: {
        deleteNumber(number) {
            if (
                !confirm(
                    `Are you sure you want to delete the number for ${number.name}?`
                )
            ) {
                return;
            }

            this.$inertia.delete(
                this.route("staff.info.number.destroy", number.id)
            );
        },
    },
};
</script>

<style scoped></style>
