<template>
    <v-card>
        <v-form @submit.prevent="addFile">
            <v-card-subtitle class="text-subtitle-1">
                Add a new file
            </v-card-subtitle>
            <validation-observer ref="validator">
                <v-card-text>
                    <validation-provider
                        name="Title"
                        v-slot="{ errors }"
                        rules="required"
                    >
                        <v-text-field
                            v-model="form.title"
                            outlined
                            dense
                            label="Title"
                            :error-messages="errors"
                            required
                            :loading="form.processing"
                        />
                    </validation-provider>
                </v-card-text>
                <v-card-text>
                    <validation-provider name="File" rules="required">
                        <select-file-and-display-single
                            max-height="100px"
                            max-width="100%"
                            v-model="form.file"
                        />
                    </validation-provider>
                </v-card-text>
            </validation-observer>
            <v-card-text class="text-right">
                <v-btn color="success" type="submit" :loading="form.processing">
                    Save
                </v-btn>
            </v-card-text>
        </v-form>
    </v-card>
</template>

<script>
import SelectFileAndDisplaySingle from "../../General/SelectFileAndDisplaySingle";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", required);

export default {
    name: "UserHrFiles",
    components: { SelectFileAndDisplaySingle },

    props: {
        storeRoute: {
            default: null,
        },
    },

    data() {
        return {
            form: this.$inertia.form({
                title: "",
                file: null,
            }),
        };
    },

    methods: {
        async addFile() {
            if (!(await this.$refs.validator.validate())) {
                return;
            }

            this.form.post(this.storeRouteComputed, {
                preserveScroll: true,
                onSuccess: () => {
                    this.form.reset();
                    this.$refs.validator.reset();
                },
            });
        },
    },

    computed: {
        storeRouteComputed() {
            if (this.storeRoute) {
                return this.storeRoute;
            }

            return this.route(
                "staff.admin.users.hrFiles.store",
                this.$page.props.user.id
            );
        },
    },
};
</script>

<style scoped></style>
