<template>
    <div class="loader-wrap">
        <div class="circle-loader mb-2" :class="{ 'load-complete': circle }">
            <div v-if="circle" class="checkmark draw"></div>
        </div>
        <div class="text-brand text center-align">
            <transition name="fade">
                <div v-if="text">
                    <slot></slot>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: "CompleteLoader",
    props: {
        show: {
            type: Boolean,
            default: true,
        },
        tickDelay: {
            type: Number,
            default: 1000,
        },

        textDelay: {
            type: Number,
            default: 2000,
        },
    },

    data() {
        return {
            circle: false,
            text: false,
        };
    },

    watch: {
        circle() {
            this.checkForCompletion();
        },
        text() {
            this.checkForCompletion();
        },
    },

    mounted() {
        if (this.show) {
            this.run();
        }
    },

    methods: {
        run() {
            //Delay before tick shows
            setTimeout(() => {
                this.circle = true;
            }, this.tickDelay);

            //Delay before text appears
            setTimeout(() => {
                this.text = true;
            }, this.textDelay);
        },

        checkForCompletion() {
            if (this.circle && this.text) {
                this.$emit("done");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
//Loader
.loader-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .text {
        min-height: 50px;
    }
}

.loader-wrap.complete {
    flex-direction: row;
    margin-bottom: 50px;

    .text {
        width: 250px;
    }

    .circle-loader {
        margin-bottom: 0px;
    }
}

// Define vars we'll be using
$brand-success: #5cb85c;
$loader-size: 7em;
$check-height: $loader-size/2;
$check-width: $check-height/2;
$check-left: ($loader-size/6 + $loader-size/12);
$check-thickness: 3px;
$check-color: #5cb85c;

.circle-loader {
    margin-bottom: $loader-size/2;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-left-color: #1db463;
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;
}

.load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: $check-color;
    transition: border 500ms ease-out;
}

.checkmark {
    &.draw:after {
        animation-duration: 800ms;
        animation-timing-function: ease;
        animation-name: checkmark;
        transform: scaleX(-1) rotate(135deg);
    }

    &:after {
        opacity: 1;
        height: $check-height;
        width: $check-width;
        transform-origin: left top;
        border-right: $check-thickness solid $check-color;
        border-top: $check-thickness solid $check-color;
        content: "";
        left: $check-left;
        top: $check-height;
        position: absolute;
    }
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }
    20% {
        height: 0;
        width: $check-width;
        opacity: 1;
    }
    40% {
        height: $check-height;
        width: $check-width;
        opacity: 1;
    }
    100% {
        height: $check-height;
        width: $check-width;
        opacity: 1;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
