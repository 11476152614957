var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fixed"},[_c('training',{attrs:{"comp":"sessions"}}),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-h6 text--secondary mb-2 mt-2"},[_vm._v("Sessions")]),_vm._v(" "),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.$refs.modal.createSession()}}},[_vm._v("\n                Run Session\n            ")])],1)]),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
            { text: 'Module', value: 'module.name' },
            { text: 'Instructor', value: 'instructor_name' },
            { text: 'Trained Users', value: 'users' },
            { text: 'Users Passed', value: 'usersPassed' },
            { text: 'Expires', value: 'expires' },
            { text: '', value: 'options' },
        ],"items":_vm.sessions},scopedSlots:_vm._u([{key:"item.users",fn:function({ item }){return [_vm._v("\n            "+_vm._s(item.users.length)+"\n        ")]}},{key:"item.usersPassed",fn:function({ item }){return [_vm._v("\n            "+_vm._s(_vm.usersPassed(item).length)+"/"+_vm._s(item.users.length)+"\n        ")]}},{key:"item.expires",fn:function({ item }){return [(_vm.expired(_vm.expires(item)))?_c('div',{staticClass:"red--text"},[_vm._v("\n                Expired On:\n                "+_vm._s(_vm.expires(item).format("ddd Do MMM YYYY"))+"\n            ")]):_vm._e(),_vm._v(" "),(!_vm.expired(_vm.expires(item)))?_c('div',{staticClass:"green--text"},[_vm._v("\n                Expires:\n                "+_vm._s(_vm.expires(item).format("ddd Do MMM YYYY"))+"\n            ")]):_vm._e()]}},{key:"item.options",fn:function({ item }){return [_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('View Session'),expression:"'View Session'"}],staticClass:"mr-2",attrs:{"elevation":"2","color":"primary","icon":""},on:{"click":function($event){return _vm.goto(item)}}},[_c('v-icon',[_vm._v("mdi-school")])],1),_vm._v(" "),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Delete Session'),expression:"'Delete Session'"}],attrs:{"elevation":"2","color":"error","icon":""},on:{"click":function($event){return _vm.destroy(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_vm._v(" "),_c('sessions-create-modal',{ref:"modal",attrs:{"users":_vm.users,"modules":_vm.modules},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }