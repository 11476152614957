<template>
    <v-sheet
        rounded="sm"
        elevation="2"
        class="ma-1 pa-2 d-flex flex-column align-center"
    >
        <div>
            <h4 class="text-truncate">{{ vehicle.name }}</h4>
            <h5 class="text-truncate">{{ vehicle.reg }}</h5>
        </div>
        <div v-if="vehicle.hired">
            <v-chip x-small label color="success"> Hired </v-chip>
        </div>
        <div v-if="hasClash">
            <v-chip
                color="error"
                small
                v-tooltip="`Clash with ${hasClash.name}`"
            >
                <div>
                    <v-icon>mdi-alert</v-icon>
                    <strong>Clash</strong>
                </div>
            </v-chip>
        </div>
    </v-sheet>
</template>

<script>
import ClashFinder from "../../../../../Mixins/Logistics/ClashFinder";

export default {
    name: "TaskInfoModalVehicle",
    mixins: [ClashFinder],
    props: {
        vehicle: {
            type: Object,
            required: true,
        },
        logistic: Object,
        task: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            hasClash: false,
        };
    },

    mounted() {
        if (this.logistic) {
            this.hasClash = this.findClash(
                this.vehiclesOtherTasks,
                this.task.time_from,
                this.task.time_to
            );
        }
    },

    computed: {
        vehiclesOtherTasks() {
            if (!this.logistic) {
                return;
            }

            return _.filter(this.logistic.tasks, (task) => {
                return (
                    _.find(task.vehicles, { id: this.vehicle.id }) &&
                    task.date === this.task.date &&
                    task.id !== this.task.id
                );
            });
        },
    },
};
</script>

<style scoped></style>
