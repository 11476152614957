import { render, staticRenderFns } from "./SelectFileAndDisplay.vue?vue&type=template&id=4c7edf9c&scoped=true"
import script from "./SelectFileAndDisplay.vue?vue&type=script&lang=js"
export * from "./SelectFileAndDisplay.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c7edf9c",
  null
  
)

export default component.exports