<template>
    <div>
        <v-row>
            <v-col cols="12" md="3" class="d-flex justify-center">
                <v-sheet
                    class="d-flex justify-center align-center flex-column pa-4"
                    style="position: relative"
                    width="100%"
                    min-height="100"
                    elevation="4"
                >
                    <div>Tool Allowance</div>
                    <expense-allowance-single
                        :to="getExpenseAllowanceLeft('tool')"
                        :out-of="getExpenseAllowance('tool')"
                        :total-allowance="getExpenseAllowance('tool', false)"
                        :admin="admin"
                        :user="user"
                        type="tool"
                    />
                </v-sheet>
            </v-col>
            <v-col cols="12" md="3" class="d-flex justify-center">
                <v-sheet
                    class="d-flex justify-center align-center flex-column pa-4"
                    style="position: relative"
                    width="100%"
                    min-height="100"
                    elevation="4"
                >
                    <div>Workwear & PPE Allowance</div>
                    <expense-allowance-single
                        :to="getExpenseAllowanceLeft('workwear')"
                        :out-of="getExpenseAllowance('workwear')"
                        :total-allowance="
                            getExpenseAllowance('workwear', false)
                        "
                        :admin="admin"
                        :user="user"
                        type="workwear"
                    />
                </v-sheet>
            </v-col>
        </v-row>
        <div class="text-caption text--secondary mt-3">
            Allowance Resets: {{ expenseResets }}
        </div>
        <div class="text-caption text--secondary">
            Please aim to submit your expenses weekly. Expense sheets will be
            submitted automatically after 1 month.
        </div>
    </div>
</template>

<script>
import ExpenseAllowanceSingle from "./ExpenseAllowanceSingle";
import moment from "moment";

export default {
    name: "ExpenseAllowances",
    components: { ExpenseAllowanceSingle },

    props: {
        user: {
            type: Object,
            required: true,
        },
        allowanceLeft: {
            type: Object,
            required: true,
        },
        admin: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        getExpenseAllowance(key, format = true) {
            if (format) {
                return this.formatMoney(
                    parseFloat(this.user.expense_allowances[key])
                );
            }

            return this.user.expense_allowances[key];
        },

        getExpenseAllowanceLeft(key) {
            return parseFloat(this.allowanceLeft[key] / 100);
        },
    },

    computed: {
        expenseResets() {
            return moment(this.$page.props.allowanceResets.expenses)
                .add(1, "days")
                .format("ddd Do MMM YYYY");
        },
    },
};
</script>

<style scoped></style>
