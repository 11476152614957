var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fixed"},[_c('header-name',[_vm._v("Freelancers")]),_vm._v(" "),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('text-input',{attrs:{"name":"Search","clearable":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mb-6"},[_c('auto-complete',{attrs:{"name":"Filter Skills","multiple":"","items":_vm.$page.props.skills,"clearable":"","chips":"","small-chips":"","dense":"","outlined":""},model:{value:(_vm.skillsFilter),callback:function ($$v) {_vm.skillsFilter=$$v},expression:"skillsFilter"}})],1)])],1),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"ml-auto mb-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.$refs.createModal.create()}}},[_vm._v("\n            Create Freelancer\n        ")]),_vm._v(" "),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(`Download as CSV`),expression:"`Download as CSV`"}],staticClass:"ml-2",attrs:{"color":"secondary","small":""},on:{"click":_vm.downloadCsv}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1),_vm._v(" "),_c('freelancers-create-modal',{ref:"createModal"}),_vm._v(" "),_c('freelancers-delete-modal',{attrs:{"freelancer":_vm.freelancer},model:{value:(_vm.deleteModel),callback:function ($$v) {_vm.deleteModel=$$v},expression:"deleteModel"}}),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":[
            { text: '', value: 'photo' },
            { text: 'Name', value: 'name' },
            { text: 'Email', value: 'email' },
            { text: 'Telephone', value: 'tel_number' },
            { text: '', value: 'options' },
        ],"items":_vm.filteredFreelancers,"no-data-text":"No freelancers have been found","search":_vm.searchString,"sort-by":"name","footer-props":{ 'items-per-page-options': [10, 25, 50, -1] },"items-per-page":25},on:{"click:row":_vm.goToFreelancer},scopedSlots:_vm._u([{key:"item.photo",fn:function({ item }){return [_c('v-avatar',{attrs:{"size":"40px"}},[_c('v-img',{attrs:{"src":item.photo.url}})],1)]}},{key:"item.name",fn:function({ item }){return [_c('div',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('div',_vm._l((item.skills),function(skill,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"x-small":"","color":"primary","label":"","outlined":""}},[_vm._v("\n                    "+_vm._s(skill)+"\n                ")])}),1)]}},{key:"item.options",fn:function({ item }){return [_c('inertia-link',{staticClass:"inertia-link",attrs:{"href":_vm.route('staff.admin.freelancers.show', item.id)}},[_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('View Freelancer'),expression:"'View Freelancer'"}],staticClass:"mr-2",attrs:{"elevation":"2","color":"primary","icon":""}},[_c('v-icon',[_vm._v("mdi-account")])],1)],1),_vm._v(" "),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Delete Freelancer'),expression:"'Delete Freelancer'"}],staticClass:"mr-2",attrs:{"elevation":"2","color":"error","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteConfirm(item)}}},[_c('v-icon',[_vm._v("mdi-delete-alert")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }