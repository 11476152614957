export default {
    data() {
        return {
            menu: [
                {
                    title: "Info",
                    icon: "mdi-information",
                    route: "staff.info.index",
                },
                {
                    title: "Vehicle Logging",
                    icon: "mdi-clipboard-text",
                    route: "staff.vehicles.logging.index",
                },
                {
                    title: "Job Reports",
                    icon: "mdi-file-chart",
                    route: "staff.job-report.index",
                },
                {
                    title: "Print Request",
                    icon: "mdi-cloud-print",
                    route: "https://forms.gle/BhFGGBHr2oMTz37D7",
                    external: true,
                },
                {
                    title: "Set Request",
                    icon: "mdi-hammer-screwdriver",
                    route: "https://docs.google.com/forms/d/e/1FAIpQLSdAcyH74LyKF7QQft9ja8By81-9u0XLk69KIsEIWbpuwsfk3g/viewform",
                    external: true,
                },
                {
                    title: "Flightcase Req...",
                    icon: "mdi-texture-box",
                    route: "https://form.asana.com/?k=vqxOdGyJlT6SF_FqFpai_w&d=1200266718867928",
                    external: true,
                },
                {
                    title: "Accident Report",
                    icon: "mdi-book-edit",
                    route: "https://forms.gle/qHygb3FW6f1BQ2uBA",
                    external: true,
                },
                {
                    title: "Weekly Checks",
                    icon: "mdi-calendar",
                    route: "https://forms.gle/bwSpt8a1S2v8Qu5E6",
                    external: true,
                },
                {
                    title: "Prem. Damage",
                    icon: "mdi-home-city",
                    route: "https://forms.gle/xRokhGkYJP8gdExM6",
                    external: true,
                },
                {
                    title: "Suppliers",
                    icon: "mdi-domain",
                    route: "staff.suppliers.index",
                },
                {
                    title: "Media Uploads",
                    icon: "mdi-image-area",
                    route: "staff.media-uploader.index",
                },
            ],
        };
    },
    mounted() {
        this.generateMenu();
        this.$root.store.sideMenuTitle = "Things";
    },

    methods: {
        generateMenu() {
            this.$root.store.sideMenu = this.menu;
        },
    },
};
