<template>
    <modal-template v-model="modal">
        <div class="text-h6">Edit Info</div>

        <div class="text-body-1 mt-4">Edit this assets info below</div>

        <form novalidate @submit.prevent="submit">
            <validation-observer ref="form">
                <validation-provider
                    v-slot="{ errors }"
                    name="Asset Number"
                    rules="required|max:255"
                >
                    <v-text-field
                        v-model="form.asset_number"
                        name="Asset Number"
                        :counter="255"
                        :error-messages="errors"
                        outlined
                        dense
                        label="Asset Number"
                        hint="A PYTCH asset number"
                        class="required mt-2"
                        required
                    >
                    </v-text-field>
                </validation-provider>
                <validation-provider
                    v-slot="{ errors }"
                    name="Location"
                    :rules="{
                        max: { length: 255 },
                    }"
                >
                    <v-text-field
                        v-model="form.location"
                        outlined
                        dense
                        name="Lcoaiton"
                        ref="locaitonInput"
                        :counter="255"
                        :error-messages="errors"
                        label="Location"
                        hint="The location of the asset e.g. 'V-A2'"
                    >
                    </v-text-field>
                </validation-provider>
                <validation-provider
                    v-slot="{ errors }"
                    name="Serial Number"
                    rules="max:255"
                >
                    <v-text-field
                        v-model="form.serial_number"
                        name="Serial Number"
                        :counter="255"
                        :error-messages="errors"
                        outlined
                        dense
                        label="Serial Number"
                        hint="The manufactures serial number"
                        class="mt-2"
                    >
                    </v-text-field>
                </validation-provider>
            </validation-observer>
            <v-btn
                color="success"
                :loading="form.processing"
                :disabled="!form.isDirty"
                class="mt-2"
                type="submit"
            >
                Save
            </v-btn>
        </form>
    </modal-template>
</template>

<script>
import ModalTemplate from "../Master/ModalTemplate";

export default {
    name: "AssetEditInfoModal",
    components: { ModalTemplate },

    props: {
        routeName: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            modal: false,
            form: this.$inertia.form({
                asset_id: null,
                product_id: null,
                old_asset_number: null,
                asset_number: null,
                serial_number: null,
                location: "",
            }),
        };
    },

    watch: {
        modal(value) {
            if (!value) {
                this.resetForm();
            }
        },
    },

    methods: {
        open(asset) {
            this.modal = true;
            this.form = this.$inertia.form({
                asset_id: asset.id,
                product_id: asset.item_id,
                old_asset_number: asset.asset_number,
                asset_number: asset.asset_number,
                serial_number: asset.serial_number,
                location: asset.location,
            });
        },

        submit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.form.put(this.route(this.routeName), {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.resetForm();
                    },
                });
            });
        },

        resetForm() {
            this.form.reset();
            this.$refs.form?.reset();
            this.modal = false;
        },
    },

    computed: {
        stores() {
            return this.$page.props.stores;
        },
    },
};
</script>

<style scoped></style>
