<template>
    <modal-template v-model="modal">
        <div class="text-h6">Create a timesheet</div>

        <form @submit.prevent="submit" novalidate>
            <validation-observer ref="form">
                <date-time-picker
                    v-model="form.start_date"
                    label="Month"
                    name="Month"
                    mode="date"
                    :disabled-dates="{
                        days: [
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16, 17, 18, 19, 20, 22, 23, 24, 25, 26, 27, 28, 29,
                            30, 31,
                        ],
                    }"
                    required
                    timezone="Etc/UTC"
                />

                <validation-provider
                    v-slot="{ errors }"
                    name="User"
                    rules="required"
                >
                    <v-select
                        v-model="user_id"
                        :items="$page.props.allUsers"
                        item-text="name"
                        item-value="id"
                        :error-messages="errors"
                        label="User"
                        persistent-hint
                        required
                        dense
                        outlined
                    />
                </validation-provider>
            </validation-observer>

            <accent-button
                do-not-stretch
                type="submit"
                color="success"
                :loading="form.processing"
            >
                Save
            </accent-button>
        </form>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../Master/ModalTemplate.vue";
import AccentButton from "../../Buttons/AccentButton.vue";
import DateTimePicker from "../../Master/DateTimePicker.vue";
export default {
    name: "AdminTimesheetCreateModal",
    components: { DateTimePicker, AccentButton, ModalTemplate },

    data() {
        return {
            modal: false,
            user_id: null,
            form: this.$inertia.form({
                start_date: this.$page.props.currentMonth,
            }),
        };
    },

    methods: {
        open() {
            this.modal = true;
        },

        submit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.form.post(
                    this.route("staff.admin.timesheets.store", this.user_id),
                    {
                        onSuccess: () => {
                            this.modal = false;
                            this.$refs.form.reset();
                            this.form.reset();
                        },
                    }
                );
            });
        },
    },
};
</script>

<style scoped></style>
