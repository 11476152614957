export default {
    name: "AdminController",

    data() {
        return {
            menu: [
                {
                    title: "Users",
                    route: "staff.admin.users.index",
                    icon: "mdi-account",
                    permission: true,
                },
                {
                    title: "Freelancers",
                    route: "staff.admin.freelancers.index",
                    icon: "mdi-account-group",
                    permission: "staffAdminFreelancers",
                },
                {
                    title: "Logistics",
                    route: "staff.admin.logistics.index",
                    icon: "mdi-clipboard-text",
                    permission: "staffAdminLogistics",
                },
                {
                    title: "Expenses",
                    route: "staff.admin.expenses.index",
                    icon: "mdi-cash",
                    permission: "staffAdminExpenses",
                },
                {
                    title: "Timesheets",
                    route: "staff.admin.timesheets.index",
                    icon: "mdi-table",
                    permission: "staffAdminTimesheets",
                },
                {
                    title: "Holidays",
                    route: "staff.admin.holidays.index",
                    icon: "mdi-calendar-range",
                    permission: "staffAdminHolidays",
                },
                {
                    title: "Training",
                    route: "staff.admin.training.modules.index",
                    icon: "mdi-school",
                    permission: "staffAdminTraining",
                },
                {
                    title: "Vehicles",
                    route: "staff.admin.vehicles.index",
                    icon: "mdi-car-estate",
                    id: "vehicles",
                    permission: "staffAdminVehicles",
                },
                {
                    title: "Surveys",
                    route: "staff.admin.surveys.index",
                    icon: "mdi-format-list-bulleted-type",
                    id: "surveys",
                    permission: "staffAdminSurveys",
                },
                {
                    title: "Permissions",
                    route: "staff.admin.permissions.index",
                    icon: "mdi-lock-open",
                    permission: false,
                },
                {
                    title: "Categories",
                    route: "staff.admin.categories.index",
                    icon: "mdi-view-module",
                    permission: true,
                },
                {
                    title: "Screens",
                    route: "staff.admin.screens.index",
                    icon: "mdi-television",
                    permission: "staffAdminScreens",
                },
                {
                    title: "Announcements",
                    route: "staff.admin.announcements.index",
                    icon: "mdi-bullhorn-outline",
                    permission: "staffAdminAnnouncements",
                },
                {
                    title: "Visitor Logs",
                    icon: "mdi-account-group",
                    route: "staff.admin.visitor-logs.index",
                    permission: "staffAdminVisitorLogs",
                },
                {
                    title: "URL Shortener",
                    icon: "mdi-at",
                    route: "staff.admin.url-shortener.index",
                    permission: "staffAdminUrlShortener",
                },
                {
                    title: "Hire Site Categories",
                    icon: "mdi-list-status",
                    route: "staff.admin.hire-site-categories.index",
                    permission: "staffAdminHireSite",
                },
                {
                    title: "Hire Site Users",
                    icon: "mdi-account-multiple-plus",
                    route: "staff.admin.hire-site-users.index",
                    permission: "staffAdminHireSite",
                },
            ],
        };
    },
    mounted() {
        this.generateMenu();
        this.$root.store.sideMenuTitle = "Admin";
    },

    methods: {
        generateMenu() {
            let menu = [];

            if (this.$page.props.auth.user.is_super_admin) {
                this.$root.store.sideMenu = this.menu;
                return;
            }

            _.each(this.menu, (menuItem) => {
                if (menuItem.permission === true) {
                    menu.push(menuItem);
                    return true;
                }

                if (menuItem.permission === false) {
                    return true;
                }

                //Check permission
                let find = _.find(
                    this.$page.props.auth.user.permissions,
                    (permission) => {
                        return permission === menuItem.permission;
                    }
                );

                if (find !== undefined) {
                    menu.push(menuItem);
                }
            });

            this.$root.store.sideMenu = menu;
        },
    },
};
