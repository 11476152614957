var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('div',{staticClass:"d-flex mb-5"},[_c('header-name',[_vm._v("Announcements")]),_vm._v(" "),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.$refs.modal.create()}}},[_vm._v("\n                Create Announcement\n            ")])],1)],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
            { text: 'Message', value: 'message' },
            { text: 'From', value: 'user.name' },
            { text: 'Notification Sent', value: 'notify' },
            { text: 'Expires At', value: 'expires_at' },
            { text: '', value: 'options' },
        ],"items":_vm.$page.props.announcements,"sort-by":"expires_at","sort-desc":""},scopedSlots:_vm._u([{key:"item.notify",fn:function({ value }){return [_c('div',[(value)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e()],1)]}},{key:"item.expires_at",fn:function({ value }){return [_c('div',[_vm._v("\n                "+_vm._s(_vm.momentFormatDateTimeYear(value))+"\n            ")])]}},{key:"item.options",fn:function({ item }){return [_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Edit'),expression:"'Edit'"}],staticClass:"mr-2",attrs:{"elevation":"2","color":"primary","icon":""},on:{"click":function($event){return _vm.$refs.modal.edit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_vm._v(" "),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Delete'),expression:"'Delete'"}],staticClass:"mr-2",attrs:{"elevation":"2","color":"error","icon":""},on:{"click":function($event){return _vm.deleteAnnouncement(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_vm._v(" "),_c('announcement-modal',{ref:"modal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }