<template>
    <modal-template v-model="modal">
        <div class="text-h6 text--secondary mb-2 mt-2">
            {{ form.id ? "Edit" : "Create" }} Shortened URL
        </div>
        <v-slide-x-transition mode="out-in">
            <div v-if="step === 1" key="step1">
                <form novalidate @submit.prevent="submit">
                    <validation-observer ref="form">
                        <v-row dense>
                            <v-col cols="12">
                                <text-input
                                    name="Name"
                                    v-model="form.name"
                                    required
                                    rules="required|max:255"
                                    counter="255"
                                    autofocus
                                />
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12">
                                <text-input
                                    name="Redirect URL"
                                    v-model="form.redirect_url"
                                    required
                                    rules="required|max:2000|urlRegex"
                                    counter="2000"
                                />
                            </v-col>
                        </v-row>
                    </validation-observer>

                    <v-btn
                        color="success"
                        type="submit"
                        class="mt-6"
                        :loading="form.processing"
                    >
                        Save
                    </v-btn>
                </form>
            </div>
            <div v-if="step === 2" key="step2">
                <h3 class="text-h6">
                    Your new shortened URL is:
                    <a
                        class="text-bold text-h5"
                        style="cursor: pointer"
                        @click="copyWithNotification(fullUrl)"
                        >{{ fullUrl }}</a
                    >
                </h3>
            </div>
        </v-slide-x-transition>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../../../../Comps/Master/ModalTemplate.vue";
import TextInput from "../../../../../Comps/Forms/TextInput.vue";
import UrlRegexRule from "../../../../../Mixins/UrlRegexRule.js";
import CopyToClipboard from "../../../../../Mixins/CopyToClipboard.js";

export default {
    name: "ShortenedUrlModal",
    components: { TextInput, ModalTemplate },
    mixins: [UrlRegexRule, CopyToClipboard],

    data() {
        return {
            modal: false,
            form: this.$inertia.form({}),
            step: 1,
            fullUrl: null,
        };
    },

    watch: {
        modal(value) {
            if (!value) {
                this.step = 1;
                this.fullUrl = null;
            }
        },
    },

    methods: {
        create() {
            this.form = this.$inertia.form({
                name: "",
                redirect_url: "",
            });

            this.modal = true;
        },
        edit(url) {
            this.form = this.$inertia.form(url);
            this.modal = true;
        },

        async submit() {
            if (!(await this.$refs.form.validate())) {
                this.$toast.error("Please check for errors", {
                    duration: 5000,
                });

                return;
            }

            if (this.form.id) {
                this.form.put(
                    this.route(
                        "staff.admin.url-shortener.update",
                        this.form.id
                    ),
                    {
                        onSuccess: () => {
                            this.modal = false;
                        },
                    }
                );
                return;
            }

            this.form.processing = true;

            this.$http
                .post(this.route("staff.admin.url-shortener.store"), this.form)
                .then((response) => {
                    this.step = 2;
                    this.fullUrl = response.data.url;
                })
                .catch((error) => {
                    this.$page.props.flash.errors = error.response.data.errors;
                })
                .finally(() => {
                    this.form.processing = false;
                });
        },
    },
};
</script>

<style scoped></style>
