<template>
    <v-app>
        <v-container>
            <v-sheet elevation="2" class="pa-8" rounded>
                <slot></slot>
            </v-sheet>
        </v-container>
    </v-app>
</template>

<script>
export default {
    name: "SecureLayout",
};
</script>

<style scoped></style>
