<template>
    <div>
        <v-alert
            v-if="timesheet.submitted_on"
            border="top"
            color="green"
            outlined
            type="success"
            class="mt-4"
        >
            <div class="d-flex">
                <div class="mr-1">Submitted At:</div>
                <div class="font-weight-bold">
                    {{ momentFormatDateTimeYear(timesheet.submitted_on) }}
                </div>
            </div>
        </v-alert>

        <v-sheet elevation="2" class="pa-4">
            <div
                class="d-flex flex-wrap flex-column flex-md-row align-md-center align-start"
            >
                <timesheet-status-chip large :status="timesheet.status" />

                <v-chip label class="ml-md-2 mt-2 mt-md-0">
                    Total Pay: £{{ formatMoney(timesheet.totals.total_pay) }}
                </v-chip>

                <v-chip
                    v-if="!isOverdue && !timesheet.submitted_on"
                    label
                    class="ml-md-2 mt-2 mt-md-0 green--text"
                >
                    Time to Submit: {{ formattedDuration }}
                </v-chip>

                <v-chip
                    v-if="isOverdue && !timesheet.submitted_on"
                    label
                    class="ml-md-2 mt-2 mt-md-0 red--text mr-2"
                >
                    Overdue By: {{ formattedOverdue }}
                </v-chip>

                <div
                    class="mt-2 mt-md-0 ml-md-auto d-flex flex-column align-start align-md-end"
                >
                    <v-btn
                        v-if="timesheet.status === 'Open'"
                        @click="submit"
                        small
                        color="warning"
                        :disabled="!canSubmit"
                        v-tooltip="'tool'"
                    >
                        Submit Timesheet
                    </v-btn>
                    <div class="text-caption text--secondary" v-if="!canSubmit">
                        You can submit this timesheet from
                        {{ this.momentFormatDateTimeYear(submissionOpensAt) }}
                    </div>
                </div>
            </div>
        </v-sheet>

        <v-menu offset-y eager v-if="admin">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    small
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    class="mt-6"
                    :disabled="timesheet.status === 'Approved'"
                >
                    Actions
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-list dense>
                <v-list-item
                    v-if="timesheet.status === 'Submitted'"
                    @click="approve"
                >
                    <v-list-item-title class="success--text">
                        Approve
                    </v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="deny"
                    v-if="timesheet.status === 'Submitted'"
                >
                    <v-list-item-title class="warning--text">
                        Deny
                    </v-list-item-title>
                </v-list-item>
                <v-list-item @click="editRates">
                    <v-list-item-title class="secondary--text">
                        Edit Rates
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-sheet class="my-8">
            <v-row>
                <v-col cols="12" md="6" order-md="last" class="text-right">
                    <v-btn
                        v-if="timesheet.status === 'Open'"
                        @click="$refs.createModal.create()"
                        small
                        class="my-2"
                        color="success"
                    >
                        Add Line
                    </v-btn>
                </v-col>
                <v-col cols="12" md="6" class="d-flex align-end">
                    Timesheet Lines
                </v-col>
            </v-row>

            <!--Shifts-->
            <timesheet-lines-table
                :timesheet="timesheet"
                :admin="admin"
                @editLine="editLine"
            />
        </v-sheet>

        <!--Holiday-->
        <v-sheet class="my-8">
            <div>Approved Holiday</div>

            <timesheet-holiday-lines />
        </v-sheet>

        <!--Totals-->
        <v-sheet class="my-8">
            <div>Totals</div>
            <timesheet-totals :timesheet="timesheet" />
        </v-sheet>
        <timesheet-line-create-modal ref="createModal" :admin="admin" />
        <timesheet-deny-modal ref="denyModal" />
        <timesheet-earning-rate-modal ref="earningModal" />
    </div>
</template>

<script>
import TimesheetLineCreateModal from "./TimesheetLineCreateModal.vue";
import TimesheetDenyModal from "./TimesheetDenyModal.vue";
import TimesheetEarningRateModal from "./TimesheetEarningRateModal.vue";
import TimesheetStatusChip from "./TimesheetStatusChip.vue";
import Permissions from "../../../Mixins/Permissions.js";
import TimeNow from "../../../Mixins/TimeNow.js";
import TimesheetTotals from "./TimesheetTotals.vue";
import TimesheetLinesTable from "./TimesheetLinesTable.vue";
import TimesheetHolidayLines from "./TimesheetHolidayLines.vue";
export default {
    name: "TimesheetShow",
    components: {
        TimesheetHolidayLines,
        TimesheetLinesTable,
        TimesheetTotals,
        TimesheetStatusChip,
        TimesheetEarningRateModal,
        TimesheetDenyModal,
        TimesheetLineCreateModal,
    },

    mixins: [Permissions, TimeNow],

    methods: {
        submit() {
            if (!confirm("Are you sure you want to submit this timesheet?")) {
                return;
            }

            this.$inertia.put(
                this.route("staff.timesheets.submit", this.timesheet)
            );
        },

        approve() {
            if (!confirm("Are you sure you want to approve this timesheet?")) {
                return;
            }

            this.$inertia.put(
                this.route("staff.admin.timesheets.approve", this.timesheet)
            );
        },

        editRates() {
            this.$refs.earningModal.open();
        },

        deny() {
            this.$refs.denyModal.open(this.timesheet);
        },

        editLine(line) {
            this.$refs.createModal.edit(line);
        },
    },

    computed: {
        admin() {
            return this.hasPermission("staffAdminTimesheets");
        },

        timesheet() {
            return this.$page.props.timesheet;
        },

        breadItems() {
            return [
                {
                    text: "Timesheets",
                    disabled: false,
                    link: this.route("staff.timesheets.index"),
                },
                {
                    text: `Timesheet ${this.momentFormatDateYear(
                        this.$page.props.timesheet.start_date
                    )}`,
                    disabled: true,
                },
            ];
        },

        dueDifference() {
            return this.moment(this.$page.props.timesheetPeriod.end_date).diff(
                this.timeNow
            );
        },

        submissionOpensAt() {
            return this.moment(
                this.$page.props.timesheetPeriod.end_date
            ).subtract(2, "days");
        },

        canSubmit() {
            return this.timeNow.isSameOrAfter(this.submissionOpensAt);
        },

        isOverdue() {
            return this.dueDifference < 0;
        },

        duration() {
            return this.moment.duration(this.dueDifference);
        },

        formattedDuration() {
            if (
                this.duration.asMilliseconds() >
                this.moment.duration(1, "day").asMilliseconds()
            ) {
                return this.duration.humanize({ w: 8 });
            }

            return this.moment
                .utc()
                .startOf("day")
                .add(this.dueDifference, "milliseconds")
                .format("HH:mm:ss");
        },

        formattedOverdue() {
            if (
                Math.abs(this.duration.asMilliseconds()) >
                this.moment.duration(1, "day").asMilliseconds()
            ) {
                return this.duration.humanize({ w: 8 });
            }

            return this.moment
                .utc()
                .startOf("day")
                .subtract(this.dueDifference, "milliseconds")
                .format("HH:mm:ss");
        },

        isPaye() {
            return this.timesheet.pay_type === "PAYE";
        },
    },
};
</script>

<style scoped></style>
