<template>
    <v-app>
        <v-main>
            <head-title title="Error" />
            <v-container class="fixed">
                <div class="mt-8">
                    <v-card class="text-center">
                        <v-card-title class="d-block primary--text text-h4">{{
                            title
                        }}</v-card-title>
                        <v-card-text>{{ description }}</v-card-text>
                    </v-card>
                </div>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import HeadTitle from "../Comps/Master/HeadTitle";
export default {
    components: { HeadTitle },
    layout: null,
    props: {
        status: Number,
    },
    computed: {
        title() {
            return {
                503: "503: Service Unavailable",
                500: "500: Server Error",
                404: "404: Page Not Found",
                403: "403: Forbidden",
            }[this.status];
        },
        description() {
            return {
                503: "Sorry, we are doing some maintenance. Please check back soon.",
                500: "Whoops, something went wrong on our servers.",
                404: "Sorry, the page you are looking for could not be found.",
                403: "Sorry, you are forbidden from accessing this page.",
            }[this.status];
        },
    },
};
</script>
