<template>
    <v-row class="hide-scrollbar">
        <v-col v-for="group in groups" :key="group.title" cols="12" sm="4">
            <v-sheet
                elevation="4"
                class="pa-2 mb-4 d-flex align-center"
                rounded
                style="position: sticky; border-top: 3px solid; z-index: 1"
                :style="{ 'border-color': group.color, top: stickyTop }"
            >
                <span v-text="group.title" class="mr-2 font-weight-medium" />
                <v-chip class="ml-auto" outlined>
                    {{ group.jobs.length }}
                </v-chip>
            </v-sheet>
            <job-tile
                show-links
                v-for="job in group.jobs"
                :job="job"
                :key="job.id"
            />
        </v-col>
    </v-row>
</template>

<script>
import JobTile from "./JobTile.vue";

export default {
    name: "JobOverviewColumns",
    components: { JobTile },
    props: {
        stickyTop: {
            type: String,
            default: "0",
        },
    },

    computed: {
        groups() {
            return [
                {
                    title: "To Prep",
                    jobs: this.toPrep,
                    color: "#e97000",
                },
                {
                    title: "Preparing",
                    jobs: this.preparing,
                    color: "#b78404",
                },
                {
                    title: "To Deprep",
                    jobs: this.toDeprep,
                    color: "#0056d9",
                },
            ];
        },
        jobs() {
            return _.sortBy(this.$page.props.companyJobs, "starts_at");
        },

        toPrep() {
            return this.jobs.filter(
                (job) => job.status < 20 && !job.needing_load && !job.prepared
            );
        },

        preparing() {
            return _.sortBy(
                this.jobs.filter(
                    (job) =>
                        job.status < 20 && (job.needing_load || job.prepared)
                ),
                "prepared"
            );
        },

        toDeprep() {
            return this.jobs.filter(
                (job) =>
                    job.status === 20 &&
                    (this.moment().endOf("day").isSameOrAfter(job.ends_at) ||
                        this.moment()
                            .endOf("day")
                            .isSameOrAfter(job.deprep_starts_at))
            );
        },
    },
};
</script>

<style>
body::-webkit-scrollbar,
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}
</style>
