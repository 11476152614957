<template>
    <v-container class="fixed">
        <v-row class="justify-center">
            <v-col cols="12" md="8">
                <header-name>PAT Records</header-name>
                <div class="body-1 text--secondary">
                    Search for an assets records in the database
                </div>

                <v-sheet class="pa-6 mt-4" elevation="2">
                    <validation-observer ref="assetSearch">
                        <form @submit.prevent="searchAsset" novalidate>
                            <validation-provider
                                v-slot="{ errors }"
                                name="Asset"
                                rules="required|max:255"
                            >
                                <v-text-field
                                    v-model="asset"
                                    name="Asset"
                                    :error-messages="errors"
                                    :loading="isSearching"
                                    label="Asset"
                                    hint="Scan or type an asset number and press enter"
                                    persistent-hint
                                    autofocus
                                    class="required"
                                    required
                                >
                                </v-text-field>
                            </validation-provider>

                            <v-btn
                                color="success"
                                type="submit"
                                small
                                class="mt-8"
                                :loading="isSearching"
                            >
                                Search
                            </v-btn>
                        </form>
                    </validation-observer>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ProcessesController from "../../../Mixins/ProcessesController";
import HeaderName from "../../../Comps/General/HeaderName";
import { required, max } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", required);
extend("max", max);

export default {
    name: "PatSearch",
    components: { HeaderName },
    mixins: [ProcessesController],

    data() {
        return {
            asset: "",
            isSearching: false,
        };
    },

    methods: {
        searchAsset() {
            this.$refs.assetSearch.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.isSearching = true;

                this.$inertia.visit(this.route("staff.pat.show", this.asset));
            });
        },
    },
};
</script>

<style scoped></style>
