<template>
    <v-list-item :value="user">
        <template v-slot:default="{ active }">
            <v-list-item-content>
                <v-list-item-title>
                    {{ user.name }} {{ user.freelancer ? " (F)" : "" }}
                </v-list-item-title>
                <v-list-item-subtitle
                    class="success--text"
                    v-if="!hasClash && !hasHolidayClash"
                >
                    No clashes
                </v-list-item-subtitle>
                <v-list-item-subtitle
                    style="white-space: normal"
                    class="error--text"
                    v-if="hasClash"
                >
                    <div>
                        This user is already assigned to task
                        <span class="font-weight-bold">
                            {{ hasClash.name }}
                        </span>
                    </div>
                </v-list-item-subtitle>
                <v-list-item-subtitle
                    style="white-space: normal"
                    class="warning--text"
                    v-if="!hasClash && hasHolidayClash"
                >
                    <div>This user has a holiday booked on this day</div>
                </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <div>
                    <v-btn small icon :color="active ? 'amber' : ''">
                        <v-icon>mdi-crown</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        @click.stop="$emit('remove')"
                        small
                        color="error"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </v-list-item-action>
        </template>
    </v-list-item>
</template>

<script>
import ClashFinder from "../../../../Mixins/Logistics/ClashFinder";

export default {
    name: "TaskUsersPanelUser",
    props: {
        user: {
            type: Object,
            required: true,
        },
        task: {
            type: Object,
            required: true,
        },
    },

    mixins: [ClashFinder],
    data() {
        return {
            hasClash: null,
            hasHolidayClash: null,
        };
    },

    watch: {
        task: {
            immediate: true,
            deep: true,
            handler() {
                this.triggerFindClash();
            },
        },
    },

    computed: {
        usersOtherTasks() {
            let thisTaskTime = this.moment(this.task.date).utc(true);
            if (!this.user.freelancer) {
                return _.filter(this.$page.props.allTasks, (task) => {
                    return (
                        _.find(task.users, { id: this.user.id }) &&
                        this.moment(task.date).isSame(thisTaskTime)
                    );
                });
            }

            return _.filter(this.$page.props.allTasks, (task) => {
                return (
                    _.find(task.freelancers, { id: this.user.id }) &&
                    this.moment(task.date).isSame(thisTaskTime)
                );
            });
        },
    },

    methods: {
        triggerFindClash() {
            this.hasClash = this.findClash(
                this.usersOtherTasks,
                this.task.time_from_original,
                this.task.time_to_original
            );
            if (!this.user.freelancer) {
                this.hasHolidayClash = this.findHolidayClashForUser(
                    this.task,
                    this.user
                );
            }
        },
    },
};
</script>

<style lang="scss">
.tooltip-task-user {
    max-width: 150px;
}
</style>
