<template>
    <v-container class="fixed">
        <v-row>
            <v-col cols="12" md="6">
                <div class="text-h5 text--secondary mb-2">My Details</div>

                <div class="mb-4 text-body-1">
                    The details below are synced with your PYTCH Google account
                </div>

                <v-card>
                    <v-card-text>
                        <UserDetails
                            :user="this.$page.props.auth.user"
                        ></UserDetails>
                    </v-card-text>
                </v-card>
            </v-col>

            <!--Password-->
            <v-col cols="12" md="6">
                <div class="text-h5 text--secondary mb-2">Change Password</div>
                <div class="mb-4 text-body-1">
                    This changes your PYTCH Google account password
                </div>
                <v-card>
                    <v-card-text>
                        <form @submit.prevent="changePassword">
                            <validation-observer ref="form">
                                <v-row>
                                    <v-col>
                                        <validation-provider
                                            v-slot="{ errors }"
                                            name="New Password"
                                            rules="required|min:8"
                                        >
                                            <v-text-field
                                                v-model="newPassword"
                                                type="password"
                                                :error-messages="errors"
                                                label="New Password"
                                                outlined
                                                dense
                                                hint="Your new password"
                                                required
                                                autocomplete="new-password"
                                            >
                                            </v-text-field>
                                        </validation-provider>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <validation-provider
                                            v-slot="{ errors }"
                                            name="New Password Confirm"
                                            rules="required|min:8"
                                        >
                                            <v-text-field
                                                v-model="newPasswordConfirm"
                                                type="password"
                                                outlined
                                                dense
                                                :error-messages="errors"
                                                label="Confirm Password"
                                                hint="Confirm your new password"
                                                required
                                                autocomplete="new-password"
                                            >
                                            </v-text-field>
                                        </validation-provider>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-btn
                                            color="success"
                                            type="submit"
                                            :loading="loading"
                                        >
                                            Change Password
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </validation-observer>
                        </form>
                    </v-card-text>
                </v-card>
            </v-col>

            <!--Personal Details-->
            <v-col cols="12">
                <div class="text-h5 text--secondary mb-2">Personal Details</div>

                <div class="mb-4 text-body-1">
                    The details below are only visible to you, and the HR
                    department. Please contact HR to update them.
                </div>

                <personal-details-viewer
                    :details="$page.props.personalDetails"
                />
            </v-col>

            <!--HR Files-->
            <v-col cols="12">
                <div class="text-h5 text--secondary mb-2">HR Files</div>

                <div class="mb-4 text-body-1">
                    The files below are only visible to you, and the HR
                    department.
                </div>

                <v-sheet elevation="2" class="pa-2" rounded>
                    <hr-files-table
                        :items="$page.props.hrFiles"
                        :user="$page.props.auth.user"
                    />
                </v-sheet>
            </v-col>

            <!--Performance Logs-->
            <v-col cols="12">
                <div class="text-h5 text--secondary mb-2">Performance Logs</div>

                <performance-logs-table :items="$page.props.performanceLogs" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import HeaderName from "../../../Comps/General/HeaderName";
import UserDetails from "../../../Comps/Staff/Personal/UserDetails";
import { required, min } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import OtherController from "../../../Mixins/OtherController";
import PersonalDetailsViewer from "../../../Comps/Staff/Personal/PersonalDetailsViewer";
import HrFilesTable from "../../../Comps/Staff/Personal/HrFilesTable";
import PerformanceLogsTable from "../../../Comps/Staff/Personal/PerformanceLogsTable";

extend("required", required);
extend("min", min);

export default {
    name: "MyDetails",
    components: {
        PerformanceLogsTable,
        HrFilesTable,
        PersonalDetailsViewer,
        UserDetails,
        HeaderName,
    },
    mixins: [OtherController],

    data() {
        return {
            newPassword: "",
            newPasswordConfirm: "",
            loading: false,
        };
    },

    mounted() {
        this.$root.store.title = "My Profile";
    },

    methods: {
        changePassword() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                //Passwords have to be the same
                if (this.newPassword !== this.newPasswordConfirm) {
                    this.$toast.error("Passwords do not match", {
                        duration: 5000,
                    });
                    return;
                }

                this.loading = true;

                this.$inertia.post(
                    this.route(
                        "staff.myDetails.changePassword",
                        this.$page.props.auth.user
                    ),
                    {
                        new_password: this.newPassword,
                    },
                    {
                        onSuccess: () => {
                            this.newPassword = this.newPasswordConfirm = "";
                            this.$refs.form.reset();
                        },
                        onFinish: () => {
                            this.loading = false;
                        },
                    }
                );
            });
        },
    },
};
</script>
