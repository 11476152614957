<template>
    <v-container class="fixed">
        <breadcrumbs :items="bread"></breadcrumbs>
        <header-name>{{ permission.long_name }}</header-name>

        <v-card class="pa-3">
            <v-checkbox
                label="Select All"
                :input-value="data.length >= users.length"
                :indeterminate="data.length >= 1 && data.length < users.length"
                @change="changeAll"
                class="ml-4"
            ></v-checkbox>

            <v-virtual-scroll
                bench="2"
                :items="users"
                height="600"
                item-height="50"
            >
                <template v-slot="{ item }">
                    <v-list-item :key="item.id">
                        <v-switch
                            v-model="data"
                            :value="item.id"
                            :label="item.name"
                        ></v-switch>
                    </v-list-item>
                </template>
            </v-virtual-scroll>

            <v-btn
                @click.prevent="storePermission"
                :loading="permissionSubmit"
                class="mt-5"
                color="success"
            >
                Save
            </v-btn>
        </v-card>
    </v-container>
</template>

<script>
import HeaderName from "../../../../Comps/General/HeaderName";
import Breadcrumbs from "../../../../Comps/General/Breadcrumbs";
import AdminController from "../../../../Mixins/AdminController";

export default {
    name: "PermissionsShow",
    components: { Breadcrumbs, HeaderName },
    props: ["permission", "users"],
    mixins: [AdminController],

    data() {
        return {
            bread: [
                {
                    text: "Permissions",
                    disabled: false,
                    link: this.route("staff.admin.permissions.index"),
                },
                {
                    text: this.permission.long_name,
                    disabled: true,
                },
            ],
            data: _.map(this.permission.users, (user) => {
                return user.id;
            }),
            permissionSubmit: false,
            selectAll: false,
        };
    },

    methods: {
        storePermission() {
            this.permissionSubmit = true;
            this.$inertia.put(
                this.route(
                    "staff.admin.permissions.update",
                    this.permission.id
                ),
                {
                    users: this.data,
                },
                {
                    onFinish: () => {
                        this.permissionSubmit = false;
                    },
                }
            );
        },
        changeAll(e) {
            if (e) {
                this.data = _.map(this.users, (user) => {
                    return user.id;
                });
            } else {
                this.data = [];
            }
        },
    },
};
</script>

<style scoped></style>
