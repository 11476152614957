<template>
    <div
        class="d-flex align-center ma-2 flex-column flex-sm-row"
        style="min-height: 40px"
    >
        <v-btn-toggle v-model="typeComputed" color="primary" mandatory>
            <v-btn value="week" small>Week</v-btn>
            <v-btn value="day" small>Day</v-btn>
        </v-btn-toggle>

        <v-chip-group
            class="ml-2"
            v-model="dayComputed"
            mandatory
            active-class="blue--text"
            v-if="type === 'day'"
        >
            <v-chip
                small
                v-for="day in days"
                :key="day.format('YYYY-MM-DD')"
                :value="day.format('YYYY-MM-DD')"
            >
                {{ day.format("ddd") }}
            </v-chip>
        </v-chip-group>
    </div>
</template>

<script>
import LocalStorage from "../../../Mixins/LocalStorage.js";

export default {
    name: "LogisticsWeekDaySelector",

    mixins: [LocalStorage],

    props: {
        type: {
            type: String,
            required: true,
        },
        day: {
            required: true,
        },
        thisWeek: {
            type: Object,
            required: true,
        },
    },

    watch: {
        day(val) {
            this.localStorageSet("logistics-gantt-day-selected", val);
        },
        type(val) {
            this.localStorageSet("logistics-gantt-type-selected", val);
        },
    },

    mounted() {
        const type = this.localStorageGet("logistics-gantt-type-selected");

        if (type) {
            this.typeComputed = type;
        }

        const day = this.localStorageGet("logistics-gantt-day-selected");

        setTimeout(() => {
            if (
                day &&
                this.days.some((moment) => moment.format("YYYY-MM-DD") === day)
            ) {
                this.dayComputed = day;
            }
        }, 200);
    },

    computed: {
        typeComputed: {
            get() {
                return this.type;
            },
            set(value) {
                this.$emit("update:type", value);
            },
        },

        dayComputed: {
            get() {
                return this.day;
            },
            set(value) {
                this.$emit("update:day", value);
            },
        },

        days() {
            const days = [];

            for (let i = 0; i < 7; i++) {
                days.push(this.thisWeek.clone().add(i, "days"));
            }

            return days;
        },
    },
};
</script>

<style scoped></style>
