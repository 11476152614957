<template>
    <v-container>
        <header-name>Media Uploader</header-name>
        <div>Search for a job to upload media:</div>
        <div class="mt-4">
            <autocomplete-search-current
                v-model="company_job"
                dense
                outlined
                hide-icon
                clearable
                route-name="staff.current.search-all-live-jobs-past-three"
            />
        </div>

        <div v-if="company_job">
            <div class="d-flex">
                <accent-button @click="$refs.fileInput.click()">
                    <v-icon>mdi-paperclip</v-icon> Upload media for
                    {{ company_job.name }}
                </accent-button>
            </div>
        </div>
        <input
            hidden
            type="file"
            multiple
            ref="fileInput"
            @change="processFiles"
            accept=".mov,.mp4,.png,.jpg,.jpeg"
        />
        <modal-template v-model="showModal">
            <div class="text-h6 mb-6">
                Media Upload for {{ company_job?.name }}

                <v-chip v-if="files.length > 1" class="ml-4" small>
                    {{ fileIndex + 1 }} of {{ files.length }}
                </v-chip>
            </div>
            <div v-if="uploadProgress < 100">
                Uploading...
                <v-progress-linear v-model="uploadProgress" height="25">
                    <strong class="white--text">
                        {{ Math.ceil(uploadProgress) }}%
                    </strong>
                </v-progress-linear>
            </div>

            <div v-if="uploadProgress === 100">
                <display-raw-file
                    v-if="uploadedFile.url"
                    class="ma-2"
                    :file="uploadedFile"
                    max-width="100%"
                    max-height="400px"
                />
                <form @submit.prevent="savePhoto">
                    <validation-observer ref="validation">
                        <text-input
                            autofocus
                            name="Description"
                            required
                            rules="required"
                            hint="Enter a description of the equipment or process shown in this item, so that it can be searched for in the future."
                            persistent-hint
                            v-model="form.description"
                        />
                        <div class="d-flex">
                            <v-btn
                                :disabled="form.processing"
                                class="mr-1"
                                @click="nextPhoto"
                            >
                                Skip Item
                            </v-btn>
                            <v-btn
                                type="submit"
                                :loading="form.processing"
                                class="ml-auto"
                                color="success"
                            >
                                Save
                            </v-btn>
                        </div>
                    </validation-observer>
                </form>
            </div>
        </modal-template>
    </v-container>
</template>

<script>
import { defineComponent } from "vue";
import HeaderName from "../../../Comps/General/HeaderName.vue";
import AutocompleteSearchCurrent from "../../../Comps/General/AutocompleteSearchCurrent.vue";
import AccentButton from "../../../Comps/Buttons/AccentButton.vue";
import Vapor from "laravel-vapor";
import FileHelpers from "../../../Mixins/FileHelpers.js";
import ModalTemplate from "../../../Comps/Master/ModalTemplate.vue";
import DisplayRawFile from "../../../Comps/General/DisplayRawFile.vue";
import TextInput from "../../../Comps/Forms/TextInput.vue";
import ThingsController from "../../../Mixins/ThingsController.js";

export default defineComponent({
    name: "MediaUploader",

    components: {
        TextInput,
        DisplayRawFile,
        ModalTemplate,
        AccentButton,
        AutocompleteSearchCurrent,
        HeaderName,
    },

    mixins: [FileHelpers, ThingsController],

    data() {
        return {
            company_job: null,
            files: [],
            fileIndex: 0,
            uploadProgress: 0,
            uploadedFile: {},
            showModal: false,
            form: this.$inertia.form({
                description: "",
                company_job_id: "",
            }),
        };
    },

    methods: {
        processFiles() {
            this.files = this.$refs.fileInput.files;
            this.fileIndex = 0;

            this.uploadFile(this.files[this.fileIndex]);
        },

        async uploadFile(file) {
            this.uploadProgress = 0;
            this.uploadedFile = {};
            this.showModal = true;

            const upload = await Vapor.store(file, {
                progress: (p) => {
                    this.uploadProgress = p * 100;
                },
                bucket: process.env.MIX_AWS_UPLOADS_BUCKET,
                visibility: "public-read",
            });

            this.uploadedFile = {
                tmp: upload.key,
                url: upload.url.split("?")[0],
                name: file.name,
                extension: this.getExtension(file),
            };

            this.uploadProgress = 100;
        },

        async savePhoto() {
            if (!(await this.$refs.validation.validate())) {
                return;
            }

            this.form
                .transform((data) => ({
                    tmp: this.uploadedFile.tmp,
                    extension: this.uploadedFile.extension,
                    type: this.getFileType(this.uploadedFile.extension),
                    ...data,
                }))
                .post(
                    this.route(
                        "staff.media-uploader.store",
                        this.company_job.id
                    ),
                    {
                        preserveScroll: true,
                        onSuccess: () => {
                            this.form.reset();
                            this.nextPhoto();
                        },
                    }
                );
        },

        nextPhoto() {
            if (this.fileIndex < this.files.length - 1) {
                this.fileIndex++;
                this.uploadFile(this.files[this.fileIndex]);
            } else {
                this.showModal = false;
            }
        },
    },
});
</script>

<style scoped></style>
