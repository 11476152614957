import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    breakpoint: {
        mobileBreakpoint: "sm", // This is equivalent to a value of 960
    },
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: "#000000",
                amber: "#FFBF00",
            },
            dark: {
                primary: "#FFBF00",
                grey: {
                    darken3: "#303030",
                },
            },
        },
    },
});
