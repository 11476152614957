<template>
    <modal-template v-model="show">
        <div class="text-h6 text--secondary mb-2 mt-2">
            <span v-if="!edit">Run a new session</span>
            <span v-else>Edit session</span>
        </div>

        <form>
            <validation-observer ref="form">
                <v-row>
                    <v-col cols="12">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Users"
                            rules="required|max:255"
                        >
                            <v-autocomplete
                                :items="users"
                                small-chips
                                multiple
                                item-text="name"
                                item-value="id"
                                v-model="usersSelect"
                                :error-messages="errors"
                                label="Users"
                                hint="The users who participated in this training session"
                                persistent-hint
                                required
                                dense
                                outlined
                                @change="updateUsers"
                            >
                            </v-autocomplete>
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-left">User</th>
                                    <th class="text-left">Pass/Fail</th>
                                    <th class="text-right">
                                        External Certificate
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in session.users"
                                    :key="item.id"
                                >
                                    <td>{{ item.user.name }}</td>
                                    <td>
                                        <v-switch
                                            v-model="item.passed"
                                            class="ml-2"
                                            color="success"
                                            label="Passed"
                                        />
                                    </td>
                                    <td>
                                        <div class="d-flex justify-end">
                                            <training-session-modal-user-certificate
                                                v-model="session.users"
                                                :user="item"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <div
                            v-if="!session.users.length"
                            class="text-h6 text-center text--secondary mt-2"
                        >
                            No users selected
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="6">
                        <v-switch
                            label="External Instructor"
                            v-model="externalInstructor"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Instructor"
                            rules="required"
                        >
                            <v-select
                                v-if="!externalInstructor"
                                v-model="session.instructor_id"
                                :items="users"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                label="Instructor"
                                hint="Select an instructor"
                                persistent-hint
                                required
                                dense
                                outlined
                            />
                            <v-text-field
                                v-if="externalInstructor"
                                v-model="session.instructor_name"
                                label="Instructor Name"
                                required
                                persistent-hint
                                hint="The Name of the Instructor"
                                dense
                                outlined
                            />
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="6">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Module"
                            rules="required"
                        >
                            <v-select
                                v-model="session.training_module_id"
                                :disabled="!!edit"
                                :items="modules"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                label="Module"
                                hint="Select a module"
                                :persistent-hint="true"
                                required
                                dense
                                outlined
                            >
                            </v-select>
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="6">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Training location"
                            rules="required|max:255"
                        >
                            <v-text-field
                                v-model="session.location"
                                :counter="255"
                                :error-messages="errors"
                                label="Training location"
                                hint="The location of the training"
                                persistent-hint
                                required
                                dense
                                outlined
                            >
                            </v-text-field>
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Followup tasks"
                            rules="max:1000"
                        >
                            <v-textarea
                                v-model="session.followup"
                                :counter="1000"
                                :error-messages="errors"
                                label="Followup Tasks"
                                hint="Followup tasks"
                                :persistent-hint="true"
                                dense
                                outlined
                            >
                            </v-textarea>
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="6">
                        <date-picker
                            v-model="session.completed_at"
                            name="Date Completed"
                            hint="The date this session happened"
                            dense
                            outlined
                        >
                        </date-picker>
                    </v-col>
                </v-row>
            </validation-observer>

            <v-row>
                <v-col>
                    <v-btn
                        color="success"
                        @click.prevent="submit"
                        :loading="session.processing"
                    >
                        {{ edit ? "Update" : "Create" }}
                    </v-btn>
                </v-col>
            </v-row>
        </form>
    </modal-template>
</template>

<script>
import { required, email, max, numeric } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import ModalTemplate from "../../Master/ModalTemplate";
import DatePicker from "../../Master/DatePicker";
import TrainingSessionModalUserCertificate from "./TrainingSessionModalUserCertificate";

extend("required", required);
extend("max", max);
extend("email", email);
extend("numeric", numeric);

export default {
    name: "SessionsCreateModal",
    components: {
        TrainingSessionModalUserCertificate,
        DatePicker,
        ModalTemplate,
    },
    props: {
        edit: {
            type: Object,
            default: null,
        },
        users: {
            type: Array,
            required: true,
        },
        modules: {
            type: Array,
            required: true,
        },
        value: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            session: this.$inertia.form({
                users: [],
            }),
            usersSelect: [],
            externalInstructor: false,
        };
    },

    watch: {
        externalInstructor(value) {
            if (value) {
                this.session.instructor_id = "";
                return;
            }

            this.session.instructor_name = "";
        },

        show(value) {
            if (value) {
                this.$nextTick(() => {
                    if (this.edit) {
                        this.mountedEdit();
                    }
                });
            }
        },
    },

    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },

    methods: {
        mountedEdit() {
            this.reset();

            const session = _.cloneDeep(this.edit);

            this.externalInstructor = !session.instructor_id;

            session.users = session.users.map((user) => {
                user.certificate = null;
                this.usersSelect.push(user.user_id);

                return user;
            });

            this.session = this.$inertia.form(session);
        },

        createSession(moduleId) {
            this.$nextTick(() => {
                this.show = true;

                this.$nextTick(() => {
                    this.reset();

                    this.session.training_module_id = moduleId;
                    this.externalInstructor = false;
                });
            });
        },

        async submit() {
            if (!(await this.$refs.form.validate())) {
                this.$toast.error("Please check for errors", {
                    duration: 5000,
                });

                return;
            }

            this.session
                .transform((data) => ({
                    ...data,
                    _method: this.edit ? "put" : "post",
                }))
                .post(
                    this.edit
                        ? this.route(
                              "staff.admin.training.sessions.update",
                              this.edit.id
                          )
                        : this.route("staff.admin.training.sessions.store"),
                    {
                        preserveState: true,
                        onSuccess: () => {
                            this.show = false;
                            this.reset();
                        },
                    }
                );
        },

        reset() {
            this.session = this.$inertia.form({
                users: [],
                instructor_id: null,
                instructor_name: null,
                training_module_id: null,
                location: null,
                followup: null,
                completed_at: null,
            });
            this.usersSelect = [];
            this.$refs.form?.reset();
        },

        updateUsers(ids) {
            this.session.users = this.session.users.filter((user) =>
                ids.includes(user.id)
            );

            ids.filter(
                (id) => !this.session.users.some((user) => user.id === id)
            ).forEach((id) => {
                const user = this.users.find((usr) => usr.id === id);

                this.session.users.push({
                    user_id: user.id,
                    user: { name: user.name },
                    passed: true,
                    certificate: null,
                });
            });
        },
    },
};
</script>

<style scoped></style>
