export default {
    computed: {
        showChip() {
            return (
                this.deleted || this.changed || this.newTask || this.unchanged
            );
        },
        chipColor() {
            if (this.deleted) {
                return "danger";
            }
            if (this.changed) {
                return "primary";
            }
            if (this.newTask) {
                return "success";
            }
            if (this.unchanged) {
                return "grey";
            }
        },
        chipText() {
            if (this.deleted) {
                return "Deleted";
            }
            if (this.changed) {
                return "Changed";
            }
            if (this.newTask) {
                return "New";
            }
            if (this.unchanged) {
                return "Unchanged";
            }
        },
        backgroundColor() {
            if (this.deleted || this.logistic.deleted_at) {
                return "grey";
            }
            if (!this.firstVersion && !this.changed && !this.newTask) {
                return "grey";
            }
            return this.task.category.color;
        },
        hasPrevious() {
            return !!this.task.previous_task;
        },
        changed() {
            if (this.newTask || this.deleted || this.firstVersion) {
                return false;
            }

            return !_.isEqual(
                this.pickKeys(this.task),
                this.pickKeys(this.task.previous_task)
            );
        },
        newTask() {
            if (this.deleted) {
                return false;
            }
            return !this.hasPrevious && !this.firstVersion;
        },
        unchanged() {
            return !(
                this.deleted ||
                this.newTask ||
                this.changed ||
                this.firstVersion
            );
        },
        firstVersion() {
            return this.logistic.version <= 1;
        },
        deleted() {
            return !!this.task.deleted_at;
        },
        countPeople() {
            let users = 0;
            let freelancers = 0;
            if (this.task.users.length) {
                users = this.task.users.length;
            }
            if (this.task.freelancers.length) {
                freelancers = this.task.freelancers.length;
            }
            return users + freelancers;
        },
    },

    methods: {
        formatForEvent(time) {
            return this.moment(time).format("HH:mm");
        },
        pickKeys(task) {
            let keys = _.pick(task, [
                "users",
                "freelancers",
                "category",
                "job",
                "name",
                "text",
                "day",
                "time_from",
                "time_to",
                "vehicles",
            ]);

            return _.mapValues(keys, (value, key) => {
                if (_.includes(["users", "freelancers", "vehicles"], key)) {
                    return _.map(value, _.partialRight(_.pick, "id"));
                }
                return value;
            });
        },
    },
};
