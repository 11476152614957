<template>
    <section>
        <v-chip-group
            v-model="selectedTab"
            mandatory
            active-class="blue white--text"
        >
            <v-chip
                v-for="tab in tabs"
                :key="tab.value"
                :value="tab.value"
                @click="selectTab(tab)"
                label
            >
                {{ tab.text }}
            </v-chip>
        </v-chip-group>

        <v-card v-if="chooseView === 'gantt'">
            <gantt-iframe @open-task-modal="openTaskModal" />
        </v-card>

        <logistics-calendar
            v-if="chooseView === 'calendar'"
            :this-week="thisWeek"
            :user="user"
            :logistic-live="logisticLive"
        />

        <task-info-modal
            :logistic="logistic"
            :live="logisticLive"
            :user="user"
            ref="taskModal"
        />
    </section>
</template>

<script>
import TabMixin from "../../../Mixins/TabMixin";
import GanttIframe from "./GanttChart/GanttIframe.vue";
import LogisticsCalendar from "./LogisticsCalendar.vue";
import TaskInfoModal from "./Tasks/TaskInfoModal/TaskInfoModal.vue";

export default {
    name: "logisticsViewer",
    components: {
        TaskInfoModal,
        LogisticsCalendar,
        GanttIframe,
    },
    mixins: [TabMixin],
    props: {
        logistic: {
            type: Object,
            required: true,
        },
        logisticLive: {
            type: Boolean,
        },
        thisWeek: {
            type: Object,
            required: true,
        },
        user: {
            type: Boolean,
            default: false,
        },
        freelancer: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            tabs: [
                { value: "gantt", text: "Gantt View" },
                { value: "calendar", text: "Calendar View" },
            ],
        };
    },

    methods: {
        openTaskModal(task) {
            this.$refs.taskModal.open(task);
        },
    },

    computed: {
        chooseView() {
            if (!this.selectedTab) {
                return "week";
            }
            return this.selectedTab;
        },
    },
};
</script>

<style scoped></style>
