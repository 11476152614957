<template>
    <modal-template v-model="modal">
        <div class="text-h6">Store Transfer</div>
        <div class="text-body-1">Current Store: {{ currentStore }}</div>

        <div class="text-body-1 mt-4">
            Choose a store below to transfer this asset to
        </div>

        <form novalidate @submit.prevent="submit">
            <validation-observer ref="form">
                <validation-provider
                    v-slot="{ errors }"
                    name="Store"
                    rules="required"
                >
                    <v-select
                        v-model="form.store_id"
                        name="Store"
                        dense
                        outlined
                        :items="stores"
                        item-text="name"
                        item-value="id"
                        :error-messages="errors"
                        label="Store"
                        hint="Choose a store to transfer the asset to"
                        class="required mt-2"
                        required
                    >
                    </v-select>
                </validation-provider>
            </validation-observer>

            <v-btn
                color="success"
                :loading="form.processing"
                class="mt-2"
                type="submit"
            >
                Transfer
            </v-btn>
        </form>
    </modal-template>
</template>

<script>
import ModalTemplate from "../Master/ModalTemplate";
export default {
    name: "StockTransferModal",
    components: { ModalTemplate },

    props: {
        routeName: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            modal: false,
            form: this.$inertia.form({
                asset_id: null,
                product_id: null,
                asset: null,
                store_id: null,
            }),
            currentStore: null,
        };
    },

    watch: {
        modal(value) {
            if (!value) {
                this.resetForm();
            }
        },
    },

    methods: {
        open(asset) {
            this.modal = true;
            this.form.asset_id = asset.id;
            this.form.product_id = asset.item_id;
            this.form.asset = asset.asset_number;
            this.currentStore = asset.store_name;
        },

        submit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.form.post(this.route(this.routeName), {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.resetForm();
                    },
                });
            });
        },

        resetForm() {
            this.form.reset();
            this.$refs.form?.reset();
            this.modal = false;
        },
    },

    computed: {
        stores() {
            return this.$page.props.stores;
        },
    },
};
</script>

<style scoped></style>
