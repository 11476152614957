export default {
    data() {
        return {
            canCopy: false,
        };
    },

    created() {
        this.canCopy = !!navigator.clipboard;
    },

    methods: {
        async copy(s) {
            await navigator.clipboard.writeText(s);
        },
        async copyWithNotification(s) {
            await this.copy(s);
            this.$toast.success("URL copied to clipboard", {
                duration: 2000,
            });
        },
    },
};
