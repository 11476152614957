<template>
    <v-container>
        <header-name>Webhook Management</header-name>

        <v-btn
            color="success"
            small
            class="mb-2 mt-6"
            @click="showModal = true"
        >
            Create
        </v-btn>

        <v-sheet elevation="2" class="pa-4">
            <div class="text-h6">Webhooks</div>
            <v-data-table
                :items="webhooks"
                :headers="webhookHeaders"
                sort-by="name"
            >
                <template v-slot:item.actions="{ item }">
                    <div class="d-flex">
                        <v-btn
                            class="mr-2"
                            color="primary"
                            @click="edit(item)"
                            icon
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                            color="error"
                            fab
                            class="ml-auto"
                            x-small
                            @click="deleteWebhook(item)"
                        >
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                    </div>
                </template>
            </v-data-table>
        </v-sheet>

        <v-sheet elevation="2" class="pa-4 mt-8">
            <div class="text-h6">Webhooks Logs</div>
            <v-data-table
                :items="$page.props.webhookLogs"
                :headers="logHeaders"
                sort-by="created_at"
                sort-desc
            >
                <template v-slot:item.webhook="{ item }">
                    {{ findWebhook(item.webhook_id) }}
                </template>
                <template v-slot:item.created_at="{ item }">
                    {{ momentFormatDateTimeYear(item.created_at) }}
                </template>
            </v-data-table>
        </v-sheet>

        <modal-template v-model="showModal">
            <div class="text-h6">
                {{ editWebhook ? "Edit" : "Create" }} Webhook
            </div>

            <form novalidate @submit.prevent="submitWebhook" class="mt-4">
                <validation-observer ref="form">
                    <validation-provider
                        v-slot="{ errors }"
                        name="Name"
                        rules="required"
                    >
                        <v-text-field
                            v-model="form.name"
                            name="Name"
                            :error-messages="errors"
                            autofocus
                            outlined
                            dense
                            label="Name"
                            class="required"
                            required
                        >
                        </v-text-field>
                    </validation-provider>
                    <validation-provider
                        v-slot="{ errors }"
                        name="Event"
                        rules="required"
                    >
                        <v-text-field
                            v-model="form.event"
                            name="Event"
                            :error-messages="errors"
                            outlined
                            dense
                            label="Event"
                            class="required"
                            required
                        >
                        </v-text-field>
                    </validation-provider>
                    <validation-provider
                        v-slot="{ errors }"
                        name="URL"
                        rules="required"
                    >
                        <v-text-field
                            v-model="form.target_url"
                            name="URL"
                            :error-messages="errors"
                            outlined
                            dense
                            label="URL"
                            class="required"
                            required
                        >
                        </v-text-field>
                    </validation-provider>

                    <v-switch
                        color="success"
                        v-model="form.active"
                        label="Active"
                    />
                </validation-observer>

                <v-btn color="success" type="submit" :loading="form.processing">
                    Save
                </v-btn>
            </form>
        </modal-template>
    </v-container>
</template>

<script>
import AdminController from "../../../Mixins/AdminController";
import HeaderName from "../../../Comps/General/HeaderName";
import ModalTemplate from "../../../Comps/Master/ModalTemplate";

export default {
    name: "WebhookManagement",
    components: { ModalTemplate, HeaderName },
    mixins: [AdminController],

    data() {
        return {
            webhookHeaders: [
                { text: "Name", value: "name" },
                { text: "Event", value: "event" },
                { text: "URL", value: "target_url" },
                { text: "Active", value: "active" },
                { text: "actions", value: "actions" },
            ],
            logHeaders: [
                { text: "Webhook", value: "webhook" },
                { text: "Response Code", value: "response_code" },
                { text: "Response", value: "response" },
                { text: "Date", value: "created_at" },
            ],
            showModal: false,
            editWebhook: null,
            form: this.$inertia.form(this.blankData()),
        };
    },

    watch: {
        showModal(value) {
            if (!value) {
                this.resetModal();
            }
        },
    },

    computed: {
        webhooks() {
            return this.$page.props.webhooks;
        },
    },

    methods: {
        findWebhook(webhookId) {
            return (
                this.webhooks.find((webhook) => webhook.id === webhookId)
                    ?.name ?? "N/A"
            );
        },

        blankData() {
            return {
                name: null,
                event: null,
                target_url: null,
                active: true,
            };
        },

        edit(webhook) {
            this.editWebhook = webhook.id;
            this.form = this.$inertia.form({
                name: webhook.name,
                event: webhook.event,
                target_url: webhook.target_url,
                active: webhook.active,
            });
            this.showModal = true;
        },

        submitWebhook() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                if (this.editWebhook) {
                    this.updateWebhook();
                    return;
                }

                this.storeWebhook();
            });
        },

        updateWebhook() {
            this.form.put(
                this.route(
                    "staff.admin.webhook-management.update",
                    this.editWebhook
                ),
                {
                    onSuccess: () => {
                        this.resetModal();
                    },
                }
            );
        },

        storeWebhook() {
            this.form.post(this.route("staff.admin.webhook-management.store"), {
                onSuccess: () => {
                    this.resetModal();
                },
            });
        },

        resetModal() {
            this.showModal = false;
            this.editWebhook = null;
            this.form = this.$inertia.form(this.blankData());
            this.$refs.form?.reset();
        },

        deleteWebhook(webhook) {
            if (!confirm("Are you sure you want to delete this webhook?")) {
                return;
            }

            this.$inertia.delete(
                this.route("staff.admin.webhook-management.destroy", webhook.id)
            );
        },
    },
};
</script>

<style scoped></style>
