<template>
    <v-sheet class="ma-1" elevation="2">
        <div class="py-6">
            <v-toolbar height="auto" flat>
                <div class="d-flex align-center flex-wrap" style="width: 100%">
                    <v-btn
                        outlined
                        small
                        class="mr-4"
                        color="grey darken-2"
                        @click="setToday"
                    >
                        Today
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="prev">
                        <v-icon small> mdi-chevron-left </v-icon>
                    </v-btn>
                    <v-btn fab text small color="grey darken-2" @click="next">
                        <v-icon small> mdi-chevron-right </v-icon>
                    </v-btn>
                    <div v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                    </div>

                    <v-spacer />

                    <v-switch
                        class="mr-4"
                        dense
                        label="Only Mine"
                        v-model="onlyMe"
                        persistent-hint
                        hint="Show only my holidays"
                    />

                    <v-menu bottom right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                small
                                outlined
                                color="grey darken-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <span>{{ typeToLabel[type] }}</span>
                                <v-icon right> mdi-menu-down </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="type = 'day'">
                                <v-list-item-title>Day</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                                <v-list-item-title>Week</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                                <v-list-item-title>Month</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-toolbar>
        </div>

        <v-responsive :aspect-ratio="16 / 12">
            <v-calendar
                :key="onlyMe"
                v-model="focus"
                style="height: 100%; width: 100%"
                class="holiday-calendar"
                ref="calendar"
                :type="type"
                :interval-count="0"
                @change="changeDateRangeShown"
                @click:more="clickMoreDate"
                @click:event="clickCalEvent"
                @click:day="clickNewDate"
                @click:date="clickMoreDate"
                event-overlap-mode="stack"
                color="primary"
                :weekdays="weekdays"
                :events="events"
            >
                <template v-slot:event="{ event }">
                    <div class="d-flex px-1">
                        <div>{{ event.user.name }}</div>
                        <div class="ml-1">
                            <v-chip
                                x-small
                                label
                                v-if="!event.approved && !event.denied"
                            >
                                Pending
                            </v-chip>
                            <v-chip x-small label v-if="event.half_day">
                                {{ event.morning ? "Morning" : "Afternoon" }}
                            </v-chip>
                            <v-chip x-small label v-if="event.evening">
                                Evening
                            </v-chip>
                        </div>
                    </div>
                </template>
            </v-calendar>
        </v-responsive>

        <holiday-view-modal ref="holidayView" />

        <holiday-create-modal ref="holidayCreate" />
    </v-sheet>
</template>

<script>
import HolidayViewModal from "./HolidayViewModal";
import HolidayCreateModal from "./HolidayCreateModal";

export default {
    name: "HolidayCalendar",
    components: { HolidayCreateModal, HolidayViewModal },

    props: {
        holidays: {
            type: Array,
            default: null,
        },
        admin: {
            type: Boolean,
            default: false,
        },

        hasHolidaysSetup: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            events: [],
            focus: "",
            weekdays: [1, 2, 3, 4, 5, 6, 0],
            type: "month",
            typeToLabel: {
                month: "Month",
                week: "Week",
                day: "Day",
            },
            startDate: null,
            endDate: null,
            onlyMe: false,
        };
    },

    watch: {
        holidays: {
            immediate: true,
            deep: true,
            handler() {
                this.calcEvents();
            },
        },
        onlyMe() {
            this.getHolidays();
        },
    },

    created() {
        if (this.queryObject.startDate) {
            this.focus = this.queryObject.startDate;
        }

        if (this.queryObject.onlyMe) {
            this.onlyMe = this.queryObject.onlyMe === "true";
        }
    },

    mounted() {
        this.$refs.calendar.checkChange();
    },

    computed: {
        queryObject() {
            return this.$page.props.queryObject;
        },
    },

    methods: {
        calcEvents() {
            if (this.holidays === null) {
                return;
            }

            this.events = [];
            _.each(this.holidays, (holiday) => {
                holiday.start = this.moment(holiday.start_date).format(
                    "YYYY-MM-DD"
                );
                holiday.end = this.moment(holiday.end_date).format(
                    "YYYY-MM-DD"
                );
                holiday.allDay = !holiday.half_day;

                this.events.push(holiday);
            });
        },
        setToday() {
            this.focus = "";
        },
        prev() {
            this.$refs.calendar.prev();
        },
        next() {
            this.$refs.calendar.next();
        },
        changeDateRangeShown(dates) {
            this.startDate = dates.start.date;
            this.endDate = dates.end.date;
            this.getHolidays();
        },
        clickMoreDate(date) {
            this.focus = date.date;
            this.type = "day";
        },

        clickCalEvent(event) {
            event.nativeEvent.stopPropagation();
            this.$refs.holidayView.open(event.event);
        },

        clickNewDate(event) {
            if (!this.hasHolidaysSetup) {
                return;
            }

            let date = this.moment(event.date).startOf("day");

            if (date.isBefore(this.moment().startOf("day"))) {
                this.$toast.warning("You cannot request a date in the past");
                return;
            }

            this.$refs.holidayCreate.create(date.toISOString());
        },

        getHolidays: _.debounce(
            function () {
                this.$inertia.reload({
                    data: {
                        startDate: this.startDate,
                        endDate: this.endDate,
                        onlyMe: this.onlyMe,
                    },
                    preserveState: true,
                    preserveScroll: true,
                    only: ["holidays", "flash"],
                });
            },
            1000,
            { leading: true }
        ),
    },
};
</script>

<style scoped></style>
