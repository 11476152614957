<template>
    <div>
        <complete-loader @done="finishLoading">
            <div class="text-h5">
                Payment complete <span class="text-h3">🎉</span>
            </div>
        </complete-loader>

        <v-fade-transition>
            <div v-if="loadingDone" class="mt-8">
                <div class="text-center text-body-1">
                    Thank you for your payment of
                    <strong>£{{ total }}</strong> for invoice
                    <strong>{{ invoiceData.number }}</strong>

                    <div class="text-subtitle-1 mt-6">
                        You can download your invoice below
                    </div>

                    <a
                        class="inertia-link"
                        :href="
                            route('secure.invoice', {
                                invoiceId: invoiceData.id,
                                uuid: invoiceData.member.uuid,
                            })
                        "
                    >
                        <v-btn class="mt-3" color="primary">
                            Download Invoice
                        </v-btn>
                    </a>
                </div>
            </div>
        </v-fade-transition>
    </div>
</template>

<script>
import SecureLayout from "../../Comps/Secure/SecureLayout";
import CompleteLoader from "../../Comps/General/CompleteLoader";

export default {
    name: "SecureSuccess",
    components: { CompleteLoader },
    layout: SecureLayout,

    props: {
        invoiceData: Object,
        total: String,
    },

    data() {
        return {
            loadingDone: false,
        };
    },

    methods: {
        finishLoading() {
            setTimeout(() => {
                this.loadingDone = true;
            }, 1000);
        },
    },
};
</script>

<style scoped></style>
