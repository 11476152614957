<template>
    <div>
        <div class="text--secondary text-caption">
            Asset information is updated every 10 minutes
        </div>

        <v-alert type="error" v-if="asset.quantity_held !== '1.0'">
            This asset has been removed from stock
        </v-alert>

        <v-row class="mt-4">
            <v-col cols="12" md="6" class="d-flex flex-column">
                <v-img
                    v-if="assetIcon"
                    transition="none"
                    max-width="300px"
                    :src="assetIcon"
                />
                <v-sheet v-else max-width="300px">
                    <delivery-truck-svg />
                </v-sheet>
                <div class="text-h6 mt-4">
                    <a-tag-with-external-icon
                        :url="`${$page.props.currentBaseUrl}/products/${asset.item_id}`"
                        :text="asset.item.name"
                    />
                </div>
                <div>
                    Asset:
                    <strong>
                        <a-tag-with-external-icon
                            :text="asset.asset_number"
                            :url="`${$page.props.currentBaseUrl}/stock_levels/${asset.id}`"
                        />
                    </strong>
                </div>
                <div>
                    Serial Number:
                    <strong>
                        {{ asset.serial_number ? asset.serial_number : "N/A" }}
                    </strong>
                </div>
                <div>
                    Store: <strong>{{ asset.store_name }}</strong>
                </div>

                <!--Actions-->
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            width="fit-content"
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            class="mt-4"
                        >
                            Actions
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            class="primary--text"
                            @click="$refs.assetEditInfoModal.open(asset)"
                        >
                            <v-list-item-title> Edit Info </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            class="primary--text"
                            @click="$refs.addToQuarantineModal.open(asset)"
                        >
                            <v-list-item-title>
                                Add to Quarantine
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            class="primary--text"
                            @click="$refs.stockTransferModal.open(asset)"
                        >
                            <v-list-item-title>
                                Transfer Store
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
            <v-col cols="12" md="6">
                <div class="d-flex" style="width: 100%">
                    <div class="my-4 mr-1">
                        <div class="font-weight-bold">Inspection Status</div>

                        <div v-if="!information" class="d-flex justify-center">
                            <v-btn icon loading x-large />
                        </div>

                        <div v-if="inspections.length">
                            <v-sheet
                                v-for="(inspection, index) in inspections"
                                width="fit-content"
                                :key="index"
                                :color="
                                    inspection.in_date && inspection.passed
                                        ? 'success'
                                        : 'error'
                                "
                                class="pa-2 white--text"
                                rounded
                            >
                                <div>{{ inspection.inspection.name }}</div>
                                <div
                                    v-if="
                                        inspection.in_date && inspection.passed
                                    "
                                >
                                    In Date
                                </div>
                                <div
                                    v-if="
                                        !inspection.in_date && inspection.passed
                                    "
                                >
                                    Expired
                                </div>
                                <div
                                    v-if="
                                        inspection.in_date && !inspection.passed
                                    "
                                >
                                    Failed
                                </div>
                            </v-sheet>
                        </div>
                        <div v-else>No inspections</div>
                    </div>
                    <div class="my-4 text-right ml-auto">
                        <div class="font-weight-bold">Location</div>
                        <v-sheet
                            v-if="asset.location"
                            width="fit-content"
                            :color="getColor(asset.location)"
                            class="pa-2"
                            :class="getTextColor(asset.location)"
                            rounded
                        >
                            <div>
                                {{ asset.location }}
                            </div>
                        </v-sheet>
                        <span v-if="!asset.location">-</span>
                    </div>
                </div>

                <v-divider class="my-2" />

                <div class="my-4 d-flex flex-wrap">
                    <v-sheet
                        v-if="isAContainer"
                        width="fit-content"
                        color="success"
                        class="pa-2 white--text ma-1"
                        rounded
                    >
                        <div>Is a Container</div>
                    </v-sheet>
                    <v-sheet
                        v-if="isAChild"
                        width="fit-content"
                        color="success"
                        class="pa-2 white--text ma-1"
                        rounded
                    >
                        <div>Is a Child</div>
                    </v-sheet>
                </div>

                <v-divider class="my-2" />

                <v-sheet elevation="2" class="pa-4 mt-6">
                    <div class="font-weight-bold">Active Jobs</div>
                    <v-divider class="my-2" />
                    <div v-if="!information" class="d-flex justify-center">
                        <v-btn icon loading x-large />
                    </div>
                    <div v-else>
                        <div v-if="information.opportunities.length">
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th>Number</th>
                                        <th>Name</th>
                                        <th>Start</th>
                                        <th>End</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="job in information.opportunities"
                                        :key="job.id"
                                    >
                                        <td>
                                            <a-tag-with-external-icon
                                                :text="job.number"
                                                :url="`${$page.props.currentBaseUrl}/opportunities/${job.id}`"
                                            />
                                        </td>
                                        <td>{{ job.subject }}</td>
                                        <td>
                                            {{
                                                momentFormatDateYear(
                                                    job.starts_at
                                                )
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                momentFormatDateYear(
                                                    job.ends_at
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </div>
                        <div v-else>Not booked on any jobs</div>
                    </div>
                </v-sheet>

                <v-sheet elevation="2" class="pa-4 mt-6">
                    <div class="font-weight-bold">Active Quarantines</div>
                    <v-divider class="my-2" />
                    <div v-if="!information" class="d-flex justify-center">
                        <v-btn icon loading x-large />
                    </div>
                    <div v-else>
                        <div v-if="information.quarantines.length">
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Start</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="quarantine in information.quarantines"
                                        :key="quarantine.id"
                                    >
                                        <td>
                                            <a-tag-with-external-icon
                                                :text="quarantine.reference"
                                                :url="`${$page.props.currentBaseUrl}/quarantines/${quarantine.id}`"
                                            />
                                        </td>
                                        <td>
                                            {{
                                                momentFormatDateYear(
                                                    quarantine.starts_at
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </div>
                        <div v-else>Not in quarantine</div>
                    </div>
                </v-sheet>
            </v-col>
        </v-row>

        <v-row class="mt-6">
            <v-col cols="12">
                <v-sheet elevation="2" class="pa-4">
                    <div class="font-weight-bold">Testing & Inspections</div>
                    <v-divider class="my-2" />
                    <div v-if="!information" class="d-flex justify-center">
                        <v-btn icon loading x-large />
                    </div>
                    <div v-else>
                        <div v-if="information.inspections.length">
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Passed</th>
                                        <th>Inspected At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="inspection in information.inspections"
                                        :key="inspection.id"
                                    >
                                        <td>
                                            {{ inspection.inspection.name }}
                                        </td>
                                        <td>{{ inspection.passed }}</td>
                                        <td>
                                            {{
                                                momentFormatDateTimeYear(
                                                    inspection.inspection_at
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </div>
                        <div v-else>No data found</div>
                    </div>
                </v-sheet>
            </v-col>
        </v-row>

        <stock-transfer-modal
            ref="stockTransferModal"
            :route-name="stockTransferRouteName"
        />
        <asset-edit-info-modal
            ref="assetEditInfoModal"
            :route-name="editInfoRouteName"
        />
        <add-to-quarantine-modal
            ref="addToQuarantineModal"
            @newQuarantine="getInformation"
            :route-name="quarantineRouteName"
        />
    </div>
</template>

<script>
import StockTransferModal from "../StockTransferModal";
import AssetEditInfoModal from "../AssetEditInfoModal";
import AddToQuarantineModal from "../AddToQuarantineModal";
import ATagWithExternalIcon from "../../General/ATagWithExternalIcon";
import moment from "moment";
import DeliveryTruckSvg from "../../General/Svgs/DeliveryTruckSvg";
export default {
    name: "AssetSearchShowInformation",
    components: {
        DeliveryTruckSvg,
        ATagWithExternalIcon,
        AddToQuarantineModal,
        AssetEditInfoModal,
        StockTransferModal,
    },

    props: {
        searchInformationRouteName: {
            type: String,
            required: true,
        },
        stockTransferRouteName: {
            type: String,
            required: true,
        },
        editInfoRouteName: {
            type: String,
            required: true,
        },
        quarantineRouteName: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            information: null,
        };
    },

    mounted() {
        this.getInformation();
    },

    methods: {
        getTextColor(location) {
            const letter = location.charAt(0).toLowerCase();

            if (letter === "l" || letter === "w") {
                return "black--text";
            }

            return "white--text";
        },
        getColor(location) {
            const letter = location.charAt(0).toLowerCase();
            switch (letter) {
                case "v":
                    return "red";
                case "l":
                    return "yellow";
                case "r":
                    return "orange";
                case "s":
                    return "green";
                case "a":
                    return "indigo";
                case "p":
                    return "light-blue";
                case "c":
                    return "purple";
                case "w":
                    return "light-green accent-3";
                case "m":
                    return "brown";
                default:
                    return "grey";
            }
        },

        getInformation() {
            this.information = null;
            this.$http
                .get(this.route(this.searchInformationRouteName, this.asset.id))
                .then((response) => {
                    this.information = response.data;
                });
        },
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },
        assetIcon() {
            return this.asset.item.icon?.url;
        },
        isAContainer() {
            return this.asset.container_mode !== null;
        },
        isAChild() {
            return this.asset.container_stock_level_id !== null;
        },
        inspections() {
            if (!this.information || !this.information.inspections.length) {
                return [];
            }

            if (!this.asset.item.assigned_inspection_ids) {
                return [];
            }

            let inspections = [];

            //Loop through each inspection id on the item
            this.asset.item.assigned_inspection_ids.forEach((id) => {
                //Find all inspections on the asset with this inspection ID
                let allInspections = _.filter(
                    this.information.inspections,
                    (inspection) => {
                        return inspection.inspection.id === id;
                    }
                );

                //If no inspections then return
                if (!allInspections.length) {
                    return;
                }

                let currentInspection = allInspections[0];

                //Work out if inspection is in date
                let expires = moment(currentInspection.inspection_at).add(
                    currentInspection.inspection.schedule_interval,
                    currentInspection.inspection.schedule_type_name
                );

                inspections.push({
                    inspection: currentInspection.inspection,
                    in_date: expires.isAfter(moment()),
                    inspection_at: currentInspection.inspection_at,
                    passed: currentInspection.passed,
                });
            });

            return inspections;
        },
    },
};
</script>

<style scoped></style>
