<template>
    <v-container fixed>
        <header-name>Hire Site Categories</header-name>

        <!--Main Categories-->
        <v-data-table
            :headers="[
                { text: 'Name', value: 'name' },
                { text: '', value: 'options', align: 'end' },
            ]"
            :items="$page.props.productCategories"
            no-data-text="No categories have been found"
            class="elevation-1"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <div class="d-flex pa-3 text-h6">
                    Main Categories
                    <div class="ml-auto">
                        <v-btn
                            small
                            color="success"
                            @click="$refs.mainCatModal.create()"
                        >
                            Create Main Category
                        </v-btn>
                    </div>
                </div>
            </template>
            <template v-slot:item.options="{ item }">
                <v-btn
                    class="mr-2"
                    elevation="2"
                    v-tooltip="'Edit'"
                    color="primary"
                    @click="$refs.mainCatModal.edit(item)"
                    icon
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                    class="mr-2"
                    elevation="2"
                    v-tooltip="'Delete'"
                    color="error"
                    @click="deleteCategory(item)"
                    icon
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <main-category-modal ref="mainCatModal" />
        <!--Sub Categories-->
        <v-data-table
            :headers="[
                { text: 'Name', value: 'name' },
                {
                    text: 'Main Category',
                    value: 'product_category.name',
                },
                { text: 'Active', value: 'show_on_hire_site' },
                { text: '', value: 'options', align: 'end' },
            ]"
            :items="$page.props.productSubCategories"
            no-data-text="No categories have been found"
            class="elevation-1 mt-8"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <div class="d-flex pa-3 text-h6">Sub Categories</div>
            </template>
            <template v-slot:item.show_on_hire_site="{ value }">
                <v-icon v-if="value" color="success" small class="mr-2">
                    mdi-check
                </v-icon>
                <v-icon v-else color="error" small class="mr-2">
                    mdi-close
                </v-icon>
            </template>
            <template v-slot:item.options="{ item }">
                <v-btn
                    class="mr-2"
                    elevation="2"
                    v-tooltip="'Edit'"
                    color="primary"
                    @click="$refs.subCatModal.edit(item)"
                    icon
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <sub-category-modal ref="subCatModal" />
    </v-container>
</template>

<script>
import { defineComponent } from "vue";
import HeaderName from "../../../../../Comps/General/HeaderName.vue";
import MainCategoryModal from "./Partials/MainCategoryModal.vue";
import AdminController from "../../../../../Mixins/AdminController.js";
import SubCategoryModal from "./Partials/SubCategoryModal.vue";
export default defineComponent({
    name: "Index",
    components: { SubCategoryModal, MainCategoryModal, HeaderName },
    mixins: [AdminController],

    methods: {
        deleteCategory(item) {
            if (
                !confirm(
                    'Are you sure you want to delete the "' +
                        item.name +
                        '" Category?'
                )
            ) {
                return;
            }

            this.$inertia.delete(
                this.route("staff.admin.hire-site-categories.destroy", item.id)
            );
        },
    },
});
</script>

<style scoped></style>
