<template>
    <v-row>
        <v-col cols="12" md="9">
            <div class="d-flex">
                <header-name>Logistics</header-name>
                <v-btn
                    icon
                    small
                    @click.prevent="icalModal = true"
                    class="ml-2 subscribe-ical"
                    v-tooltip="'Subscribe to all logistics'"
                >
                    <v-icon>mdi-calendar-export</v-icon>
                </v-btn>
            </div>
            <div class="d-flex align-center ml-2 mb-2">
                <h3 :class="nameClasses" v-if="logistic">
                    {{ momentFormatDate(thisWeek) }} - Version {{ version }} ({{
                        logistic.sent ? "Live" : "Draft"
                    }})
                </h3>
                <v-fade-transition>
                    <v-icon
                        v-tooltip="'You have accepted this version'"
                        color="success"
                        large
                        class="ml-2"
                        v-if="
                            logistic &&
                            logistic.users.length &&
                            logistic.users[0].pivot.accepted
                        "
                    >
                        mdi-check-outline
                    </v-icon>
                </v-fade-transition>
            </div>

            <logistics-date-version-picker
                :this-week.sync="thisWeek"
                :versions="versions"
                :version.sync="version"
            >
            </logistics-date-version-picker>

            <section v-if="logistic">
                <v-fade-transition>
                    <v-alert
                        v-if="
                            logistic.users.length &&
                            !logistic.users[0].pivot.accepted
                        "
                        border="top"
                        prominent
                        colored-border
                        type="info"
                        elevation="2"
                    >
                        <v-row align="center">
                            <v-col class="grow">
                                <span v-if="logistic.version > 1">
                                    Please accept this version of logistics to
                                    inform HR you have read and understood the
                                    changes
                                </span>
                                <span v-else>
                                    Please accept this first version of
                                    logistics to inform HR you have read and
                                    understood the current week
                                </span>
                            </v-col>
                            <v-col class="shrink">
                                <inertia-link
                                    :href="
                                        route(
                                            'staff.logistics.accept',
                                            logistic.slug
                                        )
                                    "
                                    method="put"
                                    as="div"
                                    class="inertia-link"
                                >
                                    <v-btn class="ml-auto" color="success">
                                        I Accept
                                    </v-btn>
                                </inertia-link>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-fade-transition>

                <v-alert
                    v-if="logistic && !logistic.sent"
                    border="top"
                    colored-border
                    type="warning"
                    elevation="2"
                >
                    <div>
                        This is a draft version of logistics and subject to
                        change
                    </div>
                </v-alert>

                <logistics-viewer
                    :this-week="thisWeek"
                    :logistic="logistic"
                    user
                >
                </logistics-viewer>
            </section>
            <div v-else class="mt-10 text-center">
                <h4 class="mb-3">
                    No logistics have been found for this week yet
                </h4>
            </div>
        </v-col>

        <v-col cols="12" md="3">
            <v-sheet>
                <h5 class="text--secondary mb-4">Information</h5>

                <v-expansion-panels v-model="information">
                    <category-key :categories="categories"></category-key>
                </v-expansion-panels>
            </v-sheet>
        </v-col>

        <modal-template v-model="icalModal">
            <h2>Subscribe to logistics</h2>
            <p>
                Below is the subscription link for your calendar application.<br />
                In your chosen calendar application create a new calendar
                subscription and paste the link in below.
            </p>

            <v-text-field readonly :value="icalUrl">
                <template v-slot:prepend>
                    <v-icon @click="clickCopy" v-tooltip="'Copy to clipboard'"
                        >mdi-content-copy</v-icon
                    >
                </template>
            </v-text-field>
        </modal-template>
    </v-row>
</template>

<script>
import HeaderName from "../../../Comps/General/HeaderName";
import logisticsViewer from "../../../Comps/Staff/Logistics/LogisticsViewer.vue";
import LogisticsMixin from "../../../Mixins/LogisticsMixin";
import LogisticsDateVersionPicker from "../../../Comps/Staff/Logistics/LogisticsDateVersionPicker";
import CategoryKey from "../../../Comps/Staff/Logistics/CategoryKey";
import ModalTemplate from "../../../Comps/Master/ModalTemplate";
import CopyToClipboard from "../../../Mixins/CopyToClipboard";
import PeopleController from "../../../Mixins/PeopleController";

export default {
    name: "Logistics",
    components: {
        ModalTemplate,
        CategoryKey,
        LogisticsDateVersionPicker,
        logisticsViewer,
        HeaderName,
    },
    props: ["logistic", "thisWeekProp", "categories"],
    mixins: [LogisticsMixin, CopyToClipboard, PeopleController],

    data() {
        return {
            information: 0,
            icalModal: false,
        };
    },

    methods: {
        updateData(dateOnly = false, data = {}) {
            let week = this.weekForLaravel;
            let version;
            if (dateOnly) {
                version = null;
            } else {
                version = this.version;
            }
            this.$inertia.get(
                this.route("staff.logistics", [week, version]),
                data
            );
        },
        clickCopy() {
            this.copy(this.icalUrl);
            this.$toast.success("URL copied to clipboard", {
                duration: 2000,
            });
        },
    },

    computed: {
        icalUrl() {
            return this.route(
                "staff.public.logistics.ical",
                this.$page.props.auth.user.guid,
                true
            );
        },

        nameClasses() {
            if (!this.logistic) {
                return;
            }

            return {
                "success--text": this.logistic.sent,
                "warning--text": !this.logistic.sent,
            };
        },
    },
};
</script>

<style lang="scss" scoped></style>
