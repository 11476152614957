import { extend } from "vee-validate";
import Autolinker from "autolinker";

export default {
    created() {
        extend("urlRegex", (value) => {
            if (this.getFirstUrl(value)) {
                return true;
            }

            return "Please enter a valid URL";
        });
    },

    methods: {
        getFirstUrl(string) {
            const urls = Autolinker.parse(string, {
                urls: {
                    schemeMatches: true,
                    tldMatches: false,
                    ipV4Matches: false,
                },
                email: false,
                phone: false,
                stripPrefix: false,
                sanitizeHtml: true,
            });

            if (urls.length) {
                return urls[0].getUrl();
            }

            return null;
        },
    },
};
