import { render, staticRenderFns } from "./LogisticsGantt.vue?vue&type=template&id=3ee98982"
import script from "./LogisticsGantt.vue?vue&type=script&lang=js"
export * from "./LogisticsGantt.vue?vue&type=script&lang=js"
import style0 from "./LogisticsGantt.vue?vue&type=style&index=0&id=3ee98982&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports