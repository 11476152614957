<template>
    <v-container class="fixed">
        <header-name>Vehicle Logging</header-name>

        <v-sheet class="pa-4" elevation="2">
            <div class="text-body-2">Book a vehicle out</div>
            <v-divider class="my-4" />
            <validation-observer ref="bookOutForm">
                <form novalidate @submit.prevent="clickBookOut">
                    <v-row>
                        <v-col v-if="!hiredSwitch" cols="12" md="6">
                            <validation-provider
                                v-slot="{ errors }"
                                name="vehicle"
                                rules="required"
                            >
                                <v-select
                                    outlined
                                    prepend-icon="mdi-car-estate"
                                    :error-messages="errors"
                                    dense
                                    v-model="selectedVehicleId"
                                    :items="vehicles"
                                    item-text="name"
                                    label="Select a vehicle"
                                    item-value="id"
                                />
                            </validation-provider>
                        </v-col>
                        <v-col v-if="hiredSwitch" cols="12" md="6">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Vehicle Name"
                                        rules="required|max:255"
                                    >
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="selectedVehicleName"
                                            name="Vehicle Name"
                                            :counter="255"
                                            :error-messages="errors"
                                            label="Vehicle Name"
                                            hint="Type a reference name for the vehicle"
                                            class="required"
                                            required
                                        >
                                        </v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Vehicle Reg"
                                        rules="required|max:20"
                                    >
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="selectedVehicleRegLower"
                                            name="Vehicle Reg"
                                            :error-messages="errors"
                                            label="Vehicle Reg"
                                            hint="The reg of the vehicle"
                                            class="required"
                                            required
                                        >
                                        </v-text-field>
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <date-picker
                                name="Date"
                                v-model="selectedDate"
                                dense
                                inline
                                outlined
                                hint="Choose a day"
                            >
                            </date-picker>
                            <time-picker
                                class="d-inline-block ml-1"
                                v-model="selectedTime"
                                :required="true"
                                inline
                                dense
                                outlined
                                name="Time"
                                hint="The time the vehicle will be booked out from"
                            >
                            </time-picker>
                        </v-col>
                    </v-row>

                    <div class="d-flex align-center">
                        <v-btn
                            :disabled="loading"
                            type="submit"
                            color="success"
                        >
                            Book Out
                        </v-btn>
                        <v-switch
                            class="ml-4"
                            v-model="hiredSwitch"
                            label="Hired Vehicle?"
                        />
                    </div>
                </form>
            </validation-observer>
        </v-sheet>

        <v-sheet elevation="2" class="pa-4 mt-8">
            <div class="text-body-2">Your booked out vehicles</div>
            <v-divider class="my-4" />

            <v-simple-table v-if="bookings.length">
                <thead>
                    <tr>
                        <th>Vehicle</th>
                        <th>REG</th>
                        <th>Out</th>
                        <th>In</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="booking in bookings" :key="booking.id">
                        <td>
                            <div v-if="booking.vehicle.hired">
                                <v-badge
                                    offset-y="5"
                                    color="green"
                                    content="Hired"
                                >
                                    {{ booking.vehicle.name }}
                                </v-badge>
                            </div>
                            <div v-else>
                                {{ booking.vehicle.name }}
                            </div>
                        </td>
                        <td>{{ booking.vehicle.reg }}</td>
                        <td>{{ momentFormatDateTime(booking.booked_out) }}</td>
                        <td>
                            <div v-if="booking.booked_in">
                                {{ momentFormatDateTime(booking.booked_in) }}
                            </div>
                            <div v-if="!booking.booked_in">
                                <v-btn
                                    @click="clickBookIn(booking)"
                                    :disabled="loading"
                                    x-small
                                    color="success"
                                >
                                    Book In
                                </v-btn>
                            </div>
                        </td>
                        <td>
                            <v-btn
                                x-small
                                v-if="booking.vehicle.hired"
                                :disabled="loading"
                                @click="
                                    congestionModal = true;
                                    congestionBooking = booking;
                                "
                                color="warning"
                            >
                                Congestion
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <div v-else>
                <div class="text-subtitle-1 text--secondary">
                    You have no vehicles booked out
                </div>
            </div>
        </v-sheet>

        <vehicle-condition-modal
            v-model="conditionShow"
            :booking="this.booking"
            :loading="loading"
            @submit="submitCondition"
            :condition.sync="condition"
            :mileage.sync="mileage"
        />

        <modal-template v-if="congestionBooking" v-model="congestionModal">
            <div class="text-h6">Vehicle Congestion</div>

            <div class="text-body-2 mt-2">
                This will mark the vehicle
                <strong>{{ congestionBooking.vehicle.name }}</strong> as having
                gone through a congestion zone in London
            </div>

            <div class="text-subtitle-1 mt-4">Are you sure?</div>

            <v-btn
                :loading="loading"
                class="mt-6"
                @click="markCongestionVehicle"
                color="error"
            >
                Mark Vehicle
            </v-btn>
        </modal-template>
    </v-container>
</template>

<script>
import HeaderName from "../../../Comps/General/HeaderName";
import VehicleConditionModal from "../../../Comps/Things/VehicleConditionModal";
import ModalTemplate from "../../../Comps/Master/ModalTemplate";
import DatePicker from "../../../Comps/Master/DatePicker";
import TimePicker from "../../../Comps/Master/TimePicker";
import ThingsController from "../../../Mixins/ThingsController";

export default {
    name: "VehicleLogging",
    components: {
        TimePicker,
        DatePicker,
        ModalTemplate,
        VehicleConditionModal,
        HeaderName,
    },
    props: ["bookings", "vehicles"],
    mixins: [ThingsController],

    data() {
        return {
            bookInId: null,
            booking: null,
            condition: {},
            mileage: null,
            conditionShow: false,
            congestionModal: false,
            congestionBooking: null,
            loading: false,
            selectedVehicleId: null,
            selectedDate: this.moment().format("YYYY-MM-DD"),
            selectedTime: this.moment().format("HH:mm"),
            selectedVehicleName: "",
            selectedVehicleReg: "",
            hiredSwitch: false,
        };
    },

    mounted() {
        this.resetVehicleData();
    },

    computed: {
        selectedVehicleRegLower: {
            get() {
                return this.selectedVehicleReg;
            },
            set(value) {
                this.selectedVehicleReg = _.replace(_.toUpper(value), " ", "");
            },
        },
    },

    methods: {
        submitCondition() {
            if (this.bookInId) {
                this.bookIn();
                return;
            }
            if (this.selectedVehicleId || this.hiredSwitch) {
                this.bookOut();
                return;
            }
        },

        resetVehicleData() {
            this.mileage = null;
            this.condition = {
                eCondition: null,
                eComments: "",
                eFaults: "",
                iCondition: null,
                iComments: "",
                iFaults: "",
                bookIn: new Date(),
            };
        },

        clickBookIn(booking) {
            this.resetVehicleData();
            this.selectedVehicleId = null;
            this.bookInId = booking.id;
            this.booking = booking;
            this.conditionShow = true;
        },

        clickBookOut() {
            this.$refs.bookOutForm.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.resetVehicleData();
                this.bookInId = null;
                this.conditionShow = true;
            });
        },

        bookIn() {
            this.loading = true;
            this.$inertia.post(
                this.route("staff.vehicles.logging.bookIn"),
                {
                    id: this.bookInId,
                    mileage: this.mileage,
                    booked_in: this.condition.bookIn,
                    condition: this.condition,
                },
                {
                    preserveState: false,
                    onSuccess: () => {
                        this.bookInId = null;
                        this.booking = null;
                        this.resetVehicleData();
                        this.conditionShow = false;
                        this.loading = false;
                    },
                }
            );
        },

        bookOut() {
            this.loading = true;
            this.$inertia.post(
                this.route("staff.vehicles.logging.bookOut"),
                {
                    date: this.selectedDate,
                    time: this.selectedTime,
                    mileage: this.mileage,
                    condition: this.condition,
                    vehicle: this.selectedVehicleId,
                    hired: this.hiredSwitch,
                    vehicle_name: this.selectedVehicleName,
                    vehicle_reg: this.selectedVehicleReg,
                },
                {
                    preserveState: false,
                    onSuccess: () => {
                        this.selectedVehicleId = null;
                        this.selectedVehicleName = this.selectedVehicleReg = "";
                        this.hiredSwitch = false;
                        this.resetVehicleData();
                        this.conditionShow = false;
                        this.loading = false;
                    },
                }
            );
        },

        markCongestionVehicle() {
            this.loading = true;
            this.$inertia.post(
                this.route(
                    "staff.vehicles.logging.congestion",
                    this.congestionBooking
                ),
                {},
                {
                    onSuccess: () => {
                        this.congestionBooking = null;
                        this.congestionModal = false;
                        this.loading = false;
                    },
                }
            );
        },
    },
};
</script>

<style scoped></style>
