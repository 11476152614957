<template>
    <modal-template v-model="show" fullscreen>
        <v-sheet v-if="src && type" height="100vh" class="text-center">
            <img
                v-if="type === 'image' && src"
                :src="src"
                style="max-width: 100%; max-height: 100vh"
            />
            <video
                v-if="type === 'video' && src"
                :src="src"
                style="max-width: 100%; max-height: 100%"
                autoplay
                loop
            />
            <embed
                v-if="type === 'pdf'"
                :src="src"
                width="100%"
                style="height: 100vh"
                type="application/pdf"
            />
        </v-sheet>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../Master/ModalTemplate";
export default {
    name: "FileDisplayModal",
    components: { ModalTemplate },

    data() {
        return {
            show: false,
            src: null,
            type: null,
        };
    },

    methods: {
        showFile(src, type) {
            this.src = src;
            this.type = type;
            this.show = true;
        },
    },
};
</script>

<style scoped></style>
