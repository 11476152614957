var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.task)?_c('modal-template',{attrs:{"native":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex",style:({ 'background-color': _vm.backgroundColor })},[_c('div',{staticClass:"white--text"},[_c('task-category-icon',{staticClass:"mr-2",attrs:{"category":_vm.task.category,"color":"white"}}),_vm._v("\n                "+_vm._s(_vm.task.name)+"\n                "),(_vm.task.job)?_c('div',{staticClass:"d-inline-block"},[_vm._v("\n                      -\n                    "),_c('a-tag-with-external-icon',{attrs:{"url":_vm.task.job.url,"text":_vm.task.job.number,"classes":"white--text"}})],1):_vm._e()],1),_vm._v(" "),(!_vm.user)?_c('div',{staticClass:"ml-auto"},[_c('inertia-link',{staticClass:"d-inline-block",attrs:{"as":"div","href":_vm.route('staff.admin.logistics.tasks.show', [
                            this.logistic.slug,
                            this.task.id,
                        ])}},[_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Edit task'),expression:"'Edit task'"}],staticClass:"mx-2",attrs:{"fab":"","x-small":"","color":"white"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pen")])],1)],1),_vm._v(" "),_c('v-menu',{attrs:{"top":"","close-on-click":"","close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v("\n                            mdi-dots-vertical\n                        ")])]}}],null,false,3915206843)},[_vm._v(" "),_c('v-list',[_c('inertia-link',{attrs:{"as":"span","href":_vm.route('staff.admin.logistics.tasks.show', [
                                    this.logistic.slug,
                                    this.task.id,
                                ])}},[(!_vm.deleted)?_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("\n                                    Edit Task\n                                ")])],1):_vm._e()],1),_vm._v(" "),(!_vm.deleted && !_vm.live)?_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.cloneTask}},[_c('v-list-item-title',[_vm._v("\n                                Clone Task\n                            ")])],1):_vm._e(),_vm._v(" "),(!_vm.live)?_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.deleteTask}},[_c('v-list-item-title',{class:!_vm.deleted
                                        ? 'error--text'
                                        : 'success--text'},[_vm._v("\n                                "+_vm._s(!_vm.deleted
                                        ? "Delete this task"
                                        : "Restore this task")+"\n                            ")])],1):_vm._e()],1)],1)],1):_vm._e()]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-text',{staticClass:"mt-3"},[(!_vm.clone)?_c('div',[_c('div',{staticClass:"d-flex align-start"},[(_vm.showChip)?_c('v-chip',{staticClass:"mb-1",attrs:{"dark":"","x-small":"","label":"","color":_vm.chipColor}},[_vm._v("\n                        "+_vm._s(_vm.chipText)+"\n                    ")]):_vm._e()],1),_vm._v(" "),_c('task-info-modal-info',{key:_vm.task.id,attrs:{"task":_vm.task,"logistic":_vm.logistic,"user":_vm.user},on:{"newNote":_vm.open}})],1):_vm._e(),_vm._v(" "),(_vm.clone)?_c('task-info-modal-clone',{attrs:{"task":_vm.task,"logistic":_vm.logistic}}):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }