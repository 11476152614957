<template>
    <validation-provider v-slot="{ errors }" :name="name" rules="required">
        <v-text-field
            v-model="totalPrice"
            prefix="£"
            :min="0"
            type="number"
            :name="name"
            :error-messages="errors"
            :label="label"
            :hint="hint"
            required
            outlined
            dense
            rounded
        >
        </v-text-field>
    </validation-provider>
</template>

<script>
export default {
    name: "CurrencyInput",
    props: {
        name: {
            type: String,
            default: "Total",
        },
        label: String,
        hint: String,
        value: Number,
    },

    methods: {
        toDecimal(amount) {
            return (amount / 100).toFixed(2);
        },
    },

    computed: {
        totalPrice: {
            get() {
                return this.toDecimal(this.value);
            },
            set(val) {
                this.$emit("input", parseFloat(val.replace(".", "")));
            },
        },
    },
};
</script>

<style scoped></style>
