<template>
    <v-container fixed>
        <div class="d-flex mb-5">
            <header-name>URL Shortener</header-name>
            <div class="ml-auto">
                <v-btn color="success" @click="$refs.modal.create()" small>
                    Create URL
                </v-btn>
            </div>
        </div>

        <!--Main Categories-->
        <v-data-table
            :headers="[
                { text: 'Name', value: 'name' },
                { text: 'URL', value: 'full_url' },
                { text: 'Redirect URL', value: 'redirect_url' },
                { text: '', value: 'options', align: 'end' },
            ]"
            :search="search"
            :items="$page.props.urls"
            no-data-text="No urls have been found"
            class="elevation-1"
        >
            <template v-slot:top>
                <div class="pa-4">
                    <text-input name="Search" v-model="search" />
                </div>
            </template>
            <template v-slot:item.full_url="{ item }">
                <div
                    style="cursor: pointer"
                    @click="copyWithNotification(item.full_url)"
                >
                    {{ item.full_url }}
                </div>
            </template>
            <template v-slot:item.redirect_url="{ item }">
                <div
                    style="cursor: pointer"
                    @click="copyWithNotification(item.redirect_url)"
                >
                    {{ item.redirect_url }}
                </div>
            </template>
            <template v-slot:item.options="{ item }">
                <div class="text-right">
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">
                                mdi-dots-vertical
                            </v-icon>
                        </template>

                        <v-list dense>
                            <v-list-item link @click="$refs.modal.edit(item)">
                                <v-list-item-title class="primary--text">
                                    Edit
                                </v-list-item-title>
                            </v-list-item>
                            <v-divider />
                            <v-list-item link @click="deleteUrl(item)">
                                <v-list-item-title class="error--text">
                                    Delete
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>
        <ShortenedUrlModal ref="modal" />
    </v-container>
</template>

<script>
import { defineComponent } from "vue";
import AdminController from "../../../../Mixins/AdminController.js";
import CopyToClipboard from "../../../../Mixins/CopyToClipboard.js";
import HeaderName from "../../../../Comps/General/HeaderName.vue";
import TextInput from "../../../../Comps/Forms/TextInput.vue";
import ShortenedUrlModal from "./Partials/ShortenedUrlModal.vue";
export default defineComponent({
    name: "ShortenedUrlsIndex",
    components: { ShortenedUrlModal, HeaderName, TextInput },
    mixins: [AdminController, CopyToClipboard],

    data() {
        return {
            search: "",
        };
    },

    methods: {
        deleteUrl(url) {
            if (!confirm("Are you sure you want to delete this URL?")) {
                return;
            }

            this.$inertia.delete(
                this.route("staff.admin.url-shortener.destroy", url)
            );
        },
    },
});
</script>
