var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fixed"},[_c('training',{attrs:{"comp":"modules"}}),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-h6 text--secondary mb-2 mt-2"},[_vm._v("Modules")]),_vm._v(" "),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":function($event){_vm.modal = true}}},[_vm._v("\n                Create Module\n            ")])],1)]),_vm._v(" "),_c('v-switch',{staticClass:"d-inline-block",attrs:{"label":"Show Deleted Modules"},model:{value:(_vm.deleted),callback:function ($$v) {_vm.deleted=$$v},expression:"deleted"}}),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
            { text: 'Name', value: 'name' },
            { text: 'Created By', value: 'user.name' },
            { text: '', value: 'options' },
        ],"items":_vm.modules,"no-data-text":"No modules found"},scopedSlots:_vm._u([{key:"item.options",fn:function({ item }){return [_c('inertia-link',{staticClass:"inertia-link",attrs:{"href":_vm.route('staff.admin.training.modules.show', item)}},[(!item.deleted_at)?_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('View Module'),expression:"'View Module'"}],staticClass:"mr-2",attrs:{"elevation":"2","color":"primary","icon":""}},[_c('v-icon',[_vm._v("mdi-view-module")])],1):_vm._e()],1),_vm._v(" "),(!item.deleted_at)?_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Create Session'),expression:"'Create Session'"}],staticClass:"mr-2",attrs:{"elevation":"2","color":"success","icon":""},on:{"click":function($event){return _vm.createSession(item)}}},[_c('v-icon',[_vm._v("mdi-school")])],1):_vm._e(),_vm._v(" "),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                    item.deleted_at ? 'Restore Module' : 'Delete Module'
                ),expression:"\n                    item.deleted_at ? 'Restore Module' : 'Delete Module'\n                "}],attrs:{"elevation":"2","color":item.deleted_at ? 'success' : 'error',"icon":""},on:{"click":function($event){return _vm.destroy(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_vm._v(" "),_c('modules-create-modal',{model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }