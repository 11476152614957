<template>
    <div>
        <head-title title="Login" />

        <h2 class="text-center mb-4">Login</h2>

        <v-expansion-panels mandatory>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Login with your PYTCH Google Account
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <a :href="route('google.redirect')" class="inertia-link">
                        <v-sheet
                            dark
                            class="d-flex justify-center align-center pa-3 mt-4"
                            elevation="2"
                        >
                            <div class="google-icon-wrapper">
                                <v-img
                                    max-width="30"
                                    src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                                />
                            </div>
                            <div class="text-body-1 font-weight-bold ml-2">
                                Continue with Google
                            </div>
                        </v-sheet>
                    </a>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Login with your Password
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="text-center mt-4">
                        <form class="mt-4" @submit.prevent="submit">
                            <validation-observer ref="form">
                                <text-input
                                    v-model="form.email"
                                    type="email"
                                    required
                                    name="Email"
                                    rules="required"
                                />
                                <text-input
                                    v-model="form.password"
                                    type="password"
                                    required
                                    name="Password"
                                    rules="required"
                                    autocomplete="current-password"
                                />

                                <div class="d-flex">
                                    <inertia-link href="/forgot-password">
                                        Forgot Password?
                                    </inertia-link>

                                    <v-btn
                                        type="submit"
                                        color="primary"
                                        class="mt-4 ml-auto"
                                        :loading="form.processing"
                                    >
                                        Login
                                    </v-btn>
                                </div>
                            </validation-observer>
                        </form>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import { required, max } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import HeadTitle from "../Comps/Master/HeadTitle";
import TextInput from "../Comps/Forms/TextInput.vue";
import LoginLayout from "../Layouts/LoginLayout.vue";

extend("required", required);
extend("max", max);

export default {
    name: "Login",
    components: { TextInput, HeadTitle },
    layout: LoginLayout,

    data() {
        return {
            showForm: false,
            form: this.$inertia.form({
                email: "",
                password: "",
            }),
        };
    },

    methods: {
        async submit() {
            if (!(await this.$refs.form.validate())) {
                this.$toast.error("Please check for errors", {
                    duration: 5000,
                });

                return;
            }

            this.form.post("/fortify/login", {
                preserveScroll: true,
            });
        },
    },
};
</script>

<style scoped></style>
