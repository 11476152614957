<template>
    <modal-template v-model="show" fullscreen>
        <v-container ref="scrollTo" fluid>
            <v-img
                contain
                max-height="100"
                :src="$page.props.files.pytchLogoWhite"
                alt="PYTCH Logo"
            />
            <information-form
                v-model="form"
                ref="formComp"
                @submit="submitForm"
            />
        </v-container>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../../Comps/Master/ModalTemplate";
import InformationForm from "./InformationForm";
export default {
    name: "EditProfileModal",
    components: { InformationForm, ModalTemplate },

    data() {
        return {
            show: false,
            form: this.$inertia.form({}),
        };
    },

    methods: {
        open() {
            this.form = this.$inertia.form({
                ...this.freelancer,
                ...{ files: [] },
            });
            this.form.files = this.freelancer.uploads;
            this.show = true;
            this.$nextTick(() => {
                this.$refs.scrollTo.scrollIntoView();
            });
        },
        submitForm() {
            this.$refs.formComp.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                if (!this.form.photo) {
                    this.$toast.error("Please upload a photo", {
                        duration: 5000,
                    });
                    return;
                }

                this.form
                    .transform((data) => ({
                        ...data,
                        _method: "put",
                    }))
                    .post(
                        this.route(
                            "freelancer.update-profile",
                            this.freelancer
                        ),
                        {
                            onSuccess: () => {
                                this.show = false;
                            },
                        }
                    );
            });
        },
    },

    computed: {
        freelancer() {
            return this.$page.props.freelancer;
        },
    },
};
</script>

<style scoped></style>
