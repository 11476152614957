<template>
    <div>
        <header-name>Training</header-name>

        <div class="d-flex justify-center">
            <v-btn-toggle v-model="component" tile color="blue accent-4">
                <v-btn value="modules">
                    <v-icon class="mr-2">mdi-view-module</v-icon>
                    Modules
                </v-btn>
                <v-btn value="sessions">
                    <v-icon class="mr-2">mdi-school</v-icon>
                    Sessions
                </v-btn>
            </v-btn-toggle>
        </div>
    </div>
</template>

<script>
import HeaderName from "../../General/HeaderName";

export default {
    name: "Training",
    components: { HeaderName },
    props: ["comp"],
    data() {
        return {
            component: this.comp,
        };
    },

    watch: {
        component(value) {
            let url;
            if (value === "modules") {
                url = this.route("staff.admin.training.modules.index");
            } else {
                url = this.route("staff.admin.training.sessions.index");
            }

            this.$inertia.visit(url);
        },
    },
};
</script>

<style scoped></style>
