<template>
    <modal-template v-model="show">
        <form @submit.prevent="save" novalidate>
            <validation-observer ref="form">
                <div class="text-h6 mb-4">Password</div>
                <v-row>
                    <v-col cols="12" md="6">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Name"
                            rules="required|max:255"
                        >
                            <v-text-field
                                autofocus
                                outlined
                                dense
                                v-model="form.name"
                                :counter="255"
                                :error-messages="errors"
                                label="Name"
                                hint="The service the password is for"
                                required
                            >
                            </v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="6">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Password"
                            rules="required|max:255"
                        >
                            <v-text-field
                                outlined
                                dense
                                v-model="form.password"
                                :counter="255"
                                :error-messages="errors"
                                label="Password"
                                hint="The password"
                                required
                            >
                            </v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col>
                        <v-btn
                            small
                            :loading="form.processing"
                            color="success"
                            type="submit"
                        >
                            Save
                        </v-btn>
                    </v-col>
                </v-row>
            </validation-observer>
        </form>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../../../Comps/Master/ModalTemplate";

export default {
    name: "PasswordEditModal",
    components: { ModalTemplate },

    data() {
        return {
            show: false,
            form: this.$inertia.form({}),
            editing: false,
        };
    },

    methods: {
        edit(password) {
            this.form = this.$inertia.form(password);
            this.show = true;
            this.editing = true;
        },

        create() {
            this.form = this.$inertia.form({
                name: "",
                password: "",
            });
            this.show = true;
            this.editing = false;
        },

        async save() {
            if (!(await this.$refs.form.validate())) {
                this.$toast.error("Please check for errors", {
                    duration: 5000,
                });

                return;
            }

            if (this.editing) {
                this.update();
                return;
            }

            this.store();
        },

        update() {
            this.form.put(
                this.route("staff.info.password.update", this.form.id),
                {
                    onSuccess: () => {
                        this.show = false;
                    },
                }
            );
        },
        store() {
            this.form.post(this.route("staff.info.password.store"), {
                onSuccess: () => {
                    this.show = false;
                },
            });
        },
    },
};
</script>

<style scoped></style>
