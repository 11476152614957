<template>
    <div style="display: grid; position: relative">
        <div
            style="
                position: sticky;
                top: 120px;
                z-index: 2;
                background-color: white;
            "
            :style="[{ top: topHeight }, gridRow]"
            class="grid-row blue-grey lighten-3 text-center text-caption text--white py-4"
        >
            <div
                style="position: sticky; left: 0"
                class="blue-grey lighten-3"
            />
            <template v-if="type === 'week'">
                <div
                    v-for="index in sections"
                    :key="index"
                    v-html="calculateSectionDate(index)"
                />
            </template>
            <div v-if="type === 'day'" v-html="getSectionDateText(day)" />
        </div>
        <div
            :style="gridRow"
            style="position: absolute; width: 100%; height: 100%"
            class="grid-row"
        >
            <span
                v-for="section in sections + 1"
                :key="section"
                class="grid-row-lines"
            />
        </div>

        <!--Users-->
        <div v-if="showUsers">
            <logistics-chart-title-row
                :grid-row-style="gridRow"
                title="Users"
            />

            <logistic-chart-row
                v-for="row in users"
                :key="`user-${row.id}`"
                :row="row"
                :sections="sections"
                :per-section="perSection"
                :range-start="rangeStart"
                @taskClick="$emit('taskClick', $event)"
                @holidayClick="holidayClick"
                :filters="filters"
                :admin="hasPermission('staffAdminTimesheets')"
            />
        </div>

        <!--Freelancers-->
        <div v-if="showFreelancers">
            <logistics-chart-title-row
                :grid-row-style="gridRow"
                title="Freelancers"
            />

            <logistic-chart-row
                v-for="row in freelancers"
                :key="`freelancer-${row.id}`"
                :row="row"
                :sections="sections"
                :per-section="perSection"
                :range-start="rangeStart"
                @taskClick="$emit('taskClick', $event)"
                :filters="filters"
                :admin="hasPermission('staffAdminTimesheets')"
            />
        </div>

        <!--Vehicles-->
        <div v-if="showVehicles">
            <logistics-chart-title-row
                :grid-row-style="gridRow"
                title="Vehicles"
            />

            <logistic-chart-row
                v-for="row in vehicles"
                :key="`vehicle-${row.id}`"
                :row="row"
                :sections="sections"
                :per-section="perSection"
                :range-start="rangeStart"
                @taskClick="$emit('taskClick', $event)"
                :filters="filters"
            />
        </div>

        <holiday-view-modal ref="holidayModal" />
    </div>
</template>

<script>
import LogisticChartRow from "./LogisticChartRow.vue";
import LogisticsChartTitleRow from "./LogisticsChartTitleRow.vue";
import HolidayViewModal from "../../Holiday/HolidayViewModal.vue";
import Permissions from "../../../../Mixins/Permissions.js";
export default {
    name: "LogisticsChart",

    components: { HolidayViewModal, LogisticsChartTitleRow, LogisticChartRow },

    mixins: [Permissions],

    props: {
        thisWeek: {
            type: Object,
            required: true,
        },
        filters: {
            type: Array,
            required: true,
        },
        day: {
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        topHeight: {
            type: String,
            required: true,
        },
    },

    methods: {
        holidayClick(holiday) {
            if (this.hasPermission("staffAdminHolidays")) {
                this.$refs.holidayModal.open(holiday);
            }
        },

        getSectionDateText(date) {
            const newDate = this.moment(date);
            return `
                <div>${newDate.format("dddd")}</div>
                <div>${newDate.format("Do MMM YY")}</div>
            `;
        },

        calculateSectionDate(index) {
            const date = this.thisWeek.clone().add(index - 1, "days");

            return this.getSectionDateText(date);
        },

        pickKeys(task) {
            let keys = _.pick(task, [
                "users",
                "freelancers",
                "category",
                "job",
                "name",
                "text",
                "day",
                "time_from",
                "time_to",
                "vehicles",
            ]);

            return _.mapValues(keys, (value, key) => {
                if (_.includes(["users", "freelancers", "vehicles"], key)) {
                    return _.map(value, _.partialRight(_.pick, "id"));
                }
                return value;
            });
        },
    },

    computed: {
        rangeStart() {
            if (this.type === "week") {
                return this.thisWeek;
            }

            return this.moment.utc(this.day);
        },

        sections() {
            if (this.type === "day") {
                return 1;
            }

            return 7;
        },

        perSection() {
            return 288;
        },

        showUsers() {
            return this.filters.includes("Users");
        },

        showFreelancers() {
            return (
                this.filters.includes("Freelancers") &&
                !this.filters.includes("Only Me")
            );
        },

        showVehicles() {
            return (
                this.filters.includes("Vehicles") &&
                !this.filters.includes("Only Me")
            );
        },

        gridRow() {
            return {
                "grid-template-columns": `150px repeat(${this.sections}, minmax(250px, 1fr))`,
            };
        },

        logistic() {
            return this.$page.props.logistic;
        },

        tasksForRange() {
            if (this.type === "week") {
                return this.logistic.tasks;
            }

            return this.logistic.tasks.filter((task) => {
                return (
                    this.moment(task.starts_at).isBefore(
                        this.moment(this.day).add("1", "day")
                    ) && this.moment(task.ends_at).isAfter(this.day)
                );
            });
        },

        tasks() {
            return this.tasksForRange.map((task) => {
                task.deleted = !!task.deleted_at;
                task.firstVersion = this.logistic.version <= 1;
                task.hasPrevious = !!task.previous_task;
                task.newTask = task.deleted
                    ? false
                    : !task.hasPrevious && !task.firstVersion;
                task.changed =
                    task.newTask || task.deleted || task.firstVersion
                        ? false
                        : !_.isEqual(
                              this.pickKeys(task),
                              this.pickKeys(task.previous_task)
                          );

                return task;
            });
        },

        holidays() {
            if (this.type === "week") {
                return this.$page.props.approvedHolidays;
            }

            return this.$page.props.approvedHolidays.filter((holiday) => {
                return (
                    this.moment
                        .utc(holiday.starts_at)
                        .isSameOrBefore(
                            this.moment.utc(this.day).endOf("day")
                        ) &&
                    this.moment
                        .utc(holiday.ends_at)
                        .isSameOrAfter(this.moment.utc(this.day))
                );
            });
        },

        usersOnlyMe() {
            return this.filters.includes("Only Me")
                ? [this.$page.props.auth.user]
                : this.$page.props.users;
        },

        users() {
            return this.usersOnlyMe.reduce((carry, user) => {
                this.$set(
                    user,
                    "tasks",
                    _.sortBy(
                        this.tasks.filter((task) =>
                            task.users.some(
                                (taskUser) => taskUser.id === user.id
                            )
                        ),
                        "starts_at"
                    )
                );

                this.$set(
                    user,
                    "holidays",
                    _.sortBy(
                        this.holidays.filter(
                            (holiday) => holiday.user_id === user.id
                        ),
                        "starts_at"
                    )
                );

                if (
                    (!user.hide_from_logistics &&
                        !this.filters.includes("Hide Available Users")) ||
                    this.filters.includes("Only Me") ||
                    user.holidays.length ||
                    user.tasks.length
                ) {
                    carry.push(user);
                }

                return carry;
            }, []);
        },

        freelancers() {
            return this.$page.props.freelancers.reduce((carry, freelancer) => {
                this.$set(
                    freelancer,
                    "tasks",
                    _.sortBy(
                        this.tasks.filter((task) =>
                            task.freelancers.some(
                                (taskUser) => taskUser.id === freelancer.id
                            )
                        ),
                        "starts_at"
                    )
                );

                this.$set(freelancer, "holidays", []);

                this.$set(freelancer, "avatar", freelancer.photo.url);

                if (freelancer.tasks.length) {
                    carry.push(freelancer);
                }

                return carry;
            }, []);
        },

        vehicles() {
            return this.$page.props.vehicles.reduce((carry, vehicle) => {
                const clone = _.cloneDeep(vehicle);

                clone.tasks = _.sortBy(
                    this.tasks.filter((task) =>
                        task.vehicles.some(
                            (taskUser) => taskUser.id === vehicle.id
                        )
                    ),
                    "starts_at"
                );

                clone.holidays = [];

                clone.avatar = null;

                clone.name = `${vehicle.name} - ${vehicle.reg}`;

                if (!vehicle.hired || clone.tasks.length) {
                    carry.push(clone);
                }

                return carry;
            }, []);
        },
    },
};
</script>

<style scoped lang="scss">
.grid-row {
    display: inline-grid;
}

.grid-row-lines {
    border-right: solid 1px rgba(0, 0, 0, 0.1);
}
</style>
