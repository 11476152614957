<template>
    <v-container class="fixed">
        <header-name>Users</header-name>

        <v-row class="mt-6">
            <v-col cols="12" md="6">
                <v-text-field v-model="search" dense outlined label="Search" />
            </v-col>
            <v-col cols="12" md="6">
                <v-select
                    label="Filter"
                    :items="['Active', 'Deleted']"
                    v-model="filter"
                    dense
                    outlined
                    @change="updateData"
                />
            </v-col>
        </v-row>

        <div v-if="hasPermission('staffAdminUsers')" class="d-flex">
            <v-btn
                class="ml-auto mb-2"
                @click="$refs.createUserModal.create()"
                color="success"
                small
            >
                Create User
            </v-btn>
        </div>

        <create-user-modal ref="createUserModal" />

        <v-data-table
            :headers="[
                { text: '', value: 'avatar' },
                { text: 'Name', value: 'name' },
                { text: 'Email', value: 'email' },
                { text: 'Status', value: 'active' },
                { text: '', value: 'options' },
            ]"
            :search="searchString"
            :items="users"
            @click:row="gotoUser"
            no-results-text="No Users have been found"
            class="elevation-1 row-pointer"
            :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
            :items-per-page="25"
        >
            <template v-slot:item.active="{ item }">
                <span v-if="item.deleted_at" class="red--text">Deleted</span>
                <span v-else class="green--text">Active</span>
            </template>

            <template v-slot:item.avatar="{ item }">
                <v-avatar size="40px"
                    ><v-img :src="item.avatar"></v-img
                ></v-avatar>
            </template>

            <template v-slot:item.options="{ item }">
                <v-btn
                    v-if="hasPermission('staffAdminUsers')"
                    class="mr-2"
                    elevation="2"
                    v-tooltip="'View User'"
                    color="primary"
                    @click="gotoUser(item)"
                    icon
                >
                    <v-icon>mdi-account</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import HeaderName from "../../../../Comps/General/HeaderName";
import AdminController from "../../../../Mixins/AdminController";
import Permissions from "../../../../Mixins/Permissions";
import CreateUserModal from "../../../../Comps/Staff/Users/CreateUserModal";

export default {
    name: "Users",
    components: { CreateUserModal, HeaderName },
    props: ["users"],
    mixins: [AdminController, Permissions],

    data() {
        return {
            filter: "Active",
            searchString: null,
        };
    },

    watch: {
        "$page.props.deleted": {
            immediate: true,
            handler(value) {
                if (value) {
                    this.filter = "Deleted";
                }
            },
        },
    },

    computed: {
        search: {
            get() {
                return this.searchString;
            },
            set: _.debounce(function (value) {
                this.searchString = value;
            }, 500),
        },
        deleted() {
            return this.filter === "Deleted";
        },
    },

    methods: {
        updateData() {
            this.$inertia.visit(this.route("staff.admin.users.index"), {
                method: "get",
                data: {
                    deleted: this.deleted,
                },
            });
        },
        gotoUser(user) {
            this.$inertia.visit(this.route("staff.admin.users.show", user.id), {
                method: "get",
            });
        },
    },
};
</script>

<style scoped></style>
