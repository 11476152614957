<template>
    <v-dialog ref="dialog" v-model="modal" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
            <validation-provider
                v-slot="{ errors }"
                :name="name"
                :class="{ 'd-inline-block': inline }"
                rules="required"
            >
                <v-text-field
                    v-model="dateInput"
                    :label="name"
                    prepend-icon="mdi-calendar-range"
                    readonly
                    :dense="dense"
                    :outlined="outlined"
                    required
                    :hint="hint"
                    persistent-hint
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
            </validation-provider>
        </template>
        <v-date-picker
            v-if="modal"
            v-model="date"
            :allowed-dates="allowedDatesCallback"
            full-width
        >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(date)">
                OK
            </v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", required);

export default {
    name: "DatePicker",
    props: {
        value: {
            type: [Object, String],
        },
        name: {
            type: String,
        },
        hint: {
            type: String,
        },
        allowedDates: {
            type: Function,
            default: null,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        showYear: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            modal: false,
        };
    },
    methods: {
        allowedDatesCallback(e) {
            if (this.allowedDates) {
                return this.allowedDates(e);
            } else {
                return e;
            }
        },
    },
    computed: {
        date: {
            get() {
                if (this.value) {
                    return this.moment(this.value).format("YYYY-MM-DD");
                } else {
                    return "";
                }
            },
            set(value) {
                this.$emit("input", this.moment(value).format("YYYY-MM-DD"));
                this.$emit("dateChanged");
            },
        },
        dateInput() {
            if (this.date) {
                return this.showYear
                    ? this.momentFormatDateYear(this.date)
                    : this.momentFormatDate(this.date);
            }

            return null;
        },
    },
};
</script>

<style scoped></style>
