import { render, staticRenderFns } from "./LolerShow.vue?vue&type=template&id=03ae2c81&scoped=true"
import script from "./LolerShow.vue?vue&type=script&lang=js"
export * from "./LolerShow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03ae2c81",
  null
  
)

export default component.exports