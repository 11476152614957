<template>
    <v-sheet elevation="2" class="pa-4">
        <div class="text-subtitle-2 mb-4">
            Scan or type an asset below to search current
        </div>

        <form novalidate @submit.prevent="search">
            <validation-observer ref="form">
                <validation-provider
                    v-slot="{ errors }"
                    name="Asset"
                    rules="required"
                >
                    <v-text-field
                        v-model="asset"
                        dense
                        outlined
                        name="Asset"
                        autofocus
                        :error-messages="errors"
                        label="Asset"
                        class="required"
                        required
                    >
                    </v-text-field>
                </validation-provider>
            </validation-observer>

            <v-btn color="success" type="submit" :loading="form.processing">
                Search
            </v-btn>
        </form>
    </v-sheet>
</template>

<script>
export default {
    name: "AssetSearchForm",

    props: {
        routeName: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            form: this.$inertia.form({}),
            asset: null,
        };
    },

    methods: {
        search() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.form.get(this.route(this.routeName, this.asset), {
                    onSuccess: () => {
                        this.form.reset();
                        this.$refs.form?.reset();
                    },
                });
            });
        },
    },
};
</script>

<style scoped></style>
