<template>
    <div
        :style="gridRowStyle"
        style="width: 100%"
        class="grid-row blue-grey lighten-5 text-center text-caption text--white"
    >
        <div
            class="blue-grey lighten-5"
            style="
                position: sticky;
                left: 0;
                border-width: 0 1px 0 1px;
                border-color: rgba(0, 0, 0, 0.2) !important;
                border-style: solid;
            "
        >
            <strong v-text="title" />
        </div>
        <div></div>
    </div>
</template>

<script>
export default {
    name: "LogisticsChartTitleRow",

    props: {
        gridRowStyle: {
            required: true,
        },
        title: {
            required: true,
            type: String,
        },
    },
};
</script>

<style scoped></style>
