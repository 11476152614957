<template>
    <modal-template native v-model="show" v-if="task">
        <v-card>
            <v-card-title
                :style="{ 'background-color': backgroundColor }"
                class="d-flex"
            >
                <div class="white--text">
                    <task-category-icon
                        :category="task.category"
                        class="mr-2"
                        color="white"
                    >
                    </task-category-icon>
                    {{ task.name }}
                    <div class="d-inline-block" v-if="task.job">
                        &nbsp; -
                        <a-tag-with-external-icon
                            :url="task.job.url"
                            :text="task.job.number"
                            classes="white--text"
                        >
                        </a-tag-with-external-icon>
                    </div>
                </div>
                <div class="ml-auto" v-if="!user">
                    <inertia-link
                        as="div"
                        class="d-inline-block"
                        :href="
                            route('staff.admin.logistics.tasks.show', [
                                this.logistic.slug,
                                this.task.id,
                            ])
                        "
                    >
                        <v-btn
                            class="mx-2"
                            fab
                            x-small
                            color="white"
                            v-tooltip="'Edit task'"
                        >
                            <v-icon dark> mdi-pen</v-icon>
                        </v-btn>
                    </inertia-link>

                    <v-menu top close-on-click close-on-content-click>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" dark v-on="on">
                                mdi-dots-vertical
                            </v-icon>
                        </template>

                        <v-list>
                            <inertia-link
                                as="span"
                                :href="
                                    route('staff.admin.logistics.tasks.show', [
                                        this.logistic.slug,
                                        this.task.id,
                                    ])
                                "
                            >
                                <v-list-item link v-if="!deleted">
                                    <v-list-item-title>
                                        Edit Task
                                    </v-list-item-title>
                                </v-list-item>
                            </inertia-link>
                            <v-list-item
                                v-if="!deleted && !live"
                                link
                                @click="cloneTask"
                            >
                                <v-list-item-title>
                                    Clone Task
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item link @click="deleteTask" v-if="!live">
                                <v-list-item-title
                                    :class="
                                        !deleted
                                            ? 'error--text'
                                            : 'success--text'
                                    "
                                >
                                    {{
                                        !deleted
                                            ? "Delete this task"
                                            : "Restore this task"
                                    }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="mt-3">
                <div v-if="!clone">
                    <div class="d-flex align-start">
                        <!--Chip-->
                        <v-chip
                            v-if="showChip"
                            class="mb-1"
                            dark
                            x-small
                            label
                            :color="chipColor"
                        >
                            {{ chipText }}
                        </v-chip>
                    </div>

                    <task-info-modal-info
                        :key="task.id"
                        :task="task"
                        :logistic="logistic"
                        :user="user"
                        @newNote="open"
                    />
                </div>
                <task-info-modal-clone
                    :task="task"
                    v-if="clone"
                    :logistic="logistic"
                />
            </v-card-text>
        </v-card>
    </modal-template>
</template>

<script>
import TaskInfoModalInfo from "./TaskInfoModalInfo";
import TaskInfoModalClone from "./TaskInfoModalClone";
import ATagWithExternalIcon from "../../../../General/ATagWithExternalIcon";
import TaskCategoryIcon from "../../TaskCategoryIcon";
import LogisticTaskMixin from "../../../../../Mixins/LogisticTaskMixin";
import ModalTemplate from "../../../../Master/ModalTemplate";

export default {
    name: "TaskInfoModal",
    components: {
        ModalTemplate,
        TaskCategoryIcon,
        ATagWithExternalIcon,
        TaskInfoModalClone,
        TaskInfoModalInfo,
    },
    props: {
        live: {
            type: Boolean,
            default: false,
        },
        logistic: {
            type: Object,
            required: false,
        },
        user: {
            type: Boolean,
            default: false,
        },
    },

    mixins: [LogisticTaskMixin],

    data() {
        return {
            show: false,
            clone: false,
            task: null,
        };
    },

    methods: {
        open(task) {
            this.task = task;
            this.show = true;
        },
        deleteTask() {
            this.$inertia.delete(
                this.route("staff.admin.logistics.tasks.destroy", [
                    this.logistic.slug,
                    this.task.id,
                ]),
                {
                    preserveState: false,
                }
            );
        },
        cloneTask() {
            this.clone = true;
        },
    },
};
</script>

<style scoped></style>
