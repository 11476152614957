<template>
    <div>{{ animatedNumber }}</div>
</template>

<script>
import gsap from "gsap";

export default {
    name: "NumberAnimation",

    props: {
        to: {
            type: Number,
            required: true,
        },
        from: {
            type: Number,
            default: 0,
        },
        fractionDigits: {
            type: Number,
            default: 1,
        },
    },

    data() {
        return {
            tweenedNumber: this.from,
        };
    },

    watch: {
        to: {
            immediate: true,
            handler(newValue) {
                gsap.to(this.$data, { duration: 1, tweenedNumber: newValue });
            },
        },
    },

    computed: {
        animatedNumber: function () {
            return this.tweenedNumber.toFixed(this.fractionDigits);
        },
    },
};
</script>

<style scoped></style>
