var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',_vm._g({staticClass:"text-center align-self-start",staticStyle:{"position":"relative"},attrs:{"width":_vm.width,"height":_vm.height,"elevation":"0"}},_vm.type !== 'other' ? { click: _vm.showFile } : {}),[(_vm.showDelete)?_c('v-btn',{staticStyle:{"top":"-14px","right":"-14px"},attrs:{"fab":"","x-small":"","color":"error","absolute":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('delete')}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),_vm._v(" "),(_vm.type === 'image' && _vm.src)?_c('img',{style:({
            'max-width': _vm.maxWidth,
            'max-height': _vm.maxHeight,
            objectFit: 'contain',
        }),attrs:{"src":_vm.src,"alt":_vm.name}}):_vm._e(),_vm._v(" "),(_vm.type === 'video' && _vm.src)?_c('video',{style:({
            'max-width': _vm.maxWidth,
            'max-height': _vm.maxHeight,
            objectFit: 'contain',
        }),attrs:{"src":_vm.src,"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true}}):_vm._e(),_vm._v(" "),(_vm.type === 'other' || _vm.type === 'pdf')?_c('v-sheet',{staticClass:"my-3 pa-2",class:{ 'grey darken-3': _vm.$vuetify.theme.isDark },attrs:{"elevation":"2"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-file")]),_vm._v(" "),_c('div',{staticClass:"text-caption font-weight-bold text-uppercase my-1"},[_vm._v("\n            "+_vm._s(_vm.name ? _vm.name : "File")+"\n        ")])],1):_vm._e(),_vm._v(" "),_c('file-display-modal',{ref:"displayModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }