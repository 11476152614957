<template>
    <div>
        <div v-if="daysToShow.length">
            <div v-for="(day, index) in daysToShow">
                <v-container>
                    <v-layout align-center justify-center
                        ><span>{{ getDay(day[0].date) }}</span></v-layout
                    >
                </v-container>

                <v-timeline>
                    <v-timeline-item
                        v-for="task in day"
                        :key="task.id"
                        :color="task.category.color"
                        :icon="task.category.icon"
                    >
                        <v-card
                            :color="task.category.color"
                            v-if="!task.holiday"
                            dark
                            link
                            @click="openInfo(task)"
                        >
                            <v-card-title
                                style="
                                    font-size: 12px;
                                    padding: 5px;
                                    line-height: 15px;
                                    min-height: 40px;
                                "
                                class="d-flex flex-column align-start"
                            >
                                <div>
                                    {{ task.name }}
                                    <span v-if="task.job"
                                        >&nbsp; - {{ task.job.number }}</span
                                    >
                                </div>
                                <div v-if="countPeople(task)" class="mt-3">
                                    {{ countPeople(task) }}
                                    {{
                                        countPeople(task) > 1
                                            ? "People"
                                            : "Person"
                                    }}
                                </div>
                                <div v-if="task.vehicles.length">
                                    {{ task.vehicles.length }}
                                    {{
                                        task.vehicles.length > 1
                                            ? "Vehicles"
                                            : "Vehicle"
                                    }}
                                </div>
                            </v-card-title>
                        </v-card>

                        <v-card
                            :color="task.category.color"
                            v-if="task.holiday"
                            dark
                            link
                            @click="clickHoliday(task)"
                        >
                            <v-card-title
                                style="
                                    font-size: 12px;
                                    padding: 5px;
                                    line-height: 15px;
                                    min-height: 40px;
                                "
                                class="d-flex flex-column align-start"
                            >
                                <timeline-holiday-task :task="task" />
                            </v-card-title>
                        </v-card>

                        <template v-slot:opposite>
                            <div :style="{ color: task.category.color }">
                                <div v-if="task.time_from">
                                    <span>{{
                                        getStartTime(task.time_from)
                                    }}</span>
                                    <span v-if="task.time_to">
                                        - {{ getStartTime(task.time_to) }}</span
                                    >
                                </div>
                                <div v-else>All Day</div>
                            </div>
                        </template>
                    </v-timeline-item>
                </v-timeline>
            </div>
        </div>

        <div v-else>
            <h5 class="text-center">No tasks left for this week</h5>
        </div>

        <task-info-modal
            ref="taskInfoModal"
            :user="user"
            :logistic="logistic"
        />

        <logistics-holiday-info-modal ref="holidayModal" />
    </div>
</template>

<script>
import TaskInfoModal from "../TaskInfoModal/TaskInfoModal";
import TimelineHolidayTask from "./TimelineHolidayTask";
import LogisticsHolidayInfoModal from "../LogisticsHolidayInfoModal";

export default {
    name: "Timeline",
    components: {
        LogisticsHolidayInfoModal,
        TimelineHolidayTask,
        TaskInfoModal,
    },
    props: {
        tasks: {
            type: Array,
        },
        logistic: {
            type: Object,
        },
        user: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            groupByDay: {},
            days: [],
        };
    },

    created() {
        this.groupDays();
    },

    methods: {
        getDay(date) {
            return this.moment(date).format("ddd Do MMM");
        },
        getStartTime(time) {
            return this.moment(time).utc().format("HH:mm");
        },
        openInfo(task) {
            this.$refs.taskInfoModal.open(task);
        },
        groupDays() {
            this.groupByDay = _.groupBy(this.allTasksWithHoliday, (task) => {
                return this.moment(task.date).format("YYYY-MM-DD");
            });
        },
        countPeople(event) {
            let users = 0;
            let freelancers = 0;
            if (event.users.length) {
                users = event.users.length;
            }
            if (event.freelancers.length) {
                freelancers = event.freelancers.length;
            }
            return users + freelancers;
        },
        clickHoliday(holiday) {
            this.$refs.holidayModal.open(this.moment.utc(holiday.date));
        },
    },
    computed: {
        weekForVue() {
            return this.moment(this.logistic.week).format("YYYY-MM-DD");
        },
        daysToShow() {
            if (this.user) {
                let now = this.moment().startOf("day");
                return _.filter(this.groupByDay, (group, index) => {
                    return this.moment(index).isSameOrAfter(now);
                });
            }
            return _.values(this.groupByDay);
        },

        allTasksWithHoliday() {
            let tasks = this.tasks;

            if (this.$page.props.approvedHolidays && this.logistic) {
                let week = this.moment(this.weekForVue);
                for (let i = 0; i < 7; i++) {
                    tasks.unshift({
                        date: week.clone().add(i, "day").format("YYYY-MM-DD"),
                        category: {
                            color: "orange",
                            icon: "mdi-beach",
                        },
                        holiday: true,
                        name: "Holiday",
                    });
                }
            }

            return _.sortBy(tasks, "date");
        },
    },
};
</script>
