<template>
    <expense-show admin />
</template>

<script>
import AdminController from "../../../../Mixins/AdminController";
import ExpenseShow from "../../../../Comps/Staff/Expenses/ExpenseShow";

export default {
    name: "Expense",
    components: { ExpenseShow },
    mixins: [AdminController],
};
</script>

<style scoped></style>
