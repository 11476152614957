<template>
    <modal-template v-model="modal">
        <div class="text-h6 text--secondary mb-2 mt-2">
            {{ form.id ? "Edit" : "Create" }} a Hire Site User
        </div>

        <form novalidate @submit.prevent="submit">
            <validation-observer ref="validation">
                <v-row>
                    <v-col cols="12" md="6">
                        <autocomplete-search-current
                            :key="form.id"
                            v-model="form.organisation"
                            :load-items="initialOrg"
                            clearable
                            dense
                            outlined
                            hide-icon
                            route-name="staff.admin.hire-site-users.organisation-search"
                            label="Linked Organisation"
                            hint="Select an organisation to link to this user"
                            :debounce-time="750"
                        />
                    </v-col>
                </v-row>
            </validation-observer>

            <v-btn
                color="success"
                type="submit"
                class="mt-6"
                :loading="form.processing"
                :disabled="!form.isDirty"
            >
                Save
            </v-btn>
        </form>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../../../../../Comps/Master/ModalTemplate.vue";
import AutocompleteSearchCurrent from "../../../../../../Comps/General/AutocompleteSearchCurrent.vue";

export default {
    name: "MainCategoryModal",
    components: {
        AutocompleteSearchCurrent,
        ModalTemplate,
    },
    data() {
        return {
            form: this.$inertia.form({
                id: null,
                organisation: null,
            }),
            modal: false,
        };
    },

    methods: {
        edit(user) {
            const formUser = {
                ...user,
                organisation: null,
            };

            if (user.organisation_id) {
                formUser.organisation = {
                    id: user.organisation_id,
                    name: user.organisation_name,
                };
            }

            this.form = this.$inertia.form(formUser);

            this.modal = true;
        },

        async submit() {
            if (!(await this.$refs.validation.validate())) {
                return;
            }

            this.form.put(
                this.route("staff.admin.hire-site-users.update", this.form.id),
                {
                    onSuccess: () => {
                        this.modal = false;
                    },
                    preserveScroll: true,
                    preserveState: true,
                }
            );
        },
    },

    computed: {
        initialOrg() {
            if (this.form.organisation_id) {
                return [
                    {
                        id: this.form.organisation_id,
                        name: this.form.organisation_name,
                    },
                ];
            }

            return [];
        },
    },
};
</script>
