<template>
    <v-container>
        <header-name>
            Timesheet for month starting
            {{ momentFormatDateYear($page.props.timesheet.start_date) }}
        </header-name>

        <breadcrumbs :items="breadItems" />

        <timesheet-show />
    </v-container>
</template>

<script>
import HeaderName from "../../../Comps/General/HeaderName.vue";
import PeopleController from "../../../Mixins/PeopleController.js";
import Breadcrumbs from "../../../Comps/General/Breadcrumbs.vue";
import TimesheetShow from "../../../Comps/Staff/Timesheets/TimesheetShow.vue";

export default {
    name: "Timesheet",

    components: {
        TimesheetShow,
        Breadcrumbs,
        HeaderName,
    },

    mixins: [PeopleController],

    computed: {
        breadItems() {
            return [
                {
                    text: "Timesheets",
                    disabled: false,
                    link: this.route("staff.timesheets.index"),
                },
                {
                    text: `Timesheet ${this.momentFormatDateYear(
                        this.$page.props.timesheet.start_date
                    )}`,
                    disabled: true,
                },
            ];
        },
    },
};
</script>

<style scoped></style>
