import { render, staticRenderFns } from "./ShowProfile.vue?vue&type=template&id=66a6f533&scoped=true"
import script from "./ShowProfile.vue?vue&type=script&lang=js"
export * from "./ShowProfile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66a6f533",
  null
  
)

export default component.exports