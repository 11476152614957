<template>
    <v-app>
        <v-main>
            <div style="height: 100%">
                <v-row class="justify-center fill-height" align="center">
                    <v-col cols="11" md="6">
                        <div>
                            <v-img
                                contain
                                max-height="150"
                                :src="$page.props.files.pytchLogoWhite"
                                alt="PYTCH Logo"
                            />
                            <h2 class="mt-6 mb-0 text-h3 text-center">
                                Freelancer Portal
                            </h2>
                        </div>

                        <div>
                            <div>
                                <v-sheet class="pa-8 mt-4" rounded="lg">
                                    <h4
                                        class="mb-4 text-center text-h6 text-white font-weight-light"
                                    >
                                        <slot name="text" />
                                    </h4>
                                    <slot />
                                </v-sheet>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "AuthenticationLayout",

    created() {
        this.$vuetify.theme.dark = true;
    },
};
</script>

<style scoped></style>
