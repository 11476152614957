<template>
    <div>
        <template v-if="admin">
            <v-btn
                v-if="!changeAllowance"
                @click="changeAllowance = true"
                color="primary"
                absolute
                x-small
                style="top: 3px; right: 3px"
            >
                Edit
            </v-btn>
            <v-btn
                v-if="changeAllowance"
                @click="changeAllowance = false"
                color="error"
                absolute
                icon
                x-small
                style="top: 3px; right: 3px"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
        <v-scroll-x-transition mode="out-in">
            <div
                key="display"
                v-if="!changeAllowance"
                class="d-flex align-end font-weight-bold text-h6"
            >
                £
                <number-animation :to="to" :fraction-digits="2" />
                <span class="text-caption text--secondary ml-1">
                    out of £{{ outOf }}
                </span>
            </div>
            <div v-if="changeAllowance" class="mt-2" key="edit">
                <form novalidate @submit.prevent="saveAllowance">
                    <validation-observer ref="form">
                        <currency-input
                            v-model="form.value"
                            label="Allowance (£)"
                            hint="The total amount of allowance"
                        />
                    </validation-observer>
                    <v-btn
                        :loading="form.processing"
                        :disabled="!form.isDirty"
                        x-small
                        color="success"
                        type="submit"
                    >
                        Save
                    </v-btn>
                </form>
            </div>
        </v-scroll-x-transition>
    </div>
</template>

<script>
import NumberAnimation from "../../General/NumberAnimation";
import CurrencyInput from "../../General/Forms/CurrencyInput";

export default {
    name: "ExpenseAllowanceSingle",
    components: { CurrencyInput, NumberAnimation },

    props: {
        to: Number,
        outOf: String,
        totalAllowance: Number,
        admin: Boolean,
        type: String,
        user: Object,
    },

    data() {
        return {
            changeAllowance: false,
            form: this.$inertia.form({}),
        };
    },

    watch: {
        totalAllowance: {
            immediate: true,
            handler(value) {
                this.form = this.$inertia.form({
                    type: this.type,
                    value: value,
                });
            },
        },
    },

    methods: {
        saveAllowance() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.form.put(
                    this.route(
                        "staff.admin.users.updateExpenseAllowance",
                        this.user
                    ),
                    {
                        onSuccess: () => {
                            this.changeAllowance = false;
                        },
                    }
                );
            });
        },
    },
};
</script>

<style scoped></style>
