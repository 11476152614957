<template>
    <v-chip label :small="!large" :color="color">
        {{ status }}
    </v-chip>
</template>

<script>
export default {
    name: "ExpenseSheetStatusChip",

    props: {
        status: String,
        large: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        color() {
            const colors = {
                Open: "",
                Pending: "primary",
                Approved: "warning",
                Paid: "success",
            };

            return colors[this.status] ?? "";
        },
    },
};
</script>

<style scoped></style>
