<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6">
                <header-name>Timesheets</header-name>
            </v-col>
            <v-col cols="12" md="6" class="text-right mb-4">
                <v-btn color="success" @click="openModal" small>
                    Create Timesheet
                </v-btn>
            </v-col>
        </v-row>

        <v-sheet elevation="2">
            <v-sheet class="px-4 pt-4">
                <v-row dense>
                    <v-col cols="12" md="5">
                        <v-select
                            dense
                            outlined
                            label="Status"
                            v-model="statusFilter"
                            :items="$page.props.allStatus"
                            multiple
                        />
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-select
                            dense
                            outlined
                            label="Month Starting"
                            v-model="monthFilter"
                            :items="$page.props.monthsList"
                        >
                            <template v-slot:item="{ item }">
                                {{ momentFormatDateYear(item) }}
                            </template>
                            <template v-slot:selection="{ item }">
                                {{ momentFormatDateYear(item) }}
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-btn
                            color="success"
                            class="mt-1"
                            @click="filter"
                            small
                        >
                            Filter
                        </v-btn>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-divider class="mb-4" />

            <v-data-table
                :items="sheets.data"
                sort-by="user.name"
                hide-default-footer
                disable-pagination
                :headers="headers"
                @click:row="viewSheet"
                class="row-pointer"
            >
                <template v-slot:item.user.name="{ item }">
                    <inertia-link
                        @click.stop
                        :href="route('staff.admin.users.show', item.user)"
                    >
                        {{ item.user.name }}
                    </inertia-link>
                </template>
                <template v-slot:item.status="{ value }">
                    <timesheet-status-chip :status="value" />
                </template>

                <template v-slot:item.options="{ item }">
                    <div class="text-right">
                        <v-menu>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on">
                                    mdi-dots-vertical
                                </v-icon>
                            </template>

                            <v-list dense>
                                <v-list-item link @click="viewSheet(item)">
                                    <v-list-item-title class="primary--text">
                                        View
                                    </v-list-item-title>
                                </v-list-item>
                                <v-divider v-if="item.status === 'Open'" />
                                <v-list-item
                                    v-if="item.status === 'Open'"
                                    link
                                    @click="remove(item)"
                                >
                                    <v-list-item-title class="error--text">
                                        Delete
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <template v-slot:item.totals.standard_hours="{ value }">
                    <span
                        :class="{ 'warning--text': value < 0 }"
                        v-text="formatTwoDecimalPlaces(value)"
                    />
                </template>
                <template v-slot:item.totals.overtime_hours="{ value }">
                    {{ formatTwoDecimalPlaces(value) }}
                </template>
                <template v-slot:item.overtime_pay="{ value }">
                    £{{ formatMoney(value) }}
                </template>
                <template v-slot:item.totals.commission_pay="{ value }">
                    £{{ formatMoney(value) }}
                </template>
                <template v-slot:item.totals.bonus_pay="{ value }">
                    £{{ formatMoney(value) }}
                </template>
                <template v-slot:item.totals.total_pay="{ value }">
                    £{{ formatMoney(value) }}
                </template>
            </v-data-table>
        </v-sheet>
        <admin-timesheet-create-modal ref="createModal" />
    </v-container>
</template>

<script>
import AdminController from "../../../../Mixins/AdminController.js";
import HeaderName from "../../../../Comps/General/HeaderName.vue";
import AdminTimesheetCreateModal from "../../../../Comps/Staff/Timesheets/AdminTimesheetCreateModal.vue";
import TimesheetStatusChip from "../../../../Comps/Staff/Timesheets/TimesheetStatusChip.vue";

export default {
    name: "Timesheets",
    components: {
        TimesheetStatusChip,
        AdminTimesheetCreateModal,
        HeaderName,
    },
    mixins: [AdminController],

    data() {
        return {
            loadingBatch: false,
            statusFilter: [],
            monthFilter: null,
        };
    },

    mounted() {
        this.checkQueryObject();
    },

    methods: {
        openModal() {
            this.$refs.createModal.open();
        },
        checkQueryObject() {
            this.statusFilter = this.mountedStatusFilter;
            this.monthFilter = this.mountedMonthFilter;
        },

        filter() {
            const data = {};

            if (this.statusFilter.length) {
                data["filter[status]"] = this.statusFilter;
            }

            if (this.monthFilter) {
                data["filter[month]"] = this.monthFilter;
            }

            this.$inertia.get(
                this.route("staff.admin.timesheets.index"),
                data,
                {
                    only: ["sheets", "queryObject", "flash"],
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => {
                        this.checkQueryObject();
                    },
                }
            );
        },

        viewSheet(item) {
            this.$inertia.visit(
                this.route("staff.admin.timesheets.show", item)
            );
        },

        remove(timesheet) {
            if (!confirm("Are you sure you want to delete this timesheet?")) {
                return;
            }

            this.$inertia.delete(
                this.route("staff.timesheets.destroy", timesheet)
            );
        },
    },

    computed: {
        headers() {
            return [
                {
                    text: "Name",
                    value: "user.name",
                },
                {
                    text: "Status",
                    value: "status",
                },
                {
                    text: "Standard Hours",
                    value: "totals.standard_hours",
                },
                {
                    text: "Overtime Hours",
                    value: "totals.overtime_hours",
                },
                {
                    text: "Overtime Rate",
                    value: "overtime_pay",
                },
                {
                    text: "Bonus Pay",
                    value: "totals.bonus_pay",
                },
                {
                    text: "Commission Pay",
                    value: "totals.commission_pay",
                },
                {
                    text: "Total Pay",
                    value: "totals.total_pay",
                },
                {
                    text: "",
                    value: "options",
                    sortable: false,
                },
            ];
        },

        mountedStatusFilter() {
            if (!this.queryObject.filter?.status) {
                return [];
            }

            return this.queryObject.filter.status;
        },
        mountedMonthFilter() {
            if (!this.queryObject.filter?.month) {
                return this.$page.props.currentMonth;
            }

            return this.queryObject.filter.month;
        },

        queryObject() {
            return this.$page.props.queryObject;
        },

        sheets() {
            return this.$page.props.sheets;
        },
    },
};
</script>

<style scoped></style>
