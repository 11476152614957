<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <div class="d-inline-block">
                <v-badge
                    v-if="expenseSheets.length"
                    :content="expenseSheets.length"
                >
                    Expenses
                </v-badge>
                <div v-else>Expenses</div>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <div class="text-caption text--secondary">
                Unpaid Expense Sheets
            </div>
            <v-list v-if="expenseSheets.length">
                <div v-for="expense in expenseSheets" :key="expense.id">
                    <v-divider></v-divider>
                    <inertia-link
                        class="inertia-link"
                        :href="route('staff.expense-sheets.show', expense)"
                    >
                        <v-list-item link>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ expense.number }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <expense-sheet-status-chip
                                    :status="expense.status"
                                />
                            </v-list-item-action>
                        </v-list-item>
                    </inertia-link>
                </div>
            </v-list>
            <div class="text-body-2" v-else>No expense sheets found</div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import ExpenseSheetStatusChip from "../Expenses/ExpenseSheetStatusChip";
export default {
    name: "DashboardExpenses",
    components: { ExpenseSheetStatusChip },
    computed: {
        expenseSheets() {
            return this.$page.props.expenseSheets.data;
        },
    },
};
</script>

<style scoped></style>
