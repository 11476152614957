<template>
    <span>
        <a :class="classes" :style="styles" :href="url" target="_blank">{{
            text
        }}</a>
        <v-icon
            style="vertical-align: top"
            :class="classes"
            :style="styles"
            x-small
            >mdi-open-in-new</v-icon
        >
    </span>
</template>

<script>
export default {
    name: "ATagWithExternalIcon",
    props: {
        url: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        classes: {
            type: String,
            required: false,
        },
        styles: {
            type: String,
            required: false,
        },
    },
};
</script>

<style scoped></style>
