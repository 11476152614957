<template>
    <expansion-panel
        icon="mdi-account-lock"
        header="Permissions"
        v-if="$page.props.auth.user.is_super_admin"
    >
        <div v-for="(group, groupName) in permissionsGroup" :key="groupName">
            <h4 class="body-2">{{ groupName }}</h4>
            <div class="d-flex flex-wrap">
                <v-checkbox
                    v-for="permission in group"
                    :key="permission.id"
                    :value="permission.id"
                    v-model="permissionStore"
                    :label="permission.long_name"
                    v-tooltip="permission.description"
                    class="ma-2"
                ></v-checkbox>
            </div>
        </div>

        <v-btn
            @click.prevent="storePermissions"
            :loading="permissionSubmit"
            color="success"
        >
            Save
        </v-btn>
    </expansion-panel>
</template>

<script>
import ExpansionPanel from "./ExpansionPanel";
export default {
    name: "PermissionsPanel",
    components: { ExpansionPanel },

    data() {
        return {
            permissionStore: _.map(
                this.$page.props.userPermissions,
                (permission) => {
                    return permission.id;
                }
            ),
            permissionSubmit: false,
        };
    },

    computed: {
        permissionsGroup() {
            return _.groupBy(this.$page.props.permissions, "category");
        },
    },

    methods: {
        storePermissions() {
            this.permissionSubmit = true;
            this.$inertia.post(
                this.route(
                    "staff.admin.users.storePermissions",
                    this.$page.props.user.id
                ),
                {
                    permissions: this.permissionStore,
                },
                {
                    only: ["user", "flash"],
                    onFinish: () => {
                        this.permissionSubmit = false;
                    },
                    preserveScroll: true,
                }
            );
        },
    },
};
</script>

<style scoped></style>
