var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fixed":""}},[_c('header-name',[_vm._v("Hire Site Users")]),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
            { text: 'Name', value: 'name' },
            { text: 'Email', value: 'email' },
            { text: 'Organisation', value: 'organisation_name' },
            { text: 'Organisation ID', value: 'organisation_id' },
            { text: 'Discount %', value: 'discount' },
            { text: 'Created At', value: 'created_at' },
            { text: '', value: 'options', align: 'end' },
        ],"search":_vm.search,"sort-by":"organisation_id","items":_vm.$page.props.hireSiteUsers,"no-data-text":"No users have been found"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"pa-4"},[_c('text-input',{attrs:{"name":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.options",fn:function({ item }){return [_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Edit'),expression:"'Edit'"}],staticClass:"mr-2",attrs:{"elevation":"2","color":"primary","icon":""},on:{"click":function($event){return _vm.$refs.usersModal.edit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.created_at",fn:function({ value }){return [_vm._v("\n            "+_vm._s(_vm.momentFormatDateTimeYear(value))+"\n        ")]}},{key:"item.organisation_id",fn:function({ value }){return [(value)?_c('div',[_vm._v("\n                "+_vm._s(value)+"\n            ")]):_c('div',[_c('v-chip',{staticClass:"white--text",attrs:{"x-small":"","label":"","color":"red"}},[_vm._v("Needs Onboarding")])],1)]}}])}),_vm._v(" "),_c('users-modal',{ref:"usersModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }