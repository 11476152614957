<template>
    <v-container>
        <header-name>Holidays</header-name>

        <div class="d-flex justify-center mb-4">
            <v-chip-group :value="selectedTab" active-class="primary" mandatory>
                <v-chip
                    v-for="tab in tabs"
                    :key="tab.value"
                    :value="tab.value"
                    @click="selectTab(tab)"
                >
                    {{ tab.text }}
                </v-chip>
            </v-chip-group>
        </div>

        <v-fade-transition group>
            <v-sheet v-if="selectedTab === 'list'" key="list" elevation="2">
                <holiday-table
                    show-user
                    show-requested-at
                    show-downloader
                    :holidays="$page.props.waitingApproval"
                >
                    No holidays waiting for approval 🙌
                </holiday-table>
            </v-sheet>

            <holiday-calendar
                v-if="selectedTab === 'calendar'"
                key="calendar"
                ref="holidayCalendar"
                :holidays="holidays"
            />
        </v-fade-transition>
    </v-container>
</template>

<script>
import AdminController from "../../../Mixins/AdminController";
import HeaderName from "../../../Comps/General/HeaderName";
import HolidayCalendar from "../../../Comps/Staff/Holiday/HolidayCalendar";
import TabMixin from "../../../Mixins/TabMixin";
import HolidayTable from "../../../Comps/Staff/Personal/Holiday/HolidayTable";

export default {
    name: "Holidays",
    components: {
        HolidayTable,
        HolidayCalendar,
        HeaderName,
    },
    mixins: [AdminController, TabMixin],
    props: {
        holidays: {
            type: Array,
            default: null,
        },
    },

    data() {
        return {
            tabs: [
                { value: "calendar", text: "Calendar" },
                { value: "list", text: "Awaiting approval" },
            ],
        };
    },
};
</script>

<style scoped></style>
