<template>
    <v-container class="fixed">
        <header-name>Freelancers</header-name>

        <v-row class="mt-6">
            <v-col cols="12" md="6">
                <text-input
                    name="Search"
                    clearable
                    v-model="search"
                    dense
                    outlined
                />
            </v-col>
            <v-col cols="12" md="6">
                <div class="mb-6">
                    <auto-complete
                        name="Filter Skills"
                        multiple
                        :items="$page.props.skills"
                        v-model="skillsFilter"
                        clearable
                        chips
                        small-chips
                        dense
                        outlined
                    />
                </div>
            </v-col>
        </v-row>

        <div class="d-flex">
            <v-btn
                color="success"
                class="ml-auto mb-2"
                small
                @click="$refs.createModal.create()"
            >
                Create Freelancer
            </v-btn>

            <v-btn
                color="secondary"
                small
                class="ml-2"
                v-tooltip="`Download as CSV`"
                @click="downloadCsv"
            >
                <v-icon>mdi-download</v-icon>
            </v-btn>
        </div>

        <freelancers-create-modal ref="createModal" />
        <freelancers-delete-modal
            v-model="deleteModel"
            :freelancer="freelancer"
        />

        <v-data-table
            :headers="[
                { text: '', value: 'photo' },
                { text: 'Name', value: 'name' },
                { text: 'Email', value: 'email' },
                { text: 'Telephone', value: 'tel_number' },
                { text: '', value: 'options' },
            ]"
            :items="filteredFreelancers"
            no-data-text="No freelancers have been found"
            class="elevation-1 row-pointer"
            @click:row="goToFreelancer"
            :search="searchString"
            sort-by="name"
            :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
            :items-per-page="25"
        >
            <template v-slot:item.photo="{ item }">
                <v-avatar size="40px">
                    <v-img :src="item.photo.url"></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.name="{ item }">
                <div>{{ item.name }}</div>
                <div>
                    <v-chip
                        v-for="(skill, index) in item.skills"
                        :key="index"
                        class="ma-1"
                        x-small
                        color="primary"
                        label
                        outlined
                    >
                        {{ skill }}
                    </v-chip>
                </div>
            </template>
            <template v-slot:item.options="{ item }">
                <inertia-link
                    class="inertia-link"
                    :href="route('staff.admin.freelancers.show', item.id)"
                >
                    <v-btn
                        class="mr-2"
                        elevation="2"
                        v-tooltip="'View Freelancer'"
                        color="primary"
                        icon
                    >
                        <v-icon>mdi-account</v-icon>
                    </v-btn>
                </inertia-link>
                <v-btn
                    class="mr-2"
                    elevation="2"
                    v-tooltip="'Delete Freelancer'"
                    color="error"
                    @click.stop="deleteConfirm(item)"
                    icon
                >
                    <v-icon>mdi-delete-alert</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import HeaderName from "../../../../Comps/General/HeaderName";
import FreelancersCreateModal from "../../../../Comps/Staff/Freelancers/FreelancersCreateModal";
import FreelancersDeleteModal from "../../../../Comps/Staff/Freelancers/FreelancersDeleteModal";
import AdminController from "../../../../Mixins/AdminController";
import AutoComplete from "../../../../Comps/Forms/AutoComplete";
import TextInput from "../../../../Comps/Forms/TextInput";
import Papa from "papaparse";

export default {
    name: "Freelancers",
    components: {
        TextInput,
        AutoComplete,
        FreelancersDeleteModal,
        FreelancersCreateModal,
        HeaderName,
    },
    mixins: [AdminController],

    data() {
        return {
            freelancer: null,
            createModel: false,
            deleteModel: false,
            searchString: null,
            skillsFilter: [],
        };
    },
    methods: {
        downloadCsv() {
            const csv = Papa.unparse(this.dataForCsv);
            const blob = new Blob([csv], { type: "octet/stream" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
            a.href = url;
            a.download = "Freelancers.csv";
            a.click();
            window.URL.revokeObjectURL(url);
        },

        deleteConfirm(freelancer) {
            this.freelancer = freelancer;
            this.deleteModel = true;
        },
        goToFreelancer(freelancer) {
            this.$inertia.visit(
                this.route("staff.admin.freelancers.show", freelancer.id),
                {
                    method: "get",
                }
            );
        },
    },
    computed: {
        freelancers() {
            return this.$page.props.freelancers;
        },
        search: {
            get() {
                return this.searchString;
            },
            set: _.debounce(function (value) {
                this.searchString = value;
            }, 500),
        },

        filteredFreelancers() {
            if (!this.skillsFilter.length) {
                return this.freelancers;
            }

            return _.filter(this.freelancers, (freelancer) => {
                if (!freelancer.skills) {
                    return false;
                }

                return freelancer.skills.some((r) =>
                    this.skillsFilter.includes(r)
                );
            });
        },

        dataForCsv() {
            return this.filteredFreelancers.map((item) => ({
                Name: item.name,
                Email: item.email,
                Phone: item.tel_number,
                Skills: item.skills?.join(", "),
            }));
        },
    },
};
</script>
<style scoped></style>
