<template>
    <v-container>
        <header-name>Expenses</header-name>

        <v-sheet elevation="2">
            <v-sheet class="px-4 pt-4">
                <v-row dense>
                    <v-col cols="12" md="4">
                        <v-select
                            dense
                            outlined
                            clearable
                            label="Status"
                            v-model="statusFilter"
                            :items="$page.props.allStatus"
                            multiple
                        />
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select
                            dense
                            outlined
                            clearable
                            label="Users"
                            v-model="userFilter"
                            item-value="id"
                            item-text="name"
                            :items="$page.props.allUsers"
                        />
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn
                            color="success"
                            @click="filter"
                            small
                            class="mt-1"
                        >
                            Filter
                        </v-btn>
                    </v-col>
                </v-row>
            </v-sheet>

            <v-divider class="mb-4" />

            <v-sheet class="ml-2 mt-md-0 mt-4">
                <div class="d-flex mb-6">
                    <v-menu offset-y eager>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                small
                                :disabled="
                                    !selectedSheets.length || loadingBatch
                                "
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                class="ma-2"
                            >
                                Actions
                                <v-chip
                                    class="action-chip mx-1"
                                    x-small
                                    v-if="selectedSheets.length"
                                >
                                    {{ selectedSheets.length }}
                                </v-chip>
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item @click="changeStatus('Approved')">
                                <v-list-item-title class="success--text">
                                    Mark Approved
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="changeStatus('In Xero')">
                                <v-list-item-title class="success--text">
                                    Push to Xero
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="changeStatus('Paid')">
                                <v-list-item-title class="success--text">
                                    Mark Paid
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-pagination
                        v-model="page"
                        :length="sheets.meta.last_page"
                    />
                </div>

                <div v-if="sheets.data.length" class="text-caption ml-3">
                    Showing {{ sheets.meta.from }} to {{ sheets.meta.to }} of
                    {{ sheets.meta.total }}
                </div>
            </v-sheet>

            <expenses-table
                :sheets="sheets"
                :selected-sheets.sync="selectedSheets"
            />

            <div class="d-flex mt-6 pb-2 ml-2">
                <v-pagination v-model="page" :length="sheets.meta.last_page" />
            </div>
        </v-sheet>
    </v-container>
</template>

<script>
import AdminController from "../../../../Mixins/AdminController";
import HeaderName from "../../../../Comps/General/HeaderName";
import ExpensesTable from "./ExpensesTable";

export default {
    name: "Expenses",
    components: {
        ExpensesTable,
        HeaderName,
    },
    mixins: [AdminController],

    data() {
        return {
            selectedSheets: [],
            loadingBatch: false,
            statusFilter: [],
            userFilter: null,
            page: this.$page.props.sheets.meta.current_page,
        };
    },

    mounted() {
        this.checkQueryObject();
    },

    watch: {
        page() {
            this.filter();
        },
    },

    computed: {
        sheets() {
            return this.$page.props.sheets;
        },
        queryObject() {
            return this.$page.props.queryObject;
        },
        mountedStatusFilter() {
            if (!this.queryObject.filter?.status) {
                return ["Pending"];
            }

            return this.queryObject.filter.status;
        },
        mountedUserFilter() {
            if (!this.queryObject.filter?.user) {
                return null;
            }

            return parseInt(this.queryObject.filter.user);
        },
    },

    methods: {
        checkQueryObject() {
            this.statusFilter = this.mountedStatusFilter;
            this.userFilter = this.mountedUserFilter;
        },
        filter() {
            let data = {
                page: this.page,
            };

            if (this.statusFilter.length) {
                data["filter[status]"] = this.statusFilter;
            }

            if (this.userFilter) {
                data["filter[user]"] = this.userFilter;
            }

            this.$inertia.get(this.route("staff.admin.expenses.index"), data, {
                only: ["sheets", "queryObject", "flash"],
                preserveScroll: true,
                preserveState: true,
                onSuccess: () => {
                    this.checkQueryObject();
                },
            });
        },
        changeStatus(status) {
            if (
                !confirm(
                    `Are you sure you want to change the status to ${status} for ${
                        this.selectedSheets.length
                    } ${this.selectedSheets.length < 2 ? "sheet" : "sheets"}?`
                )
            ) {
                return;
            }

            let sheetIds = _.map(this.selectedSheets, (sheet) => {
                return sheet.id;
            });

            this.$inertia.put(
                this.route("staff.admin.expenses.changeStatus"),
                {
                    sheets: sheetIds,
                    status: status,
                },
                {
                    onSuccess: () => {
                        this.selectedSheets = [];
                    },
                }
            );
        },
    },
};
</script>

<style scoped></style>
