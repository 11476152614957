<template>
    <v-data-table
        :items="items"
        sort-by="start_date"
        sort-desc
        :headers="headers"
        @click:row="viewSheet"
        class="row-pointer"
    >
        <template v-slot:item.start_date="{ value }">
            {{ momentFormatDateYear(value) }}
        </template>
        <template v-slot:item.submitted_on="{ value }">
            <span v-if="value">
                {{ momentFormatDateTimeYear(value) }}
            </span>
        </template>
        <template v-slot:item.status="{ value }">
            <timesheet-status-chip :status="value" />
        </template>
    </v-data-table>
</template>

<script>
import TimesheetStatusChip from "./TimesheetStatusChip.vue";

export default {
    name: "TimesheetTable",
    components: { TimesheetStatusChip },
    props: {
        items: {
            required: true,
            type: Array,
        },
    },

    methods: {
        viewSheet(item) {
            this.$inertia.visit(this.route("staff.timesheets.show", item));
        },
    },

    computed: {
        headers() {
            return [
                {
                    text: "Month Start",
                    value: "start_date",
                },
                {
                    text: "Status",
                    value: "status",
                },
                {
                    text: "Submitted At",
                    value: "submitted_on",
                },
            ];
        },
    },
};
</script>

<style scoped></style>
