<template>
    <v-data-table
        :headers="[
            { text: 'Start', value: 'start_date' },
            { text: 'End', value: 'end_date' },
            { text: 'Notes', value: 'notes' },
            { text: 'Hours', value: 'hours' },
            { text: '', value: 'actions', sortable: false },
        ]"
        class="elevation-2 my-2"
        :items="$page.props.approvedHolidays"
        disable-pagination
        hide-default-footer
        sort-by="start_date"
    >
        <template v-slot:item.start_date="{ value }">
            {{ momentFormatDateYear(value) }}
        </template>
        <template v-slot:item.end_date="{ value }">
            {{ momentFormatDateYear(value) }}
        </template>
        <template v-slot:item.actions="{ item }">
            <div>
                <v-chip v-if="item.half_day && item.morning" x-small label>
                    Morning
                </v-chip>
                <v-chip v-if="item.half_day && !item.morning" x-small label>
                    Afternoon
                </v-chip>
                <v-chip v-if="spansTwoSheets(item)" x-small label>
                    Spans two timesheets
                </v-chip>
                <v-chip v-if="item.type !== 'Paid'" x-small label>
                    {{ item.type }}
                </v-chip>
            </div>
        </template>
        <template v-slot:item.hours="{ item }">
            <div v-if="item.half_day">4</div>
            <div v-else>{{ calculateHours(item) }}</div>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "TimesheetHolidayLines",

    methods: {
        calculateHours(holiday) {
            let days =
                this.moment(holiday.end_date).diff(
                    this.moment(holiday.start_date),
                    "days"
                ) + 1;
            return days * 8;
        },

        spansTwoSheets(holiday) {
            return (
                this.moment(holiday.start_date).isBefore(
                    this.$page.props.timesheetPeriod.start_date
                ) ||
                this.moment(holiday.end_date).isAfter(
                    this.$page.props.timesheetPeriod.end_date
                )
            );
        },
    },
};
</script>

<style scoped></style>
