<template>
    <modal-template v-model="show">
        <div class="text-h6 text--secondary mb-2 mt-2">
            <span v-if="!editing">Create a new category</span>
            <span v-else>Edit category - {{ category.name }}</span>
        </div>

        <form>
            <validation-observer ref="form">
                <v-row>
                    <v-col cols="12">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Category Name"
                            rules="required|max:255"
                        >
                            <v-text-field
                                v-model="category.name"
                                autofocus
                                :counter="255"
                                :error-messages="errors"
                                label="Category Name"
                                hint="The name of the category"
                                required
                            >
                            </v-text-field>
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Icon"
                            rules="required"
                        >
                            <v-autocomplete
                                :items="icons"
                                v-model="icon"
                                label="Icon"
                                required
                                hint="Select an icon"
                                :error-messages="errors"
                            >
                                <template v-slot:item="{ item }">
                                    <div>
                                        <v-icon>mdi-{{ item }}</v-icon>
                                        <span class="ml-3">{{ item }}</span>
                                    </div>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <div>
                                        <v-icon>mdi-{{ item }}</v-icon>
                                        <span class="ml-3">{{ item }}</span>
                                    </div>
                                </template>
                            </v-autocomplete>
                        </validation-provider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div>Pick a colour</div>
                        <v-color-picker
                            :value="category.color"
                            @input="changeColor"
                        >
                        </v-color-picker>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-btn
                            color="success"
                            @click.prevent="submit"
                            :loading="loading"
                        >
                            {{ editing ? "Update" : "Create" }}
                        </v-btn>
                    </v-col>
                </v-row>
            </validation-observer>
        </form>
    </modal-template>
</template>

<script>
import { required, email, max, numeric } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { Icons } from "../General/AllMaterialIcons";
import ModalTemplate from "../Master/ModalTemplate";

extend("required", required);
extend("max", max);
extend("email", email);
extend("numeric", numeric);

export default {
    name: "CategoriesCreateModal",
    components: { ModalTemplate },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            modal: false,
            category: {
                color: "#44a2ff",
            },
            loading: false,
            icons: Icons,
            icon: null,
            editing: false,
        };
    },

    watch: {
        icon() {
            this.category.icon = "mdi-" + this.icon;
        },
        show(value) {
            if (value) {
                if (!this.edit) {
                    this.edit();
                }
            }
        },
    },

    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },

    methods: {
        changeColor(e) {
            this.category.color = e;
        },

        reset() {
            this.category = {
                color: "#44a2ff",
            };
            this.editing = false;
            this.icon = null;
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },

        edit(category) {
            this.show = true;
            this.editing = true;
            this.category = category;
            this.icon = _.trimStart(category.icon, "mdi-");
        },

        submit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.loading = true;

                let url;
                let type;
                if (this.category.id) {
                    url = this.route(
                        "staff.admin.categories.update",
                        this.category.id
                    );
                    type = "put";
                } else {
                    url = this.route("staff.admin.categories.store");
                    type = "post";
                }

                this.$inertia.visit(url, {
                    data: this.category,
                    method: type,
                    preserveState: true,
                    onSuccess: () => {
                        this.reset();
                        this.show = false;
                    },
                    onFinish: () => {
                        this.loading = false;
                    },
                });
            });
        },
    },
};
</script>

<style scoped></style>
