import * as Sentry from "@sentry/vue";

export default {
    data() {
        return {
            store: this.$root.store,
        };
    },

    created() {
        this.setupErrorTracking();
    },

    computed: {
        notification: {
            get: function () {
                return this.$page.props.flash.notification.show === undefined;
            },
            set: function (value) {
                this.$set(this.$page.props.flash.notification, "show", value);
            },
        },
        errors: {
            get: function () {
                return this.$page.props.flash.errors;
            },
            set: function (value) {
                this.$page.props.flash.errors = {};
            },
        },
    },

    methods: {
        setupErrorTracking() {
            let user = this.$page.props.auth.user;
            if (user) {
                Sentry.setUser({
                    id: user.id,
                    email: user.email,
                });
            }
            Sentry.configureScope((scope) =>
                scope.addEventProcessor(
                    (event) =>
                        new Promise((resolve) =>
                            resolve({
                                ...event,
                                environment: this.$page.props.releaseStage,
                            })
                        )
                )
            );
        },
    },
};
