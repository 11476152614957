<template>
    <iframe
        ref="iframe"
        :src="route('staff.logistics.gantt')"
        style="border: none; width: 100%; height: calc(100vh - 72px)"
    ></iframe>
</template>

<script>
export default {
    name: "GanttIframe",

    mounted() {
        window.onmessage = ({ data }) => {
            if (data?.message === "pytch-gantt-chart-ready") {
                this.$refs.iframe.contentWindow.postMessage({
                    props: this.$page.props,
                });
            }

            if (data?.message === "pytch-gantt-chart-open-task-modal") {
                this.$emit("open-task-modal", data.data);
            }
        };
    },
};
</script>

<style scoped></style>
