import WarehouseController from "../../components/Comps/Master/WarehouseController";

window._ = require("lodash");
import Vue from "vue";
import axios from "axios";
import vuetify from "../vuetify";
import moment from "moment";
import { InertiaProgress } from "@inertiajs/progress";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import { VTooltip } from "v-tooltip";

Vue.prototype.moment = moment;
InertiaProgress.init({
    showSpinner: true,
});
Vue.use(VueToast, {
    position: "top",
});

Vue.directive("tooltip", VTooltip);

//Sentry
import * as Sentry from "@sentry/vue";

Sentry.init({
    Vue: Vue,
    logErrors: true,
    dsn: process.env.MIX_SENTRY_VUE_DSN,
});

/*
 *
 * Axios
 *
 * */

// Setup Axios
//Send credentials
axios.defaults.withCredentials = true;

//Add Http request header
axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
};

//Add axios to vue global
Vue.prototype.$http = axios;

//Setup Vee Validate
import {
    configure,
    ValidationProvider,
    ValidationObserver,
} from "vee-validate";

//Configure vee-validate defaults
configure({
    classes: {
        valid: "is-valid",
        invalid: "is-invalid",
    },
});

// Global validate component
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

import { required, email, max, numeric } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", required);
extend("max", max);
extend("email", email);
extend("numeric", numeric);

//Add global mixins
Vue.mixin({
    methods: {
        /*
        Standard Moment Time Formats
         */
        // Format as a Time
        momentFormatTime(time) {
            return moment(time).format("HH:mm");
        },
        // Format as a Date
        momentFormatDate(time) {
            return moment(time).format("ddd Do MMM");
        },

        momentFormatDateYear(time) {
            return moment(time).format("ddd Do MMM YYYY");
        },

        // Format as a Date Time
        momentFormatDateTime(time) {
            return moment(time).format("ddd Do MMM HH:mm");
        },

        // Format as a Date Time
        momentFormatDateTimeYear(time) {
            return moment(time).format("ddd Do MMM YYYY HH:mm");
        },

        // Format as a Date Time
        momentFormatShortDateTime(time) {
            return moment(time).format("DD/MM/YYYY HH:mm");
        },

        // Format as a Time if today, or a date time if not
        momentFormatAuto(time) {
            if (moment(time).isSame(moment(), "day")) {
                return this.momentFormatTime(time);
            } else {
                return this.momentFormatDateTime(time);
            }
        },
        rootUrl() {
            return location.protocol + "//" + location.host;
        },
        formatMoney(pennies) {
            if (Number.isNaN(pennies)) {
                return "-";
            }
            return this.formatTwoDecimalPlaces(pennies / 100);
        },
        formatTwoDecimalPlaces(number) {
            return Number(number).toLocaleString("en-GB", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },
    },
});

// Setup Autolinker
import Autolinker from "autolinker";

Vue.prototype.$autolinker = new Autolinker({
    stripPrefix: {
        www: false,
    },
});

//Vue tour
import VueTour from "vue-tour";

require("vue-tour/dist/vue-tour.css");

Vue.use(VueTour);

//Ziggy
import route from "ziggy-js";
import { Ziggy } from "../ziggy.js";

Vue.mixin({
    methods: {
        route: (name, params, absolute = false, config = Ziggy) =>
            route(name, params, absolute, config),
    },
});

import { Link } from "@inertiajs/inertia-vue";
import { createInertiaApp } from "@inertiajs/inertia-vue";

Vue.component("InertiaLink", Link);

import VCalendar from "v-calendar";

Vue.use(VCalendar, {
    componentPrefix: "vc",
});

import store from "./store";
import Controller from "../../components/Comps/Master/Controller";
import Layout from "../../components/Comps/Master/Layout";
import WarehouseLayout from "../../components/Comps/Master/WarehouseLayout";

createInertiaApp({
    resolve: (name) => {
        const page = require(`../../components/Pages/${name}`).default;

        if (page.layout !== undefined) {
            return page;
        }

        if (name.includes("Warehouse/")) {
            page.layout = (h, page) => {
                return h(WarehouseController, [h(WarehouseLayout, [page])]);
            };
            return page;
        }

        page.layout = (h, page) => {
            return h(Controller, [h(Layout, [page])]);
        };

        return page;
    },
    setup({ el, App, props, plugin }) {
        Vue.use(plugin);

        new Vue({
            render: (h) => h(App, props),
            vuetify,
            data: store,
        }).$mount(el);
    },
});
