<template>
    <modal-template v-model="show">
        <template v-if="sheet">
            <div class="text-h6">
                Decline timesheet
                {{ momentFormatDateYear(sheet.start_date) }}
            </div>
            <div class="text-body-2 mb-6">
                Please give a reason in the message box below, this will be
                emailed to <strong>{{ sheet.user.name }}</strong> and allow them
                to re-submit the sheet
            </div>

            <form @submit.prevent="submitForm" novalidate>
                <validation-observer ref="form">
                    <text-area
                        v-model="declineForm.message"
                        counter="2000"
                        name="Message"
                        required
                        rules="max:2000"
                    />
                </validation-observer>

                <accent-button
                    do-not-stretch
                    type="submit"
                    color="error"
                    :loading="declineForm.processing"
                >
                    Decline
                </accent-button>

                <div class="caption mt-2 text--secondary">
                    Clicking decline will send the user an email with the <br />
                    message above and also reset the sheet to "Open" so they can
                    <br />
                    update and re-submit
                </div>
            </form>
        </template>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../Master/ModalTemplate.vue";
import AccentButton from "../../Buttons/AccentButton.vue";
import TextArea from "../../Forms/TextArea.vue";
export default {
    name: "TimesheetDenyModal",
    components: { TextArea, AccentButton, ModalTemplate },

    data() {
        return {
            show: false,
            sheet: null,
            message: "",
            declineForm: this.$inertia.form({
                message: "",
            }),
        };
    },
    methods: {
        open(sheet) {
            this.sheet = sheet;
            this.show = true;
        },

        submitForm() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });

                    return;
                }

                this.declineForm.put(
                    this.route("staff.admin.timesheets.deny", this.sheet),
                    {
                        onSuccess: () => {
                            this.show = false;
                            this.sheet = null;
                            this.$refs.form.reset();
                            this.declineForm.reset();
                        },
                    }
                );
            });
        },
    },
};
</script>

<style scoped></style>
