<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            <div class="d-inline-block">
                <v-badge
                    v-if="vehicleBookings.length"
                    :content="vehicleBookings.length"
                >
                    Vehicle Logs
                </v-badge>
                <div v-else>Vehicle Logs</div>
            </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-list v-if="vehicleBookings.length">
                <div v-for="booking in vehicleBookings" :key="booking.id">
                    <v-divider></v-divider>
                    <inertia-link
                        class="inertia-link"
                        :href="route('staff.vehicles.logging.index')"
                    >
                        <v-list-item link>
                            <v-list-item-content>
                                <v-list-item-title
                                    >{{ booking.vehicle.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{
                                        momentFormatDateTime(booking.booked_out)
                                    }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-chip label small color="primary">
                                    Booked Out
                                </v-chip>
                            </v-list-item-action>
                        </v-list-item>
                    </inertia-link>
                </div>
            </v-list>
            <div class="text-body-2" v-else>No vehicles booked out</div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    name: "DashboardVehicleLogs",

    computed: {
        vehicleBookings() {
            return this.$page.props.vehicleBookings;
        },
    },
};
</script>

<style scoped></style>
