<template>
    <modal-template native v-model="show">
        <v-card class="pa-4">
            <v-card-title>
                Stats - {{ momentFormatDate(logistic.week) }} - Version
                {{ logistic.version }}
            </v-card-title>

            <v-alert type="info" elevation="2">
                The below table shows all staff that have an assigned task in
                this version of logistics and their status
            </v-alert>

            <v-card-text>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th>Staff Member</th>
                            <th>Viewed</th>
                            <th>Accepted</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(user, index) in allStaff" :key="index">
                            <td>{{ user.name }}</td>
                            <td>
                                {{
                                    user.pivot.viewed
                                        ? momentFormatDateTime(
                                              user.pivot.viewed
                                          )
                                        : "N/A"
                                }}
                            </td>
                            <td>
                                {{
                                    user.pivot.accepted
                                        ? momentFormatDateTime(
                                              user.pivot.accepted
                                          )
                                        : "N/A"
                                }}
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </modal-template>
</template>

<script>
import SelectMultipleUsersChipTruncate from "../../General/SelectMultipleUsersChipTruncate";
import ModalTemplate from "../../Master/ModalTemplate";

export default {
    name: "ViewStatsModal",
    components: { ModalTemplate, SelectMultipleUsersChipTruncate },
    props: {
        logistic: {
            type: Object,
            required: true,
        },
        value: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        allStaff() {
            return this.logistic.users.concat(this.logistic.freelancers);
        },
    },
};
</script>

<style scoped></style>
