<template>
    <div>
        <v-data-table
            v-if="sheets.data.length"
            :items-per-page="-1"
            hide-default-footer
            :show-select="!!selectedSheets"
            v-model="allSelectedSheets"
            :headers="headers"
            :items="sheets.data"
            class="row-pointer"
            @click:row="clickRow"
            sort-by="number"
        >
            <template v-slot:item.lines="{ item }">
                {{ item.lines.length }}
            </template>

            <template v-slot:item.user.name="{ item }">
                <inertia-link
                    v-if="!ownUserPage"
                    @click.stop
                    :href="route('staff.admin.users.show', item.user)"
                >
                    {{ item.user.name }}
                </inertia-link>
                <div v-else>{{ item.user.name }}</div>
            </template>

            <template v-slot:item.sheetTotal="{ item }">
                £{{ formatMoney(item.sheetTotal) }}
            </template>

            <template v-slot:item.submitted_on="{ item }">
                <div v-if="item.submitted_on">
                    {{ momentFormatDateTimeYear(item.submitted_on) }}
                </div>
                <v-chip label small v-else> OPEN</v-chip>
            </template>

            <template v-slot:item.xero="{ item }">
                <div v-if="item.status !== 'Paid'">N/A</div>
                <div v-if="item.status === 'Paid'" @click.stop>
                    <div v-if="item.xero_bill_id" @click.stop>
                        <a-tag-with-external-icon
                            @click.stop
                            text="View Bill"
                            :url="`https://go.xero.com/AccountsPayable/View.aspx?InvoiceID=${item.xero_bill_id}`"
                        />
                    </div>
                    <div v-else>Submitting...</div>
                </div>
            </template>

            <template v-slot:item.status="{ item }">
                <expense-sheet-status-chip :status="item.status" />
            </template>

            <template v-slot:item.actions="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                            mdi-dots-vertical
                        </v-icon>
                    </template>

                    <v-list dense>
                        <v-list-item @click="clickRow(item)" link>
                            <v-list-item-title class="primary--text">
                                View
                            </v-list-item-title>
                        </v-list-item>
                        <template v-if="item.status !== 'Paid'">
                            <v-divider />
                            <v-list-item
                                link
                                v-if="item.status === 'Pending'"
                                @click="$refs.denyModal.openModal(item)"
                            >
                                <v-list-item-title class="warning--text">
                                    Deny
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item link @click="deleteSheet(item)">
                                <v-list-item-title class="error--text">
                                    Delete
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <div v-else class="pa-6 text-center">
            <slot>No expense sheets found</slot>
        </div>

        <deny-expense-sheet-modal ref="denyModal" />
    </div>
</template>

<script>
import ExpenseSheetStatusChip from "../../../../Comps/Staff/Expenses/ExpenseSheetStatusChip";
import ATagWithExternalIcon from "../../../../Comps/General/ATagWithExternalIcon";
import DenyExpenseSheetModal from "./Partials/DenyExpenseSheetModal";
export default {
    name: "ExpensesTable",
    components: {
        DenyExpenseSheetModal,
        ATagWithExternalIcon,
        ExpenseSheetStatusChip,
    },
    props: {
        sheets: Object,
        selectedSheets: {
            type: Array,
            default: undefined,
        },
        ownUserPage: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            headers: [
                { text: "Number", value: "number" },
                { text: "User", value: "user.name" },
                { text: "Lines", value: "lines" },
                { text: "Total", value: "sheetTotal" },
                { text: "Submitted", value: "submitted_on" },
                { text: "Xero Bill", value: "xero", sortable: false },
                { text: "Status", value: "status" },
                { text: "", value: "actions", sortable: false },
            ],
        };
    },

    computed: {
        allSelectedSheets: {
            get() {
                return this.selectedSheets;
            },
            set(value) {
                this.$emit("update:selectedSheets", value);
            },
        },
    },

    methods: {
        clickRow(sheet) {
            this.$inertia.visit(this.route("staff.admin.expenses.show", sheet));
        },
        deleteSheet(sheet) {
            if (
                !confirm(
                    `Are you sure you want to delete sheet number ${sheet.number}`
                )
            ) {
                return;
            }

            this.$inertia.delete(
                this.route("staff.admin.expenses.destroy", sheet)
            );
        },
    },
};
</script>

<style scoped></style>
