var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_vm._v("\n            "+_vm._s(_vm.user.name)+"\n\n            "),(_vm.user.has_driving_license)?_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Has a driving license'),expression:"'Has a driving license'"}],staticClass:"ml-1",attrs:{"color":"grey lighten-1"}},[_vm._v("\n                mdi-steering\n            ")]):_vm._e(),_vm._v(" "),(_vm.hasHolidayClash)?_c('div',[(
                        _vm.hasHolidayClash.half_day && _vm.hasHolidayClash.morning
                    )?_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Has a morning holiday clash'),expression:"'Has a morning holiday clash'"}],staticClass:"ml-1",attrs:{"color":"warning"}},[_vm._v("\n                    mdi-weather-sunset-up\n                ")]):_vm._e(),_vm._v(" "),(
                        _vm.hasHolidayClash.half_day && !_vm.hasHolidayClash.morning
                    )?_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Has an afternoon holiday clash'),expression:"'Has an afternoon holiday clash'"}],staticClass:"ml-1",attrs:{"color":"warning"}},[_vm._v("\n                    mdi-white-balance-sunny\n                ")]):_vm._e(),_vm._v(" "),(_vm.hasHolidayClash.evening)?_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Has an evening holiday clash'),expression:"'Has an evening holiday clash'"}],staticClass:"ml-1",attrs:{"color":"warning"}},[_vm._v("\n                    mdi-moon-waning-crescent\n                ")]):_vm._e(),_vm._v(" "),(
                        !_vm.hasHolidayClash.evening &&
                        !_vm.hasHolidayClash.half_day
                    )?_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Has a full day holiday clash'),expression:"'Has a full day holiday clash'"}],staticClass:"ml-1",attrs:{"color":"warning"}},[_vm._v("\n                    mdi-beach\n                ")]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.hasClash)?_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Has a resource clash'),expression:"'Has a resource clash'"}],staticClass:"ml-1",attrs:{"color":"error"}},[_vm._v("\n                mdi-close\n            ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }