<template>
    <validation-provider v-slot="{ errors }" :name="name" :rules="rules">
        <v-textarea
            v-model="model"
            :name="name"
            outlined
            :background-color="backgroundClasses"
            dense
            :error-messages="errors"
            v-bind="$attrs"
            rounded
            :label="computedLabel"
            :class="{ required: required }"
            :required="required"
        />
    </validation-provider>
</template>

<script>
import VModel from "../../Mixins/VModel";
import FormInput from "../../Mixins/FormInput";

export default {
    name: "TextArea",
    mixins: [VModel, FormInput],
    props: {
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
        },
        rules: {
            type: String,
            default: "",
        },
        required: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        computedLabel() {
            return this.label ? this.label : this.name;
        },
    },
};
</script>
