<template>
    <v-container fixed>
        <header-name>Visitor Logs</header-name>

        <v-sheet elevation="2" class="pa-4 mt-8">
            <v-row>
                <v-col cols="12" md="6">
                    <date-time-picker
                        mode="date"
                        name="Date"
                        label="Date"
                        v-model="date"
                        hint="Show logs for this date"
                        @input="dateSearch"
                    />
                </v-col>
                <v-col cols="12" md="6" class="d-flex justify-end">
                    <v-btn color="success" small @click="$refs.modal.create()">
                        New Visitor
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="my-4" />

            <v-data-table
                disable-pagination
                hide-default-footer
                :headers="[
                    { text: 'Name', value: 'name' },
                    { text: 'Email', value: 'email' },
                    { text: 'Arrived', value: 'arrived_at' },
                    { text: 'Departed', value: 'departed_at' },
                    { text: '', value: 'options' },
                ]"
                :items="$page.props.visitorLogs"
            >
                <template v-slot:item.arrived_at="{ value }">
                    <span
                        v-if="value"
                        v-text="momentFormatDateTimeYear(value)"
                    />
                </template>
                <template v-slot:item.departed_at="{ value }">
                    <span
                        v-if="value"
                        v-text="momentFormatDateTimeYear(value)"
                    />
                </template>
                <template v-slot:item.options="{ item }">
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">
                                mdi-dots-vertical
                            </v-icon>
                        </template>

                        <v-list dense>
                            <v-list-item @click="$refs.modal.edit(item)" link>
                                <v-list-item-title class="primary--text">
                                    Edit
                                </v-list-item-title>
                            </v-list-item>
                            <template v-if="!item.departed_at">
                                <v-divider />
                                <v-list-item @click="departed(item)" link>
                                    <v-list-item-title class="info--text">
                                        Mark as Departed
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-sheet>
        <visitor-log-modal ref="modal" />
    </v-container>
</template>

<script>
import HeaderName from "../../../../Comps/General/HeaderName.vue";
import DateTimePicker from "../../../../Comps/Master/DateTimePicker.vue";
import VisitorLogModal from "./Partials/VisitorLogModal.vue";
import AdminController from "../../../../Mixins/AdminController.js";

export default {
    name: "VisitorLogs",
    components: { VisitorLogModal, DateTimePicker, HeaderName },
    mixins: [AdminController],
    data() {
        return {
            date: new Date().toJSON(),
            form: this.$inertia.form({}),
            mounted: false,
        };
    },

    methods: {
        departed(visitorLog) {
            this.form = this.$inertia.form(visitorLog);

            this.form
                .transform((data) => ({
                    ...data,
                    departed_at: new Date().toJSON(),
                }))
                .put(
                    this.route("staff.admin.visitor-logs.update", visitorLog.id)
                );
        },

        dateSearch() {
            if (!this.mounted) return;
            this.$inertia.visit(
                this.route("staff.admin.visitor-logs.index", {
                    "filter[date]": this.date,
                })
            );
        },
    },

    mounted() {
        this.date =
            new URLSearchParams(window.location.search).get("filter[date]") ||
            new Date().toJSON();

        this.$nextTick(() => {
            this.mounted = true;
        });
    },
};
</script>

<style scoped></style>
