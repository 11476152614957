<script>
export default {
    name: "NotificationController",
    render() {
        return this.$slots.default;
    },

    computed: {
        notification() {
            return this.$page.props.flash.notification;
        },
    },

    watch: {
        notification: {
            immediate: true,
            handler(notification) {
                if (notification) {
                    this.$toast.open({
                        message: notification.text,
                        type: notification.type,
                        duration: parseInt(notification.timeout),
                    });
                }
            },
        },
    },
};
</script>
