<template>
    <modal-template small v-model="show">
        <div v-if="freelancer" class="pa-6 text-center">
            <h3 class="text--secondary font-weight-regular mb-2 mt-1">
                Are you sure you want to delete
                <strong>{{ freelancer.name }}</strong
                >?
            </h3>
            <h5 class="text--secondary">
                By deleting this freelancer they will be removed from any future
                logistic tasks and previous ones. <br />
                <span class="error--text">This cannot be undone</span>
            </h5>

            <v-btn
                :loading="loading"
                color="error"
                class="mt-5"
                @click="deleteFreelancer"
            >
                Delete
            </v-btn>
        </div>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../Master/ModalTemplate";

export default {
    name: "FreelancersDeleteModal",
    components: { ModalTemplate },
    props: {
        freelancer: {
            type: Object,
            required: false,
        },
        value: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            modal: false,
            loading: false,
        };
    },

    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },

    methods: {
        deleteFreelancer() {
            if (!confirm("Are you sure?")) {
                return;
            }

            this.loading = true;

            this.$inertia.delete(
                this.route(
                    "staff.admin.freelancers.destroy",
                    this.freelancer.id
                ),
                {
                    onSuccess: () => {
                        this.show = false;
                    },
                    onFinish: () => {
                        this.loading = false;
                    },
                }
            );
        },
    },
};
</script>

<style scoped></style>
