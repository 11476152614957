<template>
    <div>
        <div class="px-1" @click="clickHoliday">
            Holiday - {{ holidays.length ? holidays.length : "None" }}
        </div>
        <logistics-holiday-info-modal ref="modal" />
    </div>
</template>

<script>
import LogisticsHolidayInfoModal from "./LogisticsHolidayInfoModal";
import LogisticsHolidayHelpers from "../../../../Mixins/Logistics/LogisticsHolidayHelpers";
export default {
    name: "LogisticsHolidayTask",
    components: { LogisticsHolidayInfoModal },
    mixins: [LogisticsHolidayHelpers],
    props: {
        task: Object,
    },
    computed: {
        holidays() {
            return this.getDaysHolidays(this.day);
        },

        day() {
            return this.moment.utc(this.task.start);
        },
    },

    methods: {
        clickHoliday() {
            if (!this.holidays.length) {
                return;
            }

            this.$refs.modal.open(this.day);
        },
    },
};
</script>

<style scoped></style>
