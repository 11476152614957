<template>
    <v-container class="fixed">
        <v-row class="justify-center">
            <v-col cols="12" md="8">
                <header-name>LOLER Testing</header-name>

                <v-sheet class="pa-6" elevation="2">
                    <v-fade-transition mode="out-in">
                        <div key="search" v-if="!this.asset">
                            <validation-observer ref="assetSearch">
                                <form
                                    @submit.prevent="searchForAsset"
                                    novalidate
                                >
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Asset"
                                        rules="required|max:255"
                                    >
                                        <v-text-field
                                            v-model="assetSearch"
                                            name="Asset"
                                            :error-messages="errors"
                                            :loading="isSearching"
                                            ref="assetSearchInput"
                                            label="Asset"
                                            hint="Scan or type an asset number and press enter"
                                            persistent-hint
                                            autofocus
                                            class="required"
                                            required
                                        >
                                        </v-text-field>
                                    </validation-provider>
                                </form>
                            </validation-observer>
                        </div>

                        <div key="add" v-if="asset">
                            <div class="d-flex">
                                <div>
                                    <div class="subtitle-1 font-weight-bold">
                                        {{ asset.asset_number }}
                                    </div>
                                    <div class="subtitle-1">
                                        {{ product.name }}
                                    </div>
                                </div>
                                <v-btn
                                    small
                                    color="primary"
                                    v-tooltip="
                                        'Search for another asset to test'
                                    "
                                    @click="fresh"
                                    class="ml-auto"
                                >
                                    Start Over
                                </v-btn>
                            </div>

                            <v-divider class="my-4" />

                            <validation-observer ref="assetTest">
                                <form @submit.prevent="testAsset" novalidate>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <date-picker
                                                name="Date Of Manufacture"
                                                class="mx-2"
                                                v-model="toTest.DOM"
                                                hint="Date of manufacture"
                                            >
                                            </date-picker>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="SWL"
                                                rules="required|max:100"
                                            >
                                                <v-text-field
                                                    v-model="toTest.SWL"
                                                    name="SWL"
                                                    :error-messages="errors"
                                                    label="SWL"
                                                    class="required"
                                                    required
                                                >
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Next Inspection Period"
                                                rules="required"
                                            >
                                                <v-select
                                                    label="Next Inspection Period"
                                                    v-model="toTest.next_test"
                                                    item-value="value"
                                                    item-text="label"
                                                    :items="[
                                                        {
                                                            label: '6 Months',
                                                            value: 6,
                                                        },
                                                        {
                                                            label: '12 Months',
                                                            value: 12,
                                                        },
                                                    ]"
                                                    :error-messages="errors"
                                                />
                                            </validation-provider>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Tests carried Out"
                                                rules="required|max:255"
                                            >
                                                <v-textarea
                                                    v-model="toTest.test"
                                                    name="Tests carried Out"
                                                    outlined
                                                    :counter="255"
                                                    rows="2"
                                                    :error-messages="errors"
                                                    label="Tests carried Out"
                                                    hint="Details any tests carried out"
                                                    class="required"
                                                    required
                                                >
                                                </v-textarea>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <validation-provider
                                                v-slot="{ errors }"
                                                name="Repairs carried Out"
                                                rules="required|max:255"
                                            >
                                                <v-textarea
                                                    v-model="toTest.repair"
                                                    name="Repairs carried Out"
                                                    outlined
                                                    :counter="255"
                                                    rows="2"
                                                    :error-messages="errors"
                                                    label="Repairs carried Out"
                                                    hint="Details any repairs carried out"
                                                    class="required"
                                                    required
                                                >
                                                </v-textarea>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            class="d-flex flex-column align-center"
                                        >
                                            <div class="body-1 text--secondary">
                                                Overall
                                            </div>
                                            <div class="d-flex align-center">
                                                <div class="body-1 error--text">
                                                    Failed
                                                </div>
                                                <v-switch
                                                    inset
                                                    class="ml-3 mt-2"
                                                    color="success"
                                                    v-model="toTest.overall"
                                                />
                                                <div
                                                    class="body-1 success--text"
                                                >
                                                    Passed
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col class="text-center">
                                            <v-btn
                                                color="success"
                                                :loading="isSavingTest"
                                                type="submit"
                                            >
                                                Save Test
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </form>
                            </validation-observer>
                        </div>
                    </v-fade-transition>
                </v-sheet>

                <!--Recent Tests-->
                <v-fade-transition>
                    <v-sheet class="pa-6 mt-6" elevation="2">
                        <div class="text-h6">
                            <div v-if="!asset">Latest Tests</div>
                            <div v-if="asset">
                                <strong>{{ asset.asset_number }}</strong> tests
                            </div>
                        </div>

                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th>Asset</th>
                                    <th>Product</th>
                                    <th>Status</th>
                                    <th>User</th>
                                    <th>Timestamp</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-if="!asset" v-for="loler in lolers">
                                    <td>
                                        <inertia-link
                                            :href="
                                                route(
                                                    'staff.loler.show',
                                                    loler.asset
                                                )
                                            "
                                            >{{ loler.asset }}</inertia-link
                                        >
                                    </td>
                                    <td>{{ loler.product?.name }}</td>
                                    <td>
                                        <v-chip
                                            label
                                            small
                                            :color="
                                                loler.overall
                                                    ? 'success'
                                                    : 'error'
                                            "
                                        >
                                            {{
                                                loler.overall
                                                    ? "Passed"
                                                    : "Failed"
                                            }}
                                        </v-chip>
                                    </td>
                                    <td>{{ loler.user.name }}</td>
                                    <td>
                                        {{
                                            momentFormatDateTimeYear(
                                                loler.created_at
                                            )
                                        }}
                                    </td>
                                </tr>
                                <tr v-if="asset" v-for="loler in previousTests">
                                    <td>
                                        <inertia-link
                                            :href="
                                                route(
                                                    'staff.loler.show',
                                                    loler.asset
                                                )
                                            "
                                            >{{ loler.asset }}</inertia-link
                                        >
                                    </td>
                                    <td>{{ loler.product.name }}</td>
                                    <td>
                                        <v-chip
                                            label
                                            small
                                            :color="
                                                loler.overall
                                                    ? 'success'
                                                    : 'error'
                                            "
                                        >
                                            {{
                                                loler.overall
                                                    ? "Passed"
                                                    : "Failed"
                                            }}
                                        </v-chip>
                                    </td>
                                    <td>{{ loler.user.name }}</td>
                                    <td>
                                        {{
                                            momentFormatDateTimeYear(
                                                loler.created_at
                                            )
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-sheet>
                </v-fade-transition>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import HeaderName from "../../../Comps/General/HeaderName";
import ProcessesController from "../../../Mixins/ProcessesController";
import { required, max, min_value } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import DatePicker from "../../../Comps/Master/DatePicker";

extend("required", required);
extend("max", max);
extend("min_value", min_value);

export default {
    name: "LolerIndex",
    components: { DatePicker, HeaderName },
    mixins: [ProcessesController],

    props: ["lolers"],

    data() {
        return {
            isSearching: false,
            error: null,
            asset: null,
            product: null,
            previousTests: null,
            assetSearch: "",
            isSavingTest: false,
            toTest: {},
        };
    },

    mounted() {
        this.fresh();
    },

    watch: {
        previousTests(value) {
            if (!value) {
                return;
            }

            if (value && value.length) {
                this.toTest.DOM = this.moment(value[0].DOM).format(
                    "YYYY-MM-DD"
                );
                this.toTest.SWL = value[0].SWL;
            }
        },
    },

    methods: {
        fresh() {
            this.isSearching = false;
            this.error = null;
            this.asset = null;
            this.assetSearch = "";
            this.previousTests = null;
            this.product = null;
            this.isSavingTest = false;
            this.toTest = {
                next_test: 6,
                overall: true,
                test: "N/A",
                repair: "N/A",
            };
        },

        searchForAsset() {
            this.$refs.assetSearch.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.isSearching = true;

                this.$http
                    .get(
                        this.route("staff.loler.search", {
                            asset: this.assetSearch,
                        })
                    )
                    .then((response) => {
                        this.asset = response.data.current_asset;
                        this.product = response.data.product;
                        this.previousTests = response.data.previous;
                    })
                    .catch((error) => {
                        let errorText;
                        if (error.response.data.errors) {
                            errorText = Object.values(
                                error.response.data.errors
                            )[0][0];
                        } else {
                            errorText =
                                "There has been an finding adding that asset";
                        }

                        this.$toast.error(errorText, {
                            duration: 5000,
                        });

                        this.$nextTick(() => {
                            let el =
                                this.$refs.assetSearchInput.$el.querySelector(
                                    "input"
                                );
                            el.focus();
                            el.select();
                        });
                    })
                    .finally(() => {
                        this.isSearching = false;
                    });
            });
        },

        testAsset() {
            this.$refs.assetTest.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                if (
                    !this.toTest.overall &&
                    !confirm("Are you sure you want to fail this asset?")
                ) {
                    return;
                }

                this.isSavingTest = true;

                let data = this.toTest;
                data.asset = this.asset.asset_number;
                data.asset_id = this.asset.id;
                data.product_id = this.asset.item_id;

                this.$inertia.post(this.route("staff.loler.store"), data, {
                    onSuccess: () => {
                        this.fresh();
                    },

                    onError: () => {
                        this.isSavingTest = false;
                    },
                });
            });
        },
    },
};
</script>

<style scoped></style>
