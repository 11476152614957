<template>
    <v-container class="fixed">
        <header-name>Permissions</header-name>

        <v-card class="pa-3">
            <v-data-table
                :headers="headers"
                :items="permissions"
                show-group-by
                group-by="category"
            >
                <template v-slot:item.users="{ item }">
                    <v-chip
                        v-for="user in item.users"
                        :key="user.id"
                        v-tooltip="user.name"
                        class="mr-1 mb-1"
                        pill
                    >
                        <v-avatar left>
                            <v-img :src="user.avatar"></v-img>
                        </v-avatar>
                        {{ user.name[0] }}
                    </v-chip>
                </template>

                <template v-slot:item.options="{ item }">
                    <inertia-link
                        class="inertia-link"
                        :href="route('staff.admin.permissions.show', item)"
                    >
                        <v-btn
                            class="mr-2"
                            elevation="2"
                            v-tooltip="'View Permission'"
                            color="primary"
                            icon
                        >
                            <v-icon>mdi-lock-open</v-icon>
                        </v-btn>
                    </inertia-link>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import HeaderName from "../../../../Comps/General/HeaderName";
import AdminController from "../../../../Mixins/AdminController";

export default {
    name: "Permissions",
    components: { HeaderName },
    props: ["permissions"],
    mixins: [AdminController],

    data() {
        return {
            headers: [
                { text: "Permission", value: "long_name", groupable: false },
                { text: "Category", value: "category" },
                { text: "Description", value: "description", groupable: false },
                { text: "Users", value: "users", groupable: false },
                { text: "", value: "options", groupable: false },
            ],
        };
    },
};
</script>

<style scoped></style>
