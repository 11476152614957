<script>
export default {
    name: "TaskInfoModalActivity",

    props: {
        activity: {
            type: Object,
            required: true,
        },
    },

    computed: {
        description() {
            return _.upperFirst(this.activity.description);
        },
    },
};
</script>

<template>
    <div class="my-3">
        <div>Action: {{ description }}</div>
        <div>By: {{ activity.causer.name }}</div>
        <div>On: {{ momentFormatDateTimeYear(activity.created_at) }}</div>

        <v-divider class="my-2" />
    </div>
</template>
