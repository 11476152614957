<template>
    <expense-show />
</template>

<script>
import PeopleController from "../../../Mixins/PeopleController";
import ExpenseShow from "../../../Comps/Staff/Expenses/ExpenseShow";

export default {
    name: "ExpenseSheet",
    components: { ExpenseShow },
    mixins: [PeopleController],
};
</script>

<style scoped></style>
