<template>
    <v-chip label :small="!large" :color="color">
        {{ status }}
    </v-chip>
</template>

<script>
export default {
    name: "TimesheetStatusChip",
    props: {
        status: String,
        large: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        color() {
            const colors = {
                Open: "",
                Submitted: "warning",
                Approved: "success",
            };

            return colors[this.status] ?? "";
        },
    },
};
</script>

<style scoped></style>
