<template>
    <v-container class="fixed">
        <header-name>Info</header-name>

        <v-row>
            <v-col cols="12" md="6">
                <all-passwords />
            </v-col>

            <v-col cols="12" md="6">
                <all-phone-numbers />
            </v-col>

            <v-col cols="12" xl="6">
                <all-staff />
            </v-col>

            <v-col cols="12" xl="6">
                <info-files />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import HeaderName from "../../../../Comps/General/HeaderName";
import { required, max } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import ThingsController from "../../../../Mixins/ThingsController";
import AllStaff from "./AllStaff";
import AllPasswords from "./AllPasswords";
import AllPhoneNumbers from "./AllPhoneNumbers";
import InfoFiles from "./InfoFiles";

extend("required", required);
extend("max", max);

export default {
    name: "InfoPage",
    components: {
        InfoFiles,
        AllPhoneNumbers,
        AllPasswords,
        AllStaff,
        HeaderName,
    },
    mixins: [ThingsController],
};
</script>

<style scoped></style>
