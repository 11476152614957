<template>
    <div>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            eager
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <validation-provider
                    v-slot="{ errors }"
                    :name="name"
                    :rules="allRules"
                >
                    <v-text-field
                        readonly
                        v-bind="attrs"
                        :value="dateFormatted"
                        :name="name"
                        :outlined="outlined"
                        :dense="dense"
                        :autofocus="autofocus"
                        :error-messages="errors"
                        :label="label"
                        :persistent-hint="!!hint"
                        :hint="hint"
                        :class="{ required: required }"
                        :required="required"
                        @focusin="menu = true"
                        @click="menu = true"
                    />
                </validation-provider>
            </template>
            <v-sheet class="d-flex flex-column">
                <vc-date-picker
                    :is-range="isRange"
                    style="border-bottom: none"
                    v-model="date"
                    :minute-increment="minuteIncrement"
                    :masks="masks"
                    :min-date="min"
                    :max-date="max"
                    :popover="popover"
                    :disabled-dates="disabledDates"
                    :model-config="modelConfig"
                    :is-required="required"
                    :mode="mode"
                    :timezone="timezone"
                    is24hr
                />
                <div class="pa-4">
                    <v-btn small @click="menu = false" color="success">
                        Save
                    </v-btn>
                </div>
            </v-sheet>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: "DateTimePicker",

    props: {
        value: {
            type: [String, Object, Date],
        },
        minuteIncrement: {
            type: Number,
            default: 1,
        },
        min: {
            type: Date,
            default: null,
        },
        max: {
            type: Date,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        hint: {
            type: String,
            default: null,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        dense: {
            type: Boolean,
            default: true,
        },
        isRange: {
            type: Boolean,
            default: false,
        },
        timezone: {
            type: String,
            default: undefined,
        },
        mode: {
            type: String,
            default: "datetime",
        },
        rules: {
            type: [String, Object],
            default: null,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        closeOnSelect: {
            type: Boolean,
            default: false,
        },
        disabledDates: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            store: this.$root.store,
            modelConfig: {
                type: "string",
            },
            popover: {
                positionFixed: true,
            },
            masks: {
                inputDateTime24hr: "H:mm WWW D MMM",
            },
            menu: false,
        };
    },

    watch: {
        dateFormatted(value) {
            if (value && this.closeOnSelect) {
                this.menu = false;
            }
        },
    },

    methods: {
        formatDateOrTime(value) {
            if (this.mode === "datetime") {
                return this.momentFormatDateTime(value);
            }

            return this.momentFormatDate(value);
        },
    },

    computed: {
        date: {
            get() {
                if (!this.value) {
                    return null;
                }

                if (this.isRange) {
                    return {
                        start: this.moment(this.value.start).toISOString(),
                        end: this.moment(this.value.end).toISOString(),
                    };
                }

                return this.moment(this.value).toISOString();
            },
            set(value) {
                this.$emit("input", value);
            },
        },

        allRules() {
            if (this.rules) {
                return this.rules;
            }

            return { required: this.required };
        },

        dateFormatted() {
            if (!this.value) {
                return null;
            }

            if (this.isRange) {
                return (
                    this.formatDateOrTime(this.value.start) +
                    " - " +
                    this.formatDateOrTime(this.value.end)
                );
            }

            return this.formatDateOrTime(this.value);
        },
    },
};
</script>

<style scoped></style>
