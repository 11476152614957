<template>
    <div v-if="trainings.data.length">
        <div class="d-flex">
            <v-pagination v-model="page" :length="trainings.last_page">
            </v-pagination>
        </div>
        <v-list>
            <div v-for="training in trainings.data" :key="training.id">
                <v-divider></v-divider>
                <v-list-item three-line>
                    <v-list-item-content>
                        <v-list-item-title>
                            <a
                                target="_blank"
                                :href="
                                    route(
                                        'staff.admin.training.sessions.show',
                                        training.training_session
                                    )
                                "
                                >{{ training.training_session.module.name }} |
                                {{
                                    momentFormatDate(
                                        training.training_session.created_at
                                    )
                                }}</a
                            >
                        </v-list-item-title>
                        <v-list-item-subtitle
                            class="red--text"
                            v-if="expired(expires(training)) && training.passed"
                        >
                            Expired On:
                            {{ expires(training).format("ddd Do MMM YYYY") }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                            class="green--text"
                            v-if="
                                !expired(expires(training)) && training.passed
                            "
                        >
                            Expires:
                            {{ expires(training).format("ddd Do MMM YYYY") }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <v-icon v-if="training.passed" color="green">
                                mdi-emoticon-happy-outline
                            </v-icon>
                            <v-icon v-else color="red"
                                >mdi-emoticon-sad-outline
                            </v-icon>
                            <span v-if="training.passed" class="green--text"
                                >Passed</span
                            >
                            <span v-else class="red--text">Failed</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </div>
        </v-list>
    </div>
    <div v-else>
        <span v-if="!admin" class="text-h6"
            >You have not participated in any training sessions yet</span
        >
        <span v-else class="text-h6"
            >This user has not participated in any training sessions yet</span
        >
    </div>
</template>

<script>
export default {
    name: "TrainingComp",

    props: {
        trainings: {
            required: true,
        },
        admin: {
            default: false,
        },
        user: {
            default: null,
        },
    },

    data() {
        return {
            store: this.$root.store,
            page: this.trainings.current_page,
        };
    },

    methods: {
        expires(training) {
            return this.moment(training.training_session.created_at).add(
                training.training_session.module.valid_for,
                "months"
            );
        },
        expired(expires) {
            return expires.isBefore(this.moment());
        },
    },
    watch: {
        page(page) {
            if (this.admin) {
                this.$inertia.visit(
                    this.route("staff.admin.users.show", this.user.id),
                    {
                        method: "get",
                        data: {
                            trainingPage: page,
                        },
                        only: ["trainings"],
                        preserveScroll: true,
                        preserveState: true,
                    }
                );
            } else {
                this.$inertia.visit(this.route("staff.training"), {
                    method: "get",
                    data: {
                        page: page,
                    },
                });
            }
        },
    },
};
</script>

<style scoped></style>
