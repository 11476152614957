<template>
    <v-row class="justify-center">
        <v-col cols="12" md="8">
            <v-sheet rounded="lg" class="pa-6 mt-8">
                <h3 class="text-h4 font-weight-bold">
                    <span v-if="newProfile">New Profile</span>
                    <span v-else>Edit Profile</span>
                </h3>
                <p class="mt-1 text-body-1">
                    Please fill in your information below
                </p>
                <p class="mt-1 text-body-2 text--secondary">
                    This information will be shared with PYTCH, details on how
                    we store your data can be found in our
                    <a href="https://pytch.co.uk/privacy" target="_blank">
                        Privacy Policy
                    </a>
                </p>
            </v-sheet>

            <error-banner class="mt-8" />

            <validation-observer ref="form">
                <form novalidate @submit.prevent="$emit('submit')">
                    <v-sheet rounded="lg" class="pa-6 mt-8">
                        <h3 class="text-h6 font-weight-bold">About You</h3>
                        <p class="mt-1 text-body-2">Information about you</p>

                        <v-divider class="my-4" />

                        <input-layout label="Email" v-slot="{ label }">
                            <text-input
                                v-model="model.email"
                                readonly
                                :name="label"
                                :label="label"
                                hint="This cannot be changed"
                                persistent-hint
                            />
                        </input-layout>
                        <input-layout required label="Name" v-slot="{ label }">
                            <text-input
                                v-model="model.name"
                                :name="label"
                                :label="label"
                                counter="255"
                                rules="required|max:255"
                            />
                        </input-layout>
                        <input-layout label="About" v-slot="{ label }">
                            <text-area
                                v-model="model.about"
                                :name="label"
                                :label="label"
                                hint="A few lines about yourself"
                                counter="2000"
                                rules="max:2000"
                            />
                        </input-layout>

                        <input-layout required label="Photo">
                            <div style="width: fit-content">
                                <div class="d-flex align-center flex-column">
                                    <div class="mb-3">
                                        <v-avatar
                                            v-if="!model.photo"
                                            width="75px"
                                            height="75px"
                                            color="indigo"
                                        >
                                            <v-icon dark>
                                                mdi-account-circle</v-icon
                                            >
                                        </v-avatar>
                                        <v-avatar
                                            width="75px"
                                            height="75px"
                                            v-else
                                        >
                                            <v-img :src="model.photo.url" />
                                        </v-avatar>
                                    </div>
                                </div>
                            </div>

                            <select-file-and-display-single
                                :allowed-extensions="['.jpg', '.jpeg', '.png']"
                                v-model="model.photo"
                                :max-file-size="4000"
                                width="75px"
                                height="75px"
                                hide-preview
                                label="Choose Photo"
                            />
                        </input-layout>
                        <input-layout
                            required
                            label="Skills"
                            v-slot="{ label }"
                        >
                            <auto-complete
                                v-model="model.skills"
                                multiple
                                :name="label"
                                :label="label"
                                rules="required"
                                chips
                                deletable-chips
                                hint="Choose at least one from the list"
                                persistent-hint
                                small-chips
                                :items="$page.props.allowedSkills"
                            />
                        </input-layout>
                        <input-layout label="Files">
                            <p class="text-subtitle-2 text--secondary">
                                Attach files, eg your CV, show reels etc
                            </p>
                            <select-file-and-display
                                v-model="model.files"
                                width="200px"
                                max-width="100%"
                                max-height="400px"
                                :max-file-size="20000"
                            />
                        </input-layout>
                    </v-sheet>

                    <v-sheet rounded="lg" class="pa-6 mt-8">
                        <h3 class="text-h6 font-weight-bold">
                            Personal Details
                        </h3>
                        <p class="mt-1 text-body-2">
                            Personal information for our records
                        </p>
                        <v-divider class="my-4" />
                        <input-layout
                            required
                            label="Phone Number"
                            v-slot="{ label }"
                        >
                            <text-input
                                v-model="model.tel_number"
                                :name="label"
                                :label="label"
                                rules="required|max:255"
                            />
                        </input-layout>
                        <input-layout label="Street" v-slot="{ label }">
                            <text-input
                                v-model="model.address.street"
                                :name="label"
                                :label="label"
                                rules="max:255"
                            />
                        </input-layout>
                        <input-layout label="City" v-slot="{ label }">
                            <text-input
                                v-model="model.address.city"
                                :name="label"
                                :label="label"
                                rules="max:255"
                            />
                        </input-layout>
                        <input-layout label="County" v-slot="{ label }">
                            <text-input
                                v-model="model.address.county"
                                :name="label"
                                :label="label"
                                rules="max:255"
                            />
                        </input-layout>
                        <input-layout label="Postcode" v-slot="{ label }">
                            <text-input
                                v-model="model.address.postcode"
                                :name="label"
                                :label="label"
                                rules="max:255"
                            />
                        </input-layout>
                    </v-sheet>

                    <accent-button
                        :loading="model.processing"
                        type="submit"
                        class="mt-4"
                    >
                        Submit
                    </accent-button>
                </form>
            </validation-observer>
        </v-col>
    </v-row>
</template>

<script>
import AccentButton from "../../../Comps/Buttons/AccentButton";
import TextInput from "../../../Comps/Forms/TextInput";
import InputLayout from "./InputLayout";
import AutoComplete from "../../../Comps/Forms/AutoComplete";
import TextArea from "../../../Comps/Forms/TextArea";
import ErrorBanner from "../../../Comps/Forms/ErrorBanner";
import VModel from "../../../Mixins/VModel";
import SelectFileAndDisplay from "../../../Comps/General/SelectFileAndDisplay";
import SelectFileAndDisplaySingle from "../../../Comps/General/SelectFileAndDisplaySingle.vue";
export default {
    name: "InformationForm",
    components: {
        SelectFileAndDisplaySingle,
        SelectFileAndDisplay,
        ErrorBanner,
        TextArea,
        AutoComplete,
        InputLayout,
        TextInput,
        AccentButton,
    },
    mixins: [VModel],

    props: {
        newProfile: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped></style>
