<template>
    <div>
        <v-sheet class="pa-6" elevation="2">
            <v-fade-transition mode="out-in">
                <div key="search" v-if="!this.product">
                    <v-autocomplete
                        v-model="product"
                        :items="productList"
                        :loading="isSearching"
                        clearable
                        dense
                        outlined
                        autofocus
                        no-data-text="No products found"
                        cache-items
                        :search-input.sync="productSearch"
                        item-text="name"
                        item-value="id"
                        label="Search for a product"
                        placeholder="Start typing to Search"
                        prepend-icon="mdi-text-box-search"
                        return-object
                    >
                        <template v-slot:item="data">
                            <v-list-item-icon>
                                <v-img
                                    max-width="64px"
                                    max-height="64px"
                                    :src="data.item.icon"
                                />
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    v-html="data.item.name"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </div>

                <div key="add" v-if="product">
                    <div class="d-flex">
                        <div class="subtitle-1 font-weight-bold">
                            {{ product.name }}
                        </div>
                        <v-btn
                            small
                            color="primary"
                            v-tooltip="
                                'Search for another product to add stock to'
                            "
                            @click="startOver"
                            class="ml-auto"
                        >
                            Search
                        </v-btn>
                    </div>

                    <v-divider class="my-4" />

                    <div v-if="product.stock_method !== 2">
                        <div class="text-subtitle-1">
                            This product needs converting before assets can be
                            added
                        </div>
                        <div
                            class="text-body-1 font-weight-bold error--text my-4"
                        >
                            This will destroy all bulk stock levels ready for
                            assets to be scanned
                        </div>

                        <v-btn
                            :loading="isConverting"
                            @click="convert"
                            color="error"
                        >
                            Convert
                        </v-btn>
                    </div>

                    <div v-else>
                        <v-alert v-if="error" type="error" elevation="2">
                            {{ error }}
                        </v-alert>

                        <v-switch
                            v-model="enableSerial"
                            label="Enable Serial Number"
                        />

                        <v-switch
                            v-model="enableLocaiton"
                            label="Enable Location"
                        />

                        <validation-observer ref="form">
                            <form @submit.prevent="add" novalidate>
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Store"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="storeId"
                                        name="Store"
                                        dense
                                        outlined
                                        :items="stores"
                                        item-text="name"
                                        item-value="id"
                                        :error-messages="errors"
                                        label="Store"
                                        hint="Choose a store to add the asset to"
                                        class="required"
                                        required
                                    >
                                    </v-select>
                                </validation-provider>
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Location"
                                    :rules="{
                                        max: { length: 255 },
                                        required: enableLocaiton,
                                    }"
                                >
                                    <v-text-field
                                        v-model="location"
                                        outlined
                                        dense
                                        name="Lcoaiton"
                                        ref="locaitonInput"
                                        :loading="isAdding"
                                        :disabled="!enableLocaiton"
                                        :autofocus="enableLocaiton"
                                        :counter="255"
                                        :error-messages="errors"
                                        label="Location"
                                        hint="The location of the asset e.g. 'V-A2'"
                                        :class="{
                                            required: enableLocaiton,
                                        }"
                                        required
                                    >
                                    </v-text-field>
                                </validation-provider>
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Serial Number"
                                    :rules="{
                                        max: { length: 255 },
                                        required: enableSerial,
                                    }"
                                >
                                    <v-text-field
                                        v-model="serial"
                                        outlined
                                        dense
                                        name="Serial Number"
                                        ref="serialInput"
                                        :loading="isAdding"
                                        :disabled="!enableSerial"
                                        :autofocus="
                                            enableSerial && !enableLocaiton
                                        "
                                        :counter="255"
                                        :error-messages="errors"
                                        label="Serial Number"
                                        hint="The serial number of the asset, this is NOT the company barcode"
                                        :class="{
                                            required: enableSerial,
                                        }"
                                        required
                                    >
                                    </v-text-field>
                                </validation-provider>
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Asset Number"
                                    rules="required"
                                >
                                    <v-text-field
                                        v-model="asset"
                                        name="Asset Number"
                                        :autofocus="
                                            !enableSerial && !enableLocaiton
                                        "
                                        ref="assetInput"
                                        outlined
                                        dense
                                        :loading="isAdding"
                                        :error-messages="errors"
                                        label="Asset Number"
                                        hint="This is the company barcode"
                                        class="required"
                                        required
                                    >
                                    </v-text-field>
                                </validation-provider>

                                <div class="caption mt-4 text-right">
                                    Added to stock: {{ addCount }}
                                </div>

                                <v-btn
                                    color="success"
                                    small
                                    :loading="isAdding"
                                    class="mt-3"
                                    type="submit"
                                >
                                    Add to stock
                                </v-btn>
                                <div class="caption">
                                    You can also hit 'enter' to submit
                                </div>
                            </form>
                        </validation-observer>
                    </div>
                </div>
            </v-fade-transition>
        </v-sheet>

        <v-fade-transition>
            <v-sheet v-if="productStockLevels" class="pa-6 mt-6" elevation="2">
                <div class="text-h6">
                    Product Assets
                    <v-chip label small
                        >{{ productStockLevels.length }} assets</v-chip
                    >
                </div>
                <v-list>
                    <div
                        v-for="(stock, index) in productStockLevels"
                        :key="index"
                    >
                        <v-divider />
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ stock.asset_number }}
                                    <v-chip
                                        v-if="stock.new"
                                        label
                                        small
                                        color="success"
                                    >
                                        New
                                    </v-chip>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </v-list>
            </v-sheet>
        </v-fade-transition>

        <audio-playback
            :src="$page.props.files.successAudio"
            ref="successAudio"
        />
        <audio-playback :src="$page.props.files.errorAudio" ref="errorAudio" />
    </div>
</template>

<script>
import AudioPlayback from "../General/AudioPlayback";
export default {
    name: "AddStockForm",
    components: { AudioPlayback },

    props: {
        productSearchRouteName: {
            type: String,
            required: true,
        },
        changeStockRouteName: {
            type: String,
            required: true,
        },
        addStockRouteName: {
            type: String,
            required: true,
        },
        getStockLevelsRouteName: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            product: null,
            productSearch: null,
            productList: [],
            isSearching: false,
            isConverting: false,
            isAdding: false,
            enableSerial: true,
            enableLocaiton: true,
            asset: "",
            serial: "",
            location: "",
            addCount: 0,
            error: null,
            productStockLevels: null,
            storeId: null,
        };
    },

    watch: {
        productSearch: {
            handler: _.debounce(function () {
                if (
                    (this.product &&
                        this.product.name === this.productSearch) ||
                    !this.productSearch
                ) {
                    return;
                }
                this.isSearching = true;
                this.$http
                    .get(
                        this.route(this.productSearchRouteName, {
                            product: this.productSearch,
                        })
                    )
                    .then((response) => {
                        this.productList = response.data;
                        this.isSearching = false;
                    });
            }, 500),
        },
        enableSerial() {
            this.focusInput();
        },
        product(value) {
            if (value) {
                this.getStockLevels();
            }
        },
    },

    computed: {
        stores() {
            return this.$page.props.stores;
        },
    },

    methods: {
        startOver() {
            this.product = null;
            this.productSearch = null;
            this.productList = [];
            this.isSearching = false;
            this.addCount = 0;
            this.error = null;
            this.asset = this.serial = "";
            this.productStockLevels = null;
        },
        convert() {
            if (!confirm("Are you sure?")) {
                return;
            }

            this.isConverting = true;

            this.$http
                .put(this.route(this.changeStockRouteName), {
                    id: this.product.id,
                })
                .then(() => {
                    this.product.stock_method = 2;
                    this.$toast.success("Product Converted", {
                        duration: 2000,
                    });
                })
                .catch(() => {
                    this.$toast.error(
                        "There was a problem with converting that product",
                        {
                            duration: 4000,
                        }
                    );
                })
                .finally(() => {
                    this.isConverting = false;
                });
        },

        focusInput() {
            if (this.enableSerial) {
                this.$nextTick(() => {
                    this.$refs.serialInput.focus();
                });
            } else {
                this.$refs.assetInput.focus();
            }
        },

        add() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    this.focusInput();
                    return;
                }

                this.isAdding = true;
                this.error = null;

                this.$http
                    .post(this.route(this.addStockRouteName), {
                        id: this.product.id,
                        asset: this.asset,
                        serial: this.serial,
                        store_id: this.storeId,
                        location: this.location,
                    })
                    .then(() => {
                        this.$refs.successAudio.play();
                        this.addCount++;
                        this.addToStockLevel(this.asset);
                        this.serial = "";
                        this.asset = "";
                        this.$refs.form.reset();
                        this.$toast.success("Asset added!", {
                            duration: 2000,
                        });
                    })
                    .catch((error) => {
                        this.$refs.errorAudio.play();
                        if (error.response.data.errors) {
                            this.error = Object.values(
                                error.response.data.errors
                            )[0][0];
                        } else {
                            this.error =
                                "There has been an error adding that asset";
                        }
                    })
                    .finally(() => {
                        this.isAdding = false;
                        this.focusInput();
                    });
            });
        },

        getStockLevels() {
            if (!this.product || this.product.stock_method !== 2) {
                return;
            }
            this.$http
                .get(this.route(this.getStockLevelsRouteName, this.product.id))
                .then((response) => {
                    this.productStockLevels = response.data;
                });
        },

        addToStockLevel(asset) {
            this.productStockLevels.unshift({
                item_id: this.product.id,
                asset_number: asset,
                new: true,
            });
        },
    },
};
</script>

<style scoped></style>
