<template>
    <div class="text-center">
        <head-title title="Invoice Payment | Pytch" do-not-append />
        <div class="text-h6 primary--text">
            We are just setting up your payment
        </div>
        <v-btn class="mt-6" icon x-large loading />
    </div>
</template>

<script>
import SecureLayout from "../../Comps/Secure/SecureLayout";
import HeadTitle from "../../Comps/Master/HeadTitle";

export default {
    name: "SecureIndex",
    components: { HeadTitle },
    layout: SecureLayout,
    props: {
        invoiceId: String,
        memberId: String,
        memberUuid: String,
    },

    mounted() {
        this.$inertia.get(this.route("secure.payment", this.$props));
    },
};
</script>

<style scoped></style>
