<template>
    <v-breadcrumbs :items="items" class="pa-0 mb-3">
        <template v-slot:divider>
            <v-icon>mdi-forward</v-icon>
        </template>
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item link href="#" :disabled="item.disabled">
                <inertia-link as="span" :href="item.link ? item.link : '#'">
                    {{ item.text }}
                </inertia-link>
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>

<script>
export default {
    name: "Breadcrumbs",
    props: ["items"],

    methods: {
        goto(item) {
            if (item.link) {
                this.$inertia.visit(item.link, {
                    data: this.getData(item),
                });
            }
        },
        getData(item) {
            if (item.data) {
                return item.data;
            } else {
                return {};
            }
        },
    },
};
</script>

<style scoped></style>
