<template>
    <expansion-panel
        icon="mdi-table"
        header="Timesheets"
        v-if="hasPermission('staffAdminTimesheets')"
    >
        <div>
            <v-sheet class="grey lighten-5 pa-4" rounded elevation="2">
                <div v-if="!hasEarningRate" class="text-body-1">
                    This user does not have an earning rate set, please complete
                    the form below
                </div>

                <user-earning-rate-form
                    :initial-rates="$page.props.user.earning_rate"
                    :earning-route="
                        route(
                            'staff.admin.users.update-earning-rates',
                            this.$page.props.user
                        )
                    "
                />
            </v-sheet>

            <div v-if="hasEarningRate">
                <v-divider class="my-3" />

                <div class="d-flex">
                    <v-pagination
                        v-model="page"
                        :length="timesheets.last_page"
                    />
                </div>

                <timesheet-table :items="timesheets.data" hide-footer />
            </div>
        </div>
    </expansion-panel>
</template>

<script>
import ExpansionPanel from "./ExpansionPanel.vue";
import Permissions from "../../../../Mixins/Permissions.js";
import UserEarningRateForm from "./UserEarningRateForm.vue";
import TimesheetTable from "../../Timesheets/TimesheetTable.vue";

export default {
    name: "TimesheetsPanel",
    components: { TimesheetTable, UserEarningRateForm, ExpansionPanel },
    mixins: [Permissions],

    props: {
        showUser: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            page: this.$page.props.timesheets.current_page,
        };
    },

    watch: {
        page(page) {
            this.$inertia.visit(
                this.route("staff.admin.users.show", this.user.id),
                {
                    method: "get",
                    data: {
                        timesheetPage: page,
                    },
                    only: ["timesheets", "flash"],
                    preserveScroll: true,
                    preserveState: true,
                }
            );
        },
    },

    methods: {
        clickRow(row) {
            this.$inertia.visit(this.route("staff.admin.timesheets.show", row));
        },
        deleteSheet(sheet) {
            if (!confirm("Are you sure you want to delete the timesheet?")) {
                return;
            }

            this.$inertia.delete(
                this.route("staff.admin.timesheets.destroy", sheet)
            );
        },
    },

    computed: {
        hasEarningRate() {
            return !!this.$page.props.user.earning_rate;
        },
        timesheets() {
            return this.$page.props.timesheets;
        },
        headers() {
            let headers = [
                { text: "Month Start", value: "start_date", sortable: false },
                { text: "Status", value: "status", sortable: false },
                { text: "Pay Type", value: "pay_type", sortable: false },
                { text: "", value: "actions", sortable: false },
            ];

            if (this.showUser) {
                headers.unshift({
                    text: "User",
                    value: "user",
                    sortable: false,
                });
            }

            return headers;
        },
    },
};
</script>

<style scoped></style>
