<template>
    <file-sheet
        v-on="$listeners"
        v-bind="$attrs"
        v-if="type"
        :src="fileData"
        :type="type"
        :name="name"
    />
</template>

<script>
import FileHelpers from "../../Mixins/FileHelpers";
import FileSheet from "./FileDisplay/FileSheet";

export default {
    name: "DisplayRawFile",
    components: { FileSheet },
    mixins: [FileHelpers],

    props: {
        file: {
            type: [File, Object],
            required: true,
        },
    },

    data() {
        return {
            extension: null,
            name: null,
            type: null,
            fileData: null,
        };
    },

    mounted() {
        this.mountFile();
    },

    methods: {
        async mountFile() {
            if (this.file instanceof File) {
                await this.mountRawFile();
                return;
            }

            this.mountFileModel();
        },

        async mountRawFile() {
            const extension = this.getExtension(this.file);
            const type = this.getFileType(extension);
            this.extension = extension;
            this.type = type;
            this.name = this.file.name;

            this.fileData = await this.readFile(this.file);
        },
        mountFileModel() {
            const extension = this.file.extension;
            const type = this.getFileType(extension);
            this.extension = extension;
            this.type = type;
            this.name = this.file.name;

            this.fileData = this.file.url;
        },
    },
};
</script>

<style scoped></style>
