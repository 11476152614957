<template>
    <modal-template v-model="show">
        <div class="text-h6 text--secondary">Add Circuit</div>
        <v-divider class="mb-6" />

        <div v-if="circuitToAdd">
            <validation-observer v-slot="{ invalid, failed }" ref="form">
                <form novalidate @submit.prevent="checkCircuit">
                    <v-row>
                        <v-col>
                            <validation-provider
                                v-slot="{ errors }"
                                name="Circuit Number"
                                rules="required|min_value:1"
                            >
                                <v-text-field
                                    v-model="circuitToAdd.circuit_number"
                                    name="Circuit Number"
                                    type="number"
                                    :error-messages="errors"
                                    label="Circuit Number"
                                    class="required"
                                    required
                                >
                                </v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col>
                            <validation-provider
                                v-slot="{ errors }"
                                name="RCD Type"
                                rules="required"
                            >
                                <v-select
                                    v-model="circuitToAdd.rcd_type"
                                    :items="rcdTypeItems"
                                    label="RCD Type"
                                />
                            </validation-provider>
                        </v-col>
                        <v-col>
                            <validation-provider
                                v-slot="{ errors }"
                                name="IR"
                                rules="required|max_value:19.99|min_value:0.01"
                            >
                                <v-text-field
                                    v-model="circuitToAdd.insulation"
                                    type="number"
                                    step=".01"
                                    name="IR"
                                    :error-messages="errors"
                                    label="IR (MΩ)"
                                    class="required"
                                    required
                                >
                                </v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col>
                            <validation-provider
                                v-slot="{ errors }"
                                name="EC"
                                rules="required|max_value:9.99|min_value:0.00"
                            >
                                <v-text-field
                                    v-model="circuitToAdd.earth"
                                    type="number"
                                    ref="ec"
                                    autofocus
                                    step=".01"
                                    name="EC"
                                    :error-messages="errors"
                                    label="EC (Ωs)"
                                    class="required"
                                    required
                                >
                                </v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="text-body-1 text-center">Visual</div>
                            <div class="d-flex justify-center">
                                <v-btn-toggle
                                    v-model="circuitToAdd.visual"
                                    tile
                                    mandatory
                                    color="white"
                                >
                                    <v-btn
                                        tabindex="1"
                                        :color="
                                            !circuitToAdd.visual ? 'error' : ''
                                        "
                                        :value="false"
                                    >
                                        Fail
                                    </v-btn>

                                    <v-btn
                                        tabindex="1"
                                        :color="
                                            circuitToAdd.visual ? 'success' : ''
                                        "
                                        :value="true"
                                    >
                                        Pass
                                    </v-btn>
                                </v-btn-toggle>
                            </div>
                        </v-col>
                        <v-col>
                            <div class="text-body-1 text-center">Polarity</div>
                            <div class="d-flex justify-center">
                                <v-btn-toggle
                                    v-model="circuitToAdd.polarity"
                                    tile
                                    mandatory
                                    color="white"
                                >
                                    <v-btn
                                        tabindex="1"
                                        :color="
                                            !circuitToAdd.polarity
                                                ? 'error'
                                                : ''
                                        "
                                        :value="false"
                                    >
                                        Fail
                                    </v-btn>

                                    <v-btn
                                        tabindex="1"
                                        :color="
                                            circuitToAdd.polarity
                                                ? 'success'
                                                : ''
                                        "
                                        :value="true"
                                    >
                                        Pass
                                    </v-btn>
                                </v-btn-toggle>
                            </div>
                        </v-col>
                        <v-col>
                            <div class="text-body-1 text-center">Test Btn</div>
                            <div class="d-flex justify-center">
                                <v-btn-toggle
                                    v-model="circuitToAdd.test_button"
                                    tile
                                    mandatory
                                    color="white"
                                >
                                    <v-btn
                                        tabindex="1"
                                        :color="
                                            !circuitToAdd.test_button
                                                ? 'error'
                                                : ''
                                        "
                                        :value="false"
                                    >
                                        Fail
                                    </v-btn>

                                    <v-btn
                                        tabindex="1"
                                        :color="
                                            circuitToAdd.test_button
                                                ? 'success'
                                                : ''
                                        "
                                        :value="true"
                                    >
                                        Pass
                                    </v-btn>
                                </v-btn-toggle>
                            </div>
                        </v-col>
                        <v-col v-if="standardRcd">
                            <div class="text-body-1 text-center">
                                No Trip @ 1/2x
                            </div>
                            <div class="d-flex justify-center">
                                <v-btn-toggle
                                    v-model="circuitToAdd.no_trip"
                                    tile
                                    mandatory
                                    color="white"
                                >
                                    <v-btn
                                        tabindex="1"
                                        :color="
                                            !circuitToAdd.no_trip ? 'error' : ''
                                        "
                                        :value="false"
                                    >
                                        Fail
                                    </v-btn>

                                    <v-btn
                                        tabindex="1"
                                        :color="
                                            circuitToAdd.no_trip
                                                ? 'success'
                                                : ''
                                        "
                                        :value="true"
                                    >
                                        Pass
                                    </v-btn>
                                </v-btn-toggle>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row v-if="standardRcd">
                        <v-col>
                            <validation-provider
                                v-slot="{ errors }"
                                name="One Times"
                                vid="one"
                                rules="required|min_value:1|speedOne"
                            >
                                <v-text-field
                                    v-model="circuitToAdd.one"
                                    name="One Times"
                                    type="number"
                                    tabindex="0"
                                    :error-messages="errors"
                                    label="Tm @ 1x"
                                    class="required"
                                    required
                                >
                                </v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col v-if="circuitToAdd.rcd_type !== '10'">
                            <validation-provider
                                v-slot="{ errors }"
                                name="Five Times"
                                vid="five"
                                rules="required|min_value:1|speedFive"
                            >
                                <v-text-field
                                    v-model="circuitToAdd.five"
                                    name="Five Times"
                                    type="number"
                                    :error-messages="errors"
                                    label="Tm @ 5x"
                                    class="required"
                                    required
                                >
                                </v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>

                    <v-row v-if="circuitToAdd.rcd_type === 'variable'">
                        <v-col>
                            <validation-provider
                                v-slot="{ errors }"
                                name="Set mA"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="circuitToAdd.variable_ma"
                                    type="number"
                                    name="Set mA"
                                    :error-messages="errors"
                                    label="Set mA"
                                    class="required"
                                    required
                                >
                                </v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col>
                            <validation-provider
                                v-slot="{ errors }"
                                name="Set Time"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="circuitToAdd.variable_time"
                                    type="number"
                                    name="Set Time"
                                    :error-messages="errors"
                                    label="Set Time"
                                    class="required"
                                    required
                                >
                                </v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col>
                            <validation-provider
                                v-slot="{ errors }"
                                name="Trip Time"
                                rules="required|variableSpeed"
                            >
                                <v-text-field
                                    v-model="circuitToAdd.variable_trip_time"
                                    type="number"
                                    name="Trip Time"
                                    :error-messages="errors"
                                    label="Trip Time"
                                    class="required"
                                    required
                                >
                                </v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>

                    <v-btn
                        color="primary"
                        :disabled="invalid"
                        class="mt-15"
                        type="submit"
                    >
                        Add Circuit
                    </v-btn>
                    <v-btn
                        color="error"
                        v-show="(invalid && failed) || buttonsFailed"
                        type="button"
                        @click="failCircuit"
                        class="mt-15"
                    >
                        Fail Circuit
                    </v-btn>
                </form>
            </validation-observer>
        </div>
    </modal-template>
</template>

<script>
import ModalTemplate from "../Master/ModalTemplate";
import { required, max, min_value, max_value } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", required);
extend("max", max);
extend("min_value", min_value);
extend("max_value", max_value);

export default {
    name: "PatTestCircuitSingleModal",
    components: { ModalTemplate },

    props: {
        value: Boolean,
        allCircuits: {
            type: Array,
            require: true,
        },
    },

    data() {
        return {
            circuitToAdd: null,
        };
    },

    mounted() {
        this.newCircuit();
    },

    created() {
        this.createCustomRules();
    },

    watch: {
        show(value) {
            if (value) {
                this.newCircuit();
                this.$nextTick(() => {
                    this.$refs.form.reset();
                });
            }
        },

        "circuitToAdd.rcd_type"() {
            this.circuitToAdd.one =
                this.circuitToAdd.five =
                this.circuitToAdd.variable_time =
                this.circuitToAdd.variable_ma =
                this.circuitToAdd.variable_trip_time =
                    null;
            this.$nextTick(() => {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }
            });
        },
    },

    computed: {
        show: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit("input", value);
            },
        },
        rcdTypeItems() {
            return [
                { text: "No RCD", value: "No RCD" },
                { text: "10mA", value: "10" },
                { text: "30mA", value: "30" },
                { text: "100mA", value: "100" },
                { text: "variable", value: "variable" },
            ];
        },

        standardRcd() {
            return (
                this.circuitToAdd.rcd_type !== "No RCD" &&
                this.circuitToAdd.rcd_type !== "variable"
            );
        },

        buttonsFailed() {
            return (
                !this.circuitToAdd.visual ||
                !this.circuitToAdd.polarity ||
                !this.circuitToAdd.test_button ||
                !this.circuitToAdd.no_trip
            );
        },
    },

    methods: {
        newDistroCircuit() {
            return {
                circuit_number: null,
                rcd_type: "30",
                insulation: "19.99",
                earth: "0.0",
                failed: false,
                visual: true,
                polarity: true,
                test_button: true,
                no_trip: true,
                one: null,
                five: null,
                variable_ma: null,
                variable_time: null,
                variable_trip_time: null,
            };
        },

        newCircuit() {
            this.circuitToAdd = this.newDistroCircuit();
            this.circuitToAdd.circuit_number = this.allCircuits.length
                ? _.reverse(_.sortBy(this.allCircuits, "circuit_number"))[0]
                      .circuit_number + 1
                : 1;
        },

        checkCircuit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.saveCircuit();
            });
        },

        async saveCircuit() {
            await this.$emit("save", this.circuitToAdd);
            let oldRCD = this.circuitToAdd.rcd_type;
            this.newCircuit();
            this.$toast.success("Circuit added!", {
                duration: 2000,
            });
            this.$refs.form.reset();
            this.circuitToAdd.rcd_type = oldRCD;
            this.$nextTick(() => {
                let el = this.$refs.ec.$el.querySelector("input");
                el.focus();
                el.select();
            });
        },

        failCircuit() {
            this.circuitToAdd.failed = true;
            this.saveCircuit();
        },

        createCustomRules() {
            extend("speedOne", (value) => {
                let type = this.circuitToAdd.rcd_type;
                if (type === "10") {
                    if (parseInt(value) < 40) {
                        return true;
                    }

                    return "This time has to be less than 40ms to pass";
                }

                if (type === "30") {
                    if (parseInt(value) < 300) {
                        return true;
                    }

                    return "This time has to be less than 300ms to pass";
                }

                if (type === "100") {
                    if (parseInt(value) < 500) {
                        return true;
                    }

                    return "This time has to be less than 500ms to pass";
                }
            });

            extend("speedFive", (value) => {
                let type = this.circuitToAdd.rcd_type;
                if (type === "30") {
                    if (parseInt(value) < 40) {
                        return true;
                    }

                    return "This time has to be less than 40ms to pass";
                }

                if (type === "100") {
                    if (parseInt(value) < 150) {
                        return true;
                    }

                    return "This time has to be less than 150ms to pass";
                }
            });

            extend("variableSpeed", (value) => {
                value = parseInt(value);
                let time = parseInt(this.circuitToAdd.variable_time);
                let max = time + 200;
                let min = time / 2;
                if (value < max && value > min) {
                    return true;
                }

                return (
                    "This time has to be between " +
                    min +
                    "ms and " +
                    max +
                    "ms to pass"
                );
            });
        },
    },
};
</script>

<style scoped></style>
