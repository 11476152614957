<template>
    <div>
        <v-sheet elevation="2" class="pa-4">
            <div>
                <div class="text-subtitle-2 mb-4">
                    Choose a store to transfer the asset to and then type or
                    scan the asset number
                </div>

                <form novalidate @submit.prevent="submit">
                    <validation-observer ref="form">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Store"
                            rules="required"
                        >
                            <v-select
                                v-model="form.store_id"
                                name="Store"
                                dense
                                outlined
                                :items="stores"
                                item-text="name"
                                item-value="id"
                                :error-messages="errors"
                                label="Store"
                                hint="Choose a store to transfer the asset to"
                                class="required"
                                required
                            >
                            </v-select>
                        </validation-provider>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Asset"
                            rules="required"
                        >
                            <v-text-field
                                v-model="form.serial_number"
                                name="Asset"
                                autofocus
                                ref="serialNumber"
                                dense
                                outlined
                                :error-messages="errors"
                                label="Asset"
                                hint="Type or scan an asset to transfer"
                                class="required"
                                required
                            >
                            </v-text-field>
                        </validation-provider>
                    </validation-observer>

                    <v-btn
                        color="success"
                        :loading="form.processing"
                        class="mt-4"
                        type="submit"
                    >
                        Transfer
                    </v-btn>
                </form>
            </div>
        </v-sheet>

        <audio-playback
            :src="$page.props.files.successAudio"
            ref="successAudio"
        />
        <audio-playback :src="$page.props.files.errorAudio" ref="errorAudio" />
    </div>
</template>

<script>
import AudioPlayback from "../General/AudioPlayback";
export default {
    name: "StockTransferForm",
    components: { AudioPlayback },

    props: {
        transferStockRouteName: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            form: this.$inertia.form({
                serial_number: null,
                store_id: null,
            }),
        };
    },

    computed: {
        stores() {
            return this.$page.props.stores;
        },
    },

    methods: {
        submit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.form.post(this.route(this.transferStockRouteName), {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => {
                        this.form.reset("serial_number");
                        this.$refs.form.reset();
                        this.$refs.successAudio.play();

                        this.$nextTick(() => {
                            this.$refs.serialNumber.focus();
                        });
                    },

                    onError: () => {
                        this.$refs.errorAudio.play();
                        this.form.reset("serial_number");
                    },
                });
            });
        },
    },
};
</script>

<style scoped></style>
