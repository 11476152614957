<template>
    <v-container>
        <header-name>Asset Search</header-name>
        <asset-search-form route-name="asset-search.search" />
    </v-container>
</template>

<script>
import ProcessesController from "../../../Mixins/ProcessesController";
import HeaderName from "../../../Comps/General/HeaderName";
import AssetSearchForm from "../../../Comps/Processes/AssetSearch/AssetSearchForm";

export default {
    name: "AssetSearch",
    components: { AssetSearchForm, HeaderName },
    mixins: [ProcessesController],
};
</script>

<style scoped></style>
