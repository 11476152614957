export default {
    props: {
        value: {
            required: true,
        },
    },

    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
};
