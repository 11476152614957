<template>
    <modal-template v-model="show">
        <div class="text-h6 text--secondary mb-2 mt-2">
            <span v-if="!edit">Create a new module</span>
            <span v-else>Edit module - {{ edit.name }}</span>
        </div>

        <form>
            <validation-observer ref="form">
                <v-row>
                    <v-col cols="12">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Module Name"
                            rules="required|max:255"
                        >
                            <v-text-field
                                v-model="module.name"
                                autofocus
                                :counter="255"
                                :error-messages="errors"
                                label="Module Name"
                                hint="The name of the module"
                                required
                            >
                            </v-text-field>
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col col="12" md="6">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Learning Objectives"
                            rules="required"
                        >
                            <v-combobox
                                v-model="module.objectives"
                                label="Learning Objectives"
                                multiple
                                small-chips
                                :error-messages="errors"
                                hint="Type an objective and hit enter"
                                required
                            ></v-combobox>
                        </validation-provider>
                    </v-col>
                    <v-col col="12" md="6">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Skills"
                            rules="required"
                        >
                            <v-combobox
                                v-model="module.skills"
                                label="Individual skills to acquire"
                                multiple
                                small-chips
                                :error-messages="errors"
                                hint="Type a skill and hit enter"
                                required
                            ></v-combobox>
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col col="12" md="6">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Learning Resources"
                        >
                            <v-combobox
                                v-model="module.learning_resources"
                                label="Learning resources required"
                                multiple
                                small-chips
                                :error-messages="errors"
                                hint="Type a learning resource and hit enter"
                            ></v-combobox>
                        </validation-provider>
                    </v-col>
                    <v-col col="12" md="6">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Department"
                            rules="required"
                        >
                            <v-select
                                :items="departments"
                                v-model="module.department"
                                label="Department"
                                required
                                hint="Select a department this module relates to"
                                :error-messages="errors"
                            ></v-select>
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row>
                    <v-col col="12">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Training Agenda"
                            rules="required|max:2000"
                        >
                            <v-textarea
                                v-model="module.agenda"
                                :counter="2000"
                                :error-messages="errors"
                                label="Training Agenda"
                                hint="The agenda of this training module"
                                solo
                                required
                            >
                            </v-textarea>
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row>
                    <v-col cols="12">
                        <v-slider
                            v-model="module.valid_for"
                            label="Valid for (months)"
                            :min="1"
                            :max="60"
                            thumb-label="always"
                        >
                        </v-slider>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-btn
                            color="success"
                            @click.prevent="submit"
                            :loading="loading"
                        >
                            {{ edit ? "Update" : "Create" }}
                        </v-btn>
                    </v-col>
                </v-row>
            </validation-observer>
        </form>
    </modal-template>
</template>

<script>
import { required, email, max, numeric } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import ModalTemplate from "../../Master/ModalTemplate";

extend("required", required);
extend("max", max);
extend("email", email);
extend("numeric", numeric);
export default {
    name: "ModulesCreateModal",
    components: { ModalTemplate },
    props: {
        edit: {
            type: Object,
            default: null,
        },
        value: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            module: {
                valid_for: 12,
            },
            loading: false,
        };
    },

    watch: {
        show(value) {
            if (value) {
                if (this.edit) {
                    this.module = this.edit;
                }
            }
        },
    },

    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        departments() {
            const arr = [
                "Sound",
                "Lighting",
                "Video",
                "Rigging",
                "Professionalism",
                "Software",
                "Warehouse & Tooling",
                "Networking & IT",
                "Health & Safety",
                "Fabrication",
                "Power",
                "Content & Creative",
            ];

            return _.sortBy(arr);
        },
    },

    methods: {
        reset() {
            this.module = {
                valid_for: 12,
            };
            this.$refs.form.reset();
        },

        submit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.loading = true;

                let url;
                let type;
                if (this.edit) {
                    url = this.route(
                        "staff.admin.training.modules.update",
                        this.edit.id
                    );
                    type = "put";
                } else {
                    url = this.route("staff.admin.training.modules.store");
                    type = "post";
                }

                this.$inertia.visit(url, {
                    data: this.module,
                    method: type,
                    preserveState: true,
                    onSuccess: () => {
                        this.show = false;
                        this.reset();
                    },
                    onFinish: () => {
                        this.loading = false;
                    },
                });
            });
        },
    },
};
</script>

<style scoped></style>
