<template>
    <v-expansion-panel class="custom">
        <v-expansion-panel-header> Categories </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-list dense>
                <v-list-item v-for="category in categories" :key="category.id">
                    <v-list-item-icon>
                        <v-icon :color="category.color">{{
                            category.icon
                        }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ category.name }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    name: "CategoryKey",
    props: {
        categories: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.custom .v-expansion-panel-content__wrap {
    padding: 0 4px 16px;
}
</style>
