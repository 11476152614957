<template>
    <div>
        <v-container>
            <breadcrumbs :items="bread"></breadcrumbs>

            <v-alert v-if="logisticLive" type="warning">
                This task is currently in a live version of logistics. You
                should only be making MINOR adjustments, if you can create a new
                version of Logistics and send it out, you should be doing that.
                <br /><br />Staff members attached to this task ARE NOT notified
                of changes made to live tasks!
            </v-alert>

            <header-name v-if="taskProp">{{ task.name }}</header-name>
            <header-name v-else>New Task</header-name>

            <form>
                <validation-observer ref="form">
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Task Name"
                                        rules="required|max:255"
                                    >
                                        <v-text-field
                                            v-model="task.name"
                                            :counter="255"
                                            autofocus
                                            :error-messages="errors"
                                            label="Task Name"
                                            hint="The task name"
                                            required
                                        >
                                        </v-text-field>
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Category"
                                        rules="required"
                                    >
                                        <v-select
                                            v-model="task.category_id"
                                            :items="categories"
                                            item-value="id"
                                            item-text="name"
                                            label="Category"
                                            prepend-icon="mdi-view-module"
                                            :error-messages="errors"
                                        >
                                            <template v-slot:item="{ item }">
                                                <v-icon
                                                    class="mr-2"
                                                    :style="{
                                                        color: item.color,
                                                    }"
                                                >
                                                    {{ item.icon }}
                                                </v-icon>
                                                <span
                                                    :style="{
                                                        color: item.color,
                                                    }"
                                                    >{{ item.name }}</span
                                                >
                                            </template>
                                        </v-select>
                                    </validation-provider>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Linked Job"
                                    >
                                        <v-select
                                            v-model="task.job_id"
                                            :items="jobs"
                                            item-value="id"
                                            item-text="name"
                                            label="Linked Job"
                                            hint="Please select a job from this week to link to the task.
                                    <br> A <span class='error--text'>red</span> cross means the job isn't
                                    linked to a task in this weeks logistics and a <span class='success--text'>green</span>
                                    tick means it is linked to one or more tasks"
                                            persistent-hint
                                            clearable
                                        >
                                            <template
                                                v-slot:message="{
                                                    message,
                                                    key,
                                                }"
                                            >
                                                <span v-html="message"></span>
                                            </template>

                                            <template v-slot:item="{ item }">
                                                <div class="mr-1">
                                                    <v-icon
                                                        v-if="item.in_use"
                                                        color="success"
                                                        >mdi-check-outline
                                                    </v-icon>
                                                    <v-icon v-else color="error"
                                                        >mdi-close-outline
                                                    </v-icon>
                                                </div>
                                                {{ item.name }}
                                            </template>
                                        </v-select>
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Dress Code"
                                        rules="required"
                                    >
                                        <v-select
                                            v-model="task.dress_code"
                                            :items="dressCodes"
                                            item-value="value"
                                            item-text="description"
                                            label="Dress codes"
                                            :error-messages="errors"
                                        >
                                            <template v-slot:item="{ item }">
                                                <v-icon class="mr-2">
                                                    {{ item.icon }}
                                                </v-icon>
                                                <span>
                                                    {{ item.description }}
                                                </span>
                                            </template>

                                            <template
                                                v-slot:selection="{ item }"
                                            >
                                                <v-icon class="mr-2">
                                                    {{ item.icon }}
                                                </v-icon>
                                                <span>
                                                    {{ item.description }}
                                                </span>
                                            </template>
                                        </v-select>
                                    </validation-provider>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="4">
                                    <time-picker
                                        v-model="task.time_from"
                                        :max="task.time_to"
                                        :required="false"
                                        name="Start Time"
                                        hint="The start time of the task, if left empty the start time will be midnight"
                                    >
                                    </time-picker>
                                </v-col>

                                <v-col
                                    cols="12"
                                    md="4"
                                    class="d-flex align-center"
                                >
                                    <time-picker
                                        v-if="!task.when_its_done"
                                        v-model="task.time_to"
                                        :min="task.time_from"
                                        :required="false"
                                        name="End Time"
                                        hint="The end time of the task, if left empty the end time will be 23:59"
                                    >
                                        <template #append-outer>
                                            <v-checkbox
                                                v-model="task.when_its_done"
                                                v-tooltip="'When its done 🚀'"
                                                dense
                                            />
                                        </template>
                                    </time-picker>
                                    <v-checkbox
                                        v-if="task.when_its_done"
                                        v-model="task.when_its_done"
                                        dense
                                        label="When its done 🚀"
                                    />
                                </v-col>

                                <v-col cols="12" md="4">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="Day of the week"
                                    >
                                        <v-select
                                            v-model="task.date"
                                            :items="days"
                                            prepend-icon="mdi-calendar-today"
                                            label="Day of the week"
                                        >
                                        </v-select>
                                    </validation-provider>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <html-editor
                                        v-model="task.text"
                                        class="mt-5"
                                        label="Task Info"
                                    >
                                    </html-editor>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!--Users-->
                        <v-col cols="12" md="4">
                            <v-sheet :elevation="2" class="pa-2">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Users"
                                >
                                    <select-multiple-users-chip-truncate
                                        v-model="task.users"
                                        :users="usersAndFreelancers"
                                        label="Select Staff"
                                        truncate
                                        :errors="errors"
                                    >
                                        <template v-slot:item="{ item, attrs }">
                                            <v-list-item-action>
                                                <v-checkbox
                                                    :input-value="
                                                        attrs.inputValue
                                                    "
                                                ></v-checkbox>
                                            </v-list-item-action>
                                            <task-users-select-user
                                                :user="item"
                                                :task="task"
                                                :key="item.id"
                                            />
                                        </template>
                                    </select-multiple-users-chip-truncate>
                                </validation-provider>

                                <h5 class="text--secondary">Selected Staff</h5>
                                <h6 class="text--secondary font-weight-medium">
                                    Choose a project lead by clicking on the
                                    crown
                                </h6>
                                <v-divider class="mb-2" />
                                <task-users-panel
                                    v-if="selectedUsers.length"
                                    v-model="task.project_lead"
                                    :task="task"
                                    :users.sync="task.users"
                                >
                                </task-users-panel>
                                <h5
                                    v-else
                                    class="text--secondary font-weight-medium text-center"
                                >
                                    Select at least one staff member or leave
                                    empty to assign task to all users
                                </h5>

                                <v-divider class="my-4" />

                                <!--Vehicles-->
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="Vehicles"
                                >
                                    <select-multiple-users-chip-truncate
                                        v-model="task.vehicles"
                                        :users="allVehicles"
                                        label="Select Vehicles"
                                        truncate
                                        :errors="errors"
                                    >
                                        <template v-slot:item="{ item, attrs }">
                                            <v-list-item-action>
                                                <v-checkbox
                                                    :input-value="
                                                        attrs.inputValue
                                                    "
                                                ></v-checkbox>
                                            </v-list-item-action>
                                            <task-vehicle-select-vehicle
                                                :vehicle="item"
                                                :task="task"
                                                :key="`vehicle-${item.id}`"
                                            />
                                        </template>
                                    </select-multiple-users-chip-truncate>
                                </validation-provider>

                                <h5
                                    id="selectedVehicles"
                                    class="text--secondary"
                                >
                                    Selected Vehicles
                                </h5>
                                <v-divider class="mb-2" />

                                <v-list v-if="task.vehicles.length" two-line>
                                    <template
                                        v-for="(vehicle, i) in task.vehicles"
                                    >
                                        <task-vehicle
                                            :key="`item-${i}`"
                                            :task="task"
                                            :vehicle="vehicle"
                                            @remove="removeVehicle(i)"
                                        >
                                        </task-vehicle>
                                    </template>
                                </v-list>
                                <h5
                                    v-else
                                    class="text--secondary font-weight-medium text-center"
                                >
                                    Select a vehicle
                                </h5>
                            </v-sheet>
                        </v-col>

                        <v-col>
                            <v-btn
                                @click="create"
                                class="mt-5"
                                color="success"
                                :disabled="!canUpdate"
                                :loading="loading"
                            >
                                {{ this.taskProp ? "Update" : "Create" }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </validation-observer>
            </form>
        </v-container>
    </div>
</template>

<script>
import HeaderName from "../../../../Comps/General/HeaderName";
import Breadcrumbs from "../../../../Comps/General/Breadcrumbs";
import { required, max } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import TimePicker from "../../../../Comps/Master/TimePicker";
import HtmlEditor from "../../../../Comps/General/HtmlEditor";
import { Inertia } from "@inertiajs/inertia";
import TaskUsersPanel from "../../../../Comps/Staff/Logistics/Tasks/TaskUsersPanel";
import TaskVehicle from "../../../../Comps/Staff/Logistics/Tasks/TaskVehicle";
import AdminController from "../../../../Mixins/AdminController";
import SelectMultipleUsersChipTruncate from "../../../../Comps/General/SelectMultipleUsersChipTruncate";
import TaskUsersSelectUser from "../../../../Comps/Staff/Logistics/Tasks/TaskUsersSelectUser";
import TaskVehicleSelectVehicle from "../../../../Comps/Staff/Logistics/Tasks/TaskVehicleSelectVehicle";

extend("required", required);
extend("max", max);

export default {
    name: "TaskCreate",
    components: {
        TaskVehicleSelectVehicle,
        TaskUsersSelectUser,
        SelectMultipleUsersChipTruncate,
        TaskVehicle,
        TaskUsersPanel,
        HtmlEditor,
        TimePicker,
        Breadcrumbs,
        HeaderName,
    },
    props: {
        logistic: {
            type: Object,
            required: true,
        },
        jobs: {
            type: Array,
            required: true,
        },
        taskProp: {
            type: Object,
            required: false,
        },
        allUsers: {
            type: Array,
            required: true,
        },
        allFreelancers: {
            type: Array,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
        allVehicles: {
            type: Array,
            required: true,
        },
        dressCodes: {
            type: Array,
            required: true,
        },
    },

    mixins: [AdminController],

    data() {
        return {
            task: {},
            loading: false,
            originalTask: {},
        };
    },

    created() {
        //Setup the task object
        this.taskProp ? this.cloneTaskIn() : this.freshTaskObject();
    },

    watch: {
        "task.time_from"(value) {
            this.task.time_from_original = this.moment(value, "HH:mm")
                .utc(true)
                .toISOString();
        },
        "task.time_to"(value) {
            this.task.time_to_original = this.moment(value, "HH:mm")
                .utc(true)
                .toISOString();
        },
    },

    mounted() {
        window.addEventListener("beforeunload", this.preventNav);
        this.$once(
            "hook:destroyed",
            Inertia.on("before", (event) => {
                return this.preventNav(event, true);
            })
        );
    },

    destroyed() {
        window.removeEventListener("beforeunload", this.preventNav);
    },

    computed: {
        days() {
            return [
                { text: "Monday", value: this.mapWeekToDate(1) },
                { text: "Tuesday", value: this.mapWeekToDate(2) },
                { text: "Wednesday", value: this.mapWeekToDate(3) },
                { text: "Thursday", value: this.mapWeekToDate(4) },
                { text: "Friday", value: this.mapWeekToDate(5) },
                { text: "Saturday", value: this.mapWeekToDate(6) },
                { text: "Sunday", value: this.mapWeekToDate(7) },
            ];
        },
        weekFormat() {
            return this.logisticWeekMoment.format("DD-MM-YYYY");
        },
        logisticWeekMoment() {
            return this.moment(this.logistic.week);
        },
        bread() {
            return [
                {
                    text: this.weekFormat + " v" + this.logistic.version,
                    disabled: false,
                    link: this.route("staff.admin.logistics.index", [
                        this.weekFormat,
                        this.logistic.version,
                    ]),
                },
                {
                    text: this.taskProp ? this.task.name : "New Task",
                    disabled: true,
                },
            ];
        },
        canUpdate() {
            if (this.taskProp) {
                let task = _.cloneDeep(this.task);
                let originalTask = _.cloneDeep(this.originalTask);
                delete task.time_to_original;
                delete task.time_from_original;
                delete originalTask.time_from_original;
                delete originalTask.time_to_original;
                return !_.isEqual(task, originalTask);
            }
            return true;
        },
        selectedUsers() {
            return this.task.users;
        },
        usersAndFreelancers() {
            let array = [];
            array.push({ header: "Staff" });
            array.push(
                ..._.filter(this.sortedUsers, {
                    hide_from_logistics: false,
                })
            );
            array.push({ header: "Temp Staff" });
            array.push(
                ..._.filter(this.sortedUsers, {
                    hide_from_logistics: true,
                })
            );
            array.push({ divider: true });
            array.push({ header: "Freelancers" });
            array.push(..._.sortBy(this.freelancersWithAppend, "name"));
            return array;
        },
        freelancersWithAppend() {
            return _.map(this.$page.props.allFreelancers, (freelancer) => {
                freelancer.name = freelancer.name + " (F)";
                return freelancer;
            });
        },
        sortedUsers() {
            return _.sortBy(this.$page.props.allUsers, "name");
        },
        logisticLive() {
            return !!this.logistic.sent;
        },
    },

    methods: {
        removeVehicle(index) {
            this.task.vehicles.splice(index, 1);
        },

        preventNav(event, inertia = false) {
            if (this.taskProp && this.canUpdate && !this.loading) {
                if (inertia) {
                    return confirm("You have unsaved changes, Are you sure?");
                } else {
                    event.preventDefault();
                    event.returnValue = "";
                }
            }
        },
        cloneTaskIn() {
            if (!this.taskProp) {
                return;
            }
            let task;
            task = _.cloneDeepWith(this.taskProp, (task) => {
                task.time_from_original = this.moment(task.time_from)
                    .utc(true)
                    .toISOString();
                task.time_to_original = this.moment(task.time_to)
                    .utc(true)
                    .toISOString();
                task.time_from = task.time_from
                    ? this.moment(task.time_from).utc().format("HH:mm")
                    : null;
                task.time_to = task.time_to
                    ? this.moment(task.time_to).utc().format("HH:mm")
                    : null;
                task.date = this.moment(task.date).format("YYYY-MM-DD");
                this.findProjectLead(task);
                task.users = task.users.concat(task.freelancers);
                return task;
            });
            this.originalTask = _.cloneDeep(task);
            this.task = _.cloneDeep(task);
        },
        freshTaskObject() {
            this.task = {
                users: [],
                vehicles: [],
                text: "",
                date: this.mapWeekToDate(1),
                project_lead: null,
                dress_code: "standard",
                when_its_done: false,
            };
        },
        findProjectLead(task) {
            //Find the index of the lead user
            let index = _.findIndex(task.users, (user) => {
                return user.pivot.lead;
            });
            if (index !== -1) {
                task.project_lead = task.users[index];
            } else {
                //Find the index of the lead freelancer
                let index = _.findIndex(task.freelancers, (user) => {
                    return user.pivot.lead;
                });
                task.project_lead =
                    index !== -1 ? task.freelancers[index] : null;
            }
        },
        create() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                if (this.logisticLive) {
                    if (
                        !confirm(
                            "This task is currently in a live version of logistics. " +
                                "You should only be making MINOR adjustments, " +
                                "if you can create a new version of Logistics and send it out, " +
                                "you should be doing that."
                        )
                    ) {
                        return;
                    }
                }

                this.loading = true;

                //Work out route
                let url;
                let method;
                if (this.taskProp) {
                    url = this.route("staff.admin.logistics.tasks.update", [
                        this.logistic.slug,
                        this.task.id,
                    ]);
                    method = "put";
                } else {
                    url = this.route(
                        "staff.admin.logistics.tasks.store",
                        this.logistic.slug
                    );
                    method = "post";
                }

                this.$inertia.visit(url, {
                    preserveState: true,
                    data: this.task,
                    method: method,

                    onError: () => {
                        this.loading = false;
                    },
                });
            });
        },
        mapWeekToDate(dayNumber) {
            return this.logisticWeekMoment
                .clone()
                .add(dayNumber - 1, "days")
                .format("YYYY-MM-DD");
        },
    },
};
</script>

<style scoped></style>
