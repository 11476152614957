export default {
    data() {
        return {
            extensionTypes: {
                mov: "video",
                mp4: "video",
                png: "image",
                bmp: "image",
                gif: "image",
                jpg: "image",
                jpeg: "image",
                pdf: "pdf",
            },
        };
    },

    methods: {
        /**
         * get the file extension
         * @param file
         */
        getExtension(file) {
            return file.name.split(".").pop().toLowerCase();
        },

        /**
         * Read the file and return the data url
         */
        readFile(file) {
            return new Promise((resolve) => {
                let reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(file);
            });
        },

        getFileType(extension) {
            return this.extensionTypes[extension] ?? "other";
        },
    },
};
