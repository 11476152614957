<template>
    <div>
        <head-title title="Forgot Password" />

        <h2 class="text-center">Forgot Password</h2>

        <div>
            Enter your email address and we'll send you a link to reset your
            password.
        </div>

        <form v-if="!success" class="mt-4" @submit.prevent="submit">
            <text-input
                v-model="form.email"
                type="email"
                required
                name="Email"
                rules="required"
            />

            <div class="d-flex">
                <v-btn
                    type="submit"
                    color="primary"
                    class="mt-4 ml-auto"
                    :loading="form.processing"
                >
                    Submit
                </v-btn>
            </div>
        </form>

        <div v-if="success" class="my-4">
            A password reset link has been sent to your email address.
        </div>

        <div>
            <inertia-link href="/login"> Back to Login </inertia-link>
        </div>
    </div>
</template>

<script>
import HeadTitle from "../Comps/Master/HeadTitle.vue";
import LoginLayout from "../Layouts/LoginLayout.vue";
import TextInput from "../Comps/Forms/TextInput.vue";

export default {
    name: "ForgotPassword",
    components: { TextInput, HeadTitle },
    layout: LoginLayout,

    data() {
        return {
            success: false,
            form: this.$inertia.form({
                email: "",
            }),
        };
    },

    methods: {
        submit() {
            this.form.post("/fortify/forgot-password", {
                onSuccess: () => {
                    this.success = true;
                },
            });
        },
    },
};
</script>

<style scoped></style>
