<template>
    <div>
        <v-navigation-drawer v-model="sidebar" app temporary>
            <v-list-item-group v-model="tab" mandatory>
                <v-list-item
                    v-for="item in tabs"
                    :key="item.route"
                    color="primary"
                    link
                >
                    <v-list-item-content>
                        <inertia-link
                            class="inertia-link"
                            :href="route(item.route) ? route(item.route) : '/'"
                        >
                            <v-list-item-title>
                                {{ item.name }}
                            </v-list-item-title>
                        </inertia-link>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-navigation-drawer>

        <v-app-bar color="black" dense dark app>
            <v-app-bar-nav-icon
                @click="sidebar = true"
                v-if="$vuetify.breakpoint.smAndDown"
            />
            <v-toolbar-title style="width: 100px" class="mr-4">
                <v-img :src="$page.props.files.pytchLogoWhite" />
            </v-toolbar-title>

            <v-tabs
                v-if="$vuetify.breakpoint.mdAndUp"
                v-model="tab"
                class="tabs"
            >
                <v-tabs-slider color="white" />
                <inertia-link
                    as="button"
                    style="display: contents"
                    v-for="(item, index) in tabs"
                    :key="index"
                    :href="route(item.route) ? route(item.route) : '/'"
                >
                    <v-tab>{{ item.name }}</v-tab>
                </inertia-link>
            </v-tabs>
            <v-spacer></v-spacer>

            <v-menu left bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <inertia-link
                        class="inertia-link"
                        as="button"
                        method="put"
                        :href="route('warehouse.logout')"
                    >
                        <v-list-item>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item>
                    </inertia-link>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-main class="pt-0">
            <v-container fluid>
                <slot></slot>
            </v-container>
        </v-main>
    </div>
</template>

<script>
export default {
    name: "WarehouseLayout",

    data() {
        return {
            tab: null,
            sidebar: false,
        };
    },

    watch: {
        tabs: {
            immediate: true,
            handler() {
                let activeTab = _.findIndex(this.tabs, { active: true });

                if (!activeTab) {
                    return;
                }

                this.tab = activeTab;
            },
        },
    },

    mounted() {
        this.$once(
            "hook:destroyed",
            this.$inertia.on("navigate", (event) => {
                this.sidebar = false;
            })
        );
    },

    computed: {
        tabs() {
            return [
                {
                    name: "Dashboard",
                    route: "warehouse.index",
                    active: this.$page.props.route === "warehouse.index",
                },
                {
                    name: "Logistics",
                    route: "warehouse.logistics",
                    active: this.$page.props.route === "warehouse.logistics",
                },
                {
                    name: "Asset Search",
                    route: "warehouse.asset-search",
                    active: this.$page.props.route.includes("asset-search"),
                },
                {
                    name: "Add Stock",
                    route: "warehouse.add-stock",
                    active: this.$page.props.route.includes("add-stock"),
                },
                {
                    name: "Stock Transfer",
                    route: "warehouse.stock-transfer",
                    active: this.$page.props.route.includes("stock-transfer"),
                },
            ];
        },
    },
};
</script>

<style scoped>
/*.tabs :before {*/
/*    background-color: transparent;*/
/*}*/
</style>
