<template>
    <v-sheet width="100vw" height="100vh" class="black white--text">
        <head-title title="Warehouse Login" />
        <v-row class="justify-center mt-0">
            <v-col cols="12" md="6">
                <v-sheet elevation="4" class="pa-md-10 pa-4 pb-10" rounded>
                    <v-img
                        max-height="150"
                        contain
                        :src="$page.props.files.pytchLogo"
                    ></v-img>

                    <h2 class="text-center">Warehouse Login</h2>

                    <v-alert
                        border="right"
                        v-if="Object.keys($page.props.errors).length"
                        colored-border
                        type="error"
                        elevation="2"
                        class="mt-5 black white--text"
                    >
                        <ul>
                            <li
                                v-for="(error, index) in $page.props.errors"
                                :key="index"
                            >
                                {{ error }}
                            </li>
                        </ul>
                    </v-alert>

                    <form @submit.prevent="login" novalidate class="mt-4">
                        <validation-observer ref="form">
                            <validation-provider
                                v-slot="{ errors }"
                                name="Username"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="form.username"
                                    name="Username"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    label="Username"
                                    class="required"
                                    required
                                >
                                </v-text-field>
                            </validation-provider>

                            <validation-provider
                                v-slot="{ errors }"
                                name="Password"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="form.password"
                                    type="password"
                                    name="Password"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    label="Password"
                                    class="required"
                                    required
                                >
                                </v-text-field>
                            </validation-provider>
                        </validation-observer>

                        <v-btn
                            :loading="form.processing"
                            :disabled="!form.isDirty"
                            color="black white--text"
                            type="submit"
                        >
                            Login
                        </v-btn>
                    </form>
                </v-sheet>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import HeadTitle from "../../../Comps/Master/HeadTitle";
import WarehouseController from "../../../Comps/Master/WarehouseController";

export default {
    name: "Login",
    components: { HeadTitle },
    layout: WarehouseController,

    data() {
        return {
            form: this.$inertia.form({
                username: null,
                password: null,
            }),
        };
    },

    methods: {
        login() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                this.form.post(this.route("warehouse.login"));
            });
        },
    },
};
</script>

<style scoped></style>
