<template>
    <v-app>
        <error-controller></error-controller>
        <notification-controller></notification-controller>
        <slot></slot>
    </v-app>
</template>

<script>
import ErrorController from "./ErrorController";
import NotificationController from "./NotificationController";
export default {
    name: "PublicController",
    components: { NotificationController, ErrorController },
};
</script>

<style scoped></style>
