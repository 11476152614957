<template>
    <v-container>
        <header-name>
            Timesheet for {{ $page.props.timesheet.user.name }} - month starting
            {{ momentFormatDateYear($page.props.timesheet.start_date) }}
        </header-name>

        <breadcrumbs :items="breadItems" />

        <timesheet-show />
    </v-container>
</template>

<script>
import AdminController from "../../../../Mixins/AdminController.js";
import HeaderName from "../../../../Comps/General/HeaderName.vue";
import Breadcrumbs from "../../../../Comps/General/Breadcrumbs.vue";
import TimesheetShow from "../../../../Comps/Staff/Timesheets/TimesheetShow.vue";

export default {
    name: "Timesheet",
    components: { TimesheetShow, Breadcrumbs, HeaderName },
    mixins: [AdminController],
    computed: {
        breadItems() {
            return [
                {
                    text: "Timesheets",
                    disabled: false,
                    link: this.route("staff.admin.timesheets.index"),
                },
                {
                    text: `${
                        this.$page.props.timesheet.user.name
                    } ${this.momentFormatDateYear(
                        this.$page.props.timesheet.start_date
                    )}`,
                    disabled: true,
                },
            ];
        },
    },
};
</script>

<style scoped></style>
