<template>
    <v-app>
        <v-main>
            <head-title
                do-not-append
                title="New Profile | PYTCH Freelancer Portal"
            />
            <v-container fluid>
                <v-img
                    contain
                    max-height="100"
                    :src="$page.props.files.pytchLogoWhite"
                    alt="PYTCH Logo"
                />
                <information-form
                    v-model="form"
                    ref="formComp"
                    @submit="submitForm"
                    new-profile
                />
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import HeadTitle from "../../Comps/Master/HeadTitle";
import InformationForm from "./Partials/InformationForm";
import LocalStorage from "../../Mixins/LocalStorage";
export default {
    name: "NewProfile",
    layout: null,
    components: {
        InformationForm,
        HeadTitle,
    },
    mixins: [LocalStorage],

    data() {
        return {
            form: this.$inertia.form({
                name: null,
                email: this.$page.props.email,
                tel_number: null,
                address: {
                    street: null,
                    city: null,
                    county: null,
                    postcode: null,
                },
                about: null,
                photo: {},
                skills: [],
                files: [],
            }),
        };
    },

    created() {
        this.$vuetify.theme.dark = true;
    },

    methods: {
        submitForm() {
            this.$refs.formComp.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                if (!this.form.photo) {
                    this.$toast.error("Please upload a photo", {
                        duration: 5000,
                    });
                    return;
                }

                this.form.post(this.route("freelancer.store-profile"), {
                    onSuccess: () => {
                        this.localStorageSet("freelancer-new-profile", true);
                    },
                });
            });
        },
    },
};
</script>

<style scoped></style>
