<template>
    <v-alert type="error" v-if="hasErrors">
        <ul>
            <li v-for="error in errorsProp" v-html="error[0]"></li>
        </ul>
    </v-alert>
</template>

<script>
export default {
    name: "ErrorsBanner",

    props: ["errorsProp"],

    computed: {
        hasErrors() {
            return !_.isEmpty(this.errorsProp);
        },
    },
};
</script>

<style lang="scss" scoped>
.alert {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
</style>
