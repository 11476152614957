<template>
    <modal-template :key="show" v-model="show">
        <div class="text-h6 text--secondary mb-2 mt-1">
            <span v-if="!isEditing">Request Holiday</span>
            <span v-else>Edit holiday</span>
        </div>

        <form novalidate @submit.prevent="submit" class="mt-4">
            <validation-observer ref="form">
                <v-row>
                    <v-col cols="12" md="6">
                        <date-time-picker
                            v-model="dateRange"
                            is-range
                            mode="date"
                            label="Date Range"
                            name="Date Range"
                            required
                        />
                    </v-col>
                    <v-col cols="12" md="6" v-if="!holiday.evening">
                        <validation-provider
                            v-slot="{ errors }"
                            name="Type"
                            rules="required"
                        >
                            <v-select
                                v-model="holiday.type"
                                :items="types"
                                outlined
                                dense
                                label="Type"
                                required
                            />
                        </validation-provider>
                    </v-col>
                </v-row>

                <div v-if="datesSame" class="d-flex align-center flex-wrap">
                    <v-switch
                        label="Evening?"
                        v-model="holiday.evening"
                        class="mr-3"
                    />
                    <template v-if="!holiday.evening">
                        <v-switch
                            label="Half Day?"
                            v-model="holiday.half_day"
                        />
                        <v-btn-toggle
                            v-if="holiday.half_day"
                            class="ml-3 white--text"
                            rounded
                            v-model="holiday.morning"
                            mandatory
                        >
                            <v-btn
                                :color="holiday.morning ? 'primary' : 'normal'"
                                :value="true"
                                small
                            >
                                Morning
                            </v-btn>

                            <v-btn
                                :color="!holiday.morning ? 'primary' : 'normal'"
                                :value="false"
                                small
                            >
                                Afternoon
                            </v-btn>
                        </v-btn-toggle>
                    </template>
                </div>

                <div
                    v-if="datesSame && holiday.evening"
                    class="subtitle-1 caption mb-4"
                >
                    This allows you to request the evening off, it does not<br />
                    count towards your holiday allowance and you cannot claim<br />
                    holiday pay for it.
                </div>

                <v-row>
                    <v-col>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Notes"
                            rules="max:2000"
                        >
                            <v-textarea
                                outlined
                                label="Notes"
                                persistent-hint
                                :error-messages="errors"
                                hint="Notes to help with the decision of your request, only you and HR can read these"
                                v-model="holiday.notes"
                            />
                        </validation-provider>
                    </v-col>
                </v-row>

                <v-row v-if="taskClash.length">
                    <v-col>
                        <v-alert
                            border="bottom"
                            colored-border
                            type="warning"
                            elevation="2"
                        >
                            <div class="text-body-1 mb-1">
                                These dates clash with penciled or active
                                logistic tasks:
                            </div>
                            <ul class="text-subtitle-2">
                                <li v-for="task in taskClash" :key="task.id">
                                    <div>
                                        {{ task.name }} -
                                        {{ momentFormatDate(task.date) }}
                                    </div>
                                </li>
                            </ul>
                            <div class="text-body-1 mt-4">
                                Be aware this may impact your chances of getting
                                approved
                            </div>
                        </v-alert>
                    </v-col>
                </v-row>

                <v-btn
                    color="success"
                    :loading="holiday.processing"
                    :disabled="!holiday.isDirty"
                    class="mt-6"
                    type="submit"
                >
                    {{ isEditing ? "Update" : "Submit" }} Request
                </v-btn>
            </validation-observer>
        </form>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../Master/ModalTemplate";
import DateTimePicker from "../../Master/DateTimePicker";
import { extend } from "vee-validate";
import { required, max } from "vee-validate/dist/rules";

extend("required", required);
extend("max", max);

export default {
    name: "HolidayCreateModal",
    components: { DateTimePicker, ModalTemplate },

    data() {
        return {
            show: false,
            holiday: this.$inertia.form({}),
            dateRange: null,
            taskClash: [],
            types: ["Paid", "Unpaid", "Day in Lieu"],
        };
    },

    watch: {
        dateRange() {
            this.calcDateRange();
        },
        datesSame: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.holiday.half_day = false;
                }
            },
        },
    },

    methods: {
        create(startDate = null) {
            this.holiday = this.$inertia.form({
                notes: null,
                start_date: null,
                end_date: null,
                half_day: false,
                morning: false,
                evening: false,
                type: "Paid",
            });
            this.show = true;
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
            this.dateRange = null;
            if (startDate) {
                this.dateRange = {
                    start: startDate,
                    end: startDate,
                };
            }
        },

        edit(holiday) {
            this.dateRange = {
                start: holiday.start_date,
                end: holiday.end_date,
            };
            this.holiday = this.$inertia.form(holiday);
            this.show = true;
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },

        submit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    this.$toast.error("Please check for errors", {
                        duration: 5000,
                    });
                    return;
                }

                let type = "post";
                let url = this.route("staff.holidays.store");

                if (this.isEditing) {
                    type = "put";
                    url = this.route("staff.holidays.update", this.holiday);
                }

                this.holiday[type](url, {
                    onSuccess: () => {
                        this.holiday.reset();
                        this.$emit("submitted");
                        this.show = false;
                    },
                });
            });
        },

        checkTasks() {
            if (!this.dateRange) {
                return;
            }

            this.$http
                .get(
                    this.route("staff.holidays.checkTasks", {
                        startDate: this.holiday.start_date,
                        endDate: this.holiday.end_date,
                    })
                )
                .then((response) => {
                    this.taskClash = response.data;
                });
        },

        calcDateRange() {
            if (!this.dateRange) {
                return;
            }

            this.holiday.start_date = this.moment(this.dateRange.start).format(
                "YYYY-MM-DD"
            );
            this.holiday.end_date = this.moment(this.dateRange.end).format(
                "YYYY-MM-DD"
            );
            this.checkTasks();
        },
    },

    computed: {
        isEditing() {
            return !!this.holiday.id;
        },

        datesSame() {
            if (!this.dateRange) {
                return;
            }

            return this.holiday.start_date === this.holiday.end_date;
        },
    },
};
</script>

<style scoped></style>
